import React, { useContext, useState } from "react";
import { AuthContext } from "../authentication/authenticationContext";
import Spinner from "../components/spinner";
import brand from "../images/Pachira Full Logo Transparent Black.png";
import { useLocation, useNavigate, useParams } from "react-router";
import APIService from "../components/api";

const ResetPassword = () => {
  const { resetPassword } = APIService();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading } = useContext(AuthContext);
  const [isValidatingLink, setIsValidatingLink] = useState(false);
  const { userID, requestID } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get("success") === "true";
  const invalid = queryParams.get("invalid") === "true";
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordStyle, setConfirmPasswordStyle] = useState("");
  const [error, setError] = useState("");
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    specialChar: false,
  });

  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const setPasswordConfirmed = (confirmed) => {
    if (confirmed) {
      setShowConfirmMessage(false);
      setConfirmPasswordStyle("confirmInputGreen");
    } else {
      setShowConfirmMessage(true);
      setConfirmPasswordStyle("confirmInputRed");
    }
  };

  const renderValidationIcon = (isValid) => {
    return isValid ? (
      <span style={{ color: "green" }}>✓</span>
    ) : (
      <span style={{ color: "red" }}>✗</span>
    );
  };

  const handleReset = async (event) => {
    event.preventDefault();
    if (allRequirementsMet && !isValidatingLink) {
      try {
        setIsValidatingLink(true);
        const result = await resetPassword(userID, requestID, password);

        setIsValidatingLink(false);

        if (result.invalid) {
          navigate(
            `/reset-password/${encodeURI(userID)}/${encodeURI(
              requestID
            )}?invalid=true`
          );
          return;
        }
        if (result.response?.status == 422) {
          setError(result.message.message);
        }
        if (result.success) {
          navigate(
            `/reset-password/${encodeURI(userID)}/${encodeURI(
              requestID
            )}?success=true`
          );
        }
      } catch (error) {
        console.error(error);
        setError("Unable to process request. Please try again later.");
      }
    }
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setPasswordRequirements({
      minLength: value.length >= 8,
      upperCase: /[A-Z]/.test(value),
      lowerCase: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      specialChar: /[\W_]/.test(value),
    });
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    if (value === password) {
      setPasswordConfirmed(true);
    } else {
      setPasswordConfirmed(false);
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const passwordRequirementsMet =
    Object.values(passwordRequirements).every(Boolean);
  const allRequirementsMet =
    passwordRequirementsMet && confirmPassword === password;

  if (invalid) {
    return (
      <div style={styles.container}>
        <div style={styles.loginForm}>
          <img src={brand} alt="Pachira Logo" style={styles.logo} />
          <h2 style={styles.title}>Link expired</h2>
          <p>
            This link has expired. Please check your email for the most recent
            link, or request another reset by clicking Forgot Password.
          </p>

          <button style={styles.button} onClick={handleForgotPassword}>
            Forgot Password
          </button>
          <button style={styles.button} onClick={handleLogin}>
            Return To Log In
          </button>
          {isLoading && <Spinner />}
        </div>
      </div>
    );
  }

  if (success) {
    return (
      <div style={styles.container}>
        <div style={styles.loginForm}>
          <img src={brand} alt="Pachira Logo" style={styles.logo} />
          <h2 style={styles.title}>Password successfully reset</h2>
          <p>Return to log in to sign in.</p>

          <button style={styles.button} onClick={handleLogin}>
            Return To Log In
          </button>
          {isLoading && <Spinner />}
        </div>
      </div>
    );
  } else {
    return (
      <div style={styles.container}>
        <div style={styles.loginForm}>
          <img src={brand} alt="Pachira Logo" style={styles.logo} />
          <h2 style={styles.title}>Reset your password</h2>
          <form onSubmit={handleReset}>
            <label style={styles.label}>New Password:</label>
            <input
              style={styles.input}
              type="password"
              value={password}
              onChange={handlePasswordChange}
              onFocus={() => setShowPasswordRequirements(true)}
              onBlur={() => setShowPasswordRequirements(password.length > 0)}
              placeholder="Create a password"
            />
            {showPasswordRequirements && (
              <div style={styles.passwordRequirements}>
                <p
                  style={{
                    color: passwordRequirements.minLength ? "green" : "red",
                  }}
                >
                  {renderValidationIcon(passwordRequirements.minLength)} Minimum
                  8 characters
                </p>
                <p
                  style={{
                    color: passwordRequirements.upperCase ? "green" : "red",
                  }}
                >
                  {renderValidationIcon(passwordRequirements.upperCase)} At
                  least one uppercase letter
                </p>
                <p
                  style={{
                    color: passwordRequirements.lowerCase ? "green" : "red",
                  }}
                >
                  {renderValidationIcon(passwordRequirements.lowerCase)} At
                  least one lowercase letter
                </p>
                <p
                  style={{
                    color: passwordRequirements.number ? "green" : "red",
                  }}
                >
                  {renderValidationIcon(passwordRequirements.number)} At least
                  one number
                </p>
                <p
                  style={{
                    color: passwordRequirements.specialChar ? "green" : "red",
                  }}
                >
                  {renderValidationIcon(passwordRequirements.specialChar)} At
                  least one special character
                </p>
              </div>
            )}
            <label style={styles.label}>Confirm Password:</label>
            <input
              style={
                confirmPasswordStyle === ""
                  ? styles.input
                  : styles[confirmPasswordStyle]
              }
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              onFocus={() => setPasswordConfirmed(confirmPassword === password)}
              onBlur={() => setShowConfirmMessage(false)}
              placeholder="Re-type your password"
              disabled={!passwordRequirementsMet}
            />
            {showConfirmMessage && (
              <div style={styles.passwordRequirements}>
                <p style={{ color: "red" }}>Passwords don't match</p>
              </div>
            )}
            <button
              type="submit"
              style={
                allRequirementsMet
                  ? styles.button
                  : { ...styles.button, backgroundColor: "grey" }
              }
              disabled={!allRequirementsMet || isValidatingLink}
            >
              Reset
            </button>
          </form>
          {error && <p style={styles.error}>{error}</p>}
          {(isLoading || isValidatingLink) && <Spinner />}{" "}
        </div>
      </div>
    );
  }
};

const styles = {
  label: {
    display: "block",
    textAlign: "left",
    color: "#333",
    fontSize: "16px",
    marginBottom: "5px",
  },
  passwordRequirements: {
    textAlign: "left",
    marginBottom: "10px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f0f0f0",
  },
  loginForm: {
    maxWidth: "400px",
    width: "100%",
    padding: "40px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  logo: {
    maxWidth: "300px",
    marginBottom: "20px",
  },
  title: {
    color: "#001e80",
    marginBottom: "20px",
  },
  input: {
    width: "calc(100% - 22px)",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    fontSize: "16px",
  },
  confirmInputRed: {
    width: "calc(100% - 22px)",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid red",
    fontSize: "16px",
  },
  confirmInputGreen: {
    width: "calc(100% - 22px)",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid green",
    fontSize: "16px",
  },
  button: {
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    backgroundColor: "#001e80",
    color: "white",
    border: "none",
    borderRadius: "5px",
    fontSize: "18px",
    cursor: "pointer",
  },
  link: {
    display: "block",
    color: "#001e80",
    textDecoration: "none",
    marginBottom: "10px",
    fontSize: "16px",
  },
  error: {
    color: "red",
    marginTop: "10px",
  },
};

export default ResetPassword;
