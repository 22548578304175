import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { formatTableDate } from "../shared/utils/helpers";
import { CurrencyContext } from "../context/currencyContext";

const MobileTransactionRow = ({ rowData, actionButtons }) => {
  const { formatCurrency } = useContext(CurrencyContext);

  const TransactionDetail = ({ label, detail }) => {
    return (
      <Typography variant="subtitle2" fontSize={11}>
        <b>{label}:</b> {detail}
      </Typography>
    );
  };

  return (
    <Accordion sx={{ margin: 0 }}>
      <AccordionSummary
        sx={{ flexDirection: "row", justifyContent: "space-between" }}
      >
        <Box
          sx={{
            width: "100%",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Stack>
            <Typography variant="h6" fontSize={14}>
              {rowData.memo}
            </Typography>
            <Typography variant="subtitle2" fontSize={11}>
              {formatTableDate(rowData.date)}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ color: rowData.amount > 0 ? "green" : "red" }}>
              {formatCurrency(rowData.amount)}
            </Typography>
          </Stack>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={-2}
        >
          <Stack>
            <TransactionDetail
              label="Merchant"
              detail={rowData.merchant_name}
            />
            <TransactionDetail
              label="Bank"
              detail={rowData.account_display_name}
            />
            <TransactionDetail
              label="Category"
              detail={rowData.category_name || "None"}
            />
          </Stack>
          <Stack>{actionButtons}</Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default MobileTransactionRow;
