import React, { useContext, useEffect, useMemo, useState } from "react";
import "../App.css";
import APIService from "./api";
import { AccountsContext } from "../context/AccountsContext";
import { CurrencyContext } from "../context/currencyContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faCompress } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  ButtonGroup,
  Chip,
  Popover,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
const WidgetFinancialOverview = () => {
  const theme = useTheme();
  const { fetchTransactionsContext, fetchCategoriesContext } = APIService();
  const { state } = useContext(AccountsContext);
  const { formatCurrency } = useContext(CurrencyContext);
  const [expandedSections, setExpandedSections] = useState({
    income: false,
    expenses: false,
  });
  const [expandedCategories, setExpandedCategories] = useState({});
  const [isDetailedReportOpen, setIsDetailedReportOpen] = useState(false);
  const [timeRange, setTimeRange] = useState("thisMonth");

  useEffect(() => {
    if (!state.transactionsLoaded && !state.transactionsLoading) {
      fetchTransactionsContext();
    }
    if (!state.categoriesLoaded && !state.categoriesLoading) {
      fetchCategoriesContext();
    }
  }, [
    fetchTransactionsContext,
    state.transactionsLoaded,
    state.transactionsLoading,
    fetchCategoriesContext,
    state.categoriesLoaded,
    state.categoriesLoading,
  ]);

  const calculateDateRanges = () => {
    const currentDate = new Date();
    let startMonth, startYear, rangeLength;

    switch (timeRange) {
      case "last12Months":
        startMonth = currentDate.getMonth() - 11;
        startYear = currentDate.getFullYear();
        rangeLength = 12;
        break;
      case "last6Months":
        startMonth = currentDate.getMonth() - 5;
        startYear = currentDate.getFullYear();
        rangeLength = 6;
        break;
      case "last3Months":
        startMonth = currentDate.getMonth() - 2;
        startYear = currentDate.getFullYear();
        rangeLength = 3;
        break;
      case "yearToDate":
        startMonth = 0;
        startYear = currentDate.getFullYear();
        rangeLength = currentDate.getMonth() + 1;
        break;
      case "lastMonth":
        startMonth = currentDate.getMonth() - 1;
        startYear = currentDate.getFullYear();
        rangeLength = 1;
        break;
      case "thisMonth":
      default:
        startMonth = currentDate.getMonth();
        startYear = currentDate.getFullYear();
        rangeLength = 1;
    }

    const dateRanges = [];
    for (let i = 0; i < rangeLength; i++) {
      let month = startMonth + i;
      let year = startYear;
      if (month < 0) {
        month += 12;
        year -= 1;
      } else if (month >= 12) {
        month -= 12;
        year += 1;
      }
      dateRanges.push({ month, year });
    }
    return dateRanges;
  };

  const transactionsByCategory = useMemo(() => {
    const categories = { income: {}, expenses: {} };
    const dateRanges = calculateDateRanges();

    state.categories.forEach((category) => {
      const type =
        category.type === "EXP"
          ? "expenses"
          : category.type === "INC"
          ? "income"
          : null;
      if (type && !category.parent_id) {
        categories[type][category.category_name] = {
          name: category.category_name,
          monthlyAmounts: Array(dateRanges.length).fill(0),
          children: {},
        };
      }
    });

    state.transactions.forEach((transaction) => {
      const transactionDate = new Date(transaction.date);
      const transactionMonth = transactionDate.getMonth();
      const transactionYear = transactionDate.getFullYear();
      const monthIndex = dateRanges.findIndex(
        (date) =>
          date.month === transactionMonth && date.year === transactionYear
      );
      if (monthIndex === -1) return;

      const category = state.categories.find(
        (cat) => cat.category_id === transaction.pachira_category_id
      );

      if (!category || category.type === "TRA") return;

      const type = category.type === "EXP" ? "expenses" : "income";
      if (!category.parent_id) return; // Skip parent categories

      const parentCategory = state.categories.find(
        (cat) => cat.category_id === category.parent_id
      );
      if (!categories[type][parentCategory.category_name]) {
        categories[type][parentCategory.category_name] = {
          name: parentCategory.category_name,
          monthlyAmounts: Array(dateRanges.length).fill(0),
          children: {},
        };
      }

      categories[type][parentCategory.category_name].monthlyAmounts[
        monthIndex
      ] += parseFloat(transaction.amount);

      if (
        !categories[type][parentCategory.category_name].children[
          category.category_name
        ]
      ) {
        categories[type][parentCategory.category_name].children[
          category.category_name
        ] = Array(dateRanges.length).fill(0);
      }

      categories[type][parentCategory.category_name].children[
        category.category_name
      ][monthIndex] += parseFloat(transaction.amount);
    });

    return categories;
  }, [state.transactions, state.categories, timeRange]);

  const sortedCategories = (type) => {
    return Object.values(transactionsByCategory[type]).sort((a, b) =>
      type === "expenses"
        ? a.monthlyAmounts.reduce((sum, val) => sum + val, 0) -
          b.monthlyAmounts.reduce((sum, val) => sum + val, 0)
        : b.monthlyAmounts.reduce((sum, val) => sum + val, 0) -
          a.monthlyAmounts.reduce((sum, val) => sum + val, 0)
    );
  };

  const handleSectionToggle = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleCategoryToggle = (parent) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [parent]: !prev[parent],
    }));
  };

  const totalIncome = sortedCategories("income").reduce(
    (acc, category) =>
      acc + category.monthlyAmounts.reduce((sum, val) => sum + val, 0),
    0
  );

  const totalExpenses = sortedCategories("expenses").reduce(
    (acc, category) =>
      acc + category.monthlyAmounts.reduce((sum, val) => sum + val, 0),
    0
  );

  const savings = totalIncome + totalExpenses;
  const savingsRate = totalIncome ? (savings / totalIncome) * 100 : 0;

  const constrainPercentage = (value) => Math.min(Math.max(value, 0), 100);

  const renderTable = () => {
    const incomeCategories = sortedCategories("income");
    const expenseCategories = sortedCategories("expenses");
    const dateRanges = calculateDateRanges();

    return (
      <table className="customDetailedTable">
        <thead>
          <tr>
            <th>Category</th>
            {dateRanges.map((date, index) => (
              <th key={index}>
                {date.month + 1}/{date.year}
              </th>
            ))}
            <th>Total</th>
            <th>% of Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              colSpan={dateRanges.length + 3}
              className="customSectionHeader"
              onClick={() => handleSectionToggle("income")}
            >
              {expandedSections.income ? "▼" : "►"} Income
            </td>
          </tr>
          {expandedSections.income && (
            <>
              {incomeCategories.map((category) => (
                <React.Fragment key={category.name}>
                  <tr
                    className="customParentCategory"
                    onClick={() => handleCategoryToggle(category.name)}
                  >
                    <td>
                      {expandedCategories[category.name] ? "▼" : "►"}{" "}
                      {category.name}
                    </td>
                    {category.monthlyAmounts.map((amount, index) => (
                      <td key={index}>{formatCurrency(amount)}</td>
                    ))}
                    <td>
                      {formatCurrency(
                        category.monthlyAmounts.reduce(
                          (sum, val) => sum + val,
                          0
                        )
                      )}
                    </td>
                    <td>
                      <div className="progressContainerFinancial">
                        <div
                          className="progressBarFinancial"
                          style={{
                            width: `${constrainPercentage(
                              (category.monthlyAmounts.reduce(
                                (sum, val) => sum + val,
                                0
                              ) /
                                totalIncome) *
                                100
                            )}%`,
                            backgroundColor: "green",
                          }}
                        ></div>
                        <span className="percentageText">
                          {(
                            (category.monthlyAmounts.reduce(
                              (sum, val) => sum + val,
                              0
                            ) /
                              totalIncome) *
                            100
                          ).toFixed(2)}
                          %
                        </span>
                      </div>
                    </td>
                  </tr>
                  {expandedCategories[category.name] &&
                    Object.entries(category.children).map(
                      ([childName, childAmounts]) => (
                        <tr key={childName} className="customChildCategory">
                          <td>-- {childName}</td>
                          {childAmounts.map((amount, index) => (
                            <td key={index}>{formatCurrency(amount)}</td>
                          ))}
                          <td>
                            {formatCurrency(
                              childAmounts.reduce((sum, val) => sum + val, 0)
                            )}
                          </td>
                          <td>
                            <div className="progressContainerFinancial">
                              <div
                                className="progressBarFinancial"
                                style={{
                                  width: `${constrainPercentage(
                                    (childAmounts.reduce(
                                      (sum, val) => sum + val,
                                      0
                                    ) /
                                      totalIncome) *
                                      100
                                  )}%`,
                                  backgroundColor: "green",
                                }}
                              ></div>
                              <span className="percentageText">
                                {(
                                  (childAmounts.reduce(
                                    (sum, val) => sum + val,
                                    0
                                  ) /
                                    totalIncome) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                </React.Fragment>
              ))}
            </>
          )}
          <tr className="boldRow">
            <td>Total Income</td>
            {dateRanges.map((_, index) => (
              <td key={index}>
                {formatCurrency(
                  incomeCategories.reduce(
                    (sum, category) =>
                      sum + (category.monthlyAmounts[index] || 0),
                    0
                  )
                )}
              </td>
            ))}
            <td>{formatCurrency(totalIncome)}</td>
            <td>100%</td>
          </tr>
          <tr>
            <td
              colSpan={dateRanges.length + 3}
              className="customSectionHeader"
              onClick={() => handleSectionToggle("expenses")}
            >
              {expandedSections.expenses ? "▼" : "►"} Expenses
            </td>
          </tr>
          {expandedSections.expenses && (
            <>
              {expenseCategories.map((category) => (
                <React.Fragment key={category.name}>
                  <tr
                    className="customParentCategory"
                    onClick={() => handleCategoryToggle(category.name)}
                  >
                    <td>
                      {expandedCategories[category.name] ? "▼" : "►"}{" "}
                      {category.name}
                    </td>
                    {category.monthlyAmounts.map((amount, index) => (
                      <td key={index}>{formatCurrency(amount)}</td>
                    ))}
                    <td>
                      {formatCurrency(
                        category.monthlyAmounts.reduce(
                          (sum, val) => sum + val,
                          0
                        )
                      )}
                    </td>
                    <td>
                      <div className="progressContainerFinancial">
                        <div
                          className="progressBarFinancial"
                          style={{
                            width: `${constrainPercentage(
                              (category.monthlyAmounts.reduce(
                                (sum, val) => sum + val,
                                0
                              ) /
                                -totalIncome) *
                                100
                            )}%`,
                            backgroundColor: "red",
                          }}
                        ></div>
                        <span className="percentageText">
                          {(
                            (category.monthlyAmounts.reduce(
                              (sum, val) => sum + val,
                              0
                            ) /
                              -totalIncome) *
                            100
                          ).toFixed(2)}
                          %
                        </span>
                      </div>
                    </td>
                  </tr>
                  {expandedCategories[category.name] &&
                    Object.entries(category.children).map(
                      ([childName, childAmounts]) => (
                        <tr key={childName} className="customChildCategory">
                          <td>-- {childName}</td>
                          {childAmounts.map((amount, index) => (
                            <td key={index}>{formatCurrency(amount)}</td>
                          ))}
                          <td>
                            {formatCurrency(
                              childAmounts.reduce((sum, val) => sum + val, 0)
                            )}
                          </td>
                          <td>
                            <div className="progressContainerFinancial">
                              <div
                                className="progressBarFinancial"
                                style={{
                                  width: `${constrainPercentage(
                                    (childAmounts.reduce(
                                      (sum, val) => sum + val,
                                      0
                                    ) /
                                      -totalIncome) *
                                      100
                                  )}%`,
                                  backgroundColor: "red",
                                }}
                              ></div>
                              <span className="percentageText">
                                {(
                                  (childAmounts.reduce(
                                    (sum, val) => sum + val,
                                    0
                                  ) /
                                    -totalIncome) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                </React.Fragment>
              ))}
            </>
          )}
          <tr className="boldRow">
            <td>Total Expenses</td>
            {dateRanges.map((_, index) => (
              <td key={index}>
                {formatCurrency(
                  expenseCategories.reduce(
                    (sum, category) =>
                      sum + (category.monthlyAmounts[index] || 0),
                    0
                  )
                )}
              </td>
            ))}
            <td>{formatCurrency(totalExpenses)}</td>
            <td>100%</td>
          </tr>
          <tr
            className="customNetIncomeRow"
            style={{
              color: savings >= 0 ? "green" : "red",
            }}
          >
            <td>Net Income</td>
            {dateRanges.map((_, index) => (
              <td key={index}>
                {formatCurrency(
                  incomeCategories.reduce(
                    (sum, category) =>
                      sum + (category.monthlyAmounts[index] || 0),
                    0
                  ) -
                    expenseCategories.reduce(
                      (sum, category) =>
                        sum + (category.monthlyAmounts[index] || 0),
                      0
                    )
                )}
              </td>
            ))}
            <td>{formatCurrency(savings)}</td>
            <td>{constrainPercentage(savingsRate).toFixed(2)}%</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderSimpleView = () => {
    return (
      <div className="simpleOverviewCard">
        <h4>Financial Overview</h4>
        <div className="simpleOverviewSection">
          <h5>Total Income</h5>
          <p className="simpleOverviewAmount">{formatCurrency(totalIncome)}</p>
        </div>
        <div className="simpleOverviewSection">
          <h5>Total Expenses</h5>
          <p className="simpleOverviewAmount">
            {formatCurrency(totalExpenses)}
          </p>
        </div>
        <div className="simpleOverviewSection">
          <h5>Net Income</h5>
          <p
            className={`simpleOverviewAmount ${
              savings >= 0 ? "positive" : "negative"
            }`}
          >
            {formatCurrency(savings)}
          </p>
          <div className="progressContainerFinancial">
            <div
              className="progressBarFinancial"
              style={{
                width: `${constrainPercentage(savingsRate).toFixed(2)}%`,
                backgroundColor: savings >= 0 ? "green" : "red",
              }}
            ></div>
          </div>
          <p className="savingsRate">
            {savingsRate.toFixed(2)}% of your income saved
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="widgetContainer">
      <div className="header">
        <h3>Financial Overview</h3>
      </div>
      <div className="controlsContainer">
        <div className="timeRangeSelector">
          <select
            className="dropdown"
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value)}
          >
            <option value="last12Months">Last 12 Months</option>
            <option value="last6Months">Last 6 Months</option>
            <option value="last3Months">Last 3 Months</option>
            <option value="yearToDate">Year to Date</option>
            <option value="lastMonth">Last Month</option>
            <option value="thisMonth">This Month</option>
          </select>
        </div>
        <div className="iconCard">
          <Tooltip
            title={
              isDetailedReportOpen
                ? "View Simplified Report"
                : "View Detailed Report"
            }
          >
            <FontAwesomeIcon
              icon={isDetailedReportOpen ? faCompress : faExpand}
              color="#001e80"
              onClick={() => setIsDetailedReportOpen(!isDetailedReportOpen)}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        </div>
      </div>
      {isDetailedReportOpen ? (
        <div className="detailedSection">{renderTable()}</div>
      ) : (
        renderSimpleView()
      )}
    </div>
  );
};

export default WidgetFinancialOverview;
