import React, { useEffect, useMemo, useContext, useState } from "react";
import "../App.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import ConnectButton from "../components/connectButton";
import WidgetAccounts from "../components/widgetAccounts";
import WidgetRecentTransactions from "../components/widgetRecentTransactions";
import WidgetTrends from "../components/widgetTrends";
import WidgetBudgetCheck from "../components/widgetBudgetCheck";
import WidgetSpendingVsIncome from "../components/widgetSpendingVsIncome";
import WidgetAd from "../components/widgetAd";
import WidgetMonthlyExpenses from "../components/widgetMonthlyExpenses";
import WidgetFinancialOverview from "../components/widgetFinancialOverview";
import APIService from "../components/api";
import { AppContext } from "../context/AppContext";
import { AuthContext } from "../authentication/authenticationContext";
import { AccountsContext } from "../context/AccountsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faMinusSquare,
} from "@fortawesome/free-regular-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, useTheme, IconButton } from "@mui/material";
const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = () => {
  const theme = useTheme();
  const { fetchUserContext, fetchPreferences, updatePreferences } =
    APIService();
  const { dispatch: authDispatch } = useContext(AuthContext);
  const { state: appState } = useContext(AppContext);
  const { state: accountsState } = useContext(AccountsContext);
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [localVisibility, setLocalVisibility] = useState([]);
  const [localLayouts, setLocalLayouts] = useState({});

  useEffect(() => {
    document.title = "Dashboard - Pachira";
    if (!appState.userLoaded && !appState.userLoading) {
      fetchUserContext();
    }
    if (
      !appState.dashboardPreferencesLoaded &&
      !appState.dashboardPreferencesLoading
    ) {
      fetchPreferences();
    }
  }, [
    fetchUserContext,
    appState.userLoaded,
    appState.userLoading,
    appState.dashboardPreferencesLoaded,
    appState.dashboardPreferencesLoading,
  ]);

  useEffect(() => {
    if (appState.dashboardPreferencesLoaded) {
      setLocalVisibility(appState.dashboardPreferences.visibility);
      setLocalLayouts(appState.dashboardPreferences.layouts);
    }
  }, [appState.dashboardPreferencesLoaded]);

  const widgets = useMemo(
    () => ({
      WidgetTrends: <WidgetTrends />,
      WidgetAccounts: <WidgetAccounts />,
      WidgetRecentTransactions: <WidgetRecentTransactions />,
      WidgetBudgetCheck: <WidgetBudgetCheck />,
      WidgetSpendingVsIncome: <WidgetSpendingVsIncome />,
      WidgetMonthlyExpenses: <WidgetMonthlyExpenses />,
      WidgetFinancialOverview: <WidgetFinancialOverview />,

      WidgetAd: <WidgetAd />,
    }),
    []
  );

  const saveLayoutToDatabase = async (layouts, configs) => {
    const serializedLayouts = Object.fromEntries(
      Object.entries(layouts).map(([breakpoint, layout]) => [
        breakpoint,
        layout.map(({ i, x, y, w, h }) => ({ i, x, y, w, h })),
      ])
    );

    const serializedConfigs = configs.map(({ key, isShown }) => ({
      key,
      isShown,
    }));

    if (appState.userLoaded && appState.dashboardPreferencesLoaded) {
      updatePreferences(serializedLayouts, serializedConfigs);
    }
  };

  const fetchAndSetPreferences = async () => {
    if (appState.userLoaded && appState.dashboardPreferencesLoaded) {
      const data = await fetchPreferences();

      const fetchedLayouts =
        (data?.layouts &&
          Object.keys(data.layouts).length > 0 &&
          data.layouts) ||
        appState.dashboardPreferences.layouts;
      const fetchedConfigs =
        (data?.visibility && data.visibility.length > 0 && data.visibility) ||
        appState.dashboardPreferences.visibility;

      const adDimensions = {
        lg: { minW: 3, minH: 8 },
        md: { minW: 3, minH: 8 },
        sm: { minW: 6, minH: 8 },
      };

      Object.keys(fetchedLayouts).forEach((breakpoint) => {
        fetchedLayouts[breakpoint].forEach((widget) => {
          if (widget.i !== "WidgetAd") {
            widget.minW = Math.max(2, widget.minW || 2);
            widget.minH = Math.max(2, widget.minH || 4);
            widget.w = Math.max(2, widget.w || 2);
            widget.h = Math.max(2, widget.h || 4);
          } else {
            widget.minW = adDimensions[breakpoint].minW;
            widget.minH = adDimensions[breakpoint].minH;
            widget.maxW = adDimensions[breakpoint].minW + 1;
            widget.maxH = adDimensions[breakpoint].minH + 1;
            widget.w = adDimensions[breakpoint].minW;
            widget.h = adDimensions[breakpoint].minH;
          }
        });
      });

      const updatedConfigs = fetchedConfigs.map((config) => ({
        ...config,
        isShown:
          config.key === "WidgetAd"
            ? !accountsState.subscriptions.some(
                (subscription) =>
                  subscription.donation === false &&
                  (subscription.is_active === true ||
                    new Date(subscription.next_payment_date) >=
                      new Date().setHours(0, 0, 0, 0))
              )
            : config.isShown,
      }));

      setLocalVisibility(updatedConfigs);
      setLocalLayouts(fetchedLayouts);
      saveLayoutToDatabase(fetchedLayouts, updatedConfigs);
    }
  };

  useEffect(() => {
    fetchAndSetPreferences();
  }, [
    authDispatch,
    appState.userLoaded,
    appState.dashboardPreferencesLoaded,
    accountsState.subscriptions,
  ]);

  const onLayoutChange = (currentLayout, allLayouts) => {
    if (Object.values(allLayouts).some((layout) => layout.length === 0)) return;

    const adDimensions = {
      lg: { minW: 3, minH: 8 },
      md: { minW: 3, minH: 8 },
      sm: { minW: 6, minH: 8 },
    };

    Object.keys(allLayouts).forEach((breakpoint) => {
      allLayouts[breakpoint].forEach((widget) => {
        if (widget.i !== "WidgetAd") {
          widget.minW = Math.max(2, widget.minW || 2);
          widget.minH = Math.max(6, widget.minH || 6);
          widget.w = Math.max(2, widget.w || 2);
          widget.h = Math.max(6, widget.h || 6);
        } else {
          widget.minW = adDimensions[breakpoint].minW;
          widget.minH = adDimensions[breakpoint].minH;
          widget.maxW = adDimensions[breakpoint].minW + 1;
          widget.maxH = adDimensions[breakpoint].minH + 1;
          widget.w = adDimensions[breakpoint].minW;
          widget.h = adDimensions[breakpoint].minH;
        }
      });
    });

    const updatedConfigs = localVisibility.map((config) => ({
      ...config,
      isShown:
        config.key === "WidgetAd"
          ? !accountsState.subscriptions.some(
              (subscription) =>
                subscription.donation === false &&
                (subscription.is_active === true ||
                  new Date(subscription.next_payment_date) >=
                    new Date().setHours(0, 0, 0, 0))
            )
          : allLayouts.lg.some((item) => item.i === config.key),
    }));

    setLocalLayouts(allLayouts);
    setLocalVisibility(updatedConfigs);
    saveLayoutToDatabase(allLayouts, updatedConfigs);
  };

  const toggleWidgetVisibility = (key) => {
    const updatedConfigs = localVisibility.map((config) =>
      config.key === key ? { ...config, isShown: !config.isShown } : config
    );

    const updatedLayouts = { ...localLayouts };
    Object.keys(updatedLayouts).forEach((breakpoint) => {
      updatedLayouts[breakpoint] = updatedLayouts[breakpoint].map((widget) =>
        widget.i === key ? { ...widget, isHidden: !widget.isHidden } : widget
      );
    });

    setLocalVisibility(updatedConfigs);
    setLocalLayouts(updatedLayouts);
    saveLayoutToDatabase(updatedLayouts, updatedConfigs);
  };

  const formatWidgetName = (widgetKey) => {
    return widgetKey
      .replace("Widget", "")
      .split(/(?=[A-Z])/)
      .join(" ");
  };

  const Greeting = ({ userName }) => {
    const getGreeting = () => {
      const hour = new Date().getHours();
      return hour < 12
        ? "Good Morning"
        : hour < 18
        ? "Good Afternoon"
        : "Good Evening";
    };

    const firstName = userName?.split(" ")[0];

    return (
      <h1>
        {getGreeting()}, {firstName}
      </h1>
    );
  };

  return (
    <div className="dashboard">
      <div className="header">
        <Greeting userName={appState.user.fullName} />
        <div className="iconCard">
          <ConnectButton onAccountsUpdated={() => {}} />
          <Tooltip title="Edit Tiles">
            <FontAwesomeIcon
              icon={faPenToSquare}
              color={theme.palette.primary.main}
              onClick={() => setShowControlPanel((prev) => !prev)}
              style={{ cursor: "pointer" }} // Adding cursor pointer for better UX
            />
          </Tooltip>
        </div>
      </div>

      {showControlPanel && (
        <div className="widgetControlPanel" style={{ paddingTop: "60px" }}>
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              width: "40px",
              height: "40px",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "10px",
            }}
            onClick={() => setShowControlPanel((prev) => !prev)}
          >
            <FontAwesomeIcon
              id="close-rules-modal-button"
              icon={faXmark}
              color={theme.palette.primary.main}
            />
          </IconButton>
          {localVisibility
            .filter((config) => config.key !== "WidgetAd")
            .map((config) => (
              <div key={config.key} className="controlPanelItem">
                <span>{formatWidgetName(config.key)}</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={config.isShown}
                    onChange={() => toggleWidgetVisibility(config.key)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            ))}
        </div>
      )}
      <ResponsiveGridLayout
        className="layout"
        layouts={localLayouts}
        breakpoints={{ lg: 1200, md: 600, sm: 550 }}
        cols={{ lg: 12, md: 8, sm: 6 }}
        rowHeight={30}
        onLayoutChange={onLayoutChange}
        draggableHandle=".widget-drag-handle"
      >
        {localVisibility.map(
          (config) =>
            config.isShown && (
              <div key={config.key}>
                <div className="widget-drag-handle">
                  <h4>Drag</h4>
                </div>
                {widgets[config.key]}
              </div>
            )
        )}
      </ResponsiveGridLayout>
    </div>
  );
};

export default Dashboard;
