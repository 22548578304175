import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Modal from "./modal";
import logo from "../images/TransparentLogo.png";
import SupportForm from "../components/supportForm";
import { AccountsContext } from "../context/AccountsContext";
import APIService from "../components/api";
import { format, parseISO } from "date-fns";
import releaseNotes from "../versioning/releaseNotes.json";
const MobileTopBar = () => {
  const { logoutUser, syncData } = APIService();
  const { dispatch: accountsDispatch } = useContext(AccountsContext);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showRefreshLabel, setShowRefreshLabel] = useState(false);
  const latestVersion = Object.keys(releaseNotes).pop();
  const [isReleaseNotesOpen, setIsReleaseNotesOpen] = useState(false);
  const openReleaseNotes = () => setIsReleaseNotesOpen(true);
  const closeReleaseNotes = () => setIsReleaseNotesOpen(false);
  const renderReleaseNotes = () => {
    return (
      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
        {Object.entries(releaseNotes)
          .sort((a, b) =>
            b[0].localeCompare(a[0], undefined, { numeric: true })
          )
          .map(([version, details]) => (
            <li key={version}>
              <div>
                <h3>Version {version}</h3>
                <p>
                  <strong>Release Date:</strong>{" "}
                  {format(parseISO(details.releaseDate), "MMMM dd, yyyy, p")}
                </p>
                <ul>
                  {details.notes.map((note, index) => (
                    <li key={index}>{note}</li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
      </ul>
    );
  };
  const handleRefreshClick = async () => {
    await syncData();
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRefreshLabel(true);
      const hideTimer = setTimeout(() => {
        setShowRefreshLabel(false);
      }, 30000);

      return () => clearTimeout(hideTimer);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const handleSupportClick = () => {
    setIsSupportModalOpen(true);
  };

  const handleLogout = async () => {
    await logoutUser();
    localStorage.clear();
  };

  const handleLogoClick = async () => {
    setIsLoading(true);
    setShowRefreshLabel(false);
    await handleRefreshClick(accountsDispatch);
    setIsLoading(false);
  };

  return (
    <div className="mobile-top-bar">
      <button onClick={handleSupportClick} className="top-bar-button">
        <FontAwesomeIcon icon={faHeadset} />
      </button>
      <div className="top-bar-logo-container" onClick={handleLogoClick}>
        {!isLoading && (
          <img
            src={logo}
            style={{ width: "100%", height: "100%" }}
            alt="Pachira Logo"
            className={`loading-logo ${isLoading ? "logo-loading" : ""}`}
          />
        )}
        {!isLoading && <div className="static-arrows"></div>}

        {isLoading && <div className="loading-circle"></div>}
        {isLoading && (
          <img
            src={logo}
            alt="Pachira Logo"
            className={`loading-logo ${isLoading ? "logo-loading" : ""}`}
          />
        )}
        {showRefreshLabel && (
          <div className="refresh-label">
            <span className="bubble-left">Click to refresh</span>
          </div>
        )}
      </div>
      <button onClick={handleLogout} className="top-bar-button">
        <FontAwesomeIcon icon={faSignOutAlt} />
      </button>

      {isSupportModalOpen && (
        <Modal title="Support" onClose={() => setIsSupportModalOpen(false)}>
          <SupportForm user="" />
          <div class="button-container">
            <button
              className="whiteButton"
              onClick={() =>
                window.open("https://pachira.abandm.com/documents", "_blank")
              }
            >
              Policies
            </button>
            <button
              className="whiteButton"
              onClick={() =>
                window.open(
                  "https://pachira.abandm.com/documents/Starting-Guide",
                  "_blank"
                )
              }
            >
              Startup Guide
            </button>
            <button
              className="whiteButton"
              onClick={() =>
                window.open(
                  "https://pachira.abandm.com/documents/Terms-of-Service",
                  "_blank"
                )
              }
            >
              Terms of Service
            </button>
            <button className="whiteButton" onClick={openReleaseNotes}>
              Version: {latestVersion} Beta
            </button>
          </div>
        </Modal>
      )}
      {isReleaseNotesOpen && (
        <Modal onClose={closeReleaseNotes} title="Release Notes">
          <div className="release-notes-modal-content">
            <div className="release-notes">{renderReleaseNotes()}</div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MobileTopBar;
