import { format, parseISO } from "date-fns";

export const formatDateForInput = (isoDate) => {
  const date = new Date(isoDate);
  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
  return adjustedDate.toISOString().split("T")[0];
};

export const formatTableDate = (isoDate) => {
  return format(parseISO(isoDate), "PPP");
};
