import { ThemeProvider, createTheme } from "@mui/material";
import React from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#001e80",
    },
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 400,
      sm: 650,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
});

const ThemeConfig = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeConfig;
