import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { AuthContext } from "../authentication/authenticationContext";
import { AccountsContext } from "../context/AccountsContext";
import "../App.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useMediaQuery } from "@mui/material";
import { Card, Paper, CardContent, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  red,
  green,
  blue,
  purple,
  orange,
  pink,
  yellow,
  teal,
  cyan,
  indigo,
  lime,
  amber,
  deepOrange,
  deepPurple,
  lightBlue,
  lightGreen,
  brown,
  grey,
  blueGrey,
} from "@mui/material/colors";
import APIService from "../components/api";
import { CurrencyContext } from "../context/currencyContext";
import RecurringTransactionAdd from "../components/recurringTransactionAdd";
import RecurringTransactionEdit from "../components/recurringTransactionEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faPen } from "@fortawesome/free-solid-svg-icons";
import { Stack, Tooltip, useTheme } from "@mui/material";
import OverlayModal from "../components/OverlayModal";
const RecurringTransactions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const calendarRef = useRef(null);
  const { fetchAccountsContext, fetchRecurringTransactionsContext } =
    APIService();
  const { dispatch: authDispatch } = useContext(AuthContext);
  const { state, dispatch } = useContext(AccountsContext);
  const { formatCurrency } = useContext(CurrencyContext);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [suggestedTransactions, setSuggestedTransactions] = useState([]);
  const [colorByType, setColorByType] = useState(true);
  const [accountColors, setAccountColors] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const getData = useCallback(async () => {
    if (!state.accountsLoaded && !state.accountsLoading) {
      fetchAccountsContext();
    }
    if (
      !state.recurringTransactionsLoaded &&
      !state.recurringTransactionsLoading
    ) {
      fetchRecurringTransactionsContext();
    }
  }, [
    state.accountsLoaded,
    state.recurringTransactionsLoaded,
    state.accountsLoading,
    state.recurringTransactionsLoading,
  ]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [view, setView] = useState("dayGridMonth");
  const renderEventDot = (eventInfo) => {
    const { event } = eventInfo;
    return (
      <div className="event-dot-container">
        <span
          className="event-dot"
          style={{ backgroundColor: event.backgroundColor }}
        ></span>
      </div>
    );
  };

  const renderEventContent2 = (eventInfo) => {
    const { event } = eventInfo;
    return (
      <div className="transaction-container">
        <span>{event.title}</span>
        <FontAwesomeIcon icon={faPen} className="edit-icon" />
      </div>
    );
  };
  useEffect(() => {
    if (selectedDate && calendarRef.current) {
      calendarRef.current.getApi().gotoDate(selectedDate);
    }
  }, [selectedDate, view]);
  const handleDateClick = (info) => {
    setSelectedDate(info.dateStr);
    setView("dayGridDay");
    calendarRef.current.getApi().gotoDate(info.dateStr);
  };

  const handleEventClick2 = (info) => {
    const currentView = calendarRef.current
      ? calendarRef.current.getApi().view.type
      : "";
    if (isMobile && currentView !== "dayGridDay") {
      setSelectedDate(info.event.startStr);
      setView("dayGridDay");
      if (calendarRef.current) {
        calendarRef.current.getApi().gotoDate(info.event.startStr);
      }
    } else {
      const transaction = state.recurringTransactions.find(
        (t) => t.transaction_id === info.event.extendedProps.transaction_id
      );
      setSelectedTransaction(transaction);
      setShowEditModal(true);
    }
  };
  useEffect(() => {
    const generateTransactions = () => {
      const transactions = [];
      const today = new Date();
      const startDate = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      );
      const endDate = new Date(
        today.getFullYear() + 1,
        today.getMonth(),
        today.getDate()
      );

      state.recurringTransactions.forEach((transaction) => {
        const {
          transaction_id,
          transaction_name,
          pachira_account_uuid,
          transaction_amount,
          start_date,
          frequency,
          notes,
          auto_pay,
        } = transaction;
        let nextDate = new Date(start_date);

        while (nextDate <= endDate) {
          if (nextDate >= startDate) {
            transactions.push({
              transaction_id,
              transaction_name,
              pachira_account_uuid,
              transaction_amount,
              transaction_date: nextDate.toISOString().split("T")[0],
              notes,
              auto_pay,
            });
          }

          switch (frequency) {
            case "daily":
              nextDate.setDate(nextDate.getDate() + 1);
              break;
            case "weekly":
              nextDate.setDate(nextDate.getDate() + 7);
              break;
            case "bi-weekly":
              nextDate.setDate(nextDate.getDate() + 14);
              break;
            case "monthly":
              nextDate.setMonth(nextDate.getMonth() + 1);
              break;
            case "yearly":
              nextDate.setFullYear(nextDate.getFullYear() + 1);
              break;
            default:
              if (frequency.startsWith("every ")) {
                const days = parseInt(frequency.split(" ")[1], 10);
                nextDate.setDate(nextDate.getDate() + days);
              }
              break;
          }
        }
      });
      return transactions;
    };

    const suggestRecurringTransactions = (groupedTransactions) => {
      const suggestions = [];
      const seen = new Set();
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

      // Helper function to check if a suggestion matches a current recurring transaction
      const isDuplicate = (name, amount) => {
        return state.recurringTransactions.some((transaction) => {
          const transactionAmount = parseFloat(transaction.transaction_amount);
          const comparisonAmount = parseFloat(amount);
          const isMatch =
            transaction.transaction_name === name &&
            transactionAmount === comparisonAmount;

          return isMatch;
        });
      };

      // Check each grouping
      Object.keys(groupedTransactions).forEach((groupKey) => {
        const groups = groupedTransactions[groupKey];
        Object.keys(groups).forEach((key) => {
          // Filter transactions to only include those within the last 6 months
          const transactions = groups[key].filter((t) => {
            const transactionDate = new Date(t.date);
            return transactionDate >= sixMonthsAgo;
          });

          if (transactions.length > 0) {
            const dates = transactions.map((t) => new Date(t.date));
            const frequency = detectFrequency(dates);
            if (frequency) {
              const mostRecentDate = new Date(Math.max(...dates));

              const suggestion = {
                transaction_name:
                  transactions[0].memo ||
                  transactions[0].merchant_name ||
                  transactions[0].account_display_name,
                transaction_amount: transactions[0].amount,
                frequency,
                pachira_account_uuid: transactions[0].pachira_account_uuid, // Include account in suggestion
                start_date: mostRecentDate.toISOString().split("T")[0], // Store the most recent date
              };

              const suggestionKey = `${suggestion.transaction_name}_${suggestion.transaction_amount}`;
              if (
                !seen.has(suggestionKey) &&
                !isDuplicate(
                  suggestion.transaction_name,
                  suggestion.transaction_amount
                )
              ) {
                suggestions.push(suggestion);
                seen.add(suggestionKey);
              }
            }
          }
        });
      });

      return suggestions;
    };

    if (state.recurringTransactionsLoaded) {
      setTransactions(generateTransactions(state.recurringTransactions));
      setSuggestedTransactions(
        suggestRecurringTransactions(groupTransactions(state.transactions))
      );
    }
  }, [
    dispatch,
    authDispatch,
    state.recurringTransactionsLoaded,
    state.transactions,
    state.recurringTransactions,
  ]);
  // SUGGESTED TRASNACTION LOGIC
  const groupTransactions = (transactions) => {
    const groupedTransactions = {
      byMemo: {},
      byMerchantName: {},
      byAccountDisplayName: {},
    };

    transactions.forEach((transaction) => {
      const amount = transaction.amount;

      // Group by memo
      if (transaction.memo) {
        const key = `${amount}_${transaction.memo}`;
        if (!groupedTransactions.byMemo[key]) {
          groupedTransactions.byMemo[key] = [];
        }
        groupedTransactions.byMemo[key].push(transaction);
      }

      // Group by merchant_name
      if (transaction.merchant_name) {
        const key = `${amount}_${transaction.merchant_name}`;
        if (!groupedTransactions.byMerchantName[key]) {
          groupedTransactions.byMerchantName[key] = [];
        }
        groupedTransactions.byMerchantName[key].push(transaction);
      }

      // Group by account_display_name
      const key = `${amount}_${transaction.account_display_name}`;
      if (!groupedTransactions.byAccountDisplayName[key]) {
        groupedTransactions.byAccountDisplayName[key] = [];
      }
      groupedTransactions.byAccountDisplayName[key].push(transaction);
    });

    return groupedTransactions;
  };

  const detectFrequency = (dates) => {
    if (dates.length < 2) return null;

    // Sort the dates to ensure they are in chronological order
    dates.sort((a, b) => new Date(a) - new Date(b));

    const intervals = [];
    for (let i = 1; i < dates.length; i++) {
      intervals.push(
        Math.ceil(
          (new Date(dates[i]) - new Date(dates[i - 1])) / (1000 * 60 * 60 * 24)
        )
      );
    }

    const uniqueIntervals = [...new Set(intervals)];
    if (uniqueIntervals.length === 1) {
      const interval = uniqueIntervals[0];
      if (interval === 1) return "daily";
      if (interval === 7) return "weekly";
      if (interval === 14) return "bi-weekly";
      if (interval >= 28 && interval <= 34) return "monthly";
      if (interval === 365 || interval === 366) return "yearly";
      if (interval === 0) return null;
      return `every ${interval} days`;
    }

    // Check for consistency in intervals for two consecutive dates
    for (let i = 0; i < intervals.length - 1; i++) {
      if (intervals[i] === intervals[i + 1]) {
        const interval = intervals[i];
        if (interval === 1) return "daily";
        if (interval === 7) return "weekly";
        if (interval === 14) return "bi-weekly";
        if (interval >= 28 && interval <= 34) return "monthly";
        if (interval === 365 || interval === 366) return "yearly";
        if (interval === 0) return null;
        return `every ${interval} days`;
      }
    }

    return null;
  };

  const handleSuggestionClick = (suggestion) => {
    setSelectedSuggestion(suggestion);
    setShowSuggestions(false);
    // Open the add modal and set the selected suggestion as preset data
    setShowAddModal(true);
  };

  const [expensesByAccount, setExpensesByAccount] = useState({});
  const [initialBalancesByAccount, setInitialBalancesByAccount] = useState({});

  useEffect(() => {
    document.title = "Recurring Transactions - Pachira";
    getData();
    const calculateExpensesAndSimulateBalances = (
      transactions,
      accounts,
      currentDate
    ) => {
      const groupedTransactions = groupTransactionsByAccount(transactions);
      const initialBalances = {};
      const accountNeeds = {};
      const affectedAccounts = {};

      accounts.forEach((account) => {
        const accountId = account.pachira_account_uuid;
        const relevantTransactions = groupedTransactions[accountId]?.filter(
          (t) => new Date(t.transaction_date) >= currentDate
        );

        if (relevantTransactions && relevantTransactions.length > 0) {
          initialBalances[accountId] =
            Number(account.balance_available) ||
            Number(account.balance_current);
          accountNeeds[accountId] = {
            dailyExpenses: 0,
            weeklyExpenses: 0,
            monthlyExpenses: 0,
            lowestDailyBalance: initialBalances[accountId],
            lowestWeeklyBalance: initialBalances[accountId],
            lowestMonthlyBalance: initialBalances[accountId],
            nextExpenseDay: null,
            nextExpenseTotal: 0,
            nextExpenseShortfall: 0,
          };
          affectedAccounts[accountId] = account;
        }
      });

      Object.keys(groupedTransactions).forEach((accountId) => {
        if (!accountNeeds[accountId]) return;

        let runningBalance = initialBalances[accountId];
        const transactions = groupedTransactions[accountId]
          .filter((t) => new Date(t.transaction_date) >= currentDate)
          .sort(
            (a, b) =>
              new Date(a.transaction_date) - new Date(b.transaction_date)
          );

        transactions.forEach((transaction) => {
          const transactionDate = new Date(transaction.transaction_date);
          const daysDifference =
            (transactionDate - currentDate) / (1000 * 3600 * 24);

          runningBalance += Number(transaction.transaction_amount);

          if (transaction.transaction_amount < 0) {
            if (
              !accountNeeds[accountId].nextExpenseDay ||
              transactionDate < accountNeeds[accountId].nextExpenseDay
            ) {
              accountNeeds[accountId].nextExpenseDay = transactionDate;
              accountNeeds[accountId].nextExpenseTotal = Math.abs(
                transaction.transaction_amount
              );
              accountNeeds[accountId].nextExpenseShortfall =
                runningBalance < 0 ? Math.abs(runningBalance) : 0;
            } else if (
              transactionDate.getTime() ===
              accountNeeds[accountId].nextExpenseDay.getTime()
            ) {
              accountNeeds[accountId].nextExpenseTotal += Math.abs(
                transaction.transaction_amount
              );
              accountNeeds[accountId].nextExpenseShortfall =
                runningBalance < 0 ? Math.abs(runningBalance) : 0;
            }
          }

          if (transaction.transaction_amount < 0) {
            if (daysDifference <= 1) {
              accountNeeds[accountId].dailyExpenses += Math.abs(
                transaction.transaction_amount
              );
            }
            if (daysDifference <= 7) {
              accountNeeds[accountId].weeklyExpenses += Math.abs(
                transaction.transaction_amount
              );
            }
            if (daysDifference <= 30) {
              accountNeeds[accountId].monthlyExpenses += Math.abs(
                transaction.transaction_amount
              );
            }
          }

          if (daysDifference <= 1) {
            accountNeeds[accountId].lowestDailyBalance = Math.min(
              accountNeeds[accountId].lowestDailyBalance,
              runningBalance
            );
          }
          if (daysDifference <= 7) {
            accountNeeds[accountId].lowestWeeklyBalance = Math.min(
              accountNeeds[accountId].lowestWeeklyBalance,
              runningBalance
            );
          }
          if (daysDifference <= 30) {
            accountNeeds[accountId].lowestMonthlyBalance = Math.min(
              accountNeeds[accountId].lowestMonthlyBalance,
              runningBalance
            );
          }
        });
      });

      return { accountNeeds, initialBalances, affectedAccounts };
    };
    if (state.accountsLoaded) {
      const { accountNeeds, initialBalances } =
        calculateExpensesAndSimulateBalances(
          transactions,
          state.accounts,
          new Date()
        );
      setExpensesByAccount(accountNeeds);
      setInitialBalancesByAccount(initialBalances);

      // Generate a color for each account
      const colors = {};
      const colorPalette = [
        red[500],
        green[500],
        blue[500],
        purple[500],
        orange[500],
        pink[500],
        yellow[500],
        teal[500],
        cyan[500],
        indigo[500],
        lime[500],
        amber[500],
        deepOrange[500],
        deepPurple[500],
        lightBlue[500],
        lightGreen[500],
        brown[500],
        grey[500],
        blueGrey[500],
        amber[800],
      ];

      state.accounts.forEach((account, index) => {
        colors[account.pachira_account_uuid] =
          colorPalette[index % colorPalette.length];
      });
      setAccountColors(colors);
    }
  }, [state.accounts, state.accountsLoaded, transactions, getData]);

  const groupTransactionsByAccount = (transactions) => {
    return transactions.reduce((acc, transaction) => {
      const { pachira_account_uuid } = transaction;
      if (!acc[pachira_account_uuid]) {
        acc[pachira_account_uuid] = [];
      }
      acc[pachira_account_uuid].push(transaction);
      return acc;
    }, {});
  };

  const handleEventClick = (info) => {
    const transaction = state.recurringTransactions.find(
      (t) => t.transaction_id === info.event.extendedProps.transaction_id
    );

    setSelectedTransaction(transaction);
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => {
    setSelectedTransaction(null);
    setShowEditModal(false);
  };

  const handleOpenInfoModal = () => {
    setInfoModalOpen(true);
  };

  const handleCloseInfoModal = () => {
    setInfoModalOpen(false);
  };
  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    return (
      <div
        className="transaction-container"
        onClick={() => handleEventClick(event)}
      >
        <span>{event.title}</span>
        <FontAwesomeIcon icon={faPen} className="edit-icon" />
      </div>
    );
  };

  return (
    <div className="dashboard">
      <div className="header">
        <Stack direction="row" alignItems="center" spacing={2}>
          <h1>Recurring Transactions</h1>
          <Tooltip title="Info">
            <FontAwesomeIcon
              id="info-button"
              icon={faCircleInfo}
              onClick={handleOpenInfoModal}
            />
          </Tooltip>
        </Stack>
        <Stack direction="row" spacing={2}>
          <RecurringTransactionAdd />
        </Stack>
        <OverlayModal open={infoModalOpen} onClose={handleCloseInfoModal}>
          <Stack px={2}>
            <Typography variant="h6" color={theme.palette.primary.main}>
              Quick Tips: Efficient Recurring Transaction Management
            </Typography>
            <ul className="tipList">
              <li>
                To add a recurring transaction, click the + icon in the top
                right corner.
              </li>
              <li>
                Review and manage your existing recurring transactions from the
                calandar below.
              </li>
              <li>To Edit, click on the transaction you want to edit.</li>
            </ul>
          </Stack>
        </OverlayModal>
      </div>
      <Paper
        elevation={6}
        style={{
          padding: "20px",
          borderRadius: "15px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          height: "auto",
        }}
      >
        <div className="desktop-calendar">
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={transactions.map((transaction) => ({
              transaction_id: transaction.transaction_id,
              title: `${transaction.transaction_name}: ${formatCurrency(
                transaction.transaction_amount
              )}`,
              date: transaction.transaction_date,
              backgroundColor: colorByType
                ? transaction.transaction_amount >= 0
                  ? green[500]
                  : red[500]
                : accountColors[transaction.pachira_account_uuid],
              borderColor: "transparent",
            }))}
            height="800px"
            dayMaxEventRows={true}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,dayGridWeek,dayGridDay",
            }}
            contentHeight="auto"
            aspectRatio={3}
            eventClick={handleEventClick}
            eventContent={renderEventContent}
          />
        </div>
        <div className="mobile-calendar">
          <FullCalendar
            ref={calendarRef} // Attach the ref here
            key={`${view}-${selectedDate}`} // Add this line to force re-render
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView={view} // Use the view state
            events={transactions.map((transaction) => ({
              transaction_id: transaction.transaction_id,
              title: `${transaction.transaction_name}: ${formatCurrency(
                transaction.transaction_amount
              )}`,
              date: transaction.transaction_date,
              backgroundColor: colorByType
                ? transaction.transaction_amount >= 0
                  ? green[500]
                  : red[500]
                : accountColors[transaction.pachira_account_uuid],
              borderColor: "transparent",
            }))}
            eventContent={renderEventDot}
            eventClick={handleEventClick2}
            dateClick={handleDateClick}
            headerToolbar={{
              left: "prev,next",
              center: "title",
              right: "dayGridMonth",
            }}
            views={{
              dayGridMonth: {
                eventContent: renderEventDot,
              },
              dayGridDay: {
                type: "list",
                duration: { days: 1 },
                buttonText: "Day List",
                eventContent: renderEventContent2,
              },
            }}
            height="800px"
            dayMaxEventRows={true}
            allDaySlot={true}
            contentHeight="auto"
            aspectRatio={3}
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <button
            className="blueButton"
            onClick={() => setColorByType(!colorByType)}
          >
            {colorByType ? "Color by Account" : "Color by Type"}
          </button>
        </div>
        <div>
          <button
            onClick={() => setShowSuggestions(!showSuggestions)}
            className="cardButton"
          >
            {showSuggestions
              ? "Hide Suggested Recurring Transactions"
              : "Click to See Suggested Recurring Transactions"}
          </button>

          {showSuggestions && (
            <div className="suggestionsTableContainer">
              {suggestedTransactions.length > 0 ? (
                <table className="suggestionsTable">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Amount</th>
                      <th>Frequency</th>
                    </tr>
                  </thead>
                  <tbody>
                    {suggestedTransactions.map((suggestion, index) => (
                      <tr
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="suggestionRow"
                      >
                        <td>{suggestion.transaction_name}</td>
                        <td>{suggestion.transaction_amount}</td>
                        <td>
                          {suggestion.frequency.charAt(0).toUpperCase() +
                            suggestion.frequency.slice(1)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No suggestions available.</p>
              )}
            </div>
          )}
          {showAddModal && (
            <RecurringTransactionAdd
              presets={selectedSuggestion}
              autoOpen={true}
              onClose={() => setShowAddModal(false)}
            />
          )}
        </div>

        {Object.keys(expensesByAccount).map((accountId) => {
          const account = state.accounts.find(
            (a) => a.pachira_account_uuid === accountId
          );
          const {
            weeklyExpenses,
            monthlyExpenses,
            lowestWeeklyBalance,
            lowestMonthlyBalance,
            nextExpenseTotal,
            nextExpenseShortfall,
          } = expensesByAccount[accountId];

          const getColor = (balance) => (balance < 0 ? red[500] : green[500]);

          return (
            <Card
              key={accountId}
              style={{
                marginTop: "20px",
                borderColor: colorByType
                  ? "transparent"
                  : accountColors[accountId],
                borderWidth: colorByType ? "0px" : "2px",
                borderStyle: "solid",
              }}
            >
              <CardContent>
                <Typography variant="h6">
                  {account.name} (Balance:{" "}
                  {formatCurrency(
                    initialBalancesByAccount[accountId].toFixed(2)
                  )}
                  )
                </Typography>
                <Typography>
                  Next Day With Expenses{" "}
                  {formatCurrency(nextExpenseTotal.toFixed(2))}{" "}
                  <span style={{ color: red[500] }}>
                    {nextExpenseShortfall > 0 ? (
                      `(Short by ${formatCurrency(
                        nextExpenseShortfall.toFixed(2)
                      )})`
                    ) : (
                      <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    )}
                  </span>
                </Typography>
                <Typography>
                  Next 7 Days: {formatCurrency(weeklyExpenses.toFixed(2))}{" "}
                  <span style={{ color: getColor(lowestWeeklyBalance) }}>
                    {lowestWeeklyBalance < 0 ? (
                      `(Short by ${formatCurrency(
                        Math.abs(lowestWeeklyBalance).toFixed(2)
                      )})`
                    ) : (
                      <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    )}
                  </span>
                </Typography>
                <Typography>
                  Next 30 Days: {formatCurrency(monthlyExpenses.toFixed(2))}{" "}
                  <span style={{ color: getColor(lowestMonthlyBalance) }}>
                    {lowestMonthlyBalance < 0 ? (
                      `(Short by ${formatCurrency(
                        Math.abs(lowestMonthlyBalance).toFixed(2)
                      )})`
                    ) : (
                      <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    )}
                  </span>
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Paper>
      {showEditModal && (
        <RecurringTransactionEdit
          onOpen={true}
          transaction={selectedTransaction}
          onClose={handleCloseEditModal}
        />
      )}
    </div>
  );
};

export default RecurringTransactions;
