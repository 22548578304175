import React, { useState, useContext } from "react";
import Modal from "./modal";
import { ACTION_TYPES, AccountsContext } from "../context/AccountsContext";
import APIService from "../components/api";

const AddPartyButton = () => {
  const { createInvestmentParty, joinInvestmentParty } = APIService();
  const { dispatch } = useContext(AccountsContext);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [showAddPartyModal, setShowAddPartyModal] = useState(false);
  const [partyCode, setPartyCode] = useState("");
  const [partyName, setPartyName] = useState("");
  const [stockTicker, setStockTicker] = useState("");
  const handleDisclaimerAcceptance = () => {
    setShowDisclaimerModal(false);
    setShowAddPartyModal(true);
  };

  const handleCreateParty = async (e) => {
    e.preventDefault();
    let response = await createInvestmentParty({ partyName, stockTicker });

    if (response.message === "Party created successfully") {
      setShowAddPartyModal(false);
      setPartyName("");
      setStockTicker("");
    }
  };

  const handleJoinParty = async (e) => {
    e.preventDefault();

    dispatch({
      type: ACTION_TYPES.SET_UPDATING_PARTIES,
    });
    const response = await joinInvestmentParty(partyCode);

    if (response.message === "Successfully joined party") {
      setShowAddPartyModal(false);
      setPartyCode("");
    }
  };

  return (
    <>
      <button
        className="blackButton"
        onClick={() => setShowDisclaimerModal(true)}
      >
        Add Party
      </button>
      {showDisclaimerModal && (
        <Modal
          onClose={() => {
            setShowDisclaimerModal(false);
            setDisclaimerAccepted(false);
          }}
          title="Disclaimer"
          description="By creating or joining a party, you acknowledge that your investment stock data for the ticker related to the party will be shared with all members of the party. No individual data will be visible to others as it is aggregated. You consent to sharing this data with the party, and we are not liable for this data sharing. If you are the only memeber of a party, the party will show your personal investment as it is the aggregation"
        >
          <div className="modalContent">
            <form onSubmit={handleDisclaimerAcceptance}>
              <label style={{ color: "red" }}>
                <input
                  type="checkbox"
                  checked={disclaimerAccepted}
                  onChange={(e) => setDisclaimerAccepted(e.target.checked)}
                />
                I accept, understand, and acknowledge these terms.
              </label>
              <button
                type="submit"
                className="fullLengthButton"
                disabled={!disclaimerAccepted}
              >
                I Accept
              </button>
            </form>
          </div>
        </Modal>
      )}

      {showAddPartyModal && (
        <Modal
          onClose={() => setShowAddPartyModal(false)}
          title="Add or Join a Party"
          description="Join an existing party with a code or create a new one."
        >
          <div className="modalContent">
            <h3>Join a Party</h3>
            <form onSubmit={handleJoinParty}>
              <input
                type="text"
                value={partyCode}
                onChange={(e) => setPartyCode(e.target.value)}
                className="modalInput"
                placeholder="Party Code"
                required
              />
              <button type="submit" className="fullLengthButton">
                Join Party
              </button>
            </form>
            <hr />
            <h3>Create a Party</h3>
            <form onSubmit={handleCreateParty}>
              <input
                type="text"
                value={partyName}
                onChange={(e) => setPartyName(e.target.value)}
                className="modalInput"
                placeholder="Party Name"
                required
              />
              <input
                type="text"
                value={stockTicker}
                onChange={(e) => setStockTicker(e.target.value)}
                className="modalInput"
                placeholder="Stock Ticker"
                required
              />
              <button type="submit" className="fullLengthButton">
                Create Party
              </button>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddPartyButton;
