import { useContext } from "react";
import {
  AccountsContext,
  ACTION_TYPES as ACCOUNTS_ACTIONS,
} from "../context/AccountsContext";
import { AppContext, ACTION_TYPES as APP_ACTIONS } from "../context/AppContext";
import useApiRequest from "../context/apiRequestContext";
import { useNavigate } from "react-router-dom";

const useAPIService = () => {
  const navigate = useNavigate();
  const { makeApiRequest } = useApiRequest();
  const { state, dispatch } = useContext(AccountsContext);
  const { dispatch: appDispatch } = useContext(AppContext);
  const fetchTransactionsContext = async () => {
    const errorCount = state.retryDelays.transactions || 0;
    const delay = Math.pow(2, errorCount) * 1000;
    setTimeout(async () => {
      dispatch({ type: ACCOUNTS_ACTIONS.SET_LOADING_TRANSACTIONS });
      try {
        const transactions = await makeApiRequest({
          url: "/api/transactions",
        });
        if (transactions === null) {
          return;
        }
        dispatch({
          type: ACCOUNTS_ACTIONS.LOAD_TRANSACTIONS,
          payload: transactions,
        });
      } catch (e) {
        dispatch({
          type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
          payload: {
            transactions: true,
          },
        });
      }
    }, delay);
  };
  const fetchRules = async () => {
    const errorCount = state.retryDelays.rules || 0;
    const delay = Math.pow(2, errorCount) * 1000;
    setTimeout(async () => {
      dispatch({ type: ACCOUNTS_ACTIONS.SET_LOADING_RULES });
      try {
        const response = await makeApiRequest({ url: "/api/rules" });
        if (response === null) {
          return;
        }
        dispatch({
          type: ACCOUNTS_ACTIONS.LOAD_RULES,
          payload: response.userRules,
        });
      } catch (error) {
        dispatch({
          type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
          payload: {
            rules: true,
          },
        });
      }
    }, delay);
  };
  const fetchRecurringTransactionsContext = async () => {
    const errorCount = state.retryDelays.recurringTransactions || 0;
    const delay = Math.pow(2, errorCount) * 1000;
    setTimeout(async () => {
      dispatch({ type: ACCOUNTS_ACTIONS.SET_LOADING_RECURRING_TRANSACTIONS });
      try {
        const recurringTransactions = await makeApiRequest({
          url: "/api/get_recurring_transactions",
        });
        if (recurringTransactions === null) {
          return;
        }
        dispatch({
          type: ACCOUNTS_ACTIONS.LOAD_RECURRING_TRANSACTIONS,
          payload: recurringTransactions,
        });
      } catch (e) {
        dispatch({
          type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
          payload: {
            recurringTransactions: true,
          },
        });
      }
    }, delay);
  };

  const fetchCategoriesContext = async () => {
    const errorCount = state.retryDelays.categories || 0;
    const delay = Math.pow(2, errorCount) * 1000;
    setTimeout(async () => {
      dispatch({ type: ACCOUNTS_ACTIONS.SET_LOADING_CATEGORIES });
      try {
        const categories = await makeApiRequest({
          url: "/api/categories",
        });
        if (categories === null) {
          return;
        }
        dispatch({
          type: ACCOUNTS_ACTIONS.LOAD_CATEGORIES,
          payload: categories,
        });
      } catch (e) {
        dispatch({
          type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
          payload: {
            categories: true,
          },
        });
      }
    }, delay);
  };
  const fetchInvestmentsContext = async () => {
    const errorCount = state.retryDelays.investments || 0;
    const delay = Math.pow(2, errorCount) * 1000;
    setTimeout(async () => {
      dispatch({ type: ACCOUNTS_ACTIONS.SET_LOADING_INVESTMENTS });
      try {
        const investments = await makeApiRequest({
          url: "/api/investments",
        });
        if (investments === null) {
          return;
        }
        dispatch({
          type: ACCOUNTS_ACTIONS.LOAD_INVESTMENTS,
          payload: investments,
        });
      } catch (e) {
        dispatch({
          type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
          payload: {
            investments: true,
          },
        });
      }
    }, delay);
  };
  const fetchInvestmentPartiesContext = async () => {
    const errorCount = state.retryDelays.parties || 0;
    const delay = Math.pow(2, errorCount) * 1000;
    setTimeout(async () => {
      dispatch({ type: ACCOUNTS_ACTIONS.SET_LOADING_PARTIES });
      try {
        const response = await makeApiRequest({
          url: "/api/investment_parties",
        });
        if (response === null) {
          return;
        }
        dispatch({
          type: ACCOUNTS_ACTIONS.LOAD_PARTIES,
          payload: response.parties,
        });
      } catch (e) {
        dispatch({
          type: ACCOUNTS_ACTIONS.LOAD_PARTIES,
          payload: [],
        });
        dispatch({
          type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
          payload: {
            parties: true,
          },
        });
      }
    }, delay);
  };
  const fetchInvestmentsOverTimeContext = async () => {
    const errorCount = state.retryDelays.investmentsOverTime || 0;
    const delay = Math.pow(2, errorCount) * 1000;
    setTimeout(async () => {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_LOADING_INVESTMENTS_OVER_TIME,
      });
      try {
        const investmentsOverTime = await makeApiRequest({
          url: "/api/investments_over_time",
        });
        if (investmentsOverTime === null) {
          return;
        }
        dispatch({
          type: ACCOUNTS_ACTIONS.SET_INVESTMENTS_OVER_TIME,
          payload: investmentsOverTime.data,
        });
      } catch (e) {
        dispatch({
          type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
          payload: {
            investmentsOverTime: true,
          },
        });
      }
    }, delay);
  };

  const fetchAccountsContext = async () => {
    const errorCount = state.retryDelays.accounts || 0;
    const delay = Math.pow(2, errorCount) * 1000;
    setTimeout(async () => {
      dispatch({ type: ACCOUNTS_ACTIONS.SET_LOADING_ACCOUNTS });
      try {
        const accounts = await makeApiRequest({
          url: "/api/accounts",
        });
        if (accounts === null) {
          return;
        }

        dispatch({ type: ACCOUNTS_ACTIONS.LOAD_ACCOUNTS, payload: accounts });
      } catch (e) {
        dispatch({
          type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
          payload: {
            accounts: true,
          },
        });
      }
    }, delay);
  };

  const fetchPayments = async () => {
    const errorCount = state.retryDelays.payments || 0;
    const delay = Math.pow(2, errorCount) * 1000;
    setTimeout(async () => {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_LOADING_PAYMENTS,
      });
      const paymentsResponse = await makeApiRequest({
        url: "/api/get_payments",
      });
      if (paymentsResponse === null) {
        return;
      }
      dispatch({
        type: ACCOUNTS_ACTIONS.LOAD_PAYMENTS,
        payload: paymentsResponse,
      });
    }, delay);
  };
  const fetchUserContext = async () => {
    appDispatch({ type: APP_ACTIONS.SET_LOADING_USER });
    const user = await makeApiRequest({
      url: "/api/users",
    });
    if (user === null) {
      return;
    }
    appDispatch({ type: APP_ACTIONS.LOAD_USER, payload: user });
  };
  const fetchPreferences = async () => {
    const user = await makeApiRequest({
      url: "/api/user/preferences",
    });
    if (user === null) {
      return;
    }
    appDispatch({
      type: APP_ACTIONS.LOAD_DASHBOARD_PREFERENCES,
      payload: user,
    });
    return user;
  };
  const fetchNetWorthContext = async () => {
    const errorCount = state.retryDelays.accountBalancesOverTime || 0;
    const delay = Math.pow(2, errorCount) * 1000;
    setTimeout(async () => {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_LOADING_ACCOUNT_BALANCES_OVER_TIME,
      });
      try {
        const data = await makeApiRequest({
          url: "/api/net_worth",
        });

        // Check if the request was a duplicate
        if (data === null) {
          return;
        }

        dispatch({
          type: ACCOUNTS_ACTIONS.SET_ACCOUNT_BALANCES_OVER_TIME,
          payload: data,
        });
      } catch (error) {
        console.error("Error fetching NetWorth:", error);
        dispatch({
          type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
          payload: {
            accountBalanceOverTime: true,
          },
        });
      }
    }, delay);
  };
  const fetchDefaultCategories = async () => {
    try {
      const defaults = await makeApiRequest({
        url: "/api/default_categories",
      });
      if (defaults === null) {
        return;
      }
      dispatch({
        type: ACCOUNTS_ACTIONS.LOAD_DEFAULT_CATEGORIES,
        payload: defaults,
      });
    } catch (error) {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
        payload: {
          defaultCategories: true,
        },
      });
    }
  };
  const fetchAccountTypes = async () => {
    const accountTypes = await makeApiRequest({
      url: "/api/types",
    });
    if (accountTypes === null) {
      return;
    }
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_ACCOUNT_TYPES,
      payload: accountTypes,
    });
  };
  const fetchStockPrice = async (stockTicker) => {
    try {
      const data = await makeApiRequest({
        url: `/api/stock-price/${stockTicker}`,
        method: "GET",
      });
      if (data === null) {
        return;
      }
      return data;
    } catch (error) {
      console.error("Error fetching stock price from backend:", error);
    }
  };

  const fetchSubscriptions = async () => {
    const errorCount = state.retryDelays.subscriptions || 0;
    const delay = Math.pow(2, errorCount) * 1000;
    setTimeout(async () => {
      try {
        dispatch({
          type: ACCOUNTS_ACTIONS.SET_LOADING_SUBSCRIPTIONS,
        });

        const subscriptionsResponse = await makeApiRequest({
          url: "/api/get_subscriptions",
          method: "GET",
        });

        if (subscriptionsResponse === null) {
          return null;
        }

        dispatch({
          type: ACCOUNTS_ACTIONS.LOAD_SUBSCRIPTIONS,
          payload: subscriptionsResponse,
        });

        return subscriptionsResponse;
      } catch (error) {
        console.error("Failed to fetch subscriptions", error);
        throw error;
      }
    }, delay);
  };
  const refreshToken = async () => {
    try {
      const response = await makeApiRequest({
        url: "/auth/refresh",
        jsonParse: false,
      });
      return response;
    } catch (error) {
      console.error("Failed to refresh token:", error);
      throw error;
    }
  };
  const syncData = async () => {
    try {
      const data = await makeApiRequest({
        url: "/api/syncData",
      });
      if (data === null) {
        return;
      }
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_UNLOADED_TRANSACTIONS,
      });
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_UNLOADED_ACCOUNT_BALANCES_OVER_TIME,
      });
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_UNLOADED_ACCOUNTS,
      });
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_UNLOADED_INVESTMENTS,
      });
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_UNLOADED_INVESTMENTS_OVER_TIME,
      });
    } catch (error) {
      console.error("Error syncing data:", error);
    }
  };
  const sendContactRequest = async (body) => {
    const response = await makeApiRequest({
      method: "POST",
      url: "/api/contact",
      body,
    });
    return response;
  };
  const updateAccount = async (body) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_ACCOUNTS,
    });

    const response = await makeApiRequest({
      method: "POST",
      url: "/api/account_update",
      body,
    });
    if (response === null) {
      return;
    }
    if ((response.status = 200)) {
      if (response.deletedAccountIds) {
        dispatch({
          type: ACCOUNTS_ACTIONS.REMOVE_ACCOUNTS,
          payload: response.deletedAccountIds,
        });
      } else if (response.updatedAccount) {
        dispatch({
          type: ACCOUNTS_ACTIONS.UPDATE_ACCOUNTS,
          payload: [response.updatedAccount],
        });
      }
    }
  };
  const updateCategory = async (bodyData) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_CATEGORIES,
    });

    try {
      const response = await makeApiRequest({
        url: "/api/categories",
        method: "POST",
        body: bodyData,
      });
      const updatedCategoriesFromAPI = response.updatedCategories;

      dispatch({
        type: ACCOUNTS_ACTIONS.UPDATE_CATEGORIES,
        payload: updatedCategoriesFromAPI,
      });
    } catch (error) {
      console.error("Error during update category operation:", error);
    }
  };
  const updateUserCategories = async (selectedCategories) => {
    try {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_UPDATING_CATEGORIES,
      });

      const result = await makeApiRequest({
        method: "POST",
        url: "/api/update_default_categories",
        body: { categories: selectedCategories },
      });

      if (result === null) {
        return;
      }

      dispatch({
        type: ACCOUNTS_ACTIONS.LOAD_CATEGORIES,
        payload: result.updatedCategories,
      });

      return result;
    } catch (e) {
      console.error("Error saving user categories:", e);
      throw e;
    }
  };
  const updateTransaction = async (updatedTransaction) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_TRANSACTIONS,
    });
    let result = await makeApiRequest({
      method: "POST",
      url: "/api/update_transaction",
      body: updatedTransaction,
    });
    if (updatedTransaction.isDeleted) {
      dispatch({
        type: ACCOUNTS_ACTIONS.DELETE_TRANSACTIONS,
        payload: result.deletedTransactionIds,
      });
    } else {
      dispatch({
        type: ACCOUNTS_ACTIONS.UPDATE_TRANSACTIONS,
        payload: [result.updatedTransactions],
      });
    }
  };
  const updateRulePriorities = async (items) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.LOAD_RULES,
      payload: items,
    });
    const rulesWithNewPriorities = items.map((item, index) => ({
      rule_id: item.rule_id,
      priority: index,
    }));

    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_RULES,
    });

    try {
      const response = await makeApiRequest({
        method: "POST",
        url: "/api/rules/updatePriorities",
        body: { rules: rulesWithNewPriorities },
      });

      if (response.message === "Priorities updated successfully") {
        dispatch({
          type: ACCOUNTS_ACTIONS.UPDATE_RULES,
          payload: rulesWithNewPriorities,
        });
      } else {
        throw new Error(response.error || "Unexpected response message");
      }
    } catch (error) {
      console.error("Error updating priorities:", error);
    }
  };
  const updateTransactionCategory = async (body) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_TRANSACTIONS,
    });
    const updatedTransactionsResponse = await makeApiRequest({
      method: "POST",
      url: "/api/update_transaction_category",
      body,
    });
    if (updatedTransactionsResponse === null) {
      return;
    }
    dispatch({
      type: ACCOUNTS_ACTIONS.UPDATE_TRANSACTIONS,
      payload: updatedTransactionsResponse.updatedTransactions,
    });
  };
  const updateBulkTransaction = async (transactions, newCategoryId) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_TRANSACTIONS,
    });
    const updatedTransactionsResponse = await makeApiRequest({
      method: "POST",
      url: "/api/bulk_update_transaction_category",
      body: { transactions, newCategoryId },
    });
    if (updatedTransactionsResponse === null) {
      return;
    }
    dispatch({
      type: ACCOUNTS_ACTIONS.UPDATE_TRANSACTIONS,
      payload: updatedTransactionsResponse.updatedTransactions,
    });
  };
  const updateTransactionSplit = async (payload) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_TRANSACTIONS,
    });
    const updatedResponse = await makeApiRequest({
      method: "POST",
      url: "/api/update_transaction_split",
      body: payload,
    });
    if (updatedResponse === null) {
      return;
    }
    dispatch({
      type: ACCOUNTS_ACTIONS.UPDATE_TRANSACTIONS,
      payload: updatedResponse.updatedTransactions,
    });
  };
  const addAccount = async (formData) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_ACCOUNTS,
    });
    const response = await makeApiRequest({
      method: "POST",
      url: "/api/addAccount",
      body: formData,
    });
    dispatch({
      type: ACCOUNTS_ACTIONS.UPDATE_ACCOUNTS,
      payload: response.updatedAccounts,
    });
  };
  const addManualTransaction = async (transactionData) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_TRANSACTIONS,
    });
    const transactionResponse = await makeApiRequest({
      method: "POST",
      url: "/api/add_manual_transaction",
      body: transactionData,
    });
    if (transactionResponse === null) {
      return;
    }
    dispatch({
      type: ACCOUNTS_ACTIONS.ADD_TRANSACTIONS,
      payload: transactionResponse.newTransactionDetails,
    });
  };

  const addingReccurringTransaction = async (payload) => {
    try {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_UPDATING_RECURRING_TRANSACTIONS,
      });
      const updatedResponse = await makeApiRequest({
        method: "POST",
        url: "/api/add_recurring_transaction",
        body: payload,
      });
      if (updatedResponse === null) {
        return;
      }
      dispatch({
        type: ACCOUNTS_ACTIONS.UPDATE_RECURRING_TRANSACTIONS,
        payload: updatedResponse.recurringTransaction,
      });
    } catch (error) {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
        payload: {
          recurringTransactions: true,
        },
      });
      console.error("Error saving recurring transaction:", error);
    }
  };
  const createInvestmentParty = async (partyName, stockTicker) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_PARTIES,
    });
    const response = await makeApiRequest({
      url: "/api/create_investment_parties",
      method: "POST",
      body: { partyName, stockTicker },
    });
    if (response === null) {
      return;
    }
    if (response.message === "Party created successfully") {
      dispatch({
        type: ACCOUNTS_ACTIONS.ADD_PARTIES,
        payload: response.newParty,
      });
    }
    return response;
  };
  const createRule = async (body) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_RULES,
    });

    const response = await makeApiRequest({
      method: "POST",
      url: "/api/rules/create",
      body,
    });

    if (response === null) {
      return null;
    }

    dispatch({
      type: ACCOUNTS_ACTIONS.UPDATE_TRANSACTIONS,
      payload: response.updatedTransactions,
    });
    dispatch({
      type: ACCOUNTS_ACTIONS.ADD_RULES,
      payload: response.newRule,
    });

    return response;
  };
  const addBulkTransactions = async (preparedData) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_TRANSACTIONS,
    });

    const response = await makeApiRequest({
      method: "POST",
      url: "/api/bulk_manual_transaction_upload",
      body: preparedData,
    });

    if (response === null) {
      throw new Error("No response from API");
    }

    dispatch({
      type: ACCOUNTS_ACTIONS.UPDATE_TRANSACTIONS,
      payload: response.updatedTransactions,
    });

    return response;
  };
  const joinInvestmentParty = async (partyCode) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_PARTIES,
    });
    const response = await makeApiRequest({
      url: "/api/join_investment_parties",
      method: "POST",
      body: { partyCode },
    });
    if (response === null) {
      return;
    }
    if (response.message === "Successfully joined party") {
      dispatch({
        type: ACCOUNTS_ACTIONS.ADD_PARTIES,
        payload: response.updatedParty,
      });
    }
    return response;
  };
  const addYodleeAccount = async (body) => {
    try {
      dispatch({ type: ACCOUNTS_ACTIONS.SET_UPDATING_ACCOUNTS });

      const response = await makeApiRequest({
        method: "POST",
        url: "/api/add_yodlee_account",
        body,
      });

      if (response.message === "Accounts upserted successfully") {
        dispatch({
          type: ACCOUNTS_ACTIONS.UPDATE_ACCOUNTS,
          payload: response.updatedAccounts,
        });
      } else {
        console.error(
          "Failed to upsert accounts:",
          response.error || "Unknown error"
        );
      }

      return response;
    } catch (error) {
      console.error("Error in posting Yodlee account data:", error);
      throw error;
    }
  };
  const getAccessToken = async () => {
    try {
      const response = await makeApiRequest({
        url: "/auth/get_access_token",
      });

      if (response === null) {
        throw new Error("No response from API");
      }

      return response;
    } catch (error) {
      console.error("Failed to get access token:", error);
      throw error;
    }
  };
  const updateCategories = async (categories) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_CATEGORIES,
    });
    const updatedResponse = await makeApiRequest({
      method: "POST",
      url: "/api/update_categories",
      body: { categories },
    });
    if (updatedResponse === null) {
      return;
    }
    dispatch({
      type: ACCOUNTS_ACTIONS.UPDATE_CATEGORIES,
      payload: updatedResponse.updatedCategories,
    });
    dispatch({
      type: ACCOUNTS_ACTIONS.UPDATE_TRANSACTIONS,
      payload: updatedResponse.updatedTransactions,
    });
  };
  const updatePreferences = async (serializedLayouts, serializedConfigs) => {
    const updatedResponse = await makeApiRequest({
      method: "POST",
      url: "/api/user/preferences",
      body: {
        layouts: serializedLayouts,
        visibility: serializedConfigs,
      },
      jsonParse: false,
    });
    if (updatedResponse === null) {
      return;
    }
    appDispatch({
      type: ACCOUNTS_ACTIONS.UPDATED_DASHBOARD_PREFERENCES,
      payload: {
        visibility: serializedConfigs,
        layouts: serializedLayouts,
      },
    });
  };
  const updateRecurringTransaction = async (transactionData) => {
    try {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_UPDATING_RECURRING_TRANSACTIONS,
      });

      const response = await makeApiRequest({
        method: "POST",
        url: `/api/edit_recurring_transaction/${transactionData.transaction_id}`,
        body: transactionData.transactionDataToSave,
      });

      dispatch({
        type: ACCOUNTS_ACTIONS.UPDATE_RECURRING_TRANSACTIONS,
        payload: response.updatedTransaction,
      });
    } catch (error) {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
        payload: {
          recurringTransactions: true,
        },
      });
    }
  };
  const updateRule = async (body) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_RULES,
    });
    const response = await makeApiRequest({
      method: "POST",
      url: "/api/rules/edit",
      body,
    });

    if (response === null) {
      return null;
    }

    dispatch({
      type: ACCOUNTS_ACTIONS.UPDATE_TRANSACTIONS,
      payload: response.updatedTransactions,
    });
    dispatch({
      type: ACCOUNTS_ACTIONS.UPDATE_RULES,
      payload: response.updatedRule,
    });
  };
  const processPayment = async (body) => {
    const response = await makeApiRequest({
      url: "/api/process_payment",
      method: "POST",
      body: body,
    });
    if (response === null) {
      return;
    }
    return response;
  };
  const savePayment = async (body) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_SUBSCRIPTIONS,
    });
    const response = await makeApiRequest({
      url: "/api/save_payment",
      method: "POST",
      body: body,
    });
    if (response === null) {
      return;
    }

    if (body.paymentDetails.subscription) {
      dispatch({
        type: ACCOUNTS_ACTIONS.ADD_SUBSCRIPTIONS,
        payload: [response.subscriptions],
      });
    } else {
      dispatch({
        type: ACCOUNTS_ACTIONS.ADD_PAYMENTS,
        payload: [response.payment],
      });
    }
    return response;
  };
  const cancelSubscription = async (subscriptionId) => {
    try {
      const updatedSubscriptions = await makeApiRequest({
        url: `/api/cancel_subscription/${subscriptionId}`,
        method: "POST",
      });
      if (updatedSubscriptions === null) {
        return;
      }
      dispatch({
        type: ACCOUNTS_ACTIONS.UPDATE_SUBSCRIPTIONS,
        payload: updatedSubscriptions.subscriptions,
      });
    } catch (error) {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
        payload: { subscriptions: true },
      });
    }
  };

  const deleteUser = async (body) => {
    let response = await makeApiRequest({
      url: "/api/delete_user",
      method: "POST",
    });
    if (response === null) {
      return;
    }
    return true;
  };
  const deleteCategory = async (bodyData) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_CATEGORIES,
    });

    const response = await makeApiRequest({
      url: "/api/delete_category",
      method: "POST",
      body: bodyData,
    });
    if (response === null) {
      return;
    }
    dispatch({
      type: ACCOUNTS_ACTIONS.DELETE_CATEGORIES,
      payload: response.deletedCategories,
    });
  };
  const deleteTransactions = async (selectedRowIds) => {
    dispatch({
      type: ACCOUNTS_ACTIONS.SET_UPDATING_TRANSACTIONS,
    });
    let response = await makeApiRequest({
      method: "POST",
      url: "/api/delete_transactions",
      body: { transactionIds: selectedRowIds },
    });
    if (response === null) {
      return;
    }
    dispatch({
      type: ACCOUNTS_ACTIONS.DELETE_TRANSACTIONS,
      payload: selectedRowIds,
    });
  };
  const deleteParty = async (partyCode) => {
    try {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_UPDATING_PARTIES,
      });
      const response = await makeApiRequest({
        url: `/api/leave_investment_parties/${partyCode}`,
        method: "POST",
      });
      if (response === null) {
        return;
      }
      if (response.message === "Successfully left the party.") {
        dispatch({
          type: ACCOUNTS_ACTIONS.DELETE_PARTIES,
          payload: [partyCode],
        });
      } else {
        console.error("Failed to leave party:", response.error);
      }
      return response;
    } catch (error) {
      console.error("Error leaving party:", error);
    }
  };
  const deleteRecurringTransaction = async (transactionData) => {
    try {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_UPDATING_RECURRING_TRANSACTIONS,
      });
      const response = await makeApiRequest({
        method: "POST",
        url: `/api/delete_recurring_transaction/${transactionData.transaction_id}`,
      });
      dispatch({
        type: ACCOUNTS_ACTIONS.DELETE_RECURRING_TRANSACTIONS,
        payload: response.deletedTransactionIds,
      });
    } catch (error) {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_ERROR_FIELDS,
        payload: {
          recurringTransactions: true,
        },
      });
    }
  };
  const deleteRule = async (rule_id) => {
    try {
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_UPDATING_RULES,
      });

      const response = await makeApiRequest({
        method: "POST",
        url: "/api/rules/delete",
        body: { rule_id },
      });

      if (response.message === "Rule deleted successfully") {
        dispatch({
          type: ACCOUNTS_ACTIONS.DELETE_RULES,
          payload: response.deletedRules,
        });
        dispatch({
          type: ACCOUNTS_ACTIONS.UPDATE_TRANSACTIONS,
          payload: response.updatedTransactions,
        });
      } else {
        throw new Error("Failed to delete rule");
      }
    } catch (error) {
      console.error("Error deleting rule:", error);
    }
  };
  const login = async (username, password) => {
    const response = await makeApiRequest({
      method: "POST",
      url: "/auth/login",
      body: { username, password },
    });
    return response;
  };

  const register = async (fullName, username, email, password) => {
    const response = await makeApiRequest({
      method: "POST",
      url: "/auth/register",
      body: {
        fullName,
        username,
        email,
        password,
      },
    });
    return response;
  };

  const forgotPassword = async (email) => {
    const response = await makeApiRequest({
      method: "POST",
      url: "/auth/forgotPassword",
      body: { email },
      jsonParse: false,
    });

    if (response.status === 500) {
      throw new Error(await response.text());
    }
    return response;
  };

  const resetPassword = async (
    userID,
    requestID,
    password,
    currentPassword
  ) => {
    try {
      let response = await makeApiRequest({
        method: "POST",
        url: "/auth/resetPassword",
        body: {
          userID,
          requestID,
          password,
          currentPassword,
        },
      });
      if (response.response?.status === 422) {
        return response;
      }
      if (response.response?.status === 403) {
        return { invalid: true };
      }
    } catch (error) {
      return error;
    }
    return { success: true };
  };

  const mfaLogin = async (mfaLoginID, code) => {
    const response = await makeApiRequest({
      method: "POST",
      url: "/auth/mfaLogin",
      body: {
        mfaLoginID,
        code,
      },
      jsonParse: false,
    });

    if (response.status === 500) {
      throw new Error(await response.text());
    }

    if (response.status >= 400 && response.status < 500) {
      return response.text();
    }

    return response.json();
  };
  const logoutUser = async () => {
    try {
      await makeApiRequest({ url: "/auth/logout" });
      navigate("/login?logout=true"); // Redirect to the login page
      localStorage.clear();
      appDispatch({ type: APP_ACTIONS.RESET_STATE });
      dispatch({ type: ACCOUNTS_ACTIONS.RESET_STATE });
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  return {
    fetchTransactionsContext,
    fetchRules,
    fetchRecurringTransactionsContext,
    fetchCategoriesContext,
    fetchAccountsContext,
    fetchPayments,
    fetchUserContext,
    fetchInvestmentsContext,
    fetchInvestmentPartiesContext,
    fetchInvestmentsOverTimeContext,
    fetchPreferences,
    fetchDefaultCategories,
    fetchAccountTypes,
    fetchStockPrice,
    fetchSubscriptions,
    refreshToken,
    syncData,
    sendContactRequest,
    addYodleeAccount,
    getAccessToken,
    updateAccount,
    updateCategory,
    updateUserCategories,
    updateTransaction,
    updateRulePriorities,
    updateTransactionCategory,
    updateBulkTransaction,
    updateTransactionSplit,
    addAccount,
    addManualTransaction,
    addingReccurringTransaction,
    updateCategories,
    updatePreferences,
    updateRule,
    updateRecurringTransaction,
    createInvestmentParty,
    createRule,
    addBulkTransactions,
    joinInvestmentParty,
    savePayment,
    processPayment,
    cancelSubscription,
    fetchNetWorthContext,
    deleteUser,
    deleteCategory,
    deleteTransactions,
    deleteParty,
    deleteRecurringTransaction,
    deleteRule,
    login,
    register,
    forgotPassword,
    resetPassword,
    mfaLogin,
    logoutUser,
  };
};
export default useAPIService;
