import React from "react";
import { Box, IconButton, Modal, useMediaQuery, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const OverlayModal = ({
  open,
  onClose,
  isFullScreen,
  hideCloseButton,
  children,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getBoxStyles = () => ({
    position: "relative",
    width: isFullScreen || isMobile ? "100vw" : "md",
    height: isFullScreen || isMobile ? "100vh" : "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
    borderRadius: "10px",
    marginTop: isMobile ? "50px" : "0", // Add margin top for mobile
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={getBoxStyles()}>
        {!hideCloseButton && (
          <IconButton
            sx={{
              position: "absolute",
              top: isFullScreen ? 42 : 16,
              right: 16,
            }}
            onClick={onClose}
          >
            <FontAwesomeIcon
              id="close-rules-modal-button"
              icon={faXmark}
              color={theme.palette.primary.main}
            />
          </IconButton>
        )}
        {children}
      </Box>
    </Modal>
  );
};

export default OverlayModal;
