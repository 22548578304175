import React, {
  useEffect,
  useRef,
  useContext,
  useState,
  useCallback,
} from "react";
import { CollapsibleContext } from "./collapsibleContext";
import "../App.css";
import { useMediaQuery, useTheme } from "@mui/material";

const BottomAdBar = () => {
  const { isCollapsed } = useContext(CollapsibleContext);
  const adRefs = useRef([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const calculateAdWidth = useCallback(() => {
    const viewportWidth = window.innerWidth;
    const leftMargin = isCollapsed ? 104 : 224;
    const availableWidth = viewportWidth - leftMargin;
    const baseAdWidth = 500;
    const numAdsFit = Math.floor(availableWidth / baseAdWidth);

    if (numAdsFit === 0) {
      return availableWidth;
    } else {
      return availableWidth / numAdsFit;
    }
  }, [isCollapsed]);

  const calculateNumberOfAds = useCallback(() => {
    const leftMargin = isCollapsed ? 104 : 224;
    const availableWidth = window.innerWidth - leftMargin;
    const adWidth = calculateAdWidth();
    return Math.max(Math.floor(availableWidth / adWidth), 0);
  }, [isCollapsed, calculateAdWidth]);

  const [numberOfAds, setNumberOfAds] = useState(calculateNumberOfAds);
  const [adWidth, setAdWidth] = useState(calculateAdWidth);

  useEffect(() => {
    const updateAdSizeAndNumber = () => {
      setNumberOfAds(calculateNumberOfAds());
      setAdWidth(calculateAdWidth());
    };

    updateAdSizeAndNumber();
    window.addEventListener("resize", updateAdSizeAndNumber);

    return () => {
      window.removeEventListener("resize", updateAdSizeAndNumber);
    };
  }, [isCollapsed, calculateNumberOfAds, calculateAdWidth]);

  useEffect(() => {
    const loadAd = (ad, attempt = 1, maxAttempts = 5) => {
      if (attempt > maxAttempts) {
        console.error(
          `Max retries reached for ad at index ${adRefs.current.indexOf(ad)}.`
        );
        return;
      }

      if (ad && !ad.getAttribute("data-adsbygoogle-status")) {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (error) {
          console.error(`AdSense error on attempt ${attempt}:`, error.message);
          setTimeout(() => loadAd(ad, attempt + 1, maxAttempts), 1000);
        }
      }
    };

    adRefs.current.forEach((ad) => loadAd(ad));
  }, [numberOfAds]);

  return (
    !isMobile &&
    numberOfAds > 0 && (
      <div
        className="bottombar"
        style={{
          left: isCollapsed ? "104px" : "224px",
          display: "flex",
          justifyContent: "space-around",
          overflow: "hidden",
        }}
      >
        {Array.from({ length: numberOfAds }).map((_, index) => (
          <div
            key={index}
            className="ad-container"
            style={{
              border: "1px solid black",
              backgroundColor: "white",
              textAlign: "center",
              borderRadius: "10px",
              overflow: "hidden",
              width: `${adWidth}px`,
              maxHeight: "100px",
            }}
          >
            <div
              style={{
                fontSize: "0.7rem",
                textAlign: "center",
              }}
            >
              This Is A Paid Ad
            </div>
            <ins
              ref={(el) => (adRefs.current[index] = el)}
              className="adsbygoogle"
              style={{
                display: "block",
                width: "100%",
                height: "90px !important",
              }}
              data-ad-client="ca-pub-9738518352357622"
              data-ad-slot="4791214483"
              data-ad-format="horizontal"
              data-full-width-responsive="true"
            ></ins>
          </div>
        ))}
      </div>
    )
  );
};

export default BottomAdBar;
