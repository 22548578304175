import React, { useState, useContext } from "react";
import Modal from "./modal";
import "../App.css";
import Spinner from "./spinner";
import { AppContext } from "../context/AppContext";
import APIService from "../components/api";

const SupportForm = () => {
  const { sendContactRequest } = APIService();
  const { state: appState } = useContext(AppContext);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const initialIssueType = "";
  const initialMessage = "";
  const initialIsSubmitted = false;
  const [issueType, setIssueType] = useState(initialIssueType);
  const [message, setMessage] = useState(initialMessage);
  const [isSubmitted, setIsSubmitted] = useState(initialIsSubmitted);
  const [isLoading, setIsLoading] = useState(false);
  const handleSupportClick = () => {
    setIsSupportModalOpen(true);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const body = {
      subject: `New feedback from ${appState.user.fullName} about ${issueType}`,
      text: `Pachira Support\n\n${appState.user.fullName} submitted feedback about ${issueType}\n\n${message}`,
      userEmail: appState.user.email,
    };

    const response = await sendContactRequest(body);

    if (response.ok) {
      setIsLoading(false);
      setIsSubmitted(true);
    } else {
      setIsLoading(false);
    }
  };
  const clearAndCloseSupportModal = () => {
    setIssueType(initialIssueType);
    setMessage(initialMessage);
    setIsSubmitted(initialIsSubmitted);
    setIsLoading(false);
    setIsSupportModalOpen(false);
  };
  return (
    <div>
      <button className="whiteButton" onClick={handleSupportClick}>
        Contact Support
      </button>

      {isSupportModalOpen && (
        <Modal title="Support" onClose={() => clearAndCloseSupportModal()}>
          {isSubmitted ? (
            <div className="modalContent">
              <p>Thank you for your feedback!</p>
            </div>
          ) : (
            <div className="modalContent">
              <select
                className="modalSelect"
                value={issueType}
                onChange={(e) => setIssueType(e.target.value)}
              >
                <option value="" disabled>
                  Select Issue Type
                </option>
                <option value="bug">Bug Issue</option>
                <option value="feature">Feature Request</option>
                <option value="design">Design Input</option>
                <option value="other">Other</option>
              </select>
              <textarea
                className="modalInput"
                style={{ height: "150px" }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Describe your issue or feedback"
              />
              {isLoading ? (
                <Spinner />
              ) : (
                <button
                  className="fullLengthButton"
                  style={{
                    backgroundColor:
                      issueType && message ? "#001e80" : "#e0e0e0",
                    color: issueType && message ? "white" : "#ccc",
                    cursor: issueType && message ? "pointer" : "not-allowed",
                  }}
                  onClick={handleSubmit}
                  disabled={!issueType || !message}
                >
                  Send
                </button>
              )}
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default SupportForm;
