import React, { useEffect, useContext } from "react";
import "../App.css";
import { CurrencyContext } from "../context/currencyContext";
import { AccountsContext } from "../context/AccountsContext";
import APIService from "../components/api";

const AssetSummaryCard = () => {
  const { fetchInvestmentsContext } = APIService();

  const { state } = useContext(AccountsContext);
  useEffect(() => {
    if (!state.investmentsLoaded && state.investmentsLoading) {
      fetchInvestmentsContext();
    }
  }, [
    fetchInvestmentsContext,
    state.investmentsLoaded,
    state.investmentsLoading,
  ]); // Depend on the loaded flag

  const { formatCurrency } = useContext(CurrencyContext);
  const calculateSummary = () => {
    let totalValue = 0;
    let totalCostBasis = 0;

    state.investments.forEach((investment) => {
      totalValue += parseFloat(investment.current_price);
      totalCostBasis +=
        parseFloat(investment.quantity) * parseFloat(investment.cost_basis);
    });

    const percentageChange =
      totalCostBasis > 0
        ? ((totalValue - totalCostBasis) / totalCostBasis) * 100
        : 0;
    return { totalValue, percentageChange };
  };

  const { totalValue } = calculateSummary();

  return (
    <div className="asset-summary-card">
      <p>
        Total Asset Value: <strong>{formatCurrency(totalValue)}</strong>
      </p>
    </div>
  );
};

export default AssetSummaryCard;
