import React, { createContext, useState } from "react";

export const CollapsibleContext = createContext();
export const CollapsibleProvider = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <CollapsibleContext.Provider value={{ isCollapsed, toggleCollapse }}>
      {children}
    </CollapsibleContext.Provider>
  );
};
