import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPiggyBank,
  faMoneyBillWave,
  faBriefcase,
  faBars,
  faUniversity,
  faExchangeAlt,
  faRepeat,
  faTools,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import "../App.css";
import { AccountsContext } from "../context/AccountsContext";

const BottomNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path;
  };
  const adRef = useRef(null);
  const [adLoaded, setAdLoaded] = useState(false);
  const rowHeight = 60;
  const totalItems = 9;
  const itemsPerRow = 5;
  const totalRows = Math.ceil(totalItems / itemsPerRow);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleItemClick = (path) => {
    navigate(path);
    setIsExpanded(false);
  };
  const { state } = useContext(AccountsContext);
  useEffect(() => {
    const checkAdContainer = () => {
      if (adRef.current && window.adsbygoogle && !adLoaded) {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
          setAdLoaded(true); // Mark ad as loaded
        } catch (error) {
          console.error("Error loading ad: ", error);
        }
      }
    };

    // Only call this if ad not already loaded
    if (!adLoaded) {
      checkAdContainer();
    }

    return () => {
      // Potential cleanup actions
    };
  }, [adLoaded]);
  return (
    <div className="bottom-nav-wrapper" style={{}}>
      {!state.subscriptions.some(
        (subscription) =>
          subscription.donation === false &&
          (subscription.is_active === true ||
            new Date(subscription.next_payment_date) >=
              new Date().setHours(0, 0, 0, 0))
      ) && (
        <div
          className="ad-container"
          style={{
            maxHeight: "80px !important",
            width: "100%",
            overflow: "hidden",
            border: "1px solid black",
            backgroundColor: "white",
            textAlign: "center",
            borderRadius: "10px",
            display: "flex", // Enabling flexbox
            flexDirection: "column", // Stack children vertically
            justifyContent: "center", // Center items vertically if there's extra space
            alignItems: "center", // Center items horizontally
          }}
        >
          <div
            style={{
              fontSize: "0.7rem",
              textAlign: "center",
            }}
          >
            This Is A Paid Ad
          </div>
          <ins
            ref={adRef}
            className="adsbygoogle"
            style={{
              display: "inline-block",
              height: "50px",
              width: "300px",
            }}
            data-ad-client="ca-pub-9738518352357622"
            data-ad-slot="9653873946"
            data-ad-format="horizontal"
          ></ins>
        </div>
      )}
      <div
        className="bottom-nav-bar"
        style={{
          height: isExpanded ? `${rowHeight * totalRows}px` : `${rowHeight}px`,
        }}
      >
        <div
          className={`bottom-nav-item ${
            isActive("/dashboard") ? "active" : ""
          }`}
          onClick={() => handleItemClick("/dashboard")}
        >
          <FontAwesomeIcon icon={faHome} />
          <span>Dashboard</span>
        </div>

        <div
          className={`bottom-nav-item ${isActive("/budgets") ? "active" : ""}`}
          onClick={() => handleItemClick("/budgets")}
        >
          <FontAwesomeIcon icon={faPiggyBank} />
          <span>Budgets</span>
        </div>
        <div
          className={`bottom-nav-item ${isActive("/accounts") ? "active" : ""}`}
          onClick={() => handleItemClick("/accounts")}
        >
          <FontAwesomeIcon icon={faUniversity} />
          <span>Accounts</span>
        </div>
        <div
          className={`bottom-nav-item ${
            isActive("/transactions") ? "active" : ""
          }`}
          onClick={() => handleItemClick("/transactions")}
        >
          <FontAwesomeIcon icon={faExchangeAlt} />
          <span>Transactions</span>
        </div>

        <div
          className="bottom-nav-item"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <FontAwesomeIcon icon={faBars} />
          <span>More</span>
        </div>

        <div
          className={`bottom-nav-item ${isActive("/cashFlow") ? "active" : ""}`}
          onClick={() => handleItemClick("/cashFlow")}
        >
          <FontAwesomeIcon icon={faMoneyBillWave} />
          <span>Cash Flow</span>
        </div>

        <div
          className={`bottom-nav-item ${
            isActive("/portfolio") ? "active" : ""
          }`}
          onClick={() => handleItemClick("/portfolio")}
        >
          <FontAwesomeIcon icon={faBriefcase} />
          <span>Portfolio</span>
        </div>

        <div
          className={`bottom-nav-item ${
            isActive("/investmentParties") ? "active" : ""
          }`}
          onClick={() => handleItemClick("/investmentParties")}
        >
          <FontAwesomeIcon icon={faUsers} />
          <span>Investment Parties</span>
        </div>
        <div
          className={`bottom-nav-item ${
            isActive("/recurringTransactions") ? "active" : ""
          }`}
          onClick={() => handleItemClick("/recurringTransactions")}
        >
          <FontAwesomeIcon icon={faRepeat} />
          <span>Recurring</span>
        </div>
        <div
          className={`bottom-nav-item ${isActive("/settings") ? "active" : ""}`}
          onClick={() => handleItemClick("/settings")}
        >
          <FontAwesomeIcon icon={faTools} />
          <span>Settings</span>
        </div>
      </div>
    </div>
  );
};

export default BottomNavBar;
