import React, { useState } from "react";
import "../App.css";

const Modal = ({ onClose, title, description, children, style }) => {
  const [isMouseDownOnBackground, setIsMouseDownOnBackground] = useState(false);

  const handleMouseDown = (e) => {
    if (e.target.id === "modalBackground") {
      setIsMouseDownOnBackground(true);
    }
  };

  const handleMouseUp = (e) => {
    if (isMouseDownOnBackground && e.target.id === "modalBackground") {
      onClose();
    }
    setIsMouseDownOnBackground(false); // Reset the flag
  };

  return (
    <div
      id="modalBackground"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      className="modal-background"
    >
      <div className="modal-content" style={style}>
        <button onClick={onClose} className="modal-close">
          &times;
        </button>
        <h2 className="modalTitle">{title}</h2>
        {description && <p className="modal-description">{description}</p>}
        {children && (
          <div className="modal-children modalContent">{children}</div>
        )}
      </div>
    </div>
  );
};

export default Modal;
