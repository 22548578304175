import React, { useContext, useEffect, useState } from "react";
import Modal from "./modal";
import APIService from "./api";
import { DataGrid } from "@mui/x-data-grid";
import Papa from "papaparse";
import Spinner from "./spinner";
import "../App.css";
import {
  ACTION_TYPES as ACCOUNTS_ACTIONS,
  AccountsContext,
} from "../context/AccountsContext";
import { Button } from "@mui/material";
const TransactionsImport = ({ closePopover }) => {
  const { fetchAccountsContext, fetchCategoriesContext, addBulkTransactions } =
    APIService();
  const { state, dispatch } = useContext(AccountsContext);
  const [fileName, setFileName] = useState("");
  const [showHeaderMappingModal, setShowHeaderMappingModal] = useState(false);
  const [headerMappings, setHeaderMappings] = useState({});
  const [uploadedHeaders, setUploadedHeaders] = useState([]);
  const [defaultValues, setDefaultValues] = useState({});
  const [importType, setImportType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [mintFile, setMintFile] = useState(null);
  const [isImportButtonActive, setIsImportButtonActive] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [categoryMappings, setCategoryMappings] = useState({});
  const [data, setData] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [showCategoryMappingModal, setShowCategoryMappingModal] =
    useState(false);
  const [toImport, setToImport] = useState([]);
  const [notToImport, setNotToImport] = useState([]);
  const [showAccountMappingModal, setShowAccountMappingModal] = useState(false);
  const [showImportConfirmationModal, setShowImportConfirmationModal] =
    useState(false);
  const [accountMappings, setAccountMappings] = useState({});
  const [uniqueAccounts, setUniqueAccounts] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  useEffect(() => {
    if (!state.categoriesLoaded && !state.categoriesLoading) {
      fetchCategoriesContext();
    }
    if (!state.accountsLoaded && !state.accountsLoading) {
      fetchAccountsContext();
    }
  }, [
    fetchCategoriesContext,
    fetchAccountsContext,
    state.accountsLoaded,
    state.categoriesLoaded,
    state.accountsLoading,
    state.categoriesLoading,
  ]);

  const handleImportClick = (type) => {
    setImportType(type);
    setMintFile(null);
    setIsImportButtonActive(false);
    setShowModal2(true);
  };

  const handleCategoryMappingChange = (importedCategory, pachiraCategoryId) => {
    setCategoryMappings((prevMappings) => ({
      ...prevMappings,
      [importedCategory]: pachiraCategoryId,
    }));
  };
  const getUniqueAccounts = (data) => {
    const hasAccountNameColumn = data.some((row) => "Account Name" in row);
    const accountColumnName = hasAccountNameColumn ? "Account Name" : "Account";
    const accountNames = data.map((row) => row[accountColumnName] || "");
    return [...new Set(accountNames)].filter((name) => name !== "");
  };

  const handleCategoryMappingComplete = () => {
    const updatedData = data.map((row) => ({
      ...row,
      pachira_category_id: categoryMappings[row["Category"]],
    }));

    setData(updatedData);
    const accountsFromData = getUniqueAccounts(updatedData);
    setUniqueAccounts(accountsFromData);
    setShowAccountMappingModal(true);
  };

  const expectedHeaders = {
    Mint: [
      "Date",
      "Description",
      "Original Description",
      "Amount",
      "Transaction Type",
      "Category",
      "Account Name",
      "Labels",
      "Notes",
    ],
    Manual: [
      "Date",
      "Description",
      "Merchant",
      "Amount",
      "Category",
      "Account",
      "Notes",
    ],
  };
  const createCsvString = () => {
    return expectedHeaders[importType].join(",") + "\r\n";
  };
  const downloadCsv = () => {
    const csvString = createCsvString();
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Pachira_Import_Template.csv";
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const showError = (message) => {
    setErrorMessage(message);
    setShowErrorModal(true);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const headers = parseHeaders(text);

        if (importType === "Mint") {
          handleMintUpload(headers, file);
        } else {
          handleManualUpload(headers, file);
        }
      };
      reader.readAsText(file);
    } else {
      showError("Please upload a valid CSV file.");
      resetUploadState();
    }
  };
  const parseHeaders = (text) => {
    return text
      .split("\n")[0]
      .split(",")
      .map((header) => header.trim().replace(/^"|"$/g, ""));
  };
  const handleMintUpload = (headers, file) => {
    const validation = areHeadersValid(headers, expectedHeaders[importType]);
    if (validation.isValid) {
      setMintFile(file);
      setIsImportButtonActive(true);
    } else {
      showError(
        "Missing headers for Mint import:\n" +
          validation.missingHeaders.join("\n")
      );
      resetUploadState();
    }
  };
  const handleManualUpload = (headers, file) => {
    setUploadedHeaders(headers);
    const initialMappings = findSimilarHeaders(
      headers,
      expectedHeaders[importType]
    );
    setHeaderMappings(initialMappings);
    setMintFile(file);

    Papa.parse(file, {
      complete: (result) => {
        const parsedData = result.data.filter(
          (row) => Object.keys(row).length > 0 && row["Category"]
        );

        setData(parsedData);
      },
      header: true,
      skipEmptyLines: true,
    });

    setShowHeaderMappingModal(true);

    setIsImportButtonActive(true);
  };
  const findSimilarHeaders = (uploadedHeaders, expectedHeaders) => {
    let mappings = {};

    expectedHeaders.forEach((expectedHeader) => {
      const similarHeader = uploadedHeaders.find((uploadedHeader) =>
        uploadedHeader.toLowerCase().includes(expectedHeader.toLowerCase())
      );
      mappings[expectedHeader] = similarHeader || null;
    });

    return mappings;
  };

  const resetUploadState = () => {
    setMintFile(null);
    setIsImportButtonActive(false);
    setHeaderMappings({});
    setUploadedHeaders([]);
  };
  const handleHeaderMappingChange = (event, expectedHeader) => {
    const value = event.target.value;
    setHeaderMappings((prev) => ({ ...prev, [expectedHeader]: value }));
  };

  const handleDefaultValueChange = (e, expectedHeader) => {
    setDefaultValues((prevDefaults) => ({
      ...prevDefaults,
      [expectedHeader]: e.target.value,
    }));
  };
  const processMappings = () => {
    let processedData = [];
    data.forEach((row) => {
      let newRow = {};
      expectedHeaders[importType].forEach((expectedHeader) => {
        const headerMapping = headerMappings[expectedHeader];
        if (headerMapping === "setDefault") {
          newRow[expectedHeader] = defaultValues[expectedHeader];
        } else {
          newRow[expectedHeader] = row[headerMapping];
        }
      });
      processedData.push(newRow);
    });
    setData(processedData);
    setShowHeaderMappingModal(false);
  };

  const renderHeaderMappingDropdowns = (uploadedHeaders, similarMappings) => {
    if (importType && expectedHeaders[importType]) {
      return expectedHeaders[importType].map((expectedHeader) => (
        <div key={expectedHeader} className="mappingRow">
          <label className="mappingLabel">{expectedHeader}</label>
          <select
            value={headerMappings[expectedHeader] || ""}
            onChange={(e) => handleHeaderMappingChange(e, expectedHeader)}
            className="mappingDropdown"
          >
            <option value="">Select Column</option>
            {uploadedHeaders.map((header) => (
              <option key={header} value={header}>
                {header}
              </option>
            ))}
            <option value="setDefault">Set Default Value</option>
          </select>
          {headerMappings[expectedHeader] === "setDefault" && (
            <input
              type="text"
              placeholder="Enter default value"
              onChange={(e) => handleDefaultValueChange(e, expectedHeader)}
              className="defaultValueInput"
            />
          )}
        </div>
      ));
    }
    return null;
  };
  const areHeadersValid = (headers, expectedHeaders) => {
    const missingHeaders = expectedHeaders.filter(
      (expectedHeader) => !headers.includes(expectedHeader)
    );
    const extraHeaders = headers.filter(
      (header) => !expectedHeaders.includes(header)
    );

    return {
      isValid: missingHeaders.length === 0 && extraHeaders.length === 0,
      missingHeaders,
      extraHeaders,
      foundHeaders: headers,
    };
  };

  const handleStartImport = () => {
    if (mintFile && !data.length) {
      Papa.parse(mintFile, {
        complete: (result) => {
          const parsedData = result.data.filter(
            (row) => Object.keys(row).length > 0 && row["Category"]
          );

          setData(parsedData);
          const categories = getUniqueCategories(parsedData);
          setUniqueCategories(categories);
          setShowCategoryMappingModal(true);
        },
        header: true,
        skipEmptyLines: true,
      });
    } else if (mintFile && data) {
      const categories = getUniqueCategories(data);
      setUniqueCategories(categories);
      setShowCategoryMappingModal(true);
    }
  };

  const getUniqueCategories = (data) => {
    const categoryCounts = data.reduce((acc, row) => {
      const category = row["Category"];
      if (category && category.trim() !== "") {
        acc[category] = (acc[category] || 0) + 1;
      }
      return acc;
    }, {});

    return Object.entries(categoryCounts)
      .sort((a, b) => b[1] - a[1])
      .map((entry) => entry[0]);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      handleFileUpload(event);
    }
  };

  const getSortedAndGroupedCategories = () => {
    const typeOrder = { INC: 1, TRA: 2, EXP: 3 };
    let groupedCategories = [];

    // Filter and sort Level 1 categories by type
    let sortedLevel1Categories = state.categories
      .filter((cat) => cat.level === 1)
      .sort((a, b) => {
        const typeComparison = typeOrder[a.type] - typeOrder[b.type];
        if (typeComparison !== 0) {
          return typeComparison;
        }
        return a.category_name.localeCompare(b.category_name);
      });
    sortedLevel1Categories.forEach((level1Cat) => {
      let subCategories = state.categories
        .filter((cat) => cat.parent_id === level1Cat.category_id)
        .sort((a, b) => a.category_name.localeCompare(b.category_name));

      if (subCategories.length > 0) {
        groupedCategories.push({
          parent: level1Cat,
          children: subCategories,
        });
      }
    });

    return groupedCategories;
  };
  const renderDropdownOptions = () => {
    const groupedCategories = getSortedAndGroupedCategories();
    return groupedCategories.flatMap((group) => {
      return [
        <option
          key={group.parent.category_id}
          value={group.parent.category_id}
          disabled
          className="optionGroup"
        >
          {group.parent.category_name}
        </option>,
        ...group.children.map((subCategory) => (
          <option
            key={subCategory.category_id}
            value={subCategory.category_id}
            className="option"
          >
            {subCategory.category_name}
          </option>
        )),
      ];
    });
  };

  const handleAccountMappingChange = (importedAccount, pachiraAccountId) => {
    setAccountMappings((prevMappings) => ({
      ...prevMappings,
      [importedAccount]: pachiraAccountId,
    }));
  };

  const handleAccountMappingComplete = async () => {
    let updatedDataWithAccounts;

    if (importType === "Manual") {
      updatedDataWithAccounts = data.map((row) => {
        const accountUuid = accountMappings[row["Account"]];
        const accountName = row["Account"];
        return {
          ...row,
          pachira_account_uuid: accountUuid,
          account_name: accountName,
        };
      });

      const { toImport, notToImport } = prepareDataForInsertion(
        updatedDataWithAccounts
      );

      setToImport(toImport);
      setNotToImport(notToImport);
      setShowImportConfirmationModal(true);
    } else {
      updatedDataWithAccounts = data.map((row) => {
        const accountUuid = accountMappings[row["Account Name"]];
        const accountType = state.accounts.find(
          (acc) => acc.pachira_account_uuid === accountUuid
        )?.type;
        let amount = parseFloat(row["Amount"]);

        if (
          row["Transaction Type"].toLowerCase() === "debit" &&
          accountType === "asset"
        ) {
          amount = -Math.abs(amount);
        } else if (
          row["Transaction Type"].toLowerCase() === "credit" &&
          accountType === "asset"
        ) {
          amount = Math.abs(amount);
        } else if (
          row["Transaction Type"].toLowerCase() === "debit" &&
          accountType === "liability"
        ) {
          amount = -Math.abs(amount);
        } else if (
          row["Transaction Type"].toLowerCase() === "credit" &&
          accountType === "liability"
        ) {
          amount = Math.abs(amount);
        }

        return {
          ...row,
          pachira_account_uuid: accountUuid,
          adjusted_amount: amount,
          Account: row["Account Name"],
        };
      });
      const { toImport, notToImport } = prepareDataForInsertion(
        updatedDataWithAccounts
      );

      setToImport(toImport);
      setNotToImport(notToImport);
      setShowImportConfirmationModal(true);
    }
  };

  const handleImportToDatabase = async () => {
    setShowLoadingModal(true);
    try {
      await bulkUploadTransactions(toImport);
      dispatch({
        type: ACCOUNTS_ACTIONS.SET_UNLOADED_TRANSACTIONS,
      });
    } catch (error) {
      console.error("Error uploading transactions:", error);
    } finally {
      setShowLoadingModal(false);
    }
  };

  const prepareDataForInsertion = (data) => {
    const transactionsToImport = [];
    const transactionsNotToImport = [];

    data.forEach((row) => {
      if (
        row["pachira_category_id"] !== "doNotImport" &&
        row["pachira_account_uuid"] !== "doNotImport" &&
        row["pachira_account_uuid"] !== false &&
        row["pachira_account_uuid"] !== undefined
      ) {
        const amount =
          importType === "Mint" ? row["adjusted_amount"] : row["Amount"];

        transactionsToImport.push({
          date: row["Date"],
          description: row["Description"],
          merchant_name: row["Merchant"] || null,
          amount: amount,
          category_id: row["pachira_category_id"] || null,
          account_id: row["pachira_account_uuid"] || null,
          notes: row["Notes"] || "",
          category_name: row["Category"] || null,
          account_name: row["Account"] || null,
        });
      } else {
        transactionsNotToImport.push(row);
      }
    });

    return {
      toImport: transactionsToImport,
      notToImport: transactionsNotToImport,
    };
  };

  const bulkUploadTransactions = async (preparedData) => {
    await addBulkTransactions(preparedData);
    setShowSuccessModal(true);
  };
  const columns = [
    { field: "date", headerName: "Date", width: 110, maxWidth: 150 },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      flex: 1,
      maxWidth: 400,
    },
    {
      field: "merchant_name",
      headerName: "Description",
      width: 150,
      flex: 1,
      maxWidth: 400,
    },
    { field: "amount", headerName: "Amount", width: 110, maxWidth: 150 },
    {
      field: "category_name",
      headerName: "Category",
      width: 150,
      maxWidth: 200,
    },
    { field: "account_name", headerName: "Account", width: 150, maxWidth: 200 },
    { field: "notes", headerName: "Notes", width: 200, flex: 1, maxWidth: 400 },
  ];

  const columnsNotToImport = [
    { field: "Date", headerName: "Date", width: 110, maxWidth: 150 },
    {
      field: "Description",
      headerName: "Description",
      width: 200,
      flex: 1,
      maxWidth: 400,
    },
    {
      field: "Merchant",
      headerName: "Description",
      width: 150,
      flex: 1,
      maxWidth: 400,
    },
    { field: "Amount", headerName: "Amount", width: 110, maxWidth: 150 },
    {
      field: "Category",
      headerName: "Category",
      width: 150,
      maxWidth: 200,
    },
    { field: "Account", headerName: "Account", width: 150, maxWidth: 200 },
    { field: "Notes", headerName: "Notes", width: 200, flex: 1, maxWidth: 400 },
  ];
  const prepareTransactionsForGrid = (transactions) =>
    transactions.map((t, index) => ({ ...t, id: t.id || index }));
  const closeAllModals = () => {
    setShowModal(false);
    setShowModal2(false);
    closePopover();
    setMintFile(null);
    setIsImportButtonActive(false);
    setShowErrorModal(false);
    setErrorMessage("");

    setCategoryMappings({});
    setData([]);
    setShowCategoryMappingModal(false);

    setShowAccountMappingModal(false);
    setShowImportConfirmationModal(false);
    setAccountMappings({});
    setUniqueAccounts([]);
    setShowSuccessModal(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        sx={{ boxShadow: "none", "&:hover": { boxShadow: "none" } }}
        onClick={() => {
          setShowModal(true);
        }}
      >
        Import Transactions
      </Button>
      {showModal && (
        <Modal
          open={showModal}
          onClose={() => {
            closePopover();
            setShowModal(false);
          }}
          title="Import Transactions"
        >
          <div className="modalContent">
            <p className="modalText">
              Before importing transactions, please make sure that all your
              categories are set up and all accounts are either connected or
              manually added. This ensures a smooth and accurate import process.
            </p>
            <button
              className="blackButton"
              onClick={() => handleImportClick("Manual")}
            >
              Manual Import
            </button>
            <br></br>
            <button
              className="greenButton"
              onClick={() => handleImportClick("Mint")}
            >
              Mint Import
            </button>
          </div>
        </Modal>
      )}
      {showModal2 && (
        <Modal
          open={showModal}
          onClose={() => setShowModal2(false)}
          title={`${importType} Import`}
        >
          <div className="modalContent">
            <div>
              <input
                type="file"
                accept=".csv"
                id="fileInput"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />

              <button
                className="whiteButton"
                onClick={() => document.getElementById("fileInput").click()}
              >
                Choose File
              </button>

              {fileName && <span className="fileName">{fileName}</span>}
              {importType === "Mint" ? (
                <p>
                  Instructions on how to import transactions from {importType}:
                </p>
              ) : (
                <p>Instructions on how to import transactions manually:</p>
              )}
              {importType === "Mint" ? (
                <ol className="instructionsList">
                  <li>Log in to your Mint account.</li>
                  <li>Navigate to the Transactions tab.</li>
                  <li>Select the desired transactions and export as CSV.</li>
                  <li>Upload the CSV file here.</li>
                </ol>
              ) : (
                <ol className="instructionsList">
                  <strong>Option 1: Use Your Own CSV File</strong>
                  <ul>
                    <li>
                      Download transactions data from your bank, spreadsheet, or
                      budgeting app.
                    </li>
                    <li>Upload the CSV file here.</li>
                    <li>
                      You will be prompted to match each column to the
                      corresponding Pachira field.
                    </li>
                  </ul>
                  <br></br>
                  <strong>Option 2: Use Pachira's Template</strong>
                  <ul>
                    <li>
                      Download transactions data from your bank, spreadsheet, or
                      budgeting app.
                    </li>
                    <li>
                      Download the template CSV File by clicking the button
                      below.
                      <button onClick={downloadCsv} className="whiteButton">
                        Download Import Template
                      </button>
                    </li>
                    <li>
                      Fill the template with your transaction data from your
                      file.
                    </li>
                    <li>Upload the completed CSV template here.</li>
                  </ul>
                </ol>
              )}
            </div>

            <button
              onClick={handleStartImport}
              disabled={!isImportButtonActive}
              className="fullLengthButton"
            >
              Start Import
            </button>
          </div>
        </Modal>
      )}
      {showErrorModal && (
        <Modal
          open={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          title="Error"
        >
          <div className="modalContent">
            <p>{errorMessage}</p>
            <button
              onClick={() => setShowErrorModal(false)}
              className="blueButton"
            >
              Close
            </button>
          </div>
        </Modal>
      )}

      {showHeaderMappingModal && (
        <Modal
          open={showHeaderMappingModal}
          onClose={() => setShowHeaderMappingModal(false)}
          title="Map CSV Headers"
        >
          <div className="modalContent">
            {renderHeaderMappingDropdowns(uploadedHeaders, headerMappings)}
            <button onClick={processMappings} className="fullLengthButton">
              Confirm Mappings
            </button>
          </div>
        </Modal>
      )}
      {showCategoryMappingModal && (
        <Modal
          open={showCategoryMappingModal}
          onClose={() => setShowCategoryMappingModal(false)}
          title="Map Categories"
        >
          <div className="modalContent">
            <div className="mappingContainer">
              {uniqueCategories.map((category, index) => (
                <div key={index} className="MappingRow">
                  <span className="imported">{category}</span>
                  <select
                    className="pachiraDropdown"
                    onChange={(e) =>
                      handleCategoryMappingChange(category, e.target.value)
                    }
                  >
                    <option value="">Leave Uncategorized</option>
                    <option value="doNotImport">-- DO NOT IMPORT --</option>
                    {renderDropdownOptions()}
                  </select>
                </div>
              ))}
            </div>
            <button
              onClick={handleCategoryMappingComplete}
              className="fullLengthButton"
            >
              Continue
            </button>
          </div>
        </Modal>
      )}
      {showAccountMappingModal && (
        <Modal
          open={showAccountMappingModal}
          onClose={() => setShowAccountMappingModal(false)}
          title="Map Accounts"
        >
          <div className="modalContent">
            <div className="mappingContainer">
              {uniqueAccounts.map((account, index) => (
                <div key={index} className="mappingRow">
                  <span className="imported">{account}</span>
                  <select
                    className="pachiraDropdown"
                    onChange={(e) =>
                      handleAccountMappingChange(account, e.target.value)
                    }
                  >
                    <option value="" disabled>
                      Select Pachira Account
                    </option>
                    <option value="doNotImport">-- DO NOT IMPORT --</option>
                    {state.accounts.map((pachiraAccount) => {
                      let displayName = "";
                      switch (pachiraAccount.preferred_name) {
                        case "name":
                          displayName = pachiraAccount.name;
                          break;
                        case "nickname":
                          displayName = pachiraAccount.nickname;
                          break;
                        case "custom":
                          displayName = pachiraAccount.custom_name;
                          break;
                        default:
                          displayName = pachiraAccount.name;
                      }

                      return (
                        <option
                          key={pachiraAccount.pachira_account_uuid}
                          value={pachiraAccount.pachira_account_uuid}
                        >
                          {displayName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ))}
            </div>
            <button
              onClick={handleAccountMappingComplete}
              className="fullLengthButton"
            >
              Continue
            </button>
          </div>
        </Modal>
      )}
      {showImportConfirmationModal && (
        <Modal
          open={showImportConfirmationModal}
          onClose={() => setShowImportConfirmationModal(false)}
          title="Review Transactions"
          style={{ width: "auto" }}
        >
          <div className="reviewModalContent">
            <h2>Transactions to be Imported</h2>
            <div className="dataGridFullWidthContainer">
              <div className="dataGridFullWidth">
                <DataGrid
                  rows={prepareTransactionsForGrid(toImport)}
                  columns={columns}
                  pageSize={100}
                  disableRowSelectionOnClick
                />
              </div>
            </div>

            <h2>Transactions Not to be Imported</h2>
            <div className="dataGridFullWidthContainer">
              <div className="dataGridFullWidth">
                <DataGrid
                  rows={prepareTransactionsForGrid(notToImport)}
                  columns={columnsNotToImport}
                  pageSize={100}
                  disableRowSelectionOnClick
                />
              </div>
            </div>

            {showLoadingModal ? (
              <div className="modalContent">
                <Spinner />
              </div>
            ) : (
              <button
                onClick={handleImportToDatabase}
                className="fullLengthButton"
              >
                Confirm Import
              </button>
            )}
          </div>
        </Modal>
      )}

      {showSuccessModal && (
        <Modal
          open={showSuccessModal}
          onClose={() => closeAllModals()}
          title="Congratulations!"
        >
          <div className="successModalContent">
            <p>
              {importType === "Mint"
                ? "Your Mint data has been successfully uploaded into your account."
                : "Your manual data has been successfully uploaded into your account."}
            </p>
            <p>You can now go back to your dashboard to see how it looks.</p>
            <button onClick={() => closeAllModals()} className="greenButton">
              Finish
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TransactionsImport;
