import React from "react";
import logo from "../images/Icon_192x192.png";
import "../App.css";

const LoadingWithLogo = () => {
  return (
    <div className="loadingContainer">
      <div className="loading-brand-container">
        <img src={logo} alt="Main Logo" className="logo" />
      </div>
      <div className="logo-spinner"></div>
    </div>
  );
};

export default LoadingWithLogo;
