import React, { useEffect } from "react";
import "../App.css";
import AssetSummaryCard from "../components/assetSummaryCard";
import InvestmentListCard from "../components/investmentListCard";
import InvestmentGraphs from "../components/investmentGraphs";

const Portfolio = () => {
  useEffect(() => {
    document.title = "Portfolio - Pachira";
  });
  return (
    <div className="dashboard">
      <div className="header">
        <h1>Portfolio</h1>
      </div>
      <AssetSummaryCard />
      <InvestmentListCard />
      <InvestmentGraphs />
    </div>
  );
};

export default Portfolio;
