import React, { useState, useContext } from "react";
import brand from "../images/Pachira Vector Black.svg";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../components/spinner";
import { AuthContext } from "../authentication/authenticationContext";
import APIService from "../components/api";

const MFALogin = () => {
  const { mfaLogin } = APIService();
  const { dispatch: authDispatch } = useContext(AuthContext);
  const [code, setCode] = useState("");
  const { mfaLoginID } = useParams();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleCodeChange = (e) => {
    const inputCode = e.target.value;
    if (/^\d{0,8}$/.test(inputCode)) {
      setCode(inputCode);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      setError();
      const data = await mfaLogin(mfaLoginID, code, authDispatch);

      if (!data.UUID) {
        setError(data);
        return;
      }

      navigate("/dashboard");
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <div className="container">
      <div className="loginForm">
        <img src={brand} alt="Pachira Logo" className="logo" />
        <h2 className="title">Multi-Factor Authentication</h2>
        <form onSubmit={handleSubmit}>
          <label className="label">
            Enter 8-digit Authentication Code:
            <input
              className={"input-full"}
              type="text"
              value={code}
              onChange={handleCodeChange}
              maxLength={8}
              pattern="\d{0,8}"
              placeholder="Enter code"
              required
            />
          </label>
          <button
            type="submit"
            className="fullLengthButton"
            disabled={code.length !== 8 || isLoading}
          >
            {isLoading ? "Authenticating..." : "Authenticate"}{" "}
          </button>
        </form>
        <button className="fullLengthButton" onClick={handleLogin}>
          Return To Log In
        </button>
        {isLoading && <Spinner />}
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default MFALogin;
