import React, { useState, useEffect, useContext, useCallback } from "react";
import { CurrencyContext } from "../context/currencyContext";
import "../App.css";
import Spinner from "./spinner";

const BudgetTable = ({ categories, onSave }) => {
  const { formatCurrency } = useContext(CurrencyContext);
  const [budgetData, setBudgetData] = useState([]);
  const [lastEdited, setLastEdited] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const updateParentCategories = useCallback((data) => {
    return data.map((category) => {
      if (category.level === 1 && !category.parent_budget) {
        return {
          ...category,
          weekly: calculateSumOfChildren(category.category_id, "weekly", data),
          monthly: calculateSumOfChildren(
            category.category_id,
            "monthly",
            data
          ),
          yearly: calculateSumOfChildren(category.category_id, "yearly", data),
        };
      }
      return category;
    });
  }, []);
  const updateParentCategoryTotals = useCallback(
    (data) => {
      const newData = updateParentCategories(data);
      setBudgetData(newData);
    },
    [updateParentCategories]
  );
  useEffect(() => {
    const filteredCategories = categories.filter(
      (category) => category.type === "EXP"
    );

    const calculateChildrenTotal = (parentId, frequency) => {
      return filteredCategories
        .filter((child) => child.parent_id === parentId)
        .reduce(
          (acc, child) =>
            acc + calculateAmount(child.amount, child.frequency, frequency),
          0
        );
    };

    const calculateAmount = (amount, currentFrequency, targetFrequency) => {
      switch (targetFrequency) {
        case "W":
          return calculateWeeklyAmount(amount, currentFrequency);
        case "M":
          return calculateMonthlyAmount(amount, currentFrequency);
        case "Y":
          return calculateYearlyAmount(amount, currentFrequency);
        default:
          return 0;
      }
    };

    const initialData = filteredCategories.map((category) => {
      let weekly = calculateWeeklyAmount(category.amount, category.frequency);
      let monthly = calculateMonthlyAmount(category.amount, category.frequency);
      let yearly = calculateYearlyAmount(category.amount, category.frequency);

      if (category.level === 1 && category.amount === 0) {
        weekly = calculateChildrenTotal(category.category_id, "W");
        monthly = calculateChildrenTotal(category.category_id, "M");
        yearly = calculateChildrenTotal(category.category_id, "Y");
      }

      return {
        ...category,
        originalFrequency: category.frequency || "W",
        weekly: weekly,
        monthly: monthly,
        yearly: yearly,
      };
    });

    updateParentCategoryTotals(initialData);

    const initialLastEdited = filteredCategories.reduce((acc, category) => {
      acc[category.category_id] = category.frequency || "W";
      return acc;
    }, {});

    setLastEdited(initialLastEdited);
  }, [categories, updateParentCategoryTotals]);

  const handleFocus = (event) => {
    event.target.select();
  };
  const handleParentBudgetToggle = (categoryId, isParentBudget) => {
    setBudgetData((prevBudgetData) => {
      const newBudgetData = budgetData.map((category) => {
        if (category.category_id === categoryId) {
          const updatedCategory = {
            ...category,
            parent_budget: isParentBudget,
          };
          if (!isParentBudget) {
            updatedCategory.weekly = calculateSumOfChildren(
              categoryId,
              "weekly",
              budgetData
            );
            updatedCategory.monthly = calculateSumOfChildren(
              categoryId,
              "monthly",
              budgetData
            );
            updatedCategory.yearly = calculateSumOfChildren(
              categoryId,
              "yearly",
              budgetData
            );
          }
          return updatedCategory;
        }
        return category;
      });
      setBudgetData(newBudgetData);
      return newBudgetData;
    });
  };

  const handleValueChange = (categoryId, period, value, isBlur = false) => {
    const newValue = isBlur ? parseFloat(value).toFixed(2) : value;
    setLastEdited((prev) => ({ ...prev, [categoryId]: period }));
    setBudgetData((prevBudgetData) => {
      let updatedData = prevBudgetData.map((category) => {
        if (category.category_id === categoryId) {
          let updatedCategory = { ...category, [period]: newValue };

          if (
            !category.parent_budget ||
            isChild(category, categoryId, prevBudgetData)
          ) {
            switch (period) {
              case "weekly":
                updatedCategory.monthly = (parseFloat(newValue) * 4.33).toFixed(
                  2
                );
                updatedCategory.yearly = (parseFloat(newValue) * 52).toFixed(2);
                break;
              case "monthly":
                updatedCategory.weekly = (parseFloat(newValue) / 4.33).toFixed(
                  2
                );
                updatedCategory.yearly = (parseFloat(newValue) * 12).toFixed(2);
                break;
              case "yearly":
                updatedCategory.weekly = (parseFloat(newValue) / 52).toFixed(2);
                updatedCategory.monthly = (parseFloat(newValue) / 12).toFixed(
                  2
                );
                break;
              default:
                break;
            }
          }
          if (category.level === 1 && category.parent_budget) {
            switch (period) {
              case "weekly":
                updatedCategory.monthly = (parseFloat(newValue) * 4.33).toFixed(
                  2
                );
                updatedCategory.yearly = (parseFloat(newValue) * 52).toFixed(2);
                break;
              case "monthly":
                updatedCategory.weekly = (parseFloat(newValue) / 4.33).toFixed(
                  2
                );
                updatedCategory.yearly = (parseFloat(newValue) * 12).toFixed(2);
                break;
              case "yearly":
                updatedCategory.weekly = (parseFloat(newValue) / 52).toFixed(2);
                updatedCategory.monthly = (parseFloat(newValue) / 12).toFixed(
                  2
                );
                break;
              default:
                break;
            }
          }
          return updatedCategory;
        }
        return category;
      });

      if (isBlur) {
        updatedData = updateParentCategories(updatedData);
      }

      return updatedData;
    });
  };
  const calculateSumOfChildren = (parentId, period, data) => {
    return data
      .filter((child) => child.parent_id === parentId)
      .reduce((acc, child) => acc + parseFloat(child[period] || 0), 0)
      .toFixed(2);
  };
  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const handleSave = async () => {
    setIsLoading(true);

    const updatedCategories = budgetData.map((category) => {
      const frequency = lastEdited[category.category_id] || "weekly";
      let amount = category[frequency];

      if (frequency === "weekly") {
        amount = category.weekly;
      } else if (frequency === "monthly") {
        amount = category.monthly;
      } else if (frequency === "yearly") {
        amount = category.yearly;
      }

      return {
        category_id: category.category_id,
        amount: amount,
        frequency: frequency.charAt(0).toUpperCase() + frequency.slice(1),
        parent_budget:
          category.level === 1 ? category.parent_budget : undefined,
      };
    });

    try {
      await onSave(updatedCategories);
    } catch (error) {
      console.error("Error during saving:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleBlur = (categoryId, period, value) => {
    handleValueChange(categoryId, period, value, true);
    setBudgetData((prevBudgetData) => {
      return prevBudgetData.map((category) => {
        if (category.category_id === categoryId) {
          return {
            ...category,
            frequency: period.charAt(0).toUpperCase(),
          };
        }
        return category;
      });
    });
  };

  const isChild = (category, categoryId, data) => {
    return data.some((cat) => cat.category_id === category.parent_id);
  };

  const renderCategoryRow = (category, isChild = false) => {
    let isEditable;
    if (isChild) {
      const parentCategory = budgetData.find(
        (parent) => parent.category_id === category.parent_id
      );
      isEditable = parentCategory ? !parentCategory.parent_budget : true;
    } else {
      isEditable = category.level === 1 ? category.parent_budget : true;
    }

    return (
      <tr
        key={category.category_id}
        className={isChild ? "level2Category" : "level1Category"}
      >
        <td style={isChild ? { textAlign: "right", paddingRight: "10px" } : {}}>
          {isChild ? (
            <span>{category.category_name}</span>
          ) : (
            <div className="category-row">
              <div className="category-name">{category.category_name}</div>
              {category.level === 1 && (
                <div className="checkbox-container">
                  <input
                    className="budget-checkbox"
                    type="checkbox"
                    checked={category.parent_budget}
                    onChange={(e) =>
                      handleParentBudgetToggle(
                        category.category_id,
                        e.target.checked
                      )
                    }
                  />
                </div>
              )}
            </div>
          )}
        </td>
        <td>
          <input
            className="budget-input"
            type="number"
            value={category.weekly}
            onChange={(e) =>
              handleValueChange(category.category_id, "weekly", e.target.value)
            }
            onBlur={(e) =>
              handleBlur(category.category_id, "weekly", e.target.value)
            }
            disabled={!isEditable}
            style={{
              backgroundColor: isEditable ? "#fff" : "#f0f0f0",
              border: isEditable
                ? isChild
                  ? "1px solid #000000"
                  : "1px solid #001e80"
                : "1px solid #ccc",
            }}
            onFocus={handleFocus}
          />
        </td>

        <td>
          <input
            className="budget-input"
            type="number"
            value={category.monthly}
            onChange={(e) =>
              handleValueChange(category.category_id, "monthly", e.target.value)
            }
            onBlur={(e) =>
              handleBlur(category.category_id, "monthly", e.target.value)
            }
            disabled={!isEditable}
            style={{
              backgroundColor: isEditable ? "#fff" : "#f0f0f0",
              border: isEditable
                ? isChild
                  ? "1px solid #000000"
                  : "1px solid #001e80"
                : "1px solid #ccc",
            }}
            onFocus={handleFocus}
          />
        </td>
        <td>
          <input
            className="budget-input"
            type="number"
            value={category.yearly}
            onChange={(e) =>
              handleValueChange(category.category_id, "yearly", e.target.value)
            }
            onBlur={(e) =>
              handleBlur(category.category_id, "yearly", e.target.value)
            }
            disabled={!isEditable}
            style={{
              backgroundColor: isEditable ? "#fff" : "#f0f0f0",
              border: isEditable
                ? isChild
                  ? "1px solid #000000"
                  : "1px solid #001e80"
                : "1px solid #ccc",
            }}
            onFocus={handleFocus}
          />
        </td>
      </tr>
    );
  };

  const renderCategories = () => {
    return budgetData
      .filter((category) => category.level === 1)
      .map((parentCategory) => {
        const categoryRows = [renderCategoryRow(parentCategory)];
        budgetData
          .filter((child) => child.parent_id === parentCategory.category_id)
          .forEach((childCategory) => {
            categoryRows.push(renderCategoryRow(childCategory, true));
          });

        return categoryRows;
      });
  };
  const getTotal = (period) => {
    return budgetData
      .filter((category) => category.level === 1)
      .reduce((acc, category) => acc + parseFloat(category[period] || 0), 0)
      .toFixed(2);
  };

  return (
    <div className="tableContainer">
      <table className="table budget-table">
        <thead>
          <tr>
            <th>Category</th>
            <th>Weekly</th>
            <th>Monthly</th>
            <th>Yearly</th>
          </tr>
        </thead>
        <tbody>
          {renderCategories().flat()}
          <tr className="totalRow">
            <td>Total</td>
            <td>{formatCurrency(getTotal("weekly"))}</td>
            <td>{formatCurrency(getTotal("monthly"))}</td>
            <td>{formatCurrency(getTotal("yearly"))}</td>
          </tr>
        </tbody>
      </table>
      {isLoading ? (
        <Spinner />
      ) : (
        <button onClick={handleSave} className="blueButton">
          Save Changes
        </button>
      )}
    </div>
  );
};

const calculateWeeklyAmount = (amount, frequency) => {
  switch (frequency) {
    case "M":
      return (parseFloat(amount) / 4.33).toFixed(2);
    case "Y":
      return (parseFloat(amount) / 52).toFixed(2);
    default:
      return parseFloat(amount).toFixed(2);
  }
};

const calculateMonthlyAmount = (amount, frequency) => {
  switch (frequency) {
    case "W":
      return (parseFloat(amount) * 4.33).toFixed(2);
    case "Y":
      return (parseFloat(amount) / 12).toFixed(2);
    default:
      return parseFloat(amount).toFixed(2);
  }
};

const calculateYearlyAmount = (amount, frequency) => {
  switch (frequency) {
    case "W":
      return (parseFloat(amount) * 52).toFixed(2);
    case "M":
      return (parseFloat(amount) * 12).toFixed(2);
    default:
      return parseFloat(amount).toFixed(2);
  }
};
export default BudgetTable;
