import React, { useState } from "react";
import Spinner from "./spinner";
import APIService from "../components/api";
const handleRefreshClick = async () => {
  const { syncData } = APIService();
  await syncData();
};
const RefreshButton = () => {
  const { syncData } = APIService();
  const [isLoading, setIsLoading] = useState(false);
  const handleRefreshClick1 = async () => {
    await syncData();
  };
  const handleClick = async () => {
    setIsLoading(true);
    await handleRefreshClick1();
    setIsLoading(false);
  };

  return (
    <div className="refreshButtonContainer">
      {isLoading ? (
        <Spinner />
      ) : (
        <button onClick={handleClick} className="blackButton">
          Refresh
        </button>
      )}
    </div>
  );
};

export { RefreshButton, handleRefreshClick };
