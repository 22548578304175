import { useContext } from "react";
import {
  ACTION_TYPES as AUTH_ACTIONS,
  AuthContext,
} from "../authentication/authenticationContext";
import { AppContext } from "../context/AppContext";

const exclusionList = [
  "/auth/mfaLogin",
  "/auth/resetPassword",
  "/auth/forgotPassword",
  "/auth/register",
  "/auth/login",
  "/auth/logout",
  "/auth/refresh",
];

const ongoingRequests = new Map();

const useApiRequest = () => {
  const { state: appState } = useContext(AppContext);
  const { dispatch: authDispatch } = useContext(AuthContext);
  const userId = appState?.user?.user_id;

  const makeApiRequest = async ({
    method = "GET",
    url = "",
    headers = {},
    body = {},
    jsonParse = true,
  }) => {
    if (!exclusionList.includes(url)) {
      if (!ongoingRequests.has(userId)) {
        ongoingRequests.set(userId, new Map());
      }

      const userRequests = ongoingRequests.get(userId);

      if (userRequests.has(url)) {
        return null;
      }

      userRequests.set(url, true);
    }

    let requestOptions = {
      method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
    };

    if (method !== "GET") {
      requestOptions.body = JSON.stringify(body);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_EXPRESS_SERVER_URL}${url}`,
        requestOptions
      );

      if (
        authDispatch &&
        typeof authDispatch === "function" &&
        response.headers.has("X-Time-Left")
      ) {
        const timeLeft = response.headers.get("X-Time-Left");
        authDispatch({
          type: AUTH_ACTIONS.SET_TOKEN_EXPIRATION,
          payload: timeLeft,
        });
      }

      if (!jsonParse) {
        return response;
      }

      if (response.status === 422) {
        return { response, message: await response.json() };
      }
      if (response.status === 403) {
        return { response, message: await response.json() };
      }
      if (!response.ok && response.status !== 307) {
        throw new Error(
          `HTTP error! Status: ${response.status} ${
            (await response.text()) || ""
          }`
        );
      }

      return await response.json();
    } catch (error) {
      throw error;
    } finally {
      if (!exclusionList.includes(url)) {
        const userRequests = ongoingRequests.get(userId);
        userRequests.delete(url);

        if (userRequests.size === 0) {
          ongoingRequests.delete(userId);
        }
      }
    }
  };

  return { makeApiRequest };
};

export default useApiRequest;
