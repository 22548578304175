import React, { useState, useEffect, useContext } from "react";
import { CurrencyContext } from "../context/currencyContext";
import APIService from "./api";
import { CollapsibleContext } from "./collapsibleContext";
import { RefreshButton } from "../components/refreshButton";
import SupportForm from "../components/supportForm";
import "../App.css";
import { AccountsContext } from "../context/AccountsContext";
import { AppContext } from "../context/AppContext";

const TopBar = () => {
  const { fetchAccountsContext, fetchTransactionsContext, fetchUserContext } =
    APIService();
  const { formatCurrency } = useContext(CurrencyContext);
  const { state } = useContext(AccountsContext);
  const { state: appState } = useContext(AppContext);
  const { isCollapsed } = useContext(CollapsibleContext);
  const [financialMetrics, setFinancialMetrics] = useState([]);
  useEffect(() => {
    const handleResize = () => {};
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!state.transactionsLoaded && !state.transactionsLoading) {
      fetchTransactionsContext();
    }
    if (!state.accountsLoaded && !state.accountsLoading) {
      fetchAccountsContext();
    }
    if (!appState.userLoaded && !appState.userLoading) {
      fetchUserContext();
    }
  }, [
    fetchTransactionsContext,
    fetchAccountsContext,
    fetchUserContext,
    appState.userLoaded,
    state.accountsLoaded,
    state.transactionsLoaded,
    appState.userLoading,
    state.accountsLoading,
    state.transactionsLoading,
  ]);

  useEffect(() => {
    const calculateCurrentMonthSavings = (transactions) => {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      return transactions.reduce((total, transaction) => {
        const transactionDate = new Date(transaction.date);

        if (transactionDate >= startOfMonth && transactionDate <= endOfMonth) {
          if (transaction.type === "INC") {
            return total + parseFloat(transaction.amount);
          } else if (transaction.type === "EXP") {
            return total + parseFloat(transaction.amount);
          }
        }

        return total;
      }, 0);
    };

    if (state.accountsLoaded && state.transactionsLoaded) {
      const totalBalance = state.accounts.reduce(
        (sum, account) =>
          sum +
          parseFloat(account.balance_current || account.balance_avaliable),
        0
      );

      const uncategorizedTransactions = state.transactions.filter(
        (t) => !t.pachira_category_id && (!t.splits || t.splits.length === 0)
      ).length;
      const thisMonthsSavings = calculateCurrentMonthSavings(
        state.transactions
      );

      setFinancialMetrics([
        {
          title: "Net Worth",
          value: formatCurrency(totalBalance.toFixed(2)),
        },
        {
          title: "Uncategorized Transactions",
          value: uncategorizedTransactions,
        },
        {
          title: "Month's Savings",
          value: formatCurrency(thisMonthsSavings.toFixed(2)),
        },
      ]);
    }
  }, [
    state.accounts,
    state.transactions,
    formatCurrency,
    state.accountsLoaded,
    state.transactionsLoaded,
  ]);

  return (
    <div className="topbar" style={{ left: isCollapsed ? "104px" : "224px" }}>
      <div className="metrics">
        {financialMetrics.map((metric, index) => (
          <div key={index} className="metric">
            <span className="metric-title">{metric.title}: </span>
            <span className="metric-value">{metric.value}</span>
          </div>
        ))}
      </div>
      <SupportForm user={appState.user} />
      <RefreshButton />
    </div>
  );
};

export default TopBar;
