import React, { useState, useEffect, useRef } from "react";
import AdSenseAdAuto from "./adSenseAdAuto";
import "../App.css";

const WidgetAd = () => {
  const containerRef = useRef(null);
  const [isReadyToShowAd, setIsReadyToShowAd] = useState(false);
  useEffect(() => {
    if (containerRef.current) {
      setIsReadyToShowAd(true);
    }
  }, []);

  return (
    <div className="widgetContainerAd" ref={containerRef}>
      <h3 className="header">Advertisement</h3>
      {isReadyToShowAd && (
        <div className="mainAdArea">
          <AdSenseAdAuto />
        </div>
      )}
    </div>
  );
};

export default WidgetAd;
