import React, { useState, useContext, useEffect } from "react";
import "../App.css";
import { CurrencyContext } from "../context/currencyContext";
import { AccountsContext } from "../context/AccountsContext";
import APIService from "../components/api";

const InvestmentListCard = () => {
  const { fetchInvestmentsContext } = APIService();

  const { state } = useContext(AccountsContext);
  useEffect(() => {
    if (!state.investmentsLoaded && !state.investmentsLoading) {
      fetchInvestmentsContext();
    }
  }, [
    fetchInvestmentsContext,
    state.investmentsLoaded,
    state.investmentsLoading,
  ]);
  const { formatCurrency } = useContext(CurrencyContext);
  const [expandedKey, setExpandedKey] = useState(null);

  const calculateGainLossPercentage = (currentValue, costBasis) => {
    if (costBasis === 0) return 0;
    return ((currentValue - costBasis) / costBasis) * 100;
  };

  const groupByTickerOrName = () => {
    const grouped = state.investments.reduce((acc, investment) => {
      const key = investment.ticker_symbol || investment.name || "Unknown";
      if (!acc[key]) {
        acc[key] = {
          key,
          ticker_symbol: investment.ticker_symbol,
          name: investment.name,
          total_quantity: 0,
          total_value: 0,
          cost_basis: 0,
          current_price:
            parseFloat(investment.close_price) ||
            parseFloat(investment.current_price),
          accountInvestments: [],
        };
      }
      const investmentCostBasis =
        parseFloat(investment.cost_basis) || parseFloat(investment.close_price);

      acc[key].total_quantity += parseFloat(investment.quantity);
      acc[key].total_value += parseFloat(investment.current_price);

      const totalCostBasis = parseFloat(investment.current_price);
      if (
        totalCostBasis ===
        parseFloat(investment.quantity) *
          (parseFloat(investment.cost_basis) ||
            parseFloat(investment.close_price))
      ) {
        acc[key].cost_basis += totalCostBasis;
      } else {
        acc[key].cost_basis += investmentCostBasis;
      }
      acc[key].accountInvestments.push(investment);
      return acc;
    }, {});

    return Object.values(grouped);
  };

  const renderSubTable = (accountInvestments) => {
    return (
      <table className="sub-table account-table">
        <tbody>
          {accountInvestments.map((investment, index) => {
            const totalValue =
              parseFloat(investment.current_price) *
              parseFloat(investment.quantity);
            const calculatedCostBasis =
              parseFloat(investment.cost_basis) ||
              parseFloat(investment.close_price) ||
              totalValue;
            const gainLossPercentage = calculateGainLossPercentage(
              totalValue,
              calculatedCostBasis * parseFloat(investment.quantity)
            );

            return (
              <tr key={index}>
                <td>{investment.account_name}</td>
                <td>
                  $
                  {parseFloat(
                    investment.price || investment.current_price
                  ).toFixed(2)}
                </td>
                <td>{parseFloat(investment.quantity).toFixed(2)}</td>
                <td>${calculatedCostBasis.toFixed(2)}</td>
                <td>${totalValue.toFixed(2)}</td>
                <td
                  style={{ color: gainLossPercentage >= 0 ? "green" : "red" }}
                >
                  {gainLossPercentage.toFixed(2)}%
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderTableRow = (group) => {
    const gainLossPercentage = calculateGainLossPercentage(
      group.total_value,
      group.cost_basis
    );

    return (
      <React.Fragment key={group.key}>
        <tr
          onClick={() =>
            setExpandedKey(expandedKey === group.key ? null : group.key)
          }
        >
          <td>{group.ticker_symbol || group.name}</td>
          <td>{group.name}</td>
          <td>{formatCurrency(group.current_price)}</td>
          <td>{formatCurrency(group.total_quantity).replace("$", "")}</td>
          <td>{formatCurrency(group.cost_basis)}</td>
          <td>{formatCurrency(group.total_value)}</td>
          <td style={{ color: gainLossPercentage >= 0 ? "green" : "red" }}>
            {gainLossPercentage.toFixed(2)}%
          </td>
        </tr>
        {expandedKey === group.key && (
          <tr>
            <td colSpan="7">{renderSubTable(group.accountInvestments)}</td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="card">
      <h3>My Holdings</h3>
      <div className="transactionListContainer">
        <table className="account-table">
          <thead>
            <tr>
              <th>Identifier</th>
              <th>Name</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Cost Basis</th>
              <th>Current Value</th>
              <th>Gain/Loss %</th>
            </tr>
          </thead>
          <tbody>
            {groupByTickerOrName().map((group) => renderTableRow(group))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InvestmentListCard;
