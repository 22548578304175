import React, { useContext, useEffect } from "react";
import { CollapsibleContext } from "./collapsibleContext";
import { useMediaQuery, useTheme } from "@mui/material";

const MainContent = ({ children }) => {
  const { isCollapsed } = useContext(CollapsibleContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const adjustMainContentHeight = () => {
      const bottomBar = document.getElementById("bottomAdId");
      if (bottomBar) {
        const bottomBarHeight = bottomBar.offsetHeight + 6 + 60;

        const mainContent = document.querySelector(".main-content");
        if (mainContent) {
          mainContent.style.height = `calc(100vh - ${bottomBarHeight}px)`;
        }
      }
    };

    adjustMainContentHeight();
    window.addEventListener("resize", adjustMainContentHeight);
    return () => window.removeEventListener("resize", adjustMainContentHeight);
  }, [isCollapsed]);

  const mainContentStyle = {
    marginLeft: isCollapsed && !isMobile ? "-120px" : "0px",
    transition: "margin-left 0.2s ease-in-out",
  };

  return (
    <div className="main-content" style={mainContentStyle}>
      {children}
    </div>
  );
};

export default MainContent;
