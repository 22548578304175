import React, { useState, useEffect, useContext } from "react";
import Modal from "./modal";
import Spinner from "./spinner";
import { AccountsContext } from "../context/AccountsContext";
import YodleeModal from "../components/yodleeModal";
import APIService from "../components/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, useTheme } from "@mui/material";
const ManualAccountForm = ({ onSubmit, onClose }) => {
  const { fetchAccountTypes } = APIService();
  const { state } = useContext(AccountsContext);
  const [accountName, setAccountName] = useState("");
  const [accountBalance, setAccountBalance] = useState("");
  const [institutionName, setInstitutionName] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedSubtype, setSelectedSubtype] = useState("");
  useEffect(() => {
    fetchAccountTypes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await onSubmit({
        accountName,
        account_type_id: selectedSubtype,
        accountBalance,
        institutionName,
        date,
      });
    } catch (error) {
      console.error("Error during form submission:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setSelectedGroup("");
    setSelectedSubtype("");
  };
  const isLiabilityTypeSelected = () => {
    return selectedType.toLowerCase() === "liability";
  };
  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
    setSelectedSubtype("");
  };

  const handleSubtypeChange = (e) => {
    setSelectedSubtype(e.target.value);
  };

  const renderTypeOptions = () => {
    const uniqueTypes = new Set(state.accountTypes.map((item) => item.type));
    return Array.from(uniqueTypes).map((type) => (
      <option key={type} value={type}>
        {capitalize(type)}
      </option>
    ));
  };

  const renderGroupOptions = () => {
    const groupsForSelectedType = state.accountTypes
      .filter((item) => item.type === selectedType)
      .map((item) => item.group);
    const uniqueGroups = new Set(groupsForSelectedType);
    return Array.from(uniqueGroups).map((group) => (
      <option key={group} value={group}>
        {group}
      </option>
    ));
  };

  const renderSubtypeOptions = () => {
    const subtypesForSelectedGroup = state.accountTypes
      .filter(
        (item) => item.type === selectedType && item.group === selectedGroup
      )
      .map((item) => ({
        type_id: item.type_id,
        subtype: item.subtype,
      }));

    const uniqueSubtypes = Array.from(
      new Map(
        subtypesForSelectedGroup.map((item) => [item["type_id"], item])
      ).values()
    );

    return uniqueSubtypes.map(({ type_id, subtype }) => (
      <option key={type_id} value={type_id}>
        {subtype}
      </option>
    ));
  };
  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <form onSubmit={handleSubmit} className="modalContent">
      <input
        type="text"
        value={accountName}
        onChange={(e) => setAccountName(e.target.value)}
        placeholder="Account Name"
        required
        className="modalInput"
      />
      <select
        value={selectedType}
        onChange={handleTypeChange}
        className="modalInput"
        required
      >
        <option value="">Select Type</option>
        {renderTypeOptions()}
      </select>
      {selectedType && (
        <select
          value={selectedGroup}
          onChange={handleGroupChange}
          className="modalInput"
        >
          <option value="">Select Group</option>
          {renderGroupOptions()}
        </select>
      )}
      {selectedGroup && (
        <select
          value={selectedSubtype}
          onChange={handleSubtypeChange}
          className="modalInput"
        >
          <option value="">Select Subtype</option>
          {renderSubtypeOptions()}
        </select>
      )}
      <input
        type="text"
        value={institutionName}
        onChange={(e) => setInstitutionName(e.target.value)}
        placeholder="Institution Name"
        required
        className="modalInput"
      />
      <p style={{ color: "#666" }}>
        Tip: If this account has historical transactions like bank account or
        cash, mark todays date. Otherwise, mark the initial date of purchase for
        all other assets
      </p>
      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        required
        className="modalInput"
      />
      {isLiabilityTypeSelected() && (
        <div className="warning">
          This number should be negative as it is a liability.
        </div>
      )}
      <input
        type="number"
        value={accountBalance}
        onChange={(e) => setAccountBalance(e.target.value)}
        placeholder="Account Balance"
        required
        className="modalInput"
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <button type="submit" className="fullLengthButton">
          Submit
        </button>
      )}
    </form>
  );
};

const ConnectButton = ({ onAccountsUpdated, accountId, openModal }) => {
  const { addAccount } = APIService();
  const [showModal, setShowModal] = useState(openModal);
  const [showManualAccountForm, setShowManualAccountForm] = useState(false);
  const theme = useTheme();
  const [isModalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    const existingScript = document.getElementById("yodlee-fastlink-script");
    if (!existingScript) {
      const script = document.createElement("script");
      script.id = "yodlee-fastlink-script";
      script.src = "https://cdn.yodlee.com/fastlink/v4/initialize.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);
  const handleManualAccountSubmit = async (formData) => {
    try {
      await addAccount(formData);
      onAccountsUpdated();
      setShowManualAccountForm(false);
    } catch (error) {
      console.error("Failed to add account:", error);
    }
  };

  <ManualAccountForm
    onSubmit={handleManualAccountSubmit}
    onClose={() => setShowManualAccountForm(false)}
  />;

  function handleClose() {
    setShowModal(false);
  }
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseYodleeModal = () => {
    setModalOpen(false);
  };
  const handleClick = () => {
    setShowModal(true);
  };
  return (
    <>
      <Tooltip title="Add Account">
        <FontAwesomeIcon
          icon={faPlus}
          color={theme.palette.primary.main}
          onClick={handleClick}
          style={{ cursor: "pointer" }} // Adding cursor pointer for better UX
        />
      </Tooltip>

      {showModal && (
        <Modal
          onClose={() => handleClose()}
          title="Connect Your Account"
          description="Please add your account manually. Automatic Bank Connections are currently under development."
        >
          {/* "Select a service to connect your financial account. If your account is not available through Yodlee, please contact to support to request the institution and add it manually for the time being." */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              height: "100%",
            }}
          >
            {/* <button onClick={() => handleOpenModal()} className="blackButton">
              Connect with Yodlee
            </button>
            <small className="noteStyle">
              Yodlee accounts can access the last 90 days of transactions.
            </small>
            <hr className="hrStyle" /> */}
            <button
              onClick={() => setShowManualAccountForm(true)}
              className="blackButton"
            >
              Add Manual Account
            </button>
            <small className="noteStyle">
              Transactions may be added via csv import on the Transactions page
            </small>
          </div>
          {showManualAccountForm && (
            <Modal
              onClose={() => setShowManualAccountForm(false)}
              title="Add Manual Account"
              description="Enter the manual account details below and we will add it to your account."
            >
              <ManualAccountForm
                onSubmit={handleManualAccountSubmit}
                onClose={() => setShowManualAccountForm(false)}
              />
            </Modal>
          )}
          {isModalOpen && (
            <YodleeModal
              isOpen={isModalOpen}
              onClose={handleCloseYodleeModal}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default ConnectButton;
