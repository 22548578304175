import React, { useContext, useEffect, useMemo, useState } from "react";
import "../App.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import APIService from "./api";
import { AccountsContext } from "../context/AccountsContext";
import { CurrencyContext } from "../context/currencyContext";

const WidgetMonthlyExpenses = () => {
  const { fetchTransactionsContext } = APIService();
  const { state } = useContext(AccountsContext);
  const { formatCurrency } = useContext(CurrencyContext);
  const [selectedCategories, setSelectedCategories] = useState({});

  useEffect(() => {
    if (!state.transactionsLoaded && !state.transactionsLoading) {
      fetchTransactionsContext();
    }
  }, [
    fetchTransactionsContext,
    state.transactionsLoaded,
    state.transactionsLoading,
  ]);

  const getLast12Months = () => {
    const months = [];
    const now = new Date();
    now.setDate(1); // Set to the first day of the month to avoid issues with months
    for (let i = 12; i >= 0; i--) {
      const month = new Date(now.getFullYear(), now.getMonth() - i, 1);
      months.push({
        yearMonth: `${month.getFullYear()}-${String(
          month.getMonth() + 1
        ).padStart(2, "0")}`,
        monthName: month.toLocaleString("default", { month: "short" }),
      });
    }
    return months;
  };

  const calculateStandardDeviation = (array) => {
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(
      array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
    );
  };

  const monthlyExpensesData = useMemo(() => {
    const data = {};
    const months = getLast12Months();
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    const activeCategories = new Set();

    // Initialize data structure with 0 values for all categories in all months
    months.forEach(({ yearMonth, monthName }) => {
      data[yearMonth] = { month: monthName };
      state.categories.forEach((category) => {
        if (category.type === "EXP") {
          data[yearMonth][category.category_name] = 0;
        }
      });
    });

    state.transactions.forEach((transaction) => {
      const date = new Date(transaction.date);
      if (date < oneYearAgo) return;

      const month = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}`;
      const category = transaction.category_name;
      const categoryType = state.categories.find(
        (cat) => cat.category_name === category
      )?.type;

      if (categoryType !== "EXP") return;

      activeCategories.add(category);

      if (!data[month]) {
        data[month] = {
          month: new Date(
            date.getFullYear(),
            date.getMonth(),
            1
          ).toLocaleString("default", { month: "short" }),
        };
      }

      if (!data[month][category]) {
        data[month][category] = 0;
      }

      data[month][category] -= parseFloat(transaction.amount);
    });

    // Remove inactive categories from each month
    months.forEach(({ yearMonth }) => {
      for (const category in data[yearMonth]) {
        if (category !== "month" && !activeCategories.has(category)) {
          delete data[yearMonth][category];
        }
      }
    });

    return Object.values(data);
  }, [state.transactions, state.categories]);

  const categories = useMemo(() => {
    const categoriesSet = new Set();
    state.transactions.forEach((transaction) => {
      const categoryType = state.categories.find(
        (cat) => cat.category_name === transaction.category_name
      )?.type;
      if (categoryType === "EXP") {
        categoriesSet.add(transaction.category_name);
      }
    });
    return Array.from(categoriesSet).sort();
  }, [state.transactions, state.categories]);

  useEffect(() => {
    const initialSelectedCategories = {};
    categories.forEach((category) => {
      initialSelectedCategories[category] = true;
    });
    setSelectedCategories(initialSelectedCategories);
  }, [categories]);

  const handleCategoryToggle = (category) => {
    setSelectedCategories((prevSelectedCategories) => ({
      ...prevSelectedCategories,
      [category]: !prevSelectedCategories[category],
    }));
  };

  const handleUnselectVariableCategories = () => {
    const categoryVariabilities = {};

    categories.forEach((category) => {
      const categoryValues = monthlyExpensesData.map(
        (monthData) => monthData[category] || 0
      );
      const standardDeviation = calculateStandardDeviation(categoryValues);
      categoryVariabilities[category] = standardDeviation;
    });

    const averageVariability =
      Object.values(categoryVariabilities).reduce((a, b) => a + b, 0) /
      categories.length;

    setSelectedCategories((prevSelectedCategories) => {
      const newSelectedCategories = { ...prevSelectedCategories };
      for (const category in categoryVariabilities) {
        if (categoryVariabilities[category] * 3 > averageVariability) {
          newSelectedCategories[category] = false;
        }
      }
      return newSelectedCategories;
    });
  };

  const filteredCategories = categories.filter(
    (category) => selectedCategories[category]
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <div
          className="custom-tooltip"
          style={{
            padding: "10px",
            background: "linear-gradient(to bottom, #eee 0%, #ffffff 100%)",
            color: "black",
            borderRadius: "5px",
            border: "1px solid #ddd",
            boxShadow: "0px 0px 5px rgba(0,0,0,0.5)",
          }}
        >
          <p className="label" style={{ fontWeight: "bold", color: "black" }}>
            {label}
          </p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${formatCurrency(entry.value)}`}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="widgetContainer">
      <h3 className="header">Monthly Expenses by Category</h3>
      <div className="categoryFilters">
        <button
          className="blackButton"
          onClick={handleUnselectVariableCategories}
        >
          Unselect Variable Categories
        </button>
        {categories.map((category, index) => (
          <button
            key={category}
            onClick={() => handleCategoryToggle(category)}
            style={{
              backgroundColor: selectedCategories[category]
                ? generateColor(index, categories.length)
                : "lightgray",
              color: selectedCategories[category] ? "white" : "black",
              marginRight: "10px",
              border: "none",
              borderRadius: "5px",
              padding: "5px 10px",
              cursor: "pointer",
            }}
          >
            {category}
          </button>
        ))}
      </div>
      <ResponsiveContainer width="100%" height="70%">
        <LineChart data={monthlyExpensesData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {filteredCategories.map((category, index) => (
            <Line
              key={category}
              type="monotone"
              dataKey={category}
              stroke={generateColor(index, filteredCategories.length)}
              name={category}
              dot={true}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const generateColor = (index, total) => {
  const hue = (360 / total) * index;
  return `hsl(${hue}, 70%, 50%)`;
};

export default WidgetMonthlyExpenses;
