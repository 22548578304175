import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "../App.css";

const TipContainer = ({ header, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="tipContainer">
      <div className="tipHeaderContainer" onClick={toggleExpand}>
        <h4 className="tipHeader">{header}</h4>
        <FontAwesomeIcon
          icon={isExpanded ? faChevronUp : faChevronDown}
          className="dropdownIcon"
        />
      </div>
      {isExpanded && <div className="tipContent">{children}</div>}
    </div>
  );
};

export default TipContainer;
