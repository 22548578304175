import React, { useState, useEffect, useContext } from "react";
import { CurrencyContext } from "../context/currencyContext";
import "../App.css";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { format, parseISO } from "date-fns";
import APIService from "./api";
import { calculateSpendingData, calculateNetWorthData } from "./utils";
import ViewToggleButtonGroup from "./viewToggleButtonGroup";
import TimeRangeToggleButtonGroup from "./timeRangeToggleButtonGroup";
import { AccountsContext } from "../context/AccountsContext";

const WidgetTrends = () => {
  const {
    fetchCategoriesContext,
    fetchTransactionsContext,
    fetchNetWorthContext,
  } = APIService();

  const { state } = useContext(AccountsContext);
  const [view, setView] = useState("Net Worth");
  const [timeRange, setTimeRange] = useState("Last28Days");

  useEffect(() => {
    if (!state.transactionsLoaded && !state.transactionsLoading) {
      fetchTransactionsContext();
    }
    if (!state.categoriesLoaded && !state.categoriesLoading) {
      fetchCategoriesContext();
    }
    if (
      !state.accountBalancesOverTimeLoaded &&
      !state.accountBalancesOverTimeLoading
    ) {
      fetchNetWorthContext();
    }
  }, [
    fetchCategoriesContext,
    fetchNetWorthContext,
    fetchTransactionsContext,
    state.categoriesLoaded,
    state.accountBalancesOverTimeLoaded,
    state.transactionsLoaded,
    state.accountBalancesOverTimeLoading,
    state.categoriesLoading,
    state.transactionsLoading,
  ]);

  const CustomTickFormatter = (tickItem) => {
    switch (timeRange) {
      case "Last7Days":
      case "Last28Days":
      case "ThisWeek":
      case "ThisMonth":
        return format(parseISO(tickItem), "MMM dd");

      case "LastYear":
      case "AllTime":
      case "ThisYear":
        return format(parseISO(tickItem), "MMM yyyy");

      default:
        return format(parseISO(tickItem), "MMM dd");
    }
  };

  const renderChart = () => {
    let chartData = [];

    switch (view) {
      case "Spending":
        chartData = calculateSpendingData(state.transactions, timeRange);

        break;
      case "Net Worth":
        chartData = calculateNetWorthData(
          state.accountBalanceOverTime,
          timeRange
        );

        break;

      default:
        chartData = [];
    }
    chartData = chartData.map((dataPoint) => {
      return Object.entries(dataPoint).reduce((newDataPoint, [key, value]) => {
        newDataPoint[key] =
          typeof value === "number" ? parseFloat(value.toFixed(2)) : value;
        return newDataPoint;
      }, {});
    });
    const roundToNearestHundred = (num, direction = "nearest") => {
      if (direction === "up") {
        return Math.ceil(num / 100) * 100;
      } else if (direction === "down") {
        return Math.floor(num / 100) * 100;
      }
      return Math.round(num / 100) * 100;
    };
    const dataValues = chartData
      .flatMap((d) => [
        d.income,
        d.expense,
        d.primarySpending,
        d.secondarySpending,
        d.netWorth,
      ])
      .filter((value) => typeof value === "number" && !isNaN(value));

    const minValue = Math.min(...dataValues);
    const maxValue = Math.max(...dataValues);
    const calculateBuffer = (value, percent) => Math.abs(value) * percent;

    const minBuffer = calculateBuffer(minValue, 0.01);
    const maxBuffer = calculateBuffer(maxValue, 0.01);
    const minDomain = roundToNearestHundred(minValue - minBuffer, "down");
    const maxDomain = roundToNearestHundred(maxValue + maxBuffer, "up");
    const CustomTooltip = ({ active, payload, label }) => {
      const { formatCurrency } = useContext(CurrencyContext);

      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="label">{`${label}`}</p>
            {payload.map((entry, index) => (
              <p key={index} className="intro">
                {`${entry.name}: ${formatCurrency(entry.value)}`}
              </p>
            ))}
          </div>
        );
      }

      return null;
    };
    return (
      <>
        <ResponsiveContainer width="100%" maxHeight="180px">
          <AreaChart
            data={chartData}
            margin={{ top: 0, right: 0, left: -60, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickFormatter={CustomTickFormatter} />
            <YAxis domain={[minDomain, maxDomain]} tick={false} />{" "}
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" height={36} />
            {view === "IncomeVsExpenses" && (
              <>
                <Area
                  type="monotone"
                  dataKey="income"
                  name="Income"
                  stroke="#001111"
                  fill="#001e80"
                />
                <Area
                  type="monotone"
                  dataKey="expense"
                  name="Spending"
                  stroke="#FF1111"
                  fill="#FF3333"
                />
              </>
            )}
            {view === "Spending" && (
              <>
                <Area
                  type="monotone"
                  dataKey="primarySpending"
                  name="This Period"
                  stroke="#001e80"
                  fill="#334c99 "
                />
                <Area
                  type="monotone"
                  dataKey="secondarySpending"
                  name="Last Period"
                  stroke="#6680b3 "
                  fill="#99b3cc "
                />
              </>
            )}
            {view === "Net Worth" && (
              <>
                <Area
                  type="monotone"
                  dataKey="netWorth"
                  name="Net Worth"
                  stroke="#001e80"
                  fill="#334c99"
                />
              </>
            )}
          </AreaChart>
        </ResponsiveContainer>
      </>
    );
  };

  return (
    <div className="widgetContainer">
      <h3 className="header">{`Trends Over Time: ${view}`}</h3>
      <div
        className="scrollable"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ViewToggleButtonGroup view={view} setView={setView} />
        <TimeRangeToggleButtonGroup
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          className="dropdownRight"
        />
      </div>
      {!state.categoriesLoaded ||
      !state.transactionsLoaded ||
      !state.accountBalancesOverTimeLoaded ? (
        <p>Loading...</p>
      ) : (
        renderChart()
      )}
    </div>
  );
};

export default WidgetTrends;
