import React, { Component } from "react";
import { AccountsContext } from "../context/AccountsContext";

class AdSenseAd extends Component {
  static contextType = AccountsContext; // Using contextType to access context in a class component

  componentDidMount() {
    const { state } = this.context;
    if (
      !this.adAlreadyLoaded() &&
      !state.subscriptions.some(
        (subscription) =>
          subscription.donation === false &&
          (subscription.is_active === true ||
            new Date(subscription.next_payment_date) >=
              new Date().setHours(0, 0, 0, 0))
      )
    ) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }

  adAlreadyLoaded() {
    return (
      this.insElement &&
      this.insElement.getAttribute("data-adsbygoogle-status") === "done"
    );
  }

  render() {
    const { state } = this.context;

    if (
      state.subscriptions.some(
        (subscription) =>
          subscription.donation === false &&
          (subscription.is_active === true ||
            new Date(subscription.next_payment_date) >=
              new Date().setHours(0, 0, 0, 0))
      )
    ) {
      return null;
    }

    return (
      <div
        style={{
          border: "1px solid black",
          backgroundColor: "white",
          textAlign: "center",
          marginBottom: "10px",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <div style={{ fontSize: ".7rem", padding: "3px", color: "black" }}>
          This Is A Paid Ad
        </div>
        <ins
          ref={(el) => (this.insElement = el)}
          className="adsbygoogle"
          style={{
            display: "block",
            width: "210px",
            height: "180px",
          }}
          data-ad-client="ca-pub-9738518352357622"
          data-ad-slot="5680630109"
        ></ins>
      </div>
    );
  }
}

export default AdSenseAd;
