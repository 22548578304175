import React, { useState, useEffect, useContext } from "react";
import Modal from "./modal";
import APIService from "./api";
import "../App.css";
import Spinner from "./spinner";
import { AccountsContext } from "../context/AccountsContext";
import { Button } from "@mui/material";

const TransactionsAdd = ({ closePopover }) => {
  const { fetchAccountsContext, fetchCategoriesContext, addManualTransaction } =
    APIService();
  const { state } = useContext(AccountsContext);

  const [showModal, setShowModal] = useState(false);
  const initialTransactionData = {
    date: "",
    description: "",
    merchant_name: "",
    amount: "",
    category_id: "",
    pachira_account_uuid: "",
    notes: "",
  };
  const [transactionData, setTransactionData] = useState(
    initialTransactionData
  );
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!state.accountsLoaded && !state.accountsLoading) {
      fetchAccountsContext();
    }
    if (!state.categoriesLoaded && !state.categoriesLoading) {
      fetchCategoriesContext();
    }
  }, [
    fetchAccountsContext,
    fetchCategoriesContext,
    state.accountsLoaded,
    state.categoriesLoaded,
    state.accountsLoading,
    state.categoriesLoading,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setTransactionData({ ...transactionData, [name]: value });
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await addManualTransaction(transactionData);
      setIsLoading(false);
      handleCloseModal();
    } catch (error) {
      setIsLoading(false);
      console.error("Error saving transaction:", error);
    }
  };

  const getSortedAndGroupedCategories = () => {
    const typeOrder = { INC: 1, TRA: 2, EXP: 3 };
    let groupedCategories = [];

    // Filter and sort Level 1 categories by type
    let sortedLevel1Categories = state.categories
      .filter((cat) => cat.level === 1)
      .sort((a, b) => {
        const typeComparison = typeOrder[a.type] - typeOrder[b.type];
        if (typeComparison !== 0) {
          return typeComparison;
        }

        return a.category_name.localeCompare(b.category_name);
      });

    sortedLevel1Categories.forEach((level1Cat) => {
      let subCategories = state.categories
        .filter((cat) => cat.parent_id === level1Cat.category_id)
        .sort((a, b) => a.category_name.localeCompare(b.category_name));

      if (subCategories.length > 0) {
        groupedCategories.push({
          parent: level1Cat,
          children: subCategories,
        });
      }
    });

    return groupedCategories;
  };
  const renderDropdownOptions = () => {
    const groupedCategories = getSortedAndGroupedCategories();

    return groupedCategories.flatMap((group) => {
      return [
        <option
          key={group.parent.category_id}
          value={group.parent.category_id}
          disabled
          className="optionGroup"
        >
          {group.parent.category_name}
        </option>,
        ...group.children.map((subCategory) => (
          <option
            key={subCategory.category_id}
            value={subCategory.category_id}
            className="option"
          >
            {subCategory.category_name}
          </option>
        )),
      ];
    });
  };
  const validateForm = () => {
    const requiredFields = [
      "date",
      "description",
      "amount",
      "category_id",
      "pachira_account_uuid",
    ];
    for (let field of requiredFields) {
      if (!transactionData[field]) {
        return false;
      }
    }
    return true;
  };
  const handleCloseModal = () => {
    setTransactionData(initialTransactionData);
    setShowModal(false);
    closePopover();
  };
  return (
    <div>
      <Button
        variant="outlined"
        sx={{
          width: "-webkit-fill-available",
          boxShadow: "none",
          "&:hover": { boxShadow: "none" },
        }}
        onClick={() => {
          setShowModal(true);
        }}
      >
        Add Transaction
      </Button>

      {showModal && (
        <Modal
          open={showModal}
          onClose={() => handleCloseModal()}
          title="Add Manual Transaction"
        >
          <div className="modalContent">
            <input
              type="date"
              name="date"
              value={transactionData.date}
              onChange={handleChange}
              className="modalInput"
              required
            />
            <input
              type="text"
              name="description"
              placeholder="Description"
              value={transactionData.description}
              onChange={handleChange}
              className="modalInput"
              required
            />
            <input
              type="text"
              name="merchant_name"
              placeholder="Merchant"
              value={transactionData.merchant_name}
              onChange={handleChange}
              className="modalInput"
              required
            />
            <p class="dangerText">
              This should be negative if it's an expense or leaving an account.
            </p>

            <input
              type="number"
              name="amount"
              placeholder="Amount"
              value={transactionData.amount}
              onChange={handleChange}
              className="modalInput"
              required
            />
            <select
              name="category_id"
              value={transactionData.category_id}
              onChange={handleChange}
              className="modalSelect"
              required
            >
              <option value="">Select Category</option>
              {renderDropdownOptions()}
            </select>
            <select
              name="pachira_account_uuid"
              value={transactionData.pachira_account_uuid}
              onChange={handleChange}
              className="modalSelect"
              required
            >
              <option value="">Select Account</option>
              {state.accounts.map((account) => (
                <option
                  key={account.pachira_account_uuid}
                  value={account.pachira_account_uuid}
                >
                  {account.name}
                </option>
              ))}
            </select>
            <textarea
              name="notes"
              placeholder="Notes"
              value={transactionData.notes}
              onChange={handleChange}
              className="modalInput"
            />

            {isLoading ? (
              <Spinner />
            ) : (
              <button
                onClick={handleSave}
                disabled={!validateForm()}
                className="fullLengthButton"
              >
                Save
              </button>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TransactionsAdd;
