import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import PartyCard from "../components/partyCard";
import AddPartyButton from "../components/addPartyButton";
import { CollapsibleContext } from "../components/collapsibleContext";
import "../App.css";
import TipContainer from "../components/tipContainer";
import { AccountsContext } from "../context/AccountsContext";
import APIService from "../components/api";

const InvestmentParties = () => {
  const { fetchInvestmentPartiesContext } = APIService();
  const { state } = useContext(AccountsContext);
  const { isCollapsed } = useContext(CollapsibleContext);
  const hasSubscription = state.subscriptions.some(
    (subscription) =>
      subscription.donation === false &&
      (subscription.is_active === true ||
        new Date(subscription.next_payment_date) >=
          new Date().setHours(0, 0, 0, 0))
  );
  useEffect(() => {
    document.title = "Investment Parties - Pachira";
  }, []);
  useEffect(() => {
    if (!state.partiesLoaded && !state.partiesLoading) {
      fetchInvestmentPartiesContext();
    }
  }, [
    fetchInvestmentPartiesContext,
    state.partiesLoaded,
    state.partiesLoading,
  ]);
  const adRefs = useRef([]);
  const calculateAdWidth = useCallback(() => {
    const viewportWidth = window.innerWidth;
    const leftMargin = isCollapsed ? 104 : 224;
    let availableWidth;
    if (viewportWidth <= 768) {
      availableWidth = viewportWidth;
    } else {
      availableWidth = viewportWidth - leftMargin;
    }

    const baseAdWidth = 500;

    const numAdsFit = Math.floor(availableWidth / baseAdWidth);

    if (numAdsFit === 0) {
      return availableWidth;
    } else {
      return availableWidth / numAdsFit;
    }
  }, [isCollapsed]);

  const calculateNumberOfAds = useCallback(() => {
    const availableWidth = window.innerWidth - (isCollapsed ? 104 : 224);
    const adWidth = calculateAdWidth();

    const numAds = Math.floor(availableWidth / adWidth);

    return Math.max(numAds, 1);
  }, [isCollapsed, calculateAdWidth]);

  const [numberOfAds, setNumberOfAds] = useState(calculateNumberOfAds);
  const [adWidth, setAdWidth] = useState(calculateAdWidth);

  useEffect(() => {
    const updateAdSizeAndNumber = () => {
      setNumberOfAds(calculateNumberOfAds());
      setAdWidth(calculateAdWidth());
    };

    updateAdSizeAndNumber();
    window.addEventListener("resize", updateAdSizeAndNumber);

    return () => {
      window.removeEventListener("resize", updateAdSizeAndNumber);
    };
  }, [isCollapsed, calculateNumberOfAds, calculateAdWidth]);

  useEffect(() => {
    adRefs.current.forEach((ad) => {
      if (ad && !ad.getAttribute("data-adsbygoogle-status")) {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (error) {
          console.error("AdSense error:", error.message);
        }
      }
    });
  }, [numberOfAds]);
  const renderPartiesWithAds = () => {
    const elements = [];
    state.parties.forEach((party, index) => {
      elements.push(<PartyCard key={party.id} party={party} />);

      if ((index + 1) % 4 === 0 && !hasSubscription) {
        elements.push(
          numberOfAds > 0 && (
            <div
              style={{
                left: isCollapsed ? "104px" : "224px",
                display: "flex",
                justifyContent: "space-around",
                overflow: "hidden",
                paddingBottom: "10px",
              }}
            >
              {Array.from({ length: numberOfAds }).map((_, index) => (
                <div
                  key={index}
                  className="ad-container"
                  style={{
                    border: "1px solid black",
                    backgroundColor: "white",
                    textAlign: "center",
                    borderRadius: "10px",
                    overflow: "hidden",
                    width: `${adWidth}px`,
                    maxHeight: "100px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "0.7rem",
                      textAlign: "center",
                    }}
                  >
                    This Is A Paid Ad
                  </div>
                  <ins
                    ref={(el) => (adRefs.current[index] = el)}
                    className="adsbygoogle"
                    style={{
                      display: "block",
                      width: "100%",
                      height: "90px",
                    }}
                    data-ad-client="ca-pub-9738518352357622"
                    data-ad-slot="4791214483"
                    data-ad-format="horizontal"
                    data-full-width-responsive="true"
                  ></ins>
                </div>
              ))}
            </div>
          )
        );
      }
    });
    if (
      (state.parties.length % 4 !== 0 || state.parties.length === 0) &&
      !hasSubscription
    ) {
      elements.push(
        numberOfAds > 0 && (
          <div
            style={{
              left: isCollapsed ? "104px" : "224px",
              display: "flex",
              justifyContent: "space-around",
              overflow: "hidden",
              paddingBottom: "10px",
            }}
          >
            {Array.from({ length: numberOfAds }).map((_, index) => (
              <div
                key={index}
                className="ad-container"
                style={{
                  border: "1px solid black",
                  backgroundColor: "white",
                  textAlign: "center",
                  borderRadius: "10px",
                  overflow: "hidden",
                  width: `${adWidth}px`,
                  maxHeight: "100px",
                }}
              >
                <div
                  style={{
                    fontSize: "0.7rem",
                    textAlign: "center",
                  }}
                >
                  This Is A Paid Ad
                </div>
                <ins
                  ref={(el) => (adRefs.current[index] = el)}
                  className="adsbygoogle"
                  style={{
                    display: "block",
                    width: "100%",
                    height: "90px",
                  }}
                  data-ad-client="ca-pub-9738518352357622"
                  data-ad-slot="4791214483"
                  data-ad-format="horizontal"
                  data-full-width-responsive="true"
                ></ins>
              </div>
            ))}
          </div>
        )
      );
    }

    return elements;
  };

  return (
    <div className="dashboard">
      <div className="header">
        <h1>Investment Parties</h1>

        <AddPartyButton />
      </div>
      <TipContainer header="Quick Tips: Maximize Your Investment Experience">
        <ul>
          <li>
            <strong>Join or Create Investment Parties:</strong> Easily create a
            new party or join an existing one with a party code usint the Add
            Party button in the top right corner. It's a great way to
            collaborate on investment goals with communities, partners, or
            family members.
          </li>
          <li>
            <strong>Aggregate Your Investments:</strong> Each party is linked to
            a specific ticker. Your investments under that ticker will be
            aggregated with all party members’ investments to show a collective
            total, providing a comprehensive view of shared investment goals.
          </li>
          <li>
            <strong>Maintain Privacy:</strong> Rest assured, your individual
            investments or holdings remain private. Other party members see only
            the aggregated total, not your personal details.
          </li>
          <li>
            <strong>Flexible Participation:</strong> You have the freedom to
            leave a party at any time, offering flexibility in how you engage
            with collective investment tracking.
          </li>
          <li>
            <strong>Community Goals:</strong> This feature is especially
            beneficial for communities, partners, or families looking to track
            ownership of stocks collectively and achieve shared investment
            milestones.
          </li>
        </ul>
      </TipContainer>
      <div className="partyList">
        {" "}
        <div className="partyCardsContainer">{renderPartiesWithAds()}</div>
      </div>
    </div>
  );
};

export default InvestmentParties;
