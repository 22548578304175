import React, { useState, useEffect, useContext } from "react";
import Modal from "./modal";
import APIService from "./api";
import "../App.css";
import Spinner from "./spinner";
import { AccountsContext } from "../context/AccountsContext";

const RecurringTransactionEdit = ({ transaction, onClose }) => {
  const {
    fetchAccountsContext,
    updateRecurringTransaction,
    deleteRecurringTransaction,
  } = APIService();
  const { state } = useContext(AccountsContext);

  const [transactionData, setTransactionData] = useState(transaction);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!state.accountsLoaded && !state.accountsLoading) {
      fetchAccountsContext();
    }
  }, [fetchAccountsContext, state.accountsLoaded, state.accountsLoading]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTransactionData({
      ...transactionData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSave = async () => {
    setIsLoading(true);

    const transactionDataToSave = { ...transactionData };
    if (transactionDataToSave.frequency === "every X days") {
      transactionDataToSave.frequency = `every ${transactionDataToSave.custom_days} days`;
    }

    try {
      await updateRecurringTransaction({
        transaction_id: transactionData.transaction_id,
        transactionDataToSave: transactionDataToSave,
      });
      setIsLoading(false);
      onClose();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteRecurringTransaction(transactionData);

      setIsLoading(false);
      onClose();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    const requiredFields = [
      "transaction_name",
      "pachira_account_uuid",
      "transaction_amount",
      "start_date",
      "frequency",
    ];
    for (let field of requiredFields) {
      if (!transactionData[field]) {
        return false;
      }
    }
    if (
      transactionData.frequency === "every X days" &&
      !transactionData.custom_days
    ) {
      return false;
    }
    return true;
  };

  return (
    <Modal open={true} onClose={onClose} title="Edit Recurring Transaction">
      <div className="modalContent">
        <label htmlFor="transaction_name">Transaction Name</label>
        <input
          type="text"
          name="transaction_name"
          placeholder="Transaction Name"
          value={transactionData.transaction_name}
          onChange={handleChange}
          className="modalInput"
          required
        />
        <label htmlFor="pachira_account_uuid">Account</label>
        <select
          name="pachira_account_uuid"
          value={transactionData.pachira_account_uuid}
          onChange={handleChange}
          className="modalSelect"
          required
        >
          <option value="">Select Account</option>
          {state.accounts.map((account) => (
            <option
              key={account.pachira_account_uuid}
              value={account.pachira_account_uuid}
            >
              {account.name}
            </option>
          ))}
        </select>
        <label htmlFor="transaction_amount">
          Transaction Amount (negative if expense)
        </label>
        <input
          type="number"
          name="transaction_amount"
          placeholder="Transaction Amount"
          value={transactionData.transaction_amount}
          onChange={handleChange}
          className="modalInput"
          required
        />
        <label htmlFor="start_date">Start Date</label>
        <input
          type="date"
          name="start_date"
          value={transactionData.start_date.split("T")[0]} // Converts ISO date to yyyy-MM-dd
          onChange={handleChange}
          className="modalInput"
          required
        />

        <label htmlFor="frequency">Frequency</label>
        <select
          name="frequency"
          value={transactionData.frequency}
          onChange={handleChange}
          className="modalSelect"
          required
        >
          <option value="">Select Frequency</option>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="bi-weekly">Bi-Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="quarterly">Quarterly</option>
          <option value="yearly">Yearly</option>
          <option value="every X days">Every X Days</option>
        </select>
        {transactionData.frequency === "every X days" && (
          <input
            type="number"
            name="custom_days"
            placeholder="Enter number of days"
            value={transactionData.custom_days}
            onChange={handleChange}
            className="modalInput"
            required
          />
        )}
        <label className="modalCheckbox">
          <input
            type="checkbox"
            name="auto_pay"
            checked={transactionData.auto_pay}
            onChange={handleChange}
          />
          Auto Pay
        </label>
        <label htmlFor="notes">Notes</label>
        <textarea
          name="notes"
          placeholder="Notes"
          value={transactionData.notes}
          onChange={handleChange}
          className="modalInput"
        />
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="buttonGroup">
            <button
              onClick={handleSave}
              disabled={!validateForm()}
              className="fullLengthButton"
            >
              Save
            </button>
            <button
              onClick={handleDelete}
              className="fullLengthButton"
              style={{ backgroundColor: "red" }}
            >
              Delete
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default RecurringTransactionEdit;
