import React, { useState, useEffect, useContext } from "react";
import { CurrencyContext } from "../context/currencyContext";
import "../App.css";
import BudgetTable from "../components/budgetTable";
import BudgetPopup from "../components/budgetPopup";
import BudgetProgressBar from "../components/budgetProgressBar";
import TipContainer from "../components/tipContainer";
import CategoryManagement from "../components/categoryManagement";
import APIService from "../components/api";
import { AccountsContext } from "../context/AccountsContext";
import { AuthContext } from "../authentication/authenticationContext";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import OverlayModal from "../components/OverlayModal";
const BudgetPage = () => {
  const theme = useTheme();
  const {
    fetchCategoriesContext,
    fetchTransactionsContext,
    updateCategories,
    updateUserCategories,
  } = APIService();
  const { dispatch: authDispatch } = useContext(AuthContext);
  const { formatCurrency } = useContext(CurrencyContext);
  const { state, dispatch } = useContext(AccountsContext);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  useEffect(() => {
    document.title = "Budgets - Pachira";
  }, []);

  const [showPopup, setShowPopup] = useState(false);
  const [activePeriod, setActivePeriod] = useState("week");
  const [totalExpenditure, setTotalExpenditure] = useState(0);
  const handleTimePeriodChange = (period) => {
    setActivePeriod(period);
  };

  const handleOpenInfoModal = () => {
    setInfoModalOpen(true);
  };

  const handleCloseInfoModal = () => {
    setInfoModalOpen(false);
  };
  useEffect(() => {
    if (!state.categoriesLoaded && !state.categoriesLoading) {
      fetchCategoriesContext();
    } else if (
      (state.categoriesLoaded &&
        (!state.categories || state.categories.length === 0)) ||
      (!state.categories && !state.categoriesLoading)
    ) {
      setShowPopup(true);
    }

    if (
      !state.transactionsLoaded &&
      !state.transactionsLoading &&
      state.categories &&
      state.categories.length > 0
    ) {
      fetchTransactionsContext();
    }
  }, [
    fetchCategoriesContext,
    fetchTransactionsContext,
    state.categoriesLoaded,
    state.categoriesLoading,
    state.defaultCategoriesLoaded,
    state.defaultCategoriesLoading,
    state.transactionsLoaded,
    state.categories,
    state.defaultCategories,
    state.transactionsLoading,
  ]);
  useEffect(() => {
    if (
      (!state.categories || state.categories.length === 0) &&
      state.categoriesLoaded
    ) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [state.categories, state.categoriesLoaded]);
  async function saveUserCategories(selectedCategories) {
    try {
      const result = await updateUserCategories(selectedCategories);

      setShowPopup(false);
      return result;
    } catch (e) {
      console.error("Error saving user categories:", e);
    }
  }

  useEffect(() => {
    if (state.categoriesLoaded && state.categories.length > 0) {
      const updatedTotal = calculateTotalExpenditure(
        state.categories,
        state.transactions,
        activePeriod
      );

      setTotalExpenditure(updatedTotal);
    }
  }, [
    state.categoriesLoaded,
    state.categories,
    state.transactions,
    activePeriod,
  ]);

  const TimePeriodToggle = ({ activePeriod, handleTimePeriodChange }) => {
    return (
      <div className="timePeriodToggle">
        <div className="timeButtons">
          {["week", "month", "year"].map((period) => (
            <button
              key={period}
              onClick={() => handleTimePeriodChange(period)}
              className={`tabButton ${
                activePeriod === period ? "tabButtonActive" : ""
              }`}
            >
              {period.charAt(0).toUpperCase() + period.slice(1)}
            </button>
          ))}
        </div>
      </div>
    );
  };

  const handleCategorySave = async (budgets) => {
    const formattedBudgets = budgets.map((budget) => {
      let frequency = "";
      const freqUpper = budget.frequency.toUpperCase();
      if (freqUpper === "W" || freqUpper === "WEEKLY") {
        frequency = "W";
      } else if (freqUpper === "M" || freqUpper === "MONTHLY") {
        frequency = "M";
      } else if (freqUpper === "Y" || freqUpper === "YEARLY") {
        frequency = "Y";
      }
      return {
        category_id: budget.category_id,
        amount: budget.amount,
        frequency: frequency,
        parent_budget: budget.parent_budget,
      };
    });

    await updateCategories(formattedBudgets, dispatch, authDispatch);
  };

  const calculateTotalExpenditure = (
    categories,
    userTransactions,
    activePeriod
  ) => {
    const getPeriodRange = (period) => {
      const now = new Date();
      let start, end;

      switch (period) {
        case "week":
          start = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - now.getDay()
          );
          end = new Date(start);
          end.setDate(end.getDate() + 7);
          break;
        case "month":
          start = new Date(now.getFullYear(), now.getMonth(), 1);
          end = new Date(now.getFullYear(), now.getMonth() + 1, 1);
          break;
        case "year":
          start = new Date(now.getFullYear(), 0, 1);
          end = new Date(now.getFullYear() + 1, 0, 1);
          break;
        default:
          start = new Date();
          end = new Date();
      }

      return { start, end };
    };

    const { start, end } = getPeriodRange(activePeriod);
    const categoryTransactionMap = {};
    userTransactions.forEach((transaction) => {
      const transactionDate = new Date(transaction.date);
      if (transactionDate >= start && transactionDate <= end) {
        if (transaction.splits && transaction.splits.length > 0) {
          transaction.splits.forEach((split) => {
            const splitCategoryId = split.split_category_id;
            if (!categoryTransactionMap[splitCategoryId]) {
              categoryTransactionMap[splitCategoryId] = {
                spent: 0,
                transactions: [],
              };
            }
            categoryTransactionMap[splitCategoryId].spent -=
              parseFloat(split.split_amount) || 0;
            categoryTransactionMap[splitCategoryId].transactions.push(
              transaction
            );
          });
        } else {
          const categoryId = transaction.pachira_category_id;
          if (!categoryTransactionMap[categoryId]) {
            categoryTransactionMap[categoryId] = {
              spent: 0,
              transactions: [],
            };
          }
          categoryTransactionMap[categoryId].spent -=
            parseFloat(transaction.amount) || 0;
          categoryTransactionMap[categoryId].transactions.push(transaction);
        }
      }
    });
    // let level2Total = 0;

    const total = categories.reduce((total, category) => {
      let amount = parseFloat(category.amount) || 0;

      switch (category.frequency) {
        case "W":
          amount =
            activePeriod === "week"
              ? amount
              : activePeriod === "month"
              ? amount * 4.33
              : amount * 52;
          break;
        case "M":
          amount =
            activePeriod === "week"
              ? amount / 4.33
              : activePeriod === "month"
              ? amount
              : amount * 12;
          break;
        case "Y":
          amount =
            activePeriod === "week"
              ? amount / 52
              : activePeriod === "month"
              ? amount / 12
              : amount;
          break;
        default:
          break;
      }
      if (categoryTransactionMap[category.category_id]) {
        category.spent = categoryTransactionMap[category.category_id].spent;
        category.transactions =
          categoryTransactionMap[category.category_id].transactions;
      } else {
        category.spent = 0;
        category.transactions = [];
      }

      if (category.level === 2) {
        // level2Total += amount;
      }

      return total + amount;
    }, 0);

    // return total - level2Total;
    return total;
  };

  return (
    <div className="dashboard">
      <div className="header">
        <Stack direction="row" alignItems="center" spacing={2}>
          <h1>Budgets</h1>
          <Tooltip title="Info">
            <FontAwesomeIcon
              id="info-button"
              icon={faCircleInfo}
              onClick={handleOpenInfoModal}
            />
          </Tooltip>
        </Stack>
        <Stack direction="row" spacing={2}>
          {state.categoriesLoaded && state.categories ? (
            <CategoryManagement />
          ) : (
            <div>Loading categories...</div>
          )}
        </Stack>
      </div>

      <TimePeriodToggle
        activePeriod={activePeriod}
        handleTimePeriodChange={handleTimePeriodChange}
      />

      {state.categories.length !== 0 ? (
        <div>
          <div className="totalExpenditure">
            <h2 style={{ marginTop: "0px", marginBottom: "0px" }}>
              Total Planned Expenditure: {formatCurrency(totalExpenditure)}
            </h2>
          </div>
          <OverlayModal open={infoModalOpen} onClose={handleCloseInfoModal}>
            <Stack px={2}>
              <Typography variant="h6" color={theme.palette.primary.main}>
                Quick Tips: Efficient Category and Budget Management
              </Typography>
              <ul className="tipList">
                <li>
                  Kickstart your budget by setting up{" "}
                  <strong>Group Categories</strong> for Income, Transfer, and
                  Expense, like Food or Income Sources.
                </li>
                <li>
                  Dive deeper by creating <strong>Regular Categories</strong>{" "}
                  within these groups, such as Groceries or Eating Out, where
                  you'll assign your actual transactions.
                </li>
                <li>
                  Once your categories are ready, you can also set up budgets
                  for them. Choose how much to spend each week, month, or year.
                </li>
                <li>
                  On this page, you'll manage and view only your Expense
                  categories for a focused overview. Begin by clicking{" "}
                  <strong>Create Category</strong>.
                </li>
              </ul>
            </Stack>
          </OverlayModal>
          <div className="categoryList">
            <div className="budgetProgressBarContainer">
              <BudgetProgressBar
                categories={state.categories.filter(
                  (category) => category.type === "EXP"
                )}
                activePeriod={activePeriod}
              />
            </div>
            <div className="budgetTableContainer">
              <BudgetTable
                categories={state.categories.filter(
                  (category) => category.type === "EXP"
                )}
                onSave={handleCategorySave}
              />
            </div>
          </div>
        </div>
      ) : (
        showPopup && (
          <BudgetPopup
            onClose={() => setShowPopup(false)}
            onSave={saveUserCategories}
          />
        )
      )}
    </div>
  );
};
export default BudgetPage;
