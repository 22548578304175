import React, { useState, useEffect, useContext } from "react";
import { CurrencyContext } from "../context/currencyContext";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "../components/modal";
import "../App.css";
import Spinner from "./spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { AccountsContext } from "../context/AccountsContext";
import { useTheme } from "@emotion/react";
import { Button, Stack, useMediaQuery } from "@mui/material";
import { formatTableDate } from "../shared/utils/helpers";
import MobileTransactionRow from "./MobileTransactionRow";
import APIService from "./api";

const AllTransactions = ({ searchParams, topElementsRef }) => {
  const {
    fetchCategoriesContext,
    fetchTransactionsContext,
    updateTransaction,
    updateTransactionSplit,
    deleteTransactions,
  } = APIService();
  const { formatCurrency } = useContext(CurrencyContext);
  const { state } = useContext(AccountsContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = useState(false);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactionCategory, setTransactionCategory] = useState("");
  const [isDeleteChecked, setIsDeleteChecked] = useState(false);
  const [merchant, setMerchant] = useState("");
  const [memo, setMemo] = useState("");
  const [date, setDate] = useState("");
  const [amount, setAmount] = useState("");
  const [notes, setNotes] = useState("");
  const handleDeleteCheckboxChange = (e) => {
    setIsDeleteChecked(e.target.checked);
  };
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipClicked, setTooltipClicked] = React.useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const tooltipRef = React.useRef(null);
  const [tooltipDimensions, setTooltipDimensions] = React.useState({
    width: 0,
    height: 0,
  });
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    const adjustTableHeight = () => {
      const mainContent = document.querySelector(".main-content");
      if (mainContent) {
        setTableHeight(
          mainContent.offsetHeight - topElementsRef.current.offsetHeight
        );
      }
    };
    adjustTableHeight();
    window.addEventListener("resize", adjustTableHeight);
    return () => window.removeEventListener("resize", adjustTableHeight);
  }, []);

  const toggleTooltip = (content, x, y, clicked = false) => {
    setTooltipOpen(!tooltipOpen || clicked);
    setTooltipClicked(clicked);
    if (content) {
      setTooltipContent(content);
      setTooltipPosition({ x, y });
    }
  };
  React.useEffect(() => {
    if (tooltipOpen && tooltipRef.current) {
      const { width, height } = tooltipRef.current.getBoundingClientRect();
      setTooltipDimensions({ width, height });
    }
  }, [tooltipOpen, tooltipContent]);

  useEffect(() => {
    if (selectedTransaction) {
      setTransactionCategory(selectedTransaction.pachira_category_id || "");
      setMerchant(selectedTransaction.merchant_name || "");
      setNotes(selectedTransaction.notes || "");
      setMemo(selectedTransaction.memo || "");
      setAmount(selectedTransaction.amount.toString());
      setDate(selectedTransaction.date || "");
    }
  }, [selectedTransaction]);

  const [splitRows, setSplitRows] = useState([
    { category_id: "", amount: "", notes: "" },
    { category_id: "", amount: "", notes: "" },
  ]);

  const [splits, setSplits] = useState(false);

  useEffect(() => {
    if (
      selectedTransaction &&
      selectedTransaction.splits &&
      selectedTransaction.splits.length >= 2
    ) {
      setSplits(true);
      setTransactionCategory("split");
      const initialSplitRows = selectedTransaction.splits.map((split) => ({
        category_id: split.split_category_id,
        amount: split.split_amount,
        notes: split.split_notes,
      }));
      setSplitRows(initialSplitRows);
    } else {
      setSplits(false);
    }
  }, [selectedTransaction]);

  const addSplitRow = () => {
    setSplitRows([...splitRows, { category_id: "", amount: "", notes: "" }]);
  };

  const handleSplitCategoryChange = (e, index) => {
    const updatedRows = splitRows.map((row, idx) =>
      idx === index ? { ...row, category_id: e.target.value } : row
    );
    setSplitRows(updatedRows);
  };

  const handleSplitNotesChange = (e, index) => {
    const updatedRows = splitRows.map((row, idx) =>
      idx === index ? { ...row, notes: e.target.value } : row
    );
    setSplitRows(updatedRows);
  };

  const handleSplitAmountChange = (e, index) => {
    const updatedRows = splitRows.map((row, idx) =>
      idx === index ? { ...row, amount: e.target.value } : row
    );
    setSplitRows(updatedRows);
  };

  const handleSplitAmountBlur = () => {
    const totalAmount = calculateDifference();
    const remainingAmount = selectedTransaction.amount - totalAmount;

    if (splitRows.length > 1) {
      const updatedRows = [...splitRows];
      updatedRows[updatedRows.length - 1].amount = remainingAmount.toFixed(2);
      setSplitRows(updatedRows);
    }
  };

  const calculateDifference = () => {
    return splitRows
      .slice(0, -1)
      .reduce((acc, curr) => acc + (parseFloat(curr.amount) || 0), 0);
  };
  const removeSplitRow = (index) => {
    if (splitRows.length > 2) {
      const updatedRows = splitRows.filter((_, idx) => idx !== index);
      setSplitRows(updatedRows);
    }
  };
  const calculateTotal = () => {
    return splitRows
      .reduce((acc, curr) => acc + (parseFloat(curr.amount) || 0), 0)
      .toFixed(2);
  };
  const handleBulkDelete = () => {
    setShowDeleteWarningModal(true);
  };
  const confirmBulkDelete = async () => {
    setIsLoading(true);

    try {
      await deleteTransactions({ transactionIds: selectedRowIds });

      // Reset selected rows state
      setSelectedRowIds([]);
      setShowDeleteWarningModal(false);
    } catch (error) {
      console.error("Error deleting transactions:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!state.transactionsLoaded && !state.transactionsLoading) {
      fetchTransactionsContext();
    }
    if (!state.categoriesLoaded && !state.categoriesLoading) {
      fetchCategoriesContext();
    }
  }, [
    fetchTransactionsContext,
    fetchCategoriesContext,
    state.categoriesLoaded,
    state.transactionsLoaded,
    state.categoriesLoading,
    state.transactionsLoading,
  ]);
  useEffect(() => {
    const filterTransactions = () => {
      const filtered = state.transactions.filter((transaction) => {
        const startDateMatch = searchParams.startDate
          ? new Date(transaction.date) >= new Date(searchParams.startDate)
          : true;
        const endDateMatch = searchParams.endDate
          ? new Date(transaction.date) <= new Date(searchParams.endDate)
          : true;
        const memoMatch = transaction.memo
          ? transaction.memo
              .toLowerCase()
              .includes(searchParams.memo.toLowerCase())
          : true;
        const merchantMatch =
          transaction.merchant_name === searchParams.merchant ||
          !searchParams.merchant;
        const bankMatch =
          transaction.account_display_name === searchParams.bank ||
          !searchParams.bank;
        const categoryMatch =
          transaction.pachira_category_id === parseInt(searchParams.category) ||
          (transaction.splits &&
            transaction.splits.some(
              (split) =>
                parseInt(split.split_category_id) ===
                parseInt(searchParams.category)
            )) ||
          !searchParams.category;
        const minAmountMatch = searchParams.minAmount
          ? transaction.amount >= parseFloat(searchParams.minAmount)
          : true;
        const maxAmountMatch = searchParams.maxAmount
          ? transaction.amount <= parseFloat(searchParams.maxAmount)
          : true;

        return (
          startDateMatch &&
          endDateMatch &&
          memoMatch &&
          merchantMatch &&
          categoryMatch &&
          minAmountMatch &&
          maxAmountMatch &&
          bankMatch
        );
      });
      setFilteredTransactions(
        filtered.map((t) => {
          return { ...t, id: t.pachira_transaction_id };
        })
      );
    };

    filterTransactions();
  }, [searchParams, state.transactions]);

  const EditButton = ({ rowData }) => {
    return (
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.primary.main,
          px: 1,
          py: 0.25,
        }}
        onClick={() => handleEditClick(rowData)}
      >
        Edit
      </Button>
    );
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => formatTableDate(params.value),
      sortComparator: (v1, v2, param1, param2) => {
        return (
          new Date(param1.value).getTime() - new Date(param2.value).getTime()
        );
      },
    },
    {
      field: "memo",
      headerName: "Memo",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "merchant_name",
      headerName: "Merchant",
      flex: 2,
      minWidth: 100,
      maxWidth: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.merchant_logo && (
            <img
              src={params.row.merchant_logo}
              alt={params.value}
              style={{ marginRight: 8, width: "30px", height: "30px" }}
            />
          )}
          {params.value}
        </div>
      ),
    },
    {
      field: "account_display_name",
      headerName: "Bank",
      flex: 2,
      minWidth: 100,
      maxWidth: 200,
    },
    {
      field: "category_name",
      headerName: "Category",
      flex: 1,
      minWidth: 100,
      maxWidth: 200,
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      flex: 1,
      minWidth: 100,
      maxWidth: 200,
      renderCell: ({ value }) => {
        const amount = parseFloat(value);
        const isNegative = amount < 0;
        return (
          <span style={{ color: isNegative ? "red" : "green" }}>
            {formatCurrency(amount)}
          </span>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => {
        return <EditButton rowData={params.row} />;
      },
      sortable: false,
    },
    {
      field: "notesIcon",
      headerName: <FontAwesomeIcon icon={faStickyNote} />,
      width: 50,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const cellStyle = {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          cursor: "pointer",
        };

        return (
          <div
            style={cellStyle}
            onMouseEnter={(e) => {
              if (
                !tooltipClicked &&
                params.row.notes &&
                params.row.notes !== ""
              ) {
                toggleTooltip(params.row.notes, e.clientX - 50, e.clientY - 50);
              }
            }}
            onMouseLeave={() => {
              if (!tooltipClicked) {
                setTooltipOpen(false);
              }
            }}
            onClick={(e) => {
              const clicked = !tooltipClicked;
              toggleTooltip(
                params.row.notes,
                e.clientX - 50,
                e.clientY - 50,
                clicked
              );
            }}
          >
            {params.row.notes && params.row.notes !== "" && (
              <FontAwesomeIcon icon={faStickyNote} />
            )}
          </div>
        );
      },
    },
  ];

  const handleModalSubmit = async () => {
    setIsLoading(true);
    let splitPayload;
    if (splits) {
      const totalAmount = splitRows.reduce(
        (sum, row) => sum + parseFloat(row.amount || 0),
        0
      );

      if (totalAmount !== parseFloat(selectedTransaction.amount)) {
        alert(
          "The total split amounts must equal the original transaction amount."
        );
        setIsLoading(false);
        return;
      }

      for (const row of splitRows) {
        if (!row.category_id) {
          alert("Each split must have a category selected.");
          setIsLoading(false);
          return;
        }
      }

      splitPayload = splitRows.map((row) => ({
        pachira_transaction_id: selectedTransaction.pachira_transaction_id,
        category_id: row.category_id,
        amount: parseFloat(row.amount).toFixed(2),
        notes: row.notes,
      }));
    }
    if (splits) {
      await updateTransactionSplit(splitPayload);
    }
    const categoryIDNumber = parseInt(transactionCategory);

    const updatedSplits = splitRows.map((row) => ({
      split_category_id: parseInt(row.category_id),
      split_amount: parseFloat(row.amount).toFixed(2),
      split_notes: row.notes,

      split_category_name: state.categories.find(
        (cat) => cat.category_id === parseInt(row.category_id)
      )?.category_name,
    }));

    let updatedTransaction = {
      ...selectedTransaction,
      pachira_category_id:
        categoryIDNumber !== "split" ? categoryIDNumber : null,
      merchant_name: merchant,
      notes,
      memo,
      date,
      amount: parseFloat(amount),
      isDeleted: isDeleteChecked,
      category_name: state.categories.find(
        (cat) => cat.category_id === categoryIDNumber
      )?.category_name,
      splits: splits ? updatedSplits : [],
    };

    try {
      await updateTransaction(updatedTransaction);
      setIsLoading(false);
      setShowEditModal(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating transaction:", error);
    }
  };
  // Function to fetch transactions based on search parameters
  const getSortedAndGroupedCategories = () => {
    const typeOrder = { INC: 1, TRA: 2, EXP: 3 };
    let groupedCategories = [];

    // Filter and sort Level 1 categories by type
    let sortedLevel1Categories = state.categories
      .filter((cat) => cat.level === 1)
      .sort((a, b) => {
        const typeComparison = typeOrder[a.type] - typeOrder[b.type];
        if (typeComparison !== 0) {
          return typeComparison;
        }
        return a.category_name.localeCompare(b.category_name);
      });
    sortedLevel1Categories.forEach((level1Cat) => {
      let subCategories = state.categories
        .filter((cat) => cat.parent_id === level1Cat.category_id)
        .sort((a, b) => a.category_name.localeCompare(b.category_name));

      if (subCategories.length > 0) {
        groupedCategories.push({
          parent: level1Cat,
          children: subCategories,
        });
      }
    });

    return groupedCategories;
  };
  const renderDropdownOptions = () => {
    const groupedCategories = getSortedAndGroupedCategories();

    return groupedCategories.flatMap((group) => {
      return [
        <option
          key={group.parent.category_id}
          value={group.parent.category_id}
          disabled
          className="optionGroup"
        >
          {group.parent.category_name}
        </option>,
        ...group.children.map((subCategory) => (
          <option
            key={subCategory.category_id}
            value={subCategory.category_id}
            className="option"
          >
            {subCategory.category_name}
          </option>
        )),
      ];
    });
  };
  const handleEditClick = (rowData) => {
    setSelectedTransaction(rowData);
    setShowEditModal(true);
  };

  const formatDateForInput = (isoDate) => {
    const date = new Date(isoDate);
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split("T")[0];
  };

  return (
    <div>
      {tooltipOpen && (
        <div
          ref={tooltipRef}
          style={{
            position: "fixed",
            top: `${tooltipPosition.y - tooltipDimensions.height}px`,
            left: `${tooltipPosition.x - tooltipDimensions.width}px`,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "10px",
            borderRadius: "8px",
            fontSize: "0.875rem",
            color: "#333",
            zIndex: 997,
            maxWidth: "200px",
            wordWrap: "break-word",
            transition: "opacity 0.3s",
            opacity: 0.9,
          }}
        >
          {tooltipContent}
        </div>
      )}
      {selectedRowIds.length > 1 && (
        <button
          className="dangerButton"
          onClick={handleBulkDelete}
          disabled={isLoading}
        >
          Bulk Delete
        </button>
      )}
      <div className="tableContainer">
        <div className="gridContainer" style={{ height: tableHeight }}>
          {isMobile ? (
            <Stack>
              {filteredTransactions.map((transactionRow) => {
                return (
                  <MobileTransactionRow
                    rowData={transactionRow}
                    actionButtons={<EditButton rowData={transactionRow} />}
                  />
                );
              })}
            </Stack>
          ) : (
            <DataGrid
              rows={filteredTransactions}
              columns={columns}
              pageSize={100}
              loading={state.transactionsLoading || state.categoriesLoading}
              checkboxSelection
              disableSelectionOnClick
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectedRowIds(newSelectionModel);
              }}
              selectionModel={selectedRowIds}
            />
          )}
        </div>
      </div>
      {showDeleteWarningModal && (
        <Modal
          open={showDeleteWarningModal}
          onClose={() => setShowDeleteWarningModal(false)}
          title="Confirm Bulk Deletion"
        >
          <div className="modalContent">
            <p>
              Are you sure you want to permanently delete these transactions?
              This action cannot be undone.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <button
                className="blueButton"
                onClick={() => setShowDeleteWarningModal(false)}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </button>
              <button
                className="dangerButton"
                onClick={confirmBulkDelete}
                disabled={isLoading}
              >
                Confirm Delete
              </button>
            </div>
          </div>
        </Modal>
      )}
      {showEditModal && (
        <Modal
          open={showEditModal}
          onClose={() => {
            setShowEditModal(false);
            setSplits(false);
            setSplitRows([
              { category_id: "", amount: "", notes: "" },
              { category_id: "", amount: "", notes: "" },
            ]);
          }}
          title="Edit Transaction"
        >
          <div className="modalContent">
            <input
              type="date"
              value={
                selectedTransaction.date
                  ? formatDateForInput(selectedTransaction.date)
                  : ""
              }
              onChange={(e) => setDate(e.target.value)}
              className="modalInput"
            />
            <label className="modalLabel">
              Memo
              <input
                type="text"
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
                className="modalInput"
              />
            </label>
            <label className="modalLabel">
              Merchant
              <input
                type="text"
                value={merchant}
                onChange={(e) => setMerchant(e.target.value)}
                className="modalInput"
              />
            </label>

            <label className="modalLabel">
              Category
              <select
                value={transactionCategory}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setTransactionCategory(selectedValue);
                  if (selectedValue === "split") {
                    setSplits(true);
                  } else {
                    setSplits(false);
                  }
                }}
                className="modalInput"
              >
                <option value="">Select Category</option>
                <option value="split">Split</option>
                {renderDropdownOptions()}
              </select>
            </label>
            {(selectedTransaction.splits > 0 || splits) && (
              <>
                <div className="card">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Amount</th>
                        <th>Notes</th>
                        <th style={{ width: "30px" }}> </th>{" "}
                      </tr>
                    </thead>
                    <tbody>
                      {splitRows.map((split, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              value={split.category_id}
                              onChange={(e) =>
                                handleSplitCategoryChange(e, index)
                              }
                              className="modalSelect2"
                            >
                              <option value="">Select Category</option>
                              {renderDropdownOptions()}
                            </select>
                          </td>
                          <td>
                            <input
                              type="number"
                              value={split.amount}
                              onChange={(e) =>
                                handleSplitAmountChange(e, index)
                              }
                              className="modalInput"
                              onBlur={() => handleSplitAmountBlur()}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={split.notes}
                              onChange={(e) => handleSplitNotesChange(e, index)}
                              className="modalInput"
                            />
                          </td>
                          <td>
                            {splitRows.length > 2 && (
                              <button
                                className="removeButton"
                                onClick={() => removeSplitRow(index)}
                                aria-label="Remove row"
                              >
                                X
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr className="tableFooter">
                        <td>Total</td>
                        <td>{calculateTotal()}</td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <button className="whiteButton" onClick={addSplitRow}>
                  Add Split
                </button>
              </>
            )}
            <label className="modalLabel">
              Amount
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="modalInput"
              />
            </label>
            <label className="modalLabel">
              Notes
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="modalInput"
              />
            </label>
            <label className="modalLabel warning">
              <input
                type="checkbox"
                checked={selectedTransaction.isDeleted}
                className="modalCheckbox "
                onChange={handleDeleteCheckboxChange}
              />
              DELETE THIS TRANSACTION
            </label>

            {isLoading ? (
              <Spinner />
            ) : (
              <button className="fullLengthButton" onClick={handleModalSubmit}>
                Submit
              </button>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AllTransactions;
