import React, { useState, useEffect, useContext } from "react";
import { CurrencyContext } from "../context/currencyContext";
import APIService from "./api";
import "../App.css";
import { AccountsContext } from "../context/AccountsContext";

const BudgetCheck = () => {
  const { fetchCategoriesContext, fetchTransactionsContext } = APIService();
  const { state } = useContext(AccountsContext);
  const [budgetProgressData, setBudgetProgressData] = useState([]);
  const { formatCurrency } = useContext(CurrencyContext);

  useEffect(() => {
    if (!state.categoriesLoaded && !state.categoriesLoading) {
      fetchCategoriesContext();
    }
    if (!state.transactionsLoaded && !state.transactionsLoading) {
      fetchTransactionsContext();
    }
  }, [
    fetchCategoriesContext,
    fetchTransactionsContext,
    state.categoriesLoaded,
    state.transactionsLoaded,
    state.categoriesLoading,
    state.transactionsLoading,
  ]);

  useEffect(() => {
    if (state.categoriesLoaded && state.transactionsLoaded) {
      const expCategories = state.categories.filter(
        (category) => category.type === "EXP"
      );

      const categoriesByParentId = expCategories.reduce((acc, category) => {
        if (category.parent_id) {
          if (!acc[category.parent_id]) acc[category.parent_id] = [];
          acc[category.parent_id].push(category);
        }
        return acc;
      }, {});

      const processedData = expCategories
        .filter((category) => {
          const isLevel1Tracked =
            category.parent_budget === true && category.level === 1;

          // Condition for Level 2 categories with a non-tracked parent
          const hasNonTrackedParent =
            category.parent_id &&
            !expCategories.find((cat) => {
              const isParentNonTracked =
                cat.category_id === category.parent_id &&
                cat.parent_budget === true;
              return isParentNonTracked;
            });
          const includeCategory = isLevel1Tracked || hasNonTrackedParent;
          return includeCategory;
        })
        .map((category) => {
          const { startDate, endDate } = getDateRange(category.frequency);

          let categoryTransactions = state.transactions.flatMap(
            (transaction) => {
              const transactionDate = new Date(transaction.date);
              if (transactionDate >= startDate && transactionDate <= endDate) {
                if (transaction.pachira_category_id === category.category_id) {
                  return [
                    {
                      ...transaction,
                      effectiveAmount: -parseFloat(transaction.amount),
                    },
                  ];
                }
                // Handle split transactions where one of the splits matches this category
                if (!transaction.pachira_category_id && transaction.splits) {
                  return transaction.splits
                    .filter(
                      (split) =>
                        split.split_category_id === category.category_id
                    )
                    .map((split) => ({
                      ...transaction,
                      effectiveAmount: -parseFloat(split.split_amount),
                    }));
                }
              }
              return [];
            }
          );

          // If this is a level 1 category with tracked subcategories, include transactions from those subcategories
          if (
            category.parent_budget &&
            categoriesByParentId[category.category_id]
          ) {
            categoriesByParentId[category.category_id].forEach(
              (subCategory) => {
                const subCategoryTransactions = state.transactions.flatMap(
                  (transaction) => {
                    const transactionDate = new Date(transaction.date);
                    if (
                      transactionDate >= startDate &&
                      transactionDate <= endDate
                    ) {
                      if (
                        transaction.pachira_category_id ===
                        subCategory.category_id
                      ) {
                        return [
                          {
                            ...transaction,
                            effectiveAmount: -parseFloat(transaction.amount),
                          },
                        ];
                      }
                      if (
                        !transaction.pachira_category_id &&
                        transaction.splits
                      ) {
                        return transaction.splits
                          .filter(
                            (split) =>
                              split.split_category_id ===
                              subCategory.category_id
                          )
                          .map((split) => ({
                            ...transaction,
                            effectiveAmount: -parseFloat(split.split_amount),
                          }));
                      }
                    }
                    return [];
                  }
                );
                categoryTransactions = categoryTransactions.concat(
                  subCategoryTransactions
                );
              }
            );
          }

          // Calculate total spent for this category using effectiveAmount for splits or direct transactions
          const totalSpent = categoryTransactions.reduce(
            (acc, { effectiveAmount }) => acc + effectiveAmount,
            0
          );

          // Calculate spent percent
          const spentPercent = (totalSpent / category.amount) * 100;

          // Determine if spending is above proportional plus 5 percent
          const dayPercent = getDayPercent(startDate, endDate);
          const isAboveProportionalPlus5Percent = spentPercent >= dayPercent;

          // Determine if spending is above 85 percent
          const isAbove85Percent = spentPercent >= 85;

          return {
            ...category,
            totalSpent,
            spentPercent,
            isAboveProportionalPlus5Percent,
            isAbove85Percent,
          };
        });
      setBudgetProgressData(processedData);
    }
  }, [
    state.categories,
    state.transactions,
    state.categoriesLoaded,
    state.transactionsLoaded,
  ]);

  const getDateRange = (frequency) => {
    const now = new Date();
    let startDate, endDate;

    switch (frequency) {
      case "W":
        startDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - now.getDay()
        );
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        break;
      case "M":
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "Y":
        startDate = new Date(now.getFullYear(), 0, 1);
        endDate = new Date(now.getFullYear(), 11, 31);
        break;
      default:
        startDate = null;
        endDate = null;
    }

    return { startDate, endDate };
  };

  const getDayPercent = (startDate, endDate) => {
    const now = new Date();
    let dayOfYear = (now - startDate) / (1000 * 60 * 60 * 24) + 1;
    let totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;

    return (dayOfYear / totalDays) * 100 + 5;
  };

  const renderBudgetSection = (data, frequencyLabel) => {
    const sortedSectionData = [...data].sort(
      (a, b) => b.spentPercent - a.spentPercent
    );
    return (
      <div className="frequencySection">
        <h4>{frequencyLabel}</h4>
        {sortedSectionData.map((budget, index) => {
          let progressPercent = (budget.totalSpent / budget.amount) * 100;
          progressPercent = Math.max(0, Math.min(100, budget.spentPercent));

          return (
            <div key={index} className="budgetItem">
              <div className="categoryName">{budget.category_name}</div>
              <div className="progressBarContainer">
                <div
                  className="progressBar"
                  style={{
                    width: `${progressPercent}%`,
                    backgroundColor:
                      budget.isAbove85Percent ||
                      budget.isAboveProportionalPlus5Percent
                        ? "#FF3333"
                        : "green",
                  }}
                ></div>
              </div>
              <div className="budgetInfo">
                <span>{formatCurrency(budget.totalSpent || "0.00")}</span>
                <span className="budgetMiddle">
                  {budget.isAbove85Percent && (
                    <div className="warning">
                      {Math.round(budget.spentPercent)}% of budget spent
                    </div>
                  )}
                  {budget.isAboveProportionalPlus5Percent &&
                    !budget.isAbove85Percent && (
                      <div className="warning">
                        Spending is a little ahead of budget
                      </div>
                    )}
                </span>
                <span>{formatCurrency(budget.amount)}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const weeklyData = budgetProgressData.filter(
    (budget) => budget.frequency === "W"
  );
  const monthlyData = budgetProgressData.filter(
    (budget) => budget.frequency === "M"
  );
  const yearlyData = budgetProgressData.filter(
    (budget) => budget.frequency === "Y"
  );
  return (
    <div className="widgetContainer">
      <h3 className="header">Budget Check</h3>
      {weeklyData.length > 0 && renderBudgetSection(weeklyData, "Weekly")}
      {monthlyData.length > 0 && renderBudgetSection(monthlyData, "Monthly")}
      {yearlyData.length > 0 && renderBudgetSection(yearlyData, "Yearly")}
    </div>
  );
};

export default BudgetCheck;
