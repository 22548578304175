import React from "react";
import "../App.css";

const ViewToggleButtonGroup = ({ view, setView }) => {
  const getViewButtonClass = (buttonView) => {
    return `tabButton ${view === buttonView ? "tabButtonActive" : ""}`;
  };

  return (
    <div className="toggleButtons">
      <button
        className={getViewButtonClass("Spending")}
        onClick={() => setView("Spending")}
      >
        Spending
      </button>
      <button
        className={getViewButtonClass("Net Worth")}
        onClick={() => setView("Net Worth")}
      >
        Net Worth
      </button>
    </div>
  );
};

export default ViewToggleButtonGroup;
