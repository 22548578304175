function getDateRange(range, transactions) {
  const today = new Date();
  let startDate, endDate;

  const getEarliestDate = (transactions) => {
    if (transactions && transactions.length > 0) {
      return new Date(Math.min(...transactions.map((t) => new Date(t.date))));
    }
    return new Date("2000-01-01");
  };

  switch (range) {
    case "Last7Days":
      startDate = new Date();
      startDate.setDate(today.getDate() - 6);
      endDate = today;
      break;

    case "Last28Days":
      startDate = new Date();
      startDate.setDate(today.getDate() - 27);
      endDate = today;
      break;

    case "LastYear":
      startDate = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      );
      endDate = today;
      break;

    case "AllTime":
      startDate = getEarliestDate(transactions);
      endDate = today;
      break;

    case "ThisWeek":
      const dayOfWeek = today.getDay();
      startDate = new Date(today);
      startDate.setDate(today.getDate() - dayOfWeek);
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
      break;

    case "ThisMonth":
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      break;

    case "ThisYear":
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = new Date(today.getFullYear(), 11, 31);
      break;

    default:
      throw new Error("Invalid date range");
  }
  const formatLocalDate = (date) => {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  };

  return {
    startDate: formatLocalDate(startDate),
    endDate: formatLocalDate(endDate),
  };
}

function generateAggregationPoints(startDate, endDate, range) {
  let dates = [];
  let currentDate = new Date(`${startDate}T00:00:00Z`);
  endDate = new Date(`${endDate}T23:59:59Z`);

  if (["Last7Days", "Last28Days", "ThisWeek", "ThisMonth"].includes(range)) {
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate = new Date(
        currentDate.setUTCDate(currentDate.getUTCDate() + 1)
      );
    }
  } else {
    while (currentDate <= endDate) {
      dates.push(
        new Date(
          Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 1)
        )
      );
      currentDate = new Date(
        currentDate.setUTCMonth(currentDate.getUTCMonth() + 1)
      );
    }
  }
  return dates.map(
    (date) =>
      new Date(
        Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
      )
  );
}

const calculateIncomeVsExpenses = (transactions) => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  let categories = {
    income: {},
    expenses: {},
  };

  transactions.forEach((transaction) => {
    if (transaction.type === "TRA" || transaction.type === null) {
      return; // Skip transactions of type TRA or null
    }

    const transactionDate = new Date(transaction.date);
    if (
      transactionDate >= firstDayOfMonth &&
      transactionDate <= lastDayOfMonth
    ) {
      const amount = parseFloat(transaction.amount);
      const categoryName =
        transaction.original_category_name || transaction.category_name;
      const categoryGroup =
        transaction.type === "EXP" ? categories.expenses : categories.income;
      const adjustedAmount = transaction.type === "EXP" ? -amount : amount;

      if (categoryGroup[categoryName]) {
        categoryGroup[categoryName] += adjustedAmount;
      } else {
        categoryGroup[categoryName] = adjustedAmount;
      }
    }
  });

  // Round all amounts to two decimal places and sort by amount
  categories.income = sortCategories(categories.income);
  categories.expenses = sortCategories(categories.expenses);

  return categories;
};

// Helper function to sort categories by their amount values
function sortCategories(categoryData) {
  return Object.entries(categoryData)
    .sort((a, b) => Math.abs(b[1]) - Math.abs(a[1])) // Sort by absolute values descending
    .reduce((obj, [key, value]) => {
      obj[key] = parseFloat(value.toFixed(2));
      return obj;
    }, {});
}

const aggregateSpending = (transactions, startDate, endDate) => {
  let dates = generateAggregationPoints(startDate, endDate, "Last7Days");

  let aggregatedData = dates.map((date) => ({
    date: date.toISOString().split("T")[0],
    totalSpending: 0,
  }));
  startDate = new Date(new Date(startDate).toISOString().split("T")[0]);
  endDate = new Date(new Date(endDate).toISOString().split("T")[0]);
  endDate = new Date(endDate.getTime() + (23 * 60 + 59) * 60 * 1000 + 999);
  let filteredTransactions = transactions
    .filter(
      (transaction) =>
        transaction.type === "EXP" &&
        new Date(transaction.date) >= startDate &&
        new Date(transaction.date) <= endDate
    )
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  filteredTransactions.forEach((transaction) => {
    let cumulativeSpending = 0;
    cumulativeSpending -= parseFloat(transaction.amount);
    let transactionDateStr = new Date(transaction.date)
      .toISOString()
      .split("T")[0];
    aggregatedData.forEach((data) => {
      if (data.date >= transactionDateStr) {
        data.totalSpending += cumulativeSpending;
      }
    });
  });

  return aggregatedData;
};

const calculateSpendingData = (transactions, timeRange) => {
  const { startDate, endDate } = getDateRange(timeRange, transactions);

  let primaryData = aggregateSpending(transactions, startDate, endDate);

  let secondaryStartDate = new Date(startDate);
  let daysDifference =
    (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24);
  secondaryStartDate.setDate(secondaryStartDate.getDate() - daysDifference);
  const formatDate = (date) => {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  let formattedSecondaryStartDate = formatDate(secondaryStartDate);

  let secondaryData = aggregateSpending(
    transactions,
    formattedSecondaryStartDate,
    startDate
  );

  const aggregatedData = primaryData.map((primaryEntry, index) => {
    const secondaryEntry = secondaryData[index] || { totalSpending: 0 };

    return {
      date: primaryEntry.date,
      primarySpending: primaryEntry.totalSpending,
      secondarySpending: secondaryEntry.totalSpending,
    };
  });

  return aggregatedData;
};

const calculateNetWorthData = (netWorthData, timeRange) => {
  const { startDate, endDate } = getDateRange(timeRange, netWorthData);
  let dailyNetWorth = aggregateNetWorthByDate(netWorthData, startDate, endDate);
  return dailyNetWorth;
};

const aggregateNetWorthByDate = (netWorthData, startDate, endDate) => {
  let dailyNetWorth = {};
  let currentDate = new Date(startDate);
  currentDate.setHours(0, 0, 0, 0);
  currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  let endDateObj = new Date(endDate);
  endDateObj.setHours(23, 59, 59, 999);
  endDateObj = new Date(endDateObj.setDate(endDateObj.getDate() + 1));
  while (currentDate < endDateObj) {
    const dateStr = currentDate.toISOString().split("T")[0];
    dailyNetWorth[dateStr] = null;
    currentDate.setDate(currentDate.getDate() + 1);
  }
  netWorthData.forEach((entry) => {
    const entryDate = new Date(entry.date);
    entryDate.setHours(0, 0, 0, 0);
    const entryDateStr = entryDate.toISOString().split("T")[0];
    if (entryDateStr in dailyNetWorth) {
      dailyNetWorth[entryDateStr] += parseFloat(entry.balance) || 0;
    }
  });
  const dayAfterEndDateStr = endDateObj.toISOString().split("T")[0];
  delete dailyNetWorth[dayAfterEndDateStr];
  return Object.keys(dailyNetWorth)
    .sort()
    .map((date) => ({
      date,
      netWorth: dailyNetWorth[date],
    }));
};
function formatCurrency(input) {
  if (isNaN(input) || input == null) {
    input = 0;
  }
  const isNegative =
    input.toString().includes("(") && input.toString().includes(")");
  const sanitizedInput = input.toString().replace(/[$,()]/g, "");
  const number = parseFloat(sanitizedInput) * (isNegative ? -1 : 1);
  if (isNaN(number)) {
    return "Invalid input";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  }).format(number);
}
const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  }).format(number);
};
export {
  getDateRange,
  calculateIncomeVsExpenses,
  calculateSpendingData,
  calculateNetWorthData,
  formatCurrency,
  formatNumber,
};
