import React, { createContext, useReducer } from "react";

const AccountsContext = createContext(null);

const initialState = {
  transactionsLoaded: false,
  transactionsLoading: false,
  transactionsUpdated: false,
  transactionsUpdating: false,
  transactions: [],
  recurringTransactionsLoaded: false,
  recurringTransactionsLoading: false,
  recurringTransactionsUpdated: false,
  recurringTransactionsUpdating: false,
  recurringTransactions: [],
  categoriesLoaded: false,
  categoriesLoading: false,
  categoriesUpdated: false,
  categoriesUpdating: false,
  categories: [],
  defaultCategoriesLoaded: false,
  defaultCategoriesLoading: false,
  defaultCategories: [],
  accountsLoaded: false,
  accountsLoading: false,
  accountsUpdated: false,
  accountsUpdating: false,
  accounts: [],
  investmentsLoading: false,
  investmentsLoaded: false,
  investmentsUpdated: false,
  investmentsUpdating: false,
  investments: [],
  rulesLoaded: false,
  rulesLoading: false,
  rulesUpdated: false,
  rulesUpdating: false,
  rules: [],
  partiesLoaded: false,
  partiesLoading: false,
  partiesUpdated: false,
  partiesUpdating: false,
  parties: [],
  investmentsOverTimeLoaded: false,
  investmentsOverTimeLoading: false,
  investmentsOverTime: [],
  accountBalancesOverTimeLoaded: false,
  accountBalancesOverTimeLoading: false,
  accountBalancesOverTime: [],
  accountTypes: [],
  subscriptionsLoaded: false,
  subscriptionsLoading: false,
  subscriptionsUpdated: false,
  subscriptionsUpdating: false,
  subscriptions: [],
  paymentsLoaded: false,
  paymentsLoading: false,
  paymentsUpdated: false,
  paymentsUpdating: false,
  payments: [],
  errorFields: {
    transactions: false,
    recurringTransactions: false,
    categories: false,
    defaultCategories: false,
    accounts: false,
    investments: false,
    rules: false,
    parties: false,
    investmentsOverTime: false,
    accountBalancesOverTime: false,
    accountTypes: false,
    subscriptions: false,
    payments: false,
  },
  retryDelays: {
    transactions: 0,
    recurringTransactions: 0,
    categories: 0,
    defaultCategories: 0,
    accounts: 0,
    investments: 0,
    rules: 0,
    parties: 0,
    investmentsOverTime: 0,
    accountBalancesOverTime: 0,
    accountTypes: 0,
    subscriptions: 0,
    payments: 0,
  },
};

export const ACTION_TYPES = {
  // Transactions
  SET_UNLOADED_TRANSACTIONS: "SET_UNLOADED_TRANSACTIONS",
  SET_LOADING_TRANSACTIONS: "SET_LOADING_TRANSACTIONS",
  SET_UPDATING_TRANSACTIONS: "SET_UPDATING_TRANSACTIONS",
  LOAD_TRANSACTIONS: "LOAD_TRANSACTIONS",
  DELETE_TRANSACTIONS: "DELETE_TRANSACTIONS",
  ADD_TRANSACTIONS: "ADD_TRANSACTIONS",
  UPDATE_TRANSACTIONS: "UPDATE_TRANSACTIONS",
  // recurringTransactions
  SET_UNLOADED_RECURRING_TRANSACTIONS: "SET_UNLOADED_RECURRING_TRANSACTIONS",
  SET_LOADING_RECURRING_TRANSACTIONS: "SET_LOADING_RECURRING_TRANSACTIONS",
  SET_UPDATING_RECURRING_TRANSACTIONS: "SET_UPDATING_RECURRING_TRANSACTIONS",
  LOAD_RECURRING_TRANSACTIONS: "LOAD_RECURRING_TRANSACTIONS",
  DELETE_RECURRING_TRANSACTIONS: "DELETE_RECURRING_TRANSACTIONS",
  ADD_RECURRING_TRANSACTIONS: "ADD_RECURRING_TRANSACTIONS",
  UPDATE_RECURRING_TRANSACTIONS: "UPDATE_RECURRING_TRANSACTIONS",
  // Categories
  SET_LOADING_CATEGORIES: "SET_LOADING_CATEGORIES",
  SET_UPDATING_CATEGORIES: "SET_UPDATING_CATEGORIES",
  LOAD_CATEGORIES: "LOAD_CATEGORIES",
  DELETE_CATEGORIES: "DELETE_CATEGORIES",
  UPDATE_CATEGORIES: "UPDATE_CATEGORIES",
  LOAD_DEFAULT_CATEGORIES: "LOAD_DEFAULT_CATEGORIES",
  // Accounts
  SET_UNLOADED_ACCOUNTS: "SET_UNLOADED_ACCOUNTS",
  SET_LOADING_ACCOUNTS: "SET_LOADING_ACCOUNTS",
  SET_UPDATING_ACCOUNTS: "SET_UPDATING_ACCOUNTS",
  LOAD_ACCOUNTS: "LOAD_ACCOUNTS",
  UPDATE_ACCOUNTS: "UPDATE_ACCOUNTS",
  // Investments
  SET_UNLOADED_INVESTMENTS: "SET_UNLOADED_INVESTMENTS",
  SET_LOADING_INVESTMENTS: "SET_LOADING_INVESTMENTS",
  SET_UPDATING_INVESTMENTS: "SET_UPDATING_INVESTMENTS",
  LOAD_INVESTMENTS: "LOAD_INVESTMENTS",
  UPDATE_INVESTMENTS: "UPDATE_INVESTMENTS",
  // Rules
  SET_LOADING_RULES: "SET_LOADING_RULES",
  SET_UPDATING_RULES: "SET_UPDATING_RULES",
  LOAD_RULES: "LOAD_RULES",
  ADD_RULES: "ADD_RULES",
  UPDATE_RULES: "UPDATE_RULES",
  DELETE_RULES: "DELETE_RULES",
  // Investment PARTY
  SET_LOADING_PARTIES: "SET_LOADING_PARTIES",
  SET_UPDATING_PARTIES: "SET_UPDATING_PARTIES",
  LOAD_PARTIES: "LOAD_PARTIES",
  ADD_PARTIES: "ADD_PARTIES",
  DELETE_PARTIES: "DELETE_PARTIES",
  // Investments Over Time
  SET_UNLOADED_INVESTMENTS_OVER_TIME: "SET_UNLOADED_INVESTMENTS_OVER_TIME",
  SET_LOADING_INVESTMENTS_OVER_TIME: "SET_LOADING_INVESTMENTS_OVER_TIME",
  SET_INVESTMENTS_OVER_TIME: "SET_INVESTMENTS_OVER_TIME",
  // Account Balances Over Time
  SET_UNLOADED_ACCOUNT_BALANCES_OVER_TIME:
    "SET_UNLOADED_ACCOUNT_BALANCES_OVER_TIME",
  SET_LOADING_ACCOUNT_BALANCES_OVER_TIME:
    "SET_LOADING_ACCOUNT_BALANCES_OVER_TIME",
  SET_ACCOUNT_BALANCES_OVER_TIME: "SET_ACCOUNT_BALANCES_OVER_TIME",
  // Subscriptions
  SET_LOADING_SUBSCRIPTIONS: "SET_LOADING_SUBSCRIPTIONS",
  SET_UPDATING_SUBSCRIPTIONS: "SET_UPDATING_SUBSCRIPTIONS",
  LOAD_SUBSCRIPTIONS: "LOAD_SUBSCRIPTIONS",
  ADD_SUBSCRIPTIONS: "ADD_SUBSCRIPTIONS",
  UPDATE_SUBSCRIPTIONS: "UPDATE_SUBSCRIPTIONS",

  // Payments
  SET_LOADING_PAYMENTS: "SET_LOADING_PAYMENTS",
  SET_UPDATING_PAYMENTS: "SET_UPDATING_PAYMENTS",
  LOAD_PAYMENTS: "LOAD_PAYMENTS",
  ADD_PAYMENTS: "ADD_PAYMENTS",
  UPDATE_PAYMENTS: "UPDATE_PAYMENTS",
  // Other
  SET_ACCOUNT_TYPES: "SET_ACCOUNT_TYPES",
  RESET_STATE: "RESET_STATE",
  SET_ERROR_FIELDS: "SET_ERROR_FIELDS",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_UNLOADED_TRANSACTIONS:
      return {
        ...state,
        transactionsLoaded: false,
      };
    case ACTION_TYPES.SET_LOADING_TRANSACTIONS:
      return {
        ...state,
        transactionsLoading: true,
        transactionsLoaded: false,
        errorFields: {
          ...state.errorFields,
          transactions: false, // Reset error state
        },
      };

    case ACTION_TYPES.SET_UPDATING_TRANSACTIONS:
      return {
        ...state,
        transactionsUpdating: true,
        transactionsUpdated: false,
      };
    case ACTION_TYPES.LOAD_TRANSACTIONS:
      return {
        ...state,
        transactionsLoaded: true,
        transactionsLoading: false,
        transactions: action.payload,
      };
    case ACTION_TYPES.DELETE_TRANSACTIONS:
      return {
        ...state,
        transactionsUpdating: false,
        transactionsUpdated: true,
        transactions: state.transactions.filter(
          (t) => !action.payload.includes(t.pachira_transaction_id)
        ),
      };
    case ACTION_TYPES.ADD_TRANSACTIONS:
      const updatedTrans = [...state.transactions, ...action.payload];
      // Sort transactions by date before returning
      updatedTrans.sort((a, b) => new Date(b.date) - new Date(a.date));
      return {
        ...state,
        transactionsUpdating: false,
        transactionsUpdated: true,
        transactions: updatedTrans,
      };

    case ACTION_TYPES.UPDATE_TRANSACTIONS:
      // Convert action.payload into a lookup table for quick access
      const updatedTransactionsMap = action.payload.reduce((acc, curr) => {
        acc[curr.pachira_transaction_id] = curr;
        return acc;
      }, {});

      // Map over state.transactions and update them if an updated version exists
      const updatedTransactions = state.transactions.map((t) => {
        if (updatedTransactionsMap[t.pachira_transaction_id]) {
          // Merge the properties of the updated transaction into the original transaction
          return {
            ...t,
            ...updatedTransactionsMap[t.pachira_transaction_id],
          };
        }
        return t; // Return original transaction if no update is found
      });

      const newTransactions = action.payload.filter(
        (updatedTransaction) =>
          !state.transactions.some(
            (originalTransaction) =>
              originalTransaction.pachira_transaction_id ===
              updatedTransaction.pachira_transaction_id
          )
      );

      // Combine and sort transactions by date before returning
      const combinedAndSortedTransactions = [
        ...updatedTransactions,
        ...newTransactions,
      ];
      combinedAndSortedTransactions.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      return {
        ...state,
        transactionsUpdating: false,
        transactionsUpdated: true,
        transactions: combinedAndSortedTransactions,
      };
    case ACTION_TYPES.SET_UNLOADED_RECURRING_TRANSACTIONS:
      return {
        ...state,
        recurringTransactionsLoaded: false,
      };
    case ACTION_TYPES.SET_LOADING_RECURRING_TRANSACTIONS:
      return {
        ...state,
        recurringTransactionsLoading: true,
        recurringTransactionsLoaded: false,
        errorFields: {
          ...state.errorFields,
          recurringTransactions: false, // Reset error state
        },
      };

    case ACTION_TYPES.SET_UPDATING_RECURRING_TRANSACTIONS:
      return {
        ...state,
        recurringTransactionsUpdating: true,
        recurringTransactionsUpdated: false,
      };
    case ACTION_TYPES.LOAD_RECURRING_TRANSACTIONS:
      return {
        ...state,
        recurringTransactionsLoaded: true,
        recurringTransactionsLoading: false,
        recurringTransactions: action.payload,
      };
    case ACTION_TYPES.DELETE_RECURRING_TRANSACTIONS:
      return {
        ...state,
        recurringTransactionsUpdating: false,
        recurringTransactionsUpdated: true,
        recurringTransactions: state.recurringTransactions.filter(
          (t) => !action.payload.includes(t.transaction_id)
        ),
      };
    case ACTION_TYPES.ADD_RECURRING_TRANSACTIONS:
      const updatedRecurringTrans = [
        ...state.recurringTransactions,
        ...action.payload,
      ];
      // Sort transactions by date before returning
      updatedRecurringTrans.sort((a, b) => new Date(b.date) - new Date(a.date));
      return {
        ...state,
        recurringTransactionsUpdating: false,
        recurringTransactionsUpdated: true,
        recurringTransactions: updatedRecurringTrans,
      };

    case ACTION_TYPES.UPDATE_RECURRING_TRANSACTIONS:
      // Convert action.payload into a lookup table for quick access
      const updatedRecurringTransactionsMap = action.payload.reduce(
        (acc, curr) => {
          acc[curr.transaction_id] = curr;
          return acc;
        },
        {}
      );

      // Map over state.recurringTransactions and update them if an updated version exists
      const updatedRecurringTransactions = state.recurringTransactions.map(
        (t) => {
          if (updatedRecurringTransactionsMap[t.transaction_id]) {
            // Merge the properties of the updated transaction into the original transaction
            return {
              ...t,
              ...updatedRecurringTransactionsMap[t.transaction_id],
            };
          }
          return t; // Return original transaction if no update is found
        }
      );

      const newRecurringTransactions = action.payload.filter(
        (updatedTransaction) =>
          !state.recurringTransactions.some(
            (originalTransaction) =>
              originalTransaction.transaction_id ===
              updatedTransaction.transaction_id
          )
      );

      // Combine and sort transactions by date before returning
      const combinedAndSortedRecurringTransactions = [
        ...updatedRecurringTransactions,
        ...newRecurringTransactions,
      ];
      combinedAndSortedRecurringTransactions.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      return {
        ...state,
        recurringTransactionsUpdating: false,
        recurringTransactionsUpdated: true,
        recurringTransactions: combinedAndSortedRecurringTransactions,
      };
    case ACTION_TYPES.SET_LOADING_CATEGORIES:
      return {
        ...state,
        categoriesLoading: true,
        categoriesLoaded: false,
        errorFields: {
          ...state.errorFields,
          categories: false, // Reset error state
        },
      };

    case ACTION_TYPES.SET_UPDATING_CATEGORIES:
      return {
        ...state,
        categoriesUpdating: true,
        categoriesUpdated: false,
      };
    case ACTION_TYPES.LOAD_CATEGORIES:
      if (state.categoriesUpdating) {
        return {
          ...state,
          categoriesUpdated: true,
          categoriesUpdating: false,
          categories: action.payload,
        };
      } else {
        return {
          ...state,
          categoriesLoaded: true,
          categoriesLoading: false,
          categories: action.payload,
        };
      }
    case ACTION_TYPES.DELETE_CATEGORIES:
      return {
        ...state,
        categoriesUpdating: false,
        categoriesUpdated: true,
        categories: state.categories.filter(
          (category) =>
            !action.payload.some(
              (payloadCategory) =>
                payloadCategory.category_id === category.category_id
            )
        ),
      };
    case ACTION_TYPES.UPDATE_CATEGORIES:
      const updatedCategoriesMap = action.payload.reduce((acc, curr) => {
        acc[curr.category_id] = curr;
        return acc;
      }, {});

      const updatedCategories = state.categories.map((c) => {
        if (updatedCategoriesMap[c.category_id]) {
          return { ...c, ...updatedCategoriesMap[c.category_id] };
        }
        return c;
      });

      const newCategories = action.payload.filter(
        (updatedCategory) =>
          !state.categories.some(
            (originalCategory) =>
              originalCategory.category_id === updatedCategory.category_id
          )
      );

      return {
        ...state,
        categoriesUpdating: false,
        categoriesUpdated: true,
        categories: [...updatedCategories, ...newCategories],
      };
    case ACTION_TYPES.LOAD_DEFAULT_CATEGORIES:
      return {
        ...state,
        defaultCategoriesLoading: false,
        defaultCategoriesLoaded: true,
        defaultCategories: action.payload,
      };
    case ACTION_TYPES.SET_UNLOADED_ACCOUNTS:
      return {
        ...state,
        accountsLoaded: false,
      };
    case ACTION_TYPES.SET_LOADING_ACCOUNTS:
      return {
        ...state,
        accountsLoading: true,
        accountsLoaded: false,
        errorFields: {
          ...state.errorFields,
          accounts: false, // Reset error state
        },
      };

    case ACTION_TYPES.SET_UPDATING_ACCOUNTS:
      return {
        ...state,
        accountsUpdating: true,
        accountsUpdated: false,
      };
    case ACTION_TYPES.LOAD_ACCOUNTS:
      return {
        ...state,
        accountsLoaded: true,
        accountsLoading: false,
        accounts: action.payload.data.accounts,
      };
    case ACTION_TYPES.UPDATE_ACCOUNTS:
      const updatedAccountsMap = action.payload.reduce((acc, curr) => {
        acc[curr.pachira_account_uuid] = curr;
        return acc;
      }, {});

      const updatedAccounts = state.accounts.map((account) => {
        return updatedAccountsMap[account.pachira_account_uuid]
          ? { ...account, ...updatedAccountsMap[account.pachira_account_uuid] }
          : account;
      });

      const newAccounts = action.payload.filter(
        (updatedAccount) =>
          !state.accounts.some(
            (account) =>
              account.pachira_account_uuid ===
              updatedAccount.pachira_account_uuid
          )
      );

      return {
        ...state,
        accountsUpdating: false,
        accountsUpdated: true,
        accounts: [...updatedAccounts, ...newAccounts],
      };
    case ACTION_TYPES.REMOVE_ACCOUNTS:
      const remainingAccounts = state.accounts.filter(
        (account) => !action.payload.includes(account.pachira_account_uuid)
      );

      return {
        ...state,
        accountsUpdating: false,
        accountsUpdated: true,
        accounts: remainingAccounts,
      };

    case ACTION_TYPES.SET_UNLOADED_INVESTMENTS:
      return {
        ...state,
        investmentsLoaded: false,
      };
    case ACTION_TYPES.SET_LOADING_INVESTMENTS:
      return {
        ...state,
        investmentsLoading: true,
        investmentsLoaded: false,
        errorFields: {
          ...state.errorFields,
          investments: false, // Reset error state
        },
      };

    case ACTION_TYPES.SET_UPDATING_INVESTMENTS:
      return {
        ...state,
        investmentsUpdating: true,
        investmentsUpdated: false,
      };
    case ACTION_TYPES.LOAD_INVESTMENTS:
      return {
        ...state,
        investmentsLoaded: true,
        investmentsLoading: false,
        investments: action.payload.investments,
      };
    case ACTION_TYPES.SET_LOADING_RULES:
      return {
        ...state,
        rulesLoading: true,
        rulesLoaded: false,
        errorFields: {
          ...state.errorFields,
          rules: false, // Reset error state
        },
      };

    case ACTION_TYPES.SET_UPDATING_RULES:
      return {
        ...state,
        rulesUpdating: true,
        rulesUpdated: false,
      };
    case ACTION_TYPES.LOAD_RULES:
      return {
        ...state,
        rulesLoaded: true,
        rulesLoading: false,
        rules: action.payload,
      };
    case ACTION_TYPES.ADD_RULES:
      const addedRules = [...state.rules, ...action.payload];

      addedRules.sort((a, b) => a.priority - b.priority);
      return {
        ...state,
        rulesUpdating: false,
        rulesUpdated: true,
        rules: addedRules,
      };

    case ACTION_TYPES.UPDATE_RULES:
      const updatedRulesMap = action.payload.reduce((acc, curr) => {
        acc[curr.rule_id] = curr;
        return acc;
      }, {});

      const updatedRules = state.rules.map((rule) => {
        return updatedRulesMap[rule.rule_id]
          ? { ...rule, ...updatedRulesMap[rule.rule_id] }
          : rule;
      });

      const newRules = action.payload.filter(
        (updatedRule) =>
          !state.rules.some((rule) => rule.rule_id === updatedRule.rule_id)
      );

      return {
        ...state,
        rulesUpdating: false,
        rulesUpdated: true,
        rules: [...updatedRules, ...newRules],
      };
    case ACTION_TYPES.DELETE_RULES:
      return {
        ...state,
        rulesUpdating: false,
        rulesUpdated: true,
        rules: state.rules.filter((t) => !action.payload.includes(t.rule_id)),
      };
    case ACTION_TYPES.SET_LOADING_PARTIES:
      return {
        ...state,
        partiesLoading: true,
        partiesLoaded: false,
        errorFields: {
          ...state.errorFields,
          parties: false, // Reset error state
        },
      };

    case ACTION_TYPES.SET_UPDATING_PARTIES:
      return {
        ...state,
        partiesUpdating: true,
        partiesUpdated: false,
      };
    case ACTION_TYPES.LOAD_PARTIES:
      return {
        ...state,
        partiesLoaded: true,
        partiesLoading: false,
        parties: action.payload,
      };
    case ACTION_TYPES.ADD_PARTIES:
      const addedParties = [...state.parties, ...action.payload];

      addedParties.sort((a, b) => a.party_name - b.party_name);
      return {
        ...state,
        partiesUpdating: false,
        partiesUpdated: true,
        parties: addedParties,
      };

    case ACTION_TYPES.DELETE_PARTIES:
      return {
        ...state,
        partiesUpdated: true,
        parties: state.parties.filter(
          (t) => !action.payload.includes(t.party_code)
        ),
      };
    case ACTION_TYPES.SET_UNLOADED_INVESTMENTS_OVER_TIME:
      return {
        ...state,
        investmentsOverTimeLoaded: false,
      };
    case ACTION_TYPES.SET_LOADING_INVESTMENTS_OVER_TIME:
      return {
        ...state,
        investmentsOverTimeLoaded: false,
        investmentsOverTimeLoading: true,
        errorFields: {
          ...state.errorFields,
          investmentsOverTime: false, // Reset error state
        },
      };

    case ACTION_TYPES.SET_INVESTMENTS_OVER_TIME:
      return {
        ...state,
        investmentsOverTimeLoaded: true,
        investmentsOverTimeLoading: false,
        investmentsOverTime: action.payload,
      };
    case ACTION_TYPES.SET_UNLOADED_ACCOUNT_BALANCES_OVER_TIME:
      return {
        ...state,
        accountBalancesOverTimeLoaded: false,
      };
    case ACTION_TYPES.SET_LOADING_ACCOUNT_BALANCES_OVER_TIME:
      return {
        ...state,
        accountBalancesOverTimeLoaded: false,
        accountBalancesOverTimeLoading: true,
        errorFields: {
          ...state.errorFields,
          accountBalancesOverTime: false, // Reset error state
        },
      };

    case ACTION_TYPES.SET_ACCOUNT_BALANCES_OVER_TIME:
      return {
        ...state,
        accountBalancesOverTimeLoaded: true,
        accountBalancesOverTimeLoading: false,
        accountBalanceOverTime: action.payload,
      };
    case ACTION_TYPES.SET_ACCOUNT_TYPES:
      return {
        ...state,
        accountTypes: action.payload,
      };
    case ACTION_TYPES.RESET_STATE:
      return initialState;
    case ACTION_TYPES.SET_ERROR_FIELDS:
      const newErrorFields = {
        ...state.errorFields,
        ...action.payload,
      };
      const newState = {
        ...state,
        errorFields: newErrorFields,
      };
      Object.entries(action.payload).forEach(([key, value]) => {
        if (value) {
          if (state[`${key}Loading`]) {
            newState[`${key}Loading`] = false;
          }
          if (state[`${key}Updating`]) {
            newState[`${key}Updating`] = false;
          }
          // Increment the related error count
          const errorCountKey = `${key}ErrorCount`;
          newState[errorCountKey] = (state[errorCountKey] || 0) + 1;
        }
      });
      return newState;

    case ACTION_TYPES.SET_LOADING_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptionsLoading: true,
        subscriptionsLoaded: false,
        errorFields: {
          ...state.errorFields,
          subscriptions: false, // Reset error state
        },
      };

    case ACTION_TYPES.SET_UPDATING_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptionsUpdating: true,
        subscriptionsUpdated: false,
      };
    case ACTION_TYPES.LOAD_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptionsLoaded: true,
        subscriptionsLoading: false,
        subscriptions: action.payload,
      };
    case ACTION_TYPES.ADD_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptionsUpdating: false,
        subscriptionsUpdated: true,
        subscriptionsLoaded: false,
        paymentsLoaded: false,
        subscriptions: [...state.subscriptions, ...action.payload],
      };
    case ACTION_TYPES.UPDATE_SUBSCRIPTIONS:
      const updatedSubscriptionsMap = action.payload.reduce((acc, curr) => {
        acc[curr.subscription_id] = curr;
        return acc;
      }, {});

      const updatedSubscriptions = state.subscriptions.map((subscription) => {
        return updatedSubscriptionsMap[subscription.subscription_id]
          ? {
              ...subscription,
              ...updatedSubscriptionsMap[subscription.subscription_id],
            }
          : subscription;
      });

      const newSubscriptions = action.payload.filter(
        (updatedSubscription) =>
          !state.subscriptions.some(
            (subscription) =>
              subscription.subscription_id ===
              updatedSubscription.subscription_id
          )
      );

      return {
        ...state,
        subscriptionsUpdating: false,
        subscriptionsUpdated: true,
        subscriptions: [...updatedSubscriptions, ...newSubscriptions],
      };

    // Payments
    case ACTION_TYPES.SET_LOADING_PAYMENTS:
      return {
        ...state,
        paymentsLoading: true,
        paymentsLoaded: false,
        errorFields: {
          ...state.errorFields,
          payments: false, // Reset error state
        },
      };

    case ACTION_TYPES.SET_UPDATING_PAYMENTS:
      return {
        ...state,
        paymentsUpdating: true,
        paymentsUpdated: false,
      };
    case ACTION_TYPES.LOAD_PAYMENTS:
      return {
        ...state,
        paymentsLoaded: true,
        paymentsLoading: false,
        payments: action.payload,
      };
    case ACTION_TYPES.ADD_PAYMENTS:
      return {
        ...state,
        paymentsUpdating: false,
        paymentsUpdated: true,
        payments: [...state.payments, ...action.payload],
      };
    case ACTION_TYPES.UPDATE_PAYMENTS:
      const updatedPaymentsMap = action.payload.reduce((acc, curr) => {
        acc[curr.payment_id] = curr;
        return acc;
      }, {});

      const updatedPayments = state.payments.map((payment) => {
        return updatedPaymentsMap[payment.payment_id]
          ? { ...payment, ...updatedPaymentsMap[payment.payment_id] }
          : payment;
      });

      const newPayments = action.payload.filter(
        (updatedPayment) =>
          !state.payments.some(
            (payment) => payment.payment_id === updatedPayment.payment_id
          )
      );

      return {
        ...state,
        paymentsUpdating: false,
        paymentsUpdated: true,
        payments: [...updatedPayments, ...newPayments],
      };
    default:
      return state;
  }
};

const AccountsProvider = ({ children }) => {
  // const { fetchSubscriptions } = APIService();
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <AccountsContext.Provider value={value}>
      {children}
    </AccountsContext.Provider>
  );
};

export { AccountsContext, AccountsProvider };
