import React, { useContext, useEffect, useMemo } from "react";
import "../App.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { calculateIncomeVsExpenses } from "./utils";
import APIService from "./api";
import { AccountsContext } from "../context/AccountsContext";
import { CurrencyContext } from "../context/currencyContext";

const WidgetSpendingVsIncome = () => {
  const { fetchTransactionsContext } = APIService();
  const { state } = useContext(AccountsContext);
  const { formatCurrency } = useContext(CurrencyContext);
  const incomeExpenseData = useMemo(() => {
    const { income, expenses } = calculateIncomeVsExpenses(state.transactions);
    return [
      { name: "Income", ...income },
      { name: "Expenses", ...expenses },
    ];
  }, [state.transactions]);

  useEffect(() => {
    if (!state.transactionsLoaded && !state.transactionsLoading) {
      fetchTransactionsContext();
    }
  }, [
    fetchTransactionsContext,
    state.transactionsLoaded,
    state.transactionsLoading,
  ]);

  if (!state.transactionsLoaded || state.transactionsLoading) {
    return <div>Loading...</div>;
  }
  const incomeCategories = Object.keys(
    incomeExpenseData.find((data) => data.name === "Income") || {}
  );
  const expenseCategories = Object.keys(
    incomeExpenseData.find((data) => data.name === "Expenses") || {}
  );
  const allCategories = [...incomeCategories, ...expenseCategories].filter(
    (key) => key !== "name"
  );

  const generateColor = (baseColor, index, total) => {
    const intensity = 150 * (index / total);

    // Adjust this formula to start with a very dark color and lighten
    let color = baseColor;
    if (baseColor === "green") {
      color = `rgb(${0 + intensity},${120 + intensity},${0 + intensity})`; // Starts dark and gets lighter
    } else if (baseColor === "red") {
      color = `rgb(${120 + intensity},${0 + intensity},${0 + intensity})`; // Starts dark and gets lighter
    }
    return color;
  };

  const categories = Object.keys(
    incomeExpenseData.reduce((acc, cur) => ({ ...acc, ...cur }), {})
  )
    .filter((key) => key !== "name")
    .sort((a, b) => {
      // Sort categories based on the total value for gradient calculation
      const totalA = incomeExpenseData
        .map((item) => item[a] || 0)
        .reduce((sum, val) => sum + val, 0);
      const totalB = incomeExpenseData
        .map((item) => item[b] || 0)
        .reduce((sum, val) => sum + val, 0);
      return totalB - totalA;
    });
  const isIncomeCategory = (category, incomeCategories) => {
    return incomeCategories.includes(category);
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <div
          className="custom-tooltip"
          style={{
            padding: "10px",
            background: "linear-gradient(to bottom, #eee 0%, #ffffff 100%)", // Gradient from white to dark gray
            color: "black",
            borderRadius: "5px",
            border: "1px solid #ddd",
            boxShadow: "0px 0px 5px rgba(0,0,0,0.5)",
          }}
        >
          <p
            className="label"
            style={{ fontWeight: "bold", color: "black" }}
          >{`${label}`}</p>
          {payload
            .slice()
            .reverse()
            .map((entry, index) => (
              <p key={`item-${index}`} style={{ color: entry.color }}>
                {`${entry.name}: ${formatCurrency(entry.value)}`}
              </p>
            ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="widgetContainer">
      <h3 className="header">Income Vs Expenses (Current Month)</h3>
      <ResponsiveContainer width="100%" height="85%">
        <BarChart
          data={incomeExpenseData}
          margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {categories.map((category, index) => (
            <Bar
              key={category}
              dataKey={category}
              stackId="a"
              name={category}
              fill={generateColor(
                isIncomeCategory(category, incomeCategories) ? "green" : "red",
                index,
                allCategories.length
              )}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WidgetSpendingVsIncome;
