import React from "react";
import { AccountsProvider } from "./AccountsContext";
import { AppProvider } from "./AppContext";
import { AuthProvider } from "../authentication/authenticationContext";
import ThemeConfig from "../shared/theme";

function AppProviders({ children }) {
  return (
    <ThemeConfig>
      <AppProvider>
        <AuthProvider>
          <AccountsProvider>{children}</AccountsProvider>
        </AuthProvider>
      </AppProvider>
    </ThemeConfig>
  );
}

export default AppProviders;
