import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  ACTION_TYPES as AUTH_ACTIONS,
  AuthContext,
} from "../authentication/authenticationContext";
import Spinner from "../components/spinner";
import "../App.css";
import Modal from "../components/modal";
import brand from "../images/Pachira Vector Black.svg";
import releaseNotes from "../versioning/releaseNotes.json";
import APIService from "../components/api";

const latestVersion = Object.keys(releaseNotes).pop();
const Login = () => {
  const { login } = APIService();
  useEffect(() => {
    document.title = "Login - Pachira";
    checkCookieEnabled();
  }, []);
  const {
    authToken,
    state: authState,
    dispatch: authDispatch,
  } = useContext(AuthContext);
  const { isLoading } = authState; // Now correctly accessing isLoading

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isLoggedOut = queryParams.get("logout") === "true";
  const isRegistered = queryParams.get("registered") === "true";
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (!isLoading && authToken) {
      navigate("/dashboard");
    }
  }, [authToken, isLoading, navigate]);
  useEffect(() => {
    if (isLoggedOut) {
      setError("You have been logged out.");
    }

    if (isRegistered) {
      setError("");
    }
  }, [isLoggedOut, isRegistered]);
  function checkCookieEnabled() {
    document.cookie = "testcookie=1; SameSite=Lax";
    const cookieEnabled = document.cookie.indexOf("testcookie=") !== -1;

    if (cookieEnabled) {
      document.cookie =
        "testcookie=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax";
    } else {
      setShowModal(true);
    }
  }
  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      authDispatch({
        type: AUTH_ACTIONS.SET_TOKEN_LOADING,
        payload: true,
      });
      setError();

      const data = await login(username, password);

      authDispatch({
        type: AUTH_ACTIONS.SET_TOKEN_LOADING,
        payload: false,
      });
      if (data.mfa_required) {
        navigate(`/mfa-login/${data.mfa_login}`);
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      authDispatch({
        type: AUTH_ACTIONS.SET_TOKEN_LOADING,
        payload: false,
      });
      setError("Invalid username or password");
    } finally {
    }
  };
  const handleRegister = async () => {
    navigate("/register");
  };

  return (
    <div className="container">
      {showModal && (
        <Modal
          open={showModal}
          onClose={() => setShowModal(true)}
          title="Cookies Required"
        >
          <div>
            <p>
              Cookies are required to log in. Please enable cookies in your
              browser and refresh the page to continue.
            </p>
            <ul>
              <li>
                <a
                  href="https://support.google.com/chrome/answer/95647"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chrome Instructions
                </a>
              </li>
              <li>
                <a
                  href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Firefox Instructions
                </a>
              </li>
              <li>
                <a
                  href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Safari Instructions
                </a>
              </li>
              <li>
                <a
                  href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Edge Instructions
                </a>
              </li>
              <li>
                <a
                  href="https://help.opera.com/en/latest/web-preferences/#cookies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Opera Instructions
                </a>
              </li>
            </ul>
            <p>Please refresh the page after enabling cookies.</p>
          </div>
        </Modal>
      )}
      <div className="loginForm">
        <img src={brand} alt="Pachira Logo" className="logo" />
        <h2 className="title">Log In to Your Account</h2>
        {/* <p className="error">
          Pachira is currently undergoing scheduled maintenance and is
          unavailable. We apologize for any inconvenience this may cause. Please
          check back on <strong>May 7th in the evening</strong> to log in. Thank
          you for your patience and understanding.
        </p> */}
        {isRegistered && (
          <p className="registrationSuccess">
            Registration successful! Please log in with your new credentials.
          </p>
        )}
        <form onSubmit={handleLogin}>
          <input
            className="input-full"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            autoComplete="username"
          />
          <input
            className="input-full"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            autoComplete="current-password"
          />
          <button
            type="submit"
            className="fullLengthButton"
            disabled={isLoading}
          >
            {isLoading ? "Logging in..." : "Log In"}{" "}
          </button>
        </form>
        <button className="fullLengthButton" onClick={handleRegister}>
          Register
        </button>
        <a href="/forgot-password" className="link">
          Forgot password?
        </a>
        {error && <p className="error">{error}</p>}
        {isLoading && <Spinner />}
        <p style={{ marginBottom: "0" }}>Version: {latestVersion} Beta</p>
      </div>
    </div>
  );
};

export default Login;
