import React, { useEffect, useState, useContext, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import "chartjs-adapter-date-fns";
import { AccountsContext } from "../context/AccountsContext";
import APIService from "../components/api";

ChartJS.register(...registerables);
function InvestmentGraphs() {
  const { fetchInvestmentsOverTimeContext } = APIService();

  const { state: accountsState } = useContext(AccountsContext);
  const [chartWidth, setChartWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setChartWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const investmentData = useMemo(() => {
    if (!accountsState.investmentsOverTime) return {};

    const data = accountsState.investmentsOverTime;
    const processedData = {};
    data.forEach((item) => {
      const groupIdentifier = item.ticker_symbol || item.name;
      if (!processedData[groupIdentifier]) {
        processedData[groupIdentifier] = {
          dates: {},
          datasets: [
            {
              label: groupIdentifier,
              data: [],
              fill: false,
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1,
            },
          ],
        };
      }
      const formattedDate = new Date(item.date).toISOString().split("T")[0];
      if (!processedData[groupIdentifier].dates[formattedDate]) {
        processedData[groupIdentifier].dates[formattedDate] = 0;
      }
      processedData[groupIdentifier].dates[formattedDate] += parseFloat(
        item.balance
      );
    });

    Object.keys(processedData).forEach((groupIdentifier) => {
      const group = processedData[groupIdentifier];
      group.labels = Object.keys(group.dates).sort();
      group.datasets[0].data = group.labels.map((label) => group.dates[label]);
      delete group.dates;
    });

    return processedData;
  }, [accountsState.investmentsOverTime]);

  const totalInvestmentsData = useMemo(() => {
    if (!accountsState.investmentsOverTime) return { labels: [], datasets: [] };

    const totalInvestments = {};
    accountsState.investmentsOverTime.forEach((item) => {
      const date = new Date(item.date).toISOString().split("T")[0];
      const balance = parseFloat(parseFloat(item.balance).toFixed(2));
      if (!totalInvestments[date]) {
        totalInvestments[date] = 0;
      }
      totalInvestments[date] += balance;
    });

    let sortedTotalInvestments = Object.entries(totalInvestments)
      .map(([date, balance]) => ({ date, balance: balance.toFixed(2) }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    return {
      labels: sortedTotalInvestments.map((d) => d.date),
      datasets: [
        {
          label: "Total Balance over Time",
          data: sortedTotalInvestments.map((d) => d.balance),
          fill: false,
          borderColor: "rgb(255, 99, 132)",
          tension: 0.1,
        },
      ],
    };
  }, [accountsState.investmentsOverTime]);

  useEffect(() => {
    if (
      !accountsState.investmentsOverTimeLoaded &&
      accountsState.investmentsOverTimeLoading
    ) {
      fetchInvestmentsOverTimeContext();
    }
  }, [
    fetchInvestmentsOverTimeContext,
    accountsState.investmentsOverTimeLoaded,
    accountsState.investmentsOverTimeLoading,
  ]);

  function getScaleSettings(width) {
    const breakpoints = [
      { maxWidth: 600, unit: "month", stepSize: 1 },
      { maxWidth: 1200, unit: "month", stepSize: 1 },
      { maxWidth: Infinity, unit: "month", stepSize: 1 },
    ];

    const settings = breakpoints.find(
      (breakpoint) => width <= breakpoint.maxWidth
    );
    return settings || { unit: "month", stepSize: 1 };
  }

  const { unit, stepSize } = getScaleSettings(chartWidth);
  function roundToScale(value, direction) {
    const order = Math.floor(Math.log10(value));
    let scale = Math.pow(10, order);

    if (value / scale < 1.5) {
      scale = scale / 10;
    }

    if (direction === "up") {
      return Math.ceil(value / scale) * scale;
    } else {
      return Math.floor(value / scale) * scale;
    }
  }
  function getChartOptions(data) {
    const balances = data.datasets[0].data.map((item) => parseFloat(item));
    const minBalance = Math.min(...balances);
    const maxBalance = Math.max(...balances);

    return {
      maintainAspectRatio: false,
      scales: {
        x: {
          type: "time",
          time: {
            unit: unit,
            stepSize: stepSize,
          },
        },
        y: {
          beginAtZero: false,
          min: roundToScale(minBalance - minBalance * 0.01, "down"),
          max: roundToScale(maxBalance + maxBalance * 0.01, "up"),
        },
      },
      plugins: {
        legend: {
          labels: {
            generateLabels: function (chart) {
              return chart.data.datasets.map((dataset) => ({
                text: "Total Value",
                fillStyle: dataset.backgroundColor,
                strokeStyle: dataset.borderColor,
                lineWidth: dataset.borderWidth,
                hidden: !chart.isDatasetVisible(0),
              }));
            },
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
    };
  }

  return (
    <div className="graph-container">
      {totalInvestmentsData.datasets &&
        totalInvestmentsData.datasets.length > 0 && (
          <div className="graph-card">
            <div className="card-title">Total Portfolio Value</div>
            <Line
              data={totalInvestmentsData}
              options={getChartOptions(totalInvestmentsData)}
            />
          </div>
        )}
      {Object.keys(investmentData).map((investmentId) => (
        <div key={investmentId} className="graph-card">
          <div className="card-title">
            {investmentData[investmentId].datasets[0].label}
          </div>
          <Line
            data={investmentData[investmentId]}
            options={getChartOptions(investmentData[investmentId])}
          />
        </div>
      ))}
    </div>
  );
}

export default InvestmentGraphs;
