import React, { useState, useContext } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../authentication/authenticationContext";
import brand from "../images/Pachira Vector Black.svg";
import LoadingWithLogo from "../components/loadingWithLogo";
import Spinner from "../components/spinner";
import APIService from "../components/api";

const Register = () => {
  const { register } = APIService();
  const { isLoading } = useContext(AuthContext);
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordClass, setConfirmPasswordClass] = useState("");
  const [error, setError] = useState("");
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    specialChar: false,
  });

  const [readTerms, setReadTerms] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [hasClickedLink, setHasClickedLink] = useState(false);
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const setPasswordConfirmed = (confirmed) => {
    if (confirmed) {
      setShowConfirmMessage(false);
      setConfirmPasswordClass("confirmInputGreen");
    } else {
      setShowConfirmMessage(true);
      setConfirmPasswordClass("confirmInputRed");
    }
  };
  const handleLinkClick = () => {
    setHasClickedLink(true);
  };
  const handleLogin = async () => {
    navigate("/login");
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;
    return re.test(password);
  };
  const validateForm = () => {
    if (!username || !email || !password || !fullName) {
      setError("Please fill in all fields");
      return false;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return false;
    }
    if (!validatePassword(password)) {
      setError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character"
      );
      return false;
    }

    setError("");
    return true;
  };
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setPasswordRequirements({
      minLength: value.length >= 8,
      upperCase: /[A-Z]/.test(value),
      lowerCase: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      specialChar: /[\W_]/.test(value),
    });
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    if (value === password) {
      setPasswordConfirmed(true);
    } else {
      setPasswordConfirmed(false);
    }
  };

  const handleRegister = async () => {
    if (validateForm()) {
      try {
        await register(fullName, username, email, password);

        navigate("/login?registered=true");
      } catch (error) {
        let errorMessage = "Failed to register. Please try again.";

        const jsonMatch = error.message.match(/\{.*\}/);
        if (jsonMatch) {
          try {
            const errorData = JSON.parse(jsonMatch[0]);
            errorMessage = errorData.message || errorMessage;
          } catch (parseError) {
            console.error("Failed to parse error message:", parseError);
          }
        }

        setError(errorMessage);
      }
    }
  };

  if (isLoading) {
    return <LoadingWithLogo />;
  }

  const renderValidationIcon = (isValid) => {
    return isValid ? (
      <span style={{ color: "green" }}>✓</span>
    ) : (
      <span style={{ color: "red" }}>✗</span>
    );
  };

  const passwordRequirementsMet =
    Object.values(passwordRequirements).every(Boolean);
  const allRequirementsMet =
    passwordRequirementsMet &&
    confirmPassword === password &&
    readTerms &&
    agreeTerms &&
    fullName &&
    username &&
    email;

  return (
    <div className="container">
      <div className="loginForm">
        <img src={brand} alt="Pachira Logo" className="logo" />
        <h2 className="title">Register for an Account</h2>
        <label className="label">Full Name:</label>
        <input
          className="input-full"
          type="text"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          placeholder="Enter your full name"
        />
        <label className="label">Username:</label>
        <input
          className="input-full"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Choose a username"
        />
        <label className="label">Email:</label>
        <input
          className="input-full"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
        <label className="label">Password:</label>
        <input
          className="input-full"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          onFocus={() => setShowPasswordRequirements(true)}
          onBlur={() => setShowPasswordRequirements(password.length > 0)}
          placeholder="Create a password"
        />
        {showPasswordRequirements && (
          <div className="passwordRequirements">
            <p
              style={{
                color: passwordRequirements.minLength ? "green" : "red",
              }}
            >
              {renderValidationIcon(passwordRequirements.minLength)} Minimum 8
              characters
            </p>
            <p
              style={{
                color: passwordRequirements.upperCase ? "green" : "red",
              }}
            >
              {renderValidationIcon(passwordRequirements.upperCase)} At least
              one uppercase letter
            </p>
            <p
              style={{
                color: passwordRequirements.lowerCase ? "green" : "red",
              }}
            >
              {renderValidationIcon(passwordRequirements.lowerCase)} At least
              one lowercase letter
            </p>
            <p style={{ color: passwordRequirements.number ? "green" : "red" }}>
              {renderValidationIcon(passwordRequirements.number)} At least one
              number
            </p>
            <p
              style={{
                color: passwordRequirements.specialChar ? "green" : "red",
              }}
            >
              {renderValidationIcon(passwordRequirements.specialChar)} At least
              one special character
            </p>
          </div>
        )}
        <label className="label">Confirm Password:</label>
        <input
          className={
            confirmPasswordClass === ""
              ? "input-full"
              : `input-full ${confirmPasswordClass}`
          }
          type="password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          onFocus={() => setPasswordConfirmed(confirmPassword === password)}
          onBlur={() => setShowConfirmMessage(false)}
          placeholder="Re-type your password"
          disabled={!passwordRequirementsMet}
        />
        {showConfirmMessage && (
          <div className="passwordRequirements">
            <p style={{ color: "red" }}>Passwords don't match</p>
          </div>
        )}
        <p>
          <a
            href="https://pachira.abandm.com/documents/Terms-of-Service"
            target="_blank"
            onClick={handleLinkClick}
            rel="noopener noreferrer"
          >
            Read Terms of Service
          </a>
        </p>
        <div className="termsCheckbox">
          <input
            type="checkbox"
            id="readTerms"
            checked={readTerms}
            onChange={(e) => setReadTerms(e.target.checked)}
            disabled={!hasClickedLink}
          />
          <label htmlFor="readTerms">I have read the Terms of Service</label>
        </div>
        <div className="termsCheckbox">
          <input
            type="checkbox"
            id="agreeTerms"
            checked={agreeTerms}
            onChange={(e) => setAgreeTerms(e.target.checked)}
            disabled={!readTerms}
          />
          <label htmlFor="agreeTerms">I agree to the Terms of Service</label>
        </div>

        <button
          className={`fullLengthButton ${
            !allRequirementsMet ? "buttonDisabled" : ""
          }`}
          onClick={handleRegister}
          disabled={!allRequirementsMet}
        >
          {" "}
          Register
        </button>
        <button className="fullLengthButton" onClick={handleLogin}>
          Return To Log In
        </button>
        {isLoading && <Spinner />}
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default Register;
