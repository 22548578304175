import React, { useState, useEffect, useContext } from "react";
import Modal from "./modal";
import "../App.css";
import { AccountsContext } from "../context/AccountsContext";
import APIService from "./api";
import {
  Button,
  ButtonGroup,
  Chip,
  Popover,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  faPenToSquare,
  faMinusSquare,
} from "@fortawesome/free-regular-svg-icons";
const CategoryManagement = () => {
  const theme = useTheme();
  const { deleteCategory, updateCategory } = APIService();

  const { state } = useContext(AccountsContext);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const parentCategories = state.categories.filter((cat) => cat.level === 1);

  const CreateCategoryForm = ({ onSave, onCancel }) => {
    const [categoryName, setCategoryName] = useState("");
    const [type, setCategoryType] = useState("INC");
    const [level, setCategoryLevel] = useState("1");
    const [parentId, setParentCategory] = useState("");
    const hasLevelOneCategories = parentCategories.some(
      (cat) => cat.level === 1 && cat.type === type
    );
    const filteredParentCategories = parentCategories.filter(
      (cat) => cat.type === type
    );

    useEffect(() => {
      setCategoryLevel("1");
    }, [type]);

    const handleSubmit = () => {
      onSave({ category_name: categoryName, type, level, parent_id: parentId });
    };
    const validateForm = () => {
      const isCategoryNameValid = categoryName.trim() !== "";

      const isParentSelected = level !== "2" || (level === "2" && parentId);

      return isCategoryNameValid && isParentSelected;
    };
    return (
      <div className="modalContent">
        <label className="label">Category Name</label>
        <input
          className="modalInput"
          type="text"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          placeholder="Category Name"
        />
        <label className="label">Type</label>
        <select
          className="modalSelect"
          value={type}
          onChange={(e) => setCategoryType(e.target.value)}
        >
          <option value="INC">Income</option>
          <option value="EXP">Expense</option>
          <option value="TRA">Transfer</option>
        </select>
        <label className="label">Level</label>
        <select
          className="modalSelect"
          value={level || "1"}
          onChange={(e) => setCategoryLevel(e.target.value)}
        >
          <option value="1">Grouping Category</option>
          <option value="2" disabled={!hasLevelOneCategories}>
            Regular Category
          </option>
        </select>
        {level === "2" && (
          <>
            <label className="label">Parent Category</label>
            <select
              className="modalSelect"
              value={parentId}
              onChange={(e) => setParentCategory(e.target.value)}
            >
              <option value="">Select Parent Category</option>
              {filteredParentCategories.map((cat) => (
                <option key={cat.category_id} value={cat.category_id}>
                  {cat.category_name}
                </option>
              ))}
            </select>
          </>
        )}
        <button
          className="blueButton"
          onClick={handleSubmit}
          disabled={!validateForm()}
        >
          Save
        </button>
        <button className="blackButton" onClick={onCancel}>
          Cancel
        </button>
      </div>
    );
  };
  const getSortedAndGroupedCategories = () => {
    const typeOrder = { INC: 1, TRA: 2, EXP: 3 };
    let groupedCategories = [];

    // Filter and sort Level 1 categories by type
    let sortedLevel1Categories = state.categories
      .filter((cat) => cat.level === 1)
      .sort((a, b) => {
        const typeComparison = typeOrder[a.type] - typeOrder[b.type];
        if (typeComparison !== 0) {
          return typeComparison;
        }

        return a.category_name.localeCompare(b.category_name);
      });
    sortedLevel1Categories.forEach((level1Cat) => {
      let subCategories = state.categories
        .filter((cat) => cat.parent_id === level1Cat.category_id)
        .sort((a, b) => a.category_name.localeCompare(b.category_name));

      groupedCategories.push({
        parent: level1Cat,
        children: subCategories,
      });
    });

    return groupedCategories;
  };
  const renderDropdownOptions = () => {
    const groupedCategories = getSortedAndGroupedCategories();

    return groupedCategories.flatMap((group) => {
      return [
        <option
          key={group.parent.category_id}
          value={group.parent.category_id}
          className="optionGroup"
          style={{ fontWeight: "bold" }}
        >
          {group.parent.category_name}
        </option>,
        ...group.children.map((subCategory) => (
          <option
            key={subCategory.category_id}
            value={subCategory.category_id}
            className="option"
            style={{ paddingLeft: "20px" }}
          >
            {subCategory.category_name}
          </option>
        )),
      ];
    });
  };

  const EditCategoryForm = ({ onSave, onCancel }) => {
    const [selectedCategoryId, setSelectedCategoryId] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [categoryLevel, setCategoryLevel] = useState("");
    const [parentCategory, setParentCategory] = useState(null);

    const [isCategorySelected, setIsCategorySelected] = useState(false);

    useEffect(() => {
      if (selectedCategoryId) {
        const selectedCategory = state.categories.find(
          (cat) => cat.category_id === parseInt(selectedCategoryId)
        );
        if (selectedCategory) {
          setCategoryName(selectedCategory.category_name);
          setCategoryLevel(selectedCategory.level);
          setParentCategory(selectedCategory.parent_id || null);

          setIsCategorySelected(true);
        } else {
          setIsCategorySelected(false);
        }
      } else {
        setIsCategorySelected(false);
      }
    }, [selectedCategoryId]);
    const handleSubmit = () => {
      onSave({
        category_id: selectedCategoryId,
        category_name: categoryName,
        parent_id: parentCategory,
      });
    };
    const validateForm = () => {
      const isCategoryValid = selectedCategoryId !== "";

      const isCategoryNameValid =
        !isCategorySelected ||
        (isCategorySelected && categoryName.trim() !== "");

      const isParentCategoryValid =
        categoryLevel !== 2 || (categoryLevel === 2 && parentCategory !== "");

      return isCategoryValid && isCategoryNameValid && isParentCategoryValid;
    };
    return (
      <div className="modalContent">
        <label className="label">Select Category</label>
        <select
          className="modalSelect"
          value={selectedCategoryId}
          onChange={(e) => setSelectedCategoryId(e.target.value)}
        >
          <option value="">Select Category</option>
          {renderDropdownOptions()}
        </select>

        {isCategorySelected && (
          <>
            <label className="label">Category Name</label>
            <input
              className="input"
              type="text"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              placeholder="Category Name"
            />
            {isCategorySelected && categoryLevel === 2 && (
              <>
                <label className="label">Category Group</label>
                <select
                  className="modalSelect"
                  value={parentCategory}
                  onChange={(e) => setParentCategory(e.target.value)}
                >
                  <option value="">Select Category Group</option>
                  {parentCategories.map((cat) => (
                    <option key={cat.category_id} value={cat.category_id}>
                      {cat.category_name}
                    </option>
                  ))}
                </select>
              </>
            )}
          </>
        )}
        <button
          className="blueButton"
          onClick={handleSubmit}
          disabled={!validateForm()}
        >
          Save
        </button>
        <button className="blackButton" onClick={onCancel}>
          Cancel
        </button>
      </div>
    );
  };
  const DeleteCategoryForm = ({ onDelete, onCancel }) => {
    const [selectedCategoryId, setSelectedCategoryId] = useState("");
    const [remapCategoryId, setRemapCategoryId] = useState("");
    const selectedCategory = state.categories.find(
      (cat) => cat.category_id === parseFloat(selectedCategoryId)
    );
    const isLevelTwo = selectedCategory?.level === 2;

    const hasSubcategories = (categoryId) => {
      return state.categories.some((cat) => cat.parent_id === categoryId);
    };

    const getOptionText = (cat) => {
      if (cat.level === 1 && hasSubcategories(cat.category_id)) {
        return `${cat.category_name} (move or delete sub-accounts first)`;
      }
      return cat.category_name;
    };

    const handleDelete = () => {
      onDelete(selectedCategoryId, remapCategoryId);
    };
    const renderDropdownOptionsDelete = () => {
      const groupedCategories = getSortedAndGroupedCategories();

      return groupedCategories.flatMap((group) => {
        let isParentDisabled =
          group.parent.level === 1 &&
          hasSubcategories(group.parent.category_id);
        let parentOptionText = getOptionText(group.parent);

        return [
          <option
            key={group.parent.category_id}
            value={group.parent.category_id}
            disabled={isParentDisabled}
            className="optionGroup"
            style={{ fontWeight: "bold" }}
          >
            {parentOptionText}
          </option>,
          ...group.children.map((subCategory) => (
            <option
              key={subCategory.category_id}
              value={subCategory.category_id}
              className="option"
              style={{ paddingLeft: "20px" }}
            >
              {subCategory.category_name}
            </option>
          )),
        ];
      });
    };

    return (
      <div className="modalContent">
        <select
          className="modalSelect"
          value={selectedCategoryId}
          onChange={(e) => setSelectedCategoryId(e.target.value)}
        >
          <option value="">Select Category</option>
          {renderDropdownOptionsDelete()}
        </select>

        {isLevelTwo && (
          <>
            <select
              className="modalSelect"
              value={remapCategoryId}
              onChange={(e) => setRemapCategoryId(e.target.value)}
            >
              <option value="">Uncategorize Transactions</option>
              {state.categories
                .filter(
                  (cat) =>
                    cat.level === 2 && cat.category_id !== selectedCategoryId
                )
                .map((cat) => (
                  <option key={cat.category_id} value={cat.category_id}>
                    {cat.category_name}
                  </option>
                ))}
            </select>
          </>
        )}

        <button
          className="blueButton"
          onClick={handleDelete}
          disabled={!selectedCategory}
        >
          Delete
        </button>
        <button className="blackButton" onClick={onCancel}>
          Cancel
        </button>
      </div>
    );
  };

  const handleCreateCategory = () => {
    setIsCreateModalOpen(true);
  };

  const handleEditCategory = () => {
    setIsEditModalOpen(true);
  };

  const handleDeleteCategory = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCategoryOperation = async (operationType, categoryData) => {
    if (operationType === "delete") {
      let bodyData = {
        categoryId: categoryData.categoryId,
        remapId: categoryData.remapId,
      };

      await deleteCategory(bodyData);
    } else {
      let bodyData = { categories: [categoryData] };
      await updateCategory(bodyData);
    }
  };

  return (
    <div className="iconCard">
      <Tooltip title="Create Category">
        <FontAwesomeIcon
          icon={faPlus}
          color={theme.palette.primary.main}
          onClick={handleCreateCategory}
          style={{ cursor: "pointer", marginRight: "10px" }}
        />
      </Tooltip>
      <Tooltip title="Edit Category">
        <FontAwesomeIcon
          icon={faPenToSquare}
          color={theme.palette.primary.main}
          onClick={handleEditCategory}
          style={{ cursor: "pointer", marginRight: "10px" }}
        />
      </Tooltip>
      <Tooltip title="Delete Category">
        <FontAwesomeIcon
          icon={faMinusSquare}
          color={theme.palette.primary.main}
          onClick={handleDeleteCategory}
          style={{ cursor: "pointer" }}
        />
      </Tooltip>

      {isCreateModalOpen && (
        <Modal
          onClose={() => setIsCreateModalOpen(false)}
          title="Create Category"
        >
          <CreateCategoryForm
            onSave={async (newCategory) => {
              await handleCategoryOperation("create", newCategory);
              setIsCreateModalOpen(false);
            }}
            onCancel={() => setIsCreateModalOpen(false)}
          />
        </Modal>
      )}
      {isEditModalOpen && (
        <Modal onClose={() => setIsEditModalOpen(false)} title="Edit Category">
          <EditCategoryForm
            categories={state.categories}
            onSave={async (editedCategory) => {
              await handleCategoryOperation("edit", editedCategory);
              setIsEditModalOpen(false);
            }}
            onCancel={() => setIsEditModalOpen(false)}
          />
        </Modal>
      )}
      {isDeleteModalOpen && (
        <Modal
          onClose={() => setIsDeleteModalOpen(false)}
          title="Delete Category"
        >
          <DeleteCategoryForm
            categories={state.categories}
            onDelete={async (categoryId, remapId) => {
              await handleCategoryOperation("delete", { categoryId, remapId });
              setIsDeleteModalOpen(false);
            }}
            onCancel={() => setIsDeleteModalOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default CategoryManagement;
