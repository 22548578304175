import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import TransactionsAll from "../components/transactionsAll";
import TransactionsUncategorized from "../components/transactionsUncategorized";
import RulesManagerModal from "../components/transactionRules";
import TransactionsAdd from "../components/transactionsAdd";

import TransactionsImport from "../components/transactionsImport";
import "../App.css";
import APIService from "../components/api";
import { AccountsContext } from "../context/AccountsContext";
import {
  Button,
  ButtonGroup,
  Chip,
  Popover,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenRuler,
  faPlus,
  faCircleInfo,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import OverlayModal from "../components/OverlayModal";

const FILTER_LABELS = {
  memo: "Memo",
  merchant: "Merchant",
  bank: "Bank",
  category: "Category",
  minAmount: "Min",
  maxAmount: "Max",
  startDate: "Start Date",
  endDate: "End Date",
};

const Transactions = () => {
  const theme = useTheme();
  const { fetchTransactionsContext } = APIService();
  const { state } = useContext(AccountsContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const topElementsRef = useRef(null);

  const [selectedTab, setSelectedTab] = useState("uncategorized");
  const [rulesModalOpen, setRulesModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [addTransactionAnchor, setAddTransactionAnchor] = useState(null);

  const initialSearchParams = {
    memo: "",
    merchant: "",
    bank: "",
    category: "",
    minAmount: "",
    maxAmount: "",
    startDate: "",
    endDate: "",
  };

  const [searchParams, setSearchParams] = useState(initialSearchParams);

  const resetSearchParams = () => {
    setSearchParams(initialSearchParams);
  };

  const removeSearchParam = (key) => {
    const newSearchParams = {
      ...searchParams,
      [key]: "",
    };
    setSearchParams(newSearchParams);
  };

  const chipsArray = useMemo(() => {
    return Object.entries(searchParams)
      .map(([key, value]) => {
        if (value !== "") {
          return (
            <Chip
              label={`${FILTER_LABELS[key]}: ${value}`}
              onDelete={() => removeSearchParam(key)}
              sx={{
                marginLeft: "8px",
                marginTop: "4px",
                marginBottom: "4px",
              }}
              variant="outlined"
            />
          );
        } else {
          return false;
        }
      })
      .filter((chip) => chip);
  }, [searchParams]);

  const onOpenTransactionPopover = (event) => {
    setAddTransactionAnchor(event.currentTarget);
  };

  const onCloseTransactionPopover = () => {
    setAddTransactionAnchor(null);
  };

  const transactionPopoverOpen = Boolean(addTransactionAnchor);
  const transactionAnchorId = transactionPopoverOpen
    ? "add-transaction"
    : undefined;

  useEffect(() => {
    document.title = "Transactions - Pachira";
  }, []);
  useEffect(() => {
    if (!state.transactionsLoaded && !state.transactionsLoading) {
      fetchTransactionsContext();
    }
  }, [
    fetchTransactionsContext,
    state.transactionsLoaded,
    state.transactionsLoading,
  ]);

  const handleTabClick = (event, newTab) => {
    setSelectedTab(newTab);
  };

  const handleOpenRulesModal = () => {
    setRulesModalOpen(true);
  };

  const handleCloseRulesModal = () => {
    setRulesModalOpen(false);
  };

  const handleOpenInfoModal = () => {
    setInfoModalOpen(true);
  };

  const handleCloseInfoModal = () => {
    setInfoModalOpen(false);
  };

  const handleOpenFilterModal = () => {
    setFilterModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setFilterModalOpen(false);
  };

  const FilterChips = () => {
    return (
      <div
        className="card"
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Chip label="Remove Filters" onClick={resetSearchParams} />
        {chipsArray}
      </div>
    );
  };

  const FilterButton = () => {
    return (
      <Tooltip title="Filter Transactions">
        <FontAwesomeIcon
          id={transactionAnchorId}
          icon={faMagnifyingGlass}
          color={theme.palette.primary.main}
          onClick={handleOpenFilterModal}
        ></FontAwesomeIcon>
      </Tooltip>
    );
  };

  return (
    <div className="dashboard">
      <div ref={topElementsRef}>
        <div className="header">
          <Stack direction="row" alignItems="center" spacing={2}>
            <h1>Transactions</h1>
            <Tooltip title="Info">
              <FontAwesomeIcon
                id="info-button"
                icon={faCircleInfo}
                onClick={handleOpenInfoModal}
              />
            </Tooltip>
          </Stack>
          <div className="iconCard">
            <Stack direction="row" spacing={2}>
              <FilterButton />
              <Tooltip title="Add Transactions">
                <FontAwesomeIcon
                  id={transactionAnchorId}
                  icon={faPlus}
                  color={theme.palette.primary.main}
                  onClick={onOpenTransactionPopover}
                />
              </Tooltip>
              <Popover
                id={transactionAnchorId}
                open={transactionPopoverOpen}
                anchorEl={addTransactionAnchor}
                onClose={onCloseTransactionPopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <ButtonGroup orientation="vertical">
                  <TransactionsAdd closePopover={onCloseTransactionPopover} />
                  <TransactionsImport
                    closePopover={onCloseTransactionPopover}
                  />
                </ButtonGroup>
              </Popover>
              <Tooltip title="Manage Rules">
                <FontAwesomeIcon
                  icon={faPenRuler}
                  color={theme.palette.primary.main}
                  onClick={handleOpenRulesModal}
                />
              </Tooltip>
            </Stack>
          </div>
        </div>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tabs value={selectedTab} onChange={handleTabClick}>
            <Tab label="Uncategorized" value="uncategorized" />
            <Tab label="All" value="all" />
          </Tabs>
        </Stack>
        {chipsArray.length > 0 && <FilterChips />}
      </div>
      {state.transactionsLoading ? (
        <p>Loading transactions...</p>
      ) : selectedTab === "all" ? (
        <TransactionsAll
          searchParams={searchParams}
          topElementsRef={topElementsRef}
        />
      ) : selectedTab === "uncategorized" ? (
        <TransactionsUncategorized
          searchParams={searchParams}
          topElementsRef={topElementsRef}
        />
      ) : null}
      <RulesManagerModal
        open={rulesModalOpen}
        onClose={handleCloseRulesModal}
      />
      <OverlayModal open={infoModalOpen} onClose={handleCloseInfoModal}>
        <Stack px={2}>
          <Typography variant="h6" color={theme.palette.primary.main}>
            Quick Tips: Efficient Transaction Management
          </Typography>
          <ul className="tipList">
            {selectedTab === "uncategorized" ? (
              <>
                <li>
                  <strong>Suggested Categories:</strong> Transactions with
                  suggested categories will be highlighted in blue, with the add
                  button enabled for easy categorization.
                </li>
                <li>
                  <strong>Custom Selection:</strong> Although suggestions are
                  provided, you can still select a category of your own from the
                  dropdown to add the transaction.
                </li>
                <li>
                  <strong>Search for Transactions:</strong> Use the search bar
                  to quickly locate transactions. Try searching by parts of a
                  memo, merchant names, or specific amounts for targeted
                  results.
                </li>
                <li>
                  <strong>Bulk Categorization:</strong> Selecting multiple
                  transactions activates the 'Bulk Add' button, allowing for
                  efficient, user-friendly categorization.
                </li>
              </>
            ) : (
              <>
                <li>
                  <strong>Maximize the Search Bar:</strong> Enhance your
                  transaction management by utilizing the search bar. Quickly
                  filter your transactions by entering specific details, such as
                  parts of a memo, merchant names, or exact amounts.
                </li>
                <li>
                  <strong>Achieve Pinpoint Accuracy:</strong> This search
                  feature is designed to help you swiftly locate exactly what
                  you're searching for, thereby streamlining your financial
                  management process and saving time.
                </li>
              </>
            )}
          </ul>
        </Stack>
      </OverlayModal>
      <OverlayModal
        open={filterModalOpen}
        onClose={handleCloseFilterModal}
        hideCloseButton
      >
        <Stack spacing={2}>
          <Stack spacing={2}>
            <div className="searchSmallCard">
              <div className="searchGroup">
                <label className="searchlabel">Start Date</label>
                <input
                  className="searchInput searchInputDate"
                  type="date"
                  onChange={(e) =>
                    setSearchParams({
                      ...searchParams,
                      startDate: e.target.value,
                    })
                  }
                />
              </div>
              <div className="searchGroup">
                <label className="searchlabel">End Date</label>
                <input
                  className="searchInput searchInputDate"
                  type="date"
                  onChange={(e) =>
                    setSearchParams({
                      ...searchParams,
                      endDate: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="searchSmallCard">
              <div className="searchGroup">
                <label className="searchlabel">Memo</label>
                <input
                  className="searchInput"
                  type="text"
                  placeholder="Memo"
                  onChange={(e) =>
                    setSearchParams({ ...searchParams, memo: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="searchSmallCard">
              <div className="searchGroup">
                <label className="searchlabel">Merchant</label>
                <select
                  className="searchInput"
                  onChange={(e) =>
                    setSearchParams({
                      ...searchParams,
                      merchant: e.target.value,
                    })
                  }
                >
                  <option value="">Merchant</option>
                  {Array.from(
                    new Set(
                      state.transactions
                        .filter(
                          (transaction) =>
                            !transaction.pachira_category_id &&
                            !transaction.category_name
                        )
                        .map((t) => t.merchant_name)
                    )
                  )
                    .sort()
                    .map((merchant) => (
                      <option key={merchant} value={merchant}>
                        {merchant}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="searchSmallCard">
              <div className="searchGroup">
                <label className="searchlabel">Bank</label>
                <select
                  className="searchInput"
                  onChange={(e) =>
                    setSearchParams({ ...searchParams, bank: e.target.value })
                  }
                >
                  <option value="">Select Bank</option>
                  {Array.from(
                    new Set(
                      state.transactions.map((t) => t.account_display_name)
                    )
                  )
                    .sort()
                    .map((bank) => (
                      <option key={bank} value={bank}>
                        {bank}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="searchSmallCard">
              <div className="searchGroup">
                <label className="searchlabel">Suggested Category</label>
                <select
                  className="searchInput"
                  onChange={(e) =>
                    setSearchParams({
                      ...searchParams,
                      category: e.target.value,
                    })
                  }
                >
                  <option value="">Category</option>
                  {Array.from(
                    new Set(state.transactions.map((t) => t.suggested_category))
                  )
                    .sort()
                    .map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="searchSmallCard">
              <div className="searchGroup">
                <label className="searchlabel">Min Amount</label>
                <input
                  className="searchInput"
                  type="number"
                  placeholder="Min Amount"
                  onChange={(e) =>
                    setSearchParams({
                      ...searchParams,
                      minAmount: e.target.value,
                    })
                  }
                />
              </div>
              <div className="searchGroup">
                <label className="searchlabel">Max Amount</label>
                <input
                  className="searchInput"
                  type="number"
                  placeholder="Max Amount"
                  onChange={(e) =>
                    setSearchParams({
                      ...searchParams,
                      maxAmount: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <Button
              variant="contained"
              onClick={handleCloseFilterModal}
              sx={{
                alignSelf: "flex-end",
                maxWidth: "150px",
              }}
            >
              Done
            </Button>
          </Stack>
        </Stack>
      </OverlayModal>
    </div>
  );
};

export default Transactions;
