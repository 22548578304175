import React, { useEffect, useContext, useMemo } from "react";
import { subDays, isAfter } from "date-fns";
import "../App.css";
import MobileTransactionRow from "./MobileTransactionRow";
import APIService from "./api";
import { AccountsContext } from "../context/AccountsContext";

const RecentTransactions = () => {
  const { fetchTransactionsContext } = APIService();

  const { state } = useContext(AccountsContext);

  const recentTransactions = useMemo(() => {
    return (
      state.transactions.filter((transaction) =>
        isAfter(new Date(transaction.date), subDays(new Date(), 7))
      ) || []
    );
  }, [state.transactions]);

  useEffect(() => {
    if (!state.transactionsLoaded && !state.transactionsLoading) {
      fetchTransactionsContext();
    }
  }, [
    fetchTransactionsContext,
    state.transactionsLoaded,
    state.transactionsLoading,
  ]);

  if (!state.transactionsLoaded || state.transactionsLoading) {
    return <div>Loading...</div>;
  }

  if (!recentTransactions || recentTransactions.length === 0) {
    return (
      <div className="widgetContainer">
        <h3 className="header">Recent Transactions</h3>
        <div className="transactionListContainer">
          <p style={{ textAlign: "center" }}>No recent Transactions</p>
        </div>
      </div>
    );
  }
  return (
    <div className="widgetContainer">
      <h3 className="header">Recent Transactions</h3>
      {recentTransactions.map((transaction) => {
        return <MobileTransactionRow rowData={transaction} />;
      })}
    </div>
  );
};

export default RecentTransactions;
