import CashFlow from "../components/cashFlow";
import "../App.css";
import { useEffect, useState } from "react";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import OverlayModal from "../components/OverlayModal";
const CashFlowPage = () => {
  const theme = useTheme();
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  useEffect(() => {
    document.title = "Cash Flow - Pachira";
  }, []);

  const handleOpenInfoModal = () => {
    setInfoModalOpen(true);
  };

  const handleCloseInfoModal = () => {
    setInfoModalOpen(false);
  };
  return (
    <div className="dashboard">
      <div className="header">
        <Stack direction="row" alignItems="center" spacing={2}>
          <h1>Cash Flow Analysis</h1>
          <Tooltip title="Info">
            <FontAwesomeIcon
              id="info-button"
              icon={faCircleInfo}
              onClick={handleOpenInfoModal}
            />
          </Tooltip>
        </Stack>
      </div>
      <CashFlow />
      <OverlayModal open={infoModalOpen} onClose={handleCloseInfoModal}>
        <Stack px={2}>
          <Typography variant="h6" color={theme.palette.primary.main}>
            Quick Tips: Efficient Cash Flow Management
          </Typography>
          <ul className="tipList">
            <li>
              Adjust the time range using the date selector at the top to see a
              detailed view of your financial activity over different periods.
            </li>
            <li>
              Use the cash flow insights to understand your income and expenses,
              helping you make informed decisions about your spending and saving
              habits.
            </li>
            <li>
              Track whether you’ve added to your savings or spent from them
              during the selected period, and identify patterns that can guide
              future financial planning.
            </li>
          </ul>
        </Stack>
      </OverlayModal>
    </div>
  );
};

export default CashFlowPage;
