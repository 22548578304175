import React, { useEffect, useState } from "react";
import APIService from "./api";

const YodleeModal = ({ isOpen, onClose, accountId }) => {
  const { getAccessToken, addYodleeAccount } = APIService();

  useEffect(() => {
    if (isOpen) {
      const handleYodleeClick = async () => {
        try {
          const data = await getAccessToken();

          const onSuccess = (data) => {};
          console.log("checking account id", accountId);
          const fastLinkParams = accountId
            ? {
                providerAccountId: accountId,
                flow: "edit",
                configName: "aggregation",
              }
            : {
                configName: "aggregation",
              };

          window.fastlink.open(
            {
              fastLinkURL:
                "https://finapp.fingoalchannel.yodlee.com/authenticate/Pachira/fastlink?channelAppName=fingoalchannel",
              accessToken: `Bearer ${data.accessToken}`,
              params: fastLinkParams,
              onSuccess,
              onError: function (error) {
                console.error("FastLink Error:", error);
              },
              onClose: async (fastlinkData) => {
                onClose();
                if (fastlinkData) {
                  try {
                    const requestBody = {
                      accessToken: data.accessToken,
                      fastlinkData: fastlinkData,
                    };

                    await addYodleeAccount(requestBody);
                  } catch (error) {
                    console.error(
                      "Error in posting Yodlee account data:",
                      error
                    );
                  }
                }
                // Call the original onClose handler
              },
              onEvent: function (event) {
                console.log("FastLink Event:", event);
              },
            },
            "container-fastlink"
          );
        } catch (error) {
          console.error("Failed to connect with Yodlee:", error);
        }
      };
      handleYodleeClick();
    }
    return () => {
      // Cleanup FastLink session when the component is unmounted or modal is closed
      if (window.fastlink) {
        window.fastlink.close();
      }
    };
  }, [isOpen, accountId, onClose, addYodleeAccount, getAccessToken]);
  const [isMouseDownOnBackground, setIsMouseDownOnBackground] = useState(false);

  const handleMouseDown = (e) => {
    if (e.target.id === "modalBackground") {
      setIsMouseDownOnBackground(true);
    }
  };

  const handleMouseUp = (e) => {
    if (isMouseDownOnBackground && e.target.id === "modalBackground") {
      onClose();
    }
    setIsMouseDownOnBackground(false); // Reset the flag
  };

  if (!isOpen) return null;

  return (
    <div
      id="modalBackground"
      className="modal-background"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div className="modal-content">
        <div
          id="container-fastlink"
          class="container-fastlink"
          style={{ width: "100%", height: "100%" }}
        >
          {/* Ensure FastLink fills the entire card */}
        </div>
      </div>
    </div>
  );
};

export default YodleeModal;
