import React, { useContext, useState } from "react";
import { AuthContext } from "../authentication/authenticationContext";
import Spinner from "../components/spinner";
import brand from "../images/Pachira Full Logo Transparent Black.png";
import { useLocation, useNavigate } from "react-router";
import APIService from "../components/api";

const ForgotPassword = () => {
  const { forgotPassword } = APIService();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [status, setStatus] = useState("");
  const { isLoading } = useContext(AuthContext);
  const queryParams = new URLSearchParams(location.search);
  const isSubmitted = queryParams.get("submitted") === "true";

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleReset = async (event) => {
    event.preventDefault();
    if (validateEmail(email)) {
      const response = await forgotPassword(email);
      if (!response.ok) {
        const res = await response.text();

        setStatus(res);
        console.error(res);
        return;
      }
      navigate("/forgot-password?submitted=true");
    }
  };

  const handleEmailUpdate = (e) => {
    const { value } = e.target;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  const handleLogin = async () => {
    navigate("/login");
  };

  if (!isSubmitted) {
    return (
      <div style={styles.container}>
        <div style={styles.loginForm}>
          <img src={brand} alt="Pachira Logo" style={styles.logo} />
          <h2 style={styles.title}>Reset your password</h2>

          <form onSubmit={handleReset}>
            <input
              style={styles.input}
              type="text"
              value={email}
              onChange={handleEmailUpdate}
              placeholder="Email"
            />
            <button
              type="submit"
              className="buttonHover"
              style={
                isLoading || !isEmailValid
                  ? { ...styles.button, backgroundColor: "grey" }
                  : styles.button
              }
              disabled={isLoading || !isEmailValid}
            >
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </form>
          <button style={styles.button} onClick={handleLogin}>
            Return To Log In
          </button>
          {status && <p style={styles.status}>{status}</p>}
          {isLoading && <Spinner />}
        </div>
      </div>
    );
  } else {
    return (
      <div style={styles.container}>
        <div style={styles.loginForm}>
          <img src={brand} alt="Pachira Logo" style={styles.logo} />
          <h2 style={styles.title}>Check your email</h2>
          <p>
            You will receive an email shortly with a link to reset your
            password. If you don't see one, check your spam. You may have to
            wait a few minutes for the email to show up in your inbox.
          </p>

          <button style={styles.button} onClick={handleLogin}>
            Return To Log In
          </button>
          {isLoading && <Spinner />}
        </div>
      </div>
    );
  }
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f0f0f0",
  },
  loginForm: {
    maxWidth: "400px",
    width: "100%",
    padding: "40px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  logo: {
    maxWidth: "300px",
    marginBottom: "20px",
  },
  title: {
    color: "#001e80",
    marginBottom: "20px",
  },
  input: {
    width: "calc(100% - 22px)",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    fontSize: "16px",
  },
  button: {
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    backgroundColor: "#001e80",
    color: "white",
    border: "none",
    borderRadius: "15px",
    fontSize: "18px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    ":hover": {
      backgroundColor: "#003399",
    },
  },
  link: {
    display: "block",
    color: "#001e80",
    textDecoration: "none",
    marginBottom: "10px",
    fontSize: "16px",
  },
  status: {
    color: "red",
    marginTop: "10px",
  },
};

export default ForgotPassword;
