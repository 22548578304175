import React, { useState, useEffect, useContext, useCallback } from "react";
import { CurrencyContext } from "../context/currencyContext";
import { Sankey } from "react-vis";
import APIService from "./api";
import { AccountsContext } from "../context/AccountsContext";

import "react-datepicker/dist/react-datepicker.css";

function lightenColor(color, percent) {
  const num = parseInt(color.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt;

  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? R : 255) * 0x10000 +
      (G < 255 ? G : 255) * 0x100 +
      (B < 255 ? B : 255)
    )
      .toString(16)
      .slice(1)
  );
}

const CashFlow = () => {
  const { fetchCategoriesContext, fetchTransactionsContext } = APIService();
  const { state } = useContext(AccountsContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRange, setSelectedRange] = useState("Last Month");
  const { formatCurrency } = useContext(CurrencyContext);
  const [categories, setCategories] = useState([]);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const handleRangeSelect = (range) => {
    setSelectedRange(range);
    updateDates(range);
    const startDateInput = document.getElementById("start-date");
    const endDateInput = document.getElementById("end-date");
    const today = new Date();
    let startDate, endDate;

    switch (range) {
      case "This Week":
        const firstDayOfWeek = today.getDate() - today.getDay() + 1;
        startDate = new Date(today.setDate(firstDayOfWeek));
        endDate = new Date(today.setDate(firstDayOfWeek + 6));
        break;
      case "Last Week":
        const firstDayOfLastWeek = today.getDate() - today.getDay() - 6;
        startDate = new Date(today.setDate(firstDayOfLastWeek));
        endDate = new Date(today.setDate(firstDayOfLastWeek + 6));
        break;
      case "This Month":
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case "Last Month":
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case "Year to Date":
        startDate = new Date(today.getFullYear(), 0, 1);
        endDate = today;
        break;
    }

    startDateInput.value = startDate.toISOString().substr(0, 10);
    endDateInput.value = endDate.toISOString().substr(0, 10);
  };
  const updateDates = (range) => {
    const now = new Date();
    let start, end;

    switch (range) {
      case "This Week":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date();
        break;
      case "Last Week":
        now.setDate(now.getDate() - now.getDay() + 1); // Set to start of this week
        end = new Date(now.setDate(now.getDate() - 1)); // Set to end of last week
        start = new Date(now.setDate(now.getDate() - 6)); // Set to start of last week
        break;
      case "This Month":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date();
        break;
      case "Last Month":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "Year to Date":
        start = new Date(now.getFullYear(), 0, 1);
        end = new Date();
        break;
      default:
        return; // Optionally handle unknown range
    }

    setStartDate(start);
    setEndDate(end);
  };

  // Effect to set the default date range when the component mounts
  useEffect(() => {
    updateDates(selectedRange);
  }, [selectedRange]); // Empty dependency array to run only once on mount

  const adjustText = useCallback(() => {
    document
      .querySelectorAll(".rv-xy-plot__series--label-text")
      .forEach((text) => {
        // Remove any existing white rectangles associated with the text
        const siblingRects =
          text.parentNode.querySelectorAll("rect[fill='white']");
        siblingRects.forEach((rect) => text.parentNode.removeChild(rect));
      });
    document
      .querySelectorAll(".rv-xy-plot__series--label-text")
      .forEach((text) => {
        let firstPart = text.textContent.split("|")[0] + "|";
        let secondPart = text.textContent.split("|")[1];
        const approxCharWidth = 6;
        const longestPart = Math.max(firstPart.length, secondPart.length);
        const textWidth = longestPart * approxCharWidth;
        const textHeight = 30;
        const padding = 5;
        const xPos = parseFloat(text.getAttribute("x"));
        const yPos = parseFloat(text.getAttribute("y"));

        const anchor = text.getAttribute("text-anchor");
        let rectX = xPos - padding;
        if (anchor === "end") rectX = xPos - textWidth - padding;
        const background = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "rect"
        );
        const previousBackground = text.previousElementSibling;
        if (previousBackground && previousBackground.nodeName === "rect") {
          text.parentNode.removeChild(previousBackground);
        }
        text.parentNode.insertBefore(background, text);
        background.setAttribute("x", rectX);
        background.setAttribute("y", yPos - textHeight / 2 - padding / 2 + 10);
        background.setAttribute("width", textWidth + 2 * padding);
        background.setAttribute("height", textHeight + padding);
        background.setAttribute("fill", "white");
        background.setAttribute("opacity", "0.5");
        background.setAttribute("rx", "5");
        background.setAttribute("ry", "5");
        text.textContent = "";
        const tspan1 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "tspan"
        );
        tspan1.setAttribute("x", xPos);
        tspan1.setAttribute("dy", "-0.4em");
        tspan1.textContent = firstPart;
        text.appendChild(tspan1);

        const tspan2 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "tspan"
        );
        tspan2.setAttribute("x", xPos);
        tspan2.setAttribute("dy", "1.2em");
        tspan2.textContent = secondPart;
        text.appendChild(tspan2);
      });
  }, []);
  useEffect(() => {
    if (!state.transactionsLoaded && !state.transactionsLoading) {
      fetchTransactionsContext();
    }
    if (!state.categoriesLoaded && !state.categoriesLoading) {
      fetchCategoriesContext();
    }

    function computeCashFlow(categories, transactions, startDate, endDate) {
      const start = new Date(startDate);
      start.setHours(0, 0, 0, 0);

      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);

      const filteredTransactions = transactions.filter((tx) => {
        const txDate = new Date(tx.date);
        const startOfDay = new Date(start.toDateString());
        const endOfDay = new Date(end.toDateString());
        endOfDay.setHours(23, 59, 59, 999);

        return txDate >= startOfDay && txDate <= endOfDay;
      });
      const levelTwoCategories = categories.filter(
        (cat) => cat.level === 2 && cat.type !== "TRA"
      );
      const joinedData = levelTwoCategories
        .map((cat) => {
          const catTransactions = filteredTransactions.filter(
            (tx) => tx.pachira_category_id === cat.category_id
          );
          const totalAmount = catTransactions.reduce((acc, tx) => {
            return acc + parseFloat(tx.amount);
          }, 0);

          const positiveTotalAmount = Math.abs(totalAmount);
          return {
            ...cat,
            totalAmount: positiveTotalAmount,
          };
        })
        .filter((cat) => {
          return cat.totalAmount > 0;
        });

      const cashFlowData = [];
      joinedData.forEach((item) => {
        const parentCategory = categories.find(
          (cat) => cat.category_id === item.parent_id
        );
        if (parentCategory) {
          const group = parentCategory.category_name;
          const existingGroup = cashFlowData.find(
            (g) =>
              g.type === item.type &&
              g.group === group &&
              g.subtype === item.category_name
          );
          if (existingGroup) {
            existingGroup.amount += item.totalAmount;
          } else {
            cashFlowData.push({
              type: item.type === "INC" ? "inc" : "exp",
              group,
              subtype: item.category_name,
              amount: item.totalAmount,
            });
          }
        }
      });
      return cashFlowData;
    }

    if (state.categoriesLoaded && state.transactionsLoaded) {
      setCategories(
        computeCashFlow(
          state.categories,
          state.transactions,
          startDate,
          endDate
        )
      );
      setIsLoading(false);
    }
  }, [
    state.categoriesLoaded,
    state.transactionsLoaded,
    state.transactions,
    state.categories,
    startDate,
    endDate,
    state.categoriesLoading,
    state.transactionsLoading,
  ]);
  const handleResize = useCallback(() => {
    const isMobile = window.innerWidth <= 768;
    const minWidthForMobile = 960;
    const newWidth = isMobile
      ? Math.max(window.innerWidth, minWidthForMobile)
      : window.innerWidth - 250;

    setDimensions({
      width: newWidth,
      height: window.innerHeight * 0.72,
    });
    adjustText();
  }, [adjustText]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);
  useEffect(() => {
    adjustText();
  }, [isLoading, dimensions, adjustText, categories]);

  const baseIncomeColor = "#001e80"; // Pachira Blue
  const customRedColor = "#8B0000"; // Darker red color
  const customGreenColor = "#006400"; // Darker green color
  const expenseColors = [
    // Purples
    "#800080", // Dark Purple
    "#9932CC", // Dark Orchid

    // Reds
    "#C71585", // Medium Violet Red
    "#DC143C", // Crimson
    "#FF4500", // Orange Red
    "#FF6347", // Tomato
    "#FF7F50", // Coral
    "#FFB6C1", // Light Pink
    "#FFC0CB", // Pink

    // Oranges
    "#D2691E", // Chocolate
    "#FF8C00", // Dark Orange
    "#FFA07A", // Light Salmon
    "#FFA500", // Orange
    "#FFD700", // Gold
    "#FFDAB9", // Peach Puff
    "#FFE4B5", // Moccasin
    "#FFDEAD", // Navajo White
    "#FFEFD5", // Papaya Whip
  ];
  let nodes = [];
  let links = [];
  let incomeSubtypeMap = {};
  let incomeGroupMap = {};
  let expenseGroupMap = {};
  let expenseSubtypeMap = {};
  let nextNodeIndex = 0;
  let totalIncome = categories
    .filter((cat) => cat.type === "inc")
    .reduce((sum, cat) => sum + cat.amount, 0);
  let totalExpenses = 0;

  nodes.push({
    name: `Total Income|${formatCurrency(totalIncome)} (${(
      (totalIncome / totalIncome) *
      100
    ).toFixed(1)}%)`,
    color: baseIncomeColor,
  });
  nextNodeIndex++;

  categories.forEach((cat) => {
    let nodeName = `${cat.subtype}|${formatCurrency(cat.amount)} (${(
      (cat.amount / totalIncome) *
      100
    ).toFixed(1)}%)`;
    let nodeNameGroup = `${cat.group}|${formatCurrency(cat.amount)} (${(
      (cat.amount / totalIncome) *
      100
    ).toFixed(1)}%)`;
    if (cat.type === "inc") {
      if (!incomeSubtypeMap[cat.subtype]) {
        nodes.push({
          name: nodeName,
          color: lightenColor(baseIncomeColor, 30),
        });
        incomeSubtypeMap[cat.subtype] = nextNodeIndex++;
      }
      if (!incomeGroupMap[cat.group]) {
        nodes.push({
          name: nodeNameGroup,
          color: lightenColor(baseIncomeColor, 15),
        });
        incomeGroupMap[cat.group] = nextNodeIndex++;
      }
      links.push({
        source: incomeSubtypeMap[cat.subtype],
        target: incomeGroupMap[cat.group],
        value: cat.amount,
        color: lightenColor(baseIncomeColor, 20),
      });
      links.push({
        source: incomeGroupMap[cat.group],
        target: 0,
        value: cat.amount,
        color: baseIncomeColor,
      });
    } else if (cat.type === "exp") {
      if (!expenseGroupMap[cat.group]) {
        const groupColor =
          expenseColors[
            Object.keys(expenseGroupMap).length % expenseColors.length
          ];
        nodes.push({ name: nodeNameGroup, color: groupColor });
        expenseGroupMap[cat.group] = nextNodeIndex++;
      }
      if (!expenseSubtypeMap[cat.subtype]) {
        // Lighten the color for the subtype
        const groupColor = nodes[expenseGroupMap[cat.group]].color;
        nodes.push({ name: nodeName, color: lightenColor(groupColor, 20) });
        expenseSubtypeMap[cat.subtype] = nextNodeIndex++;
      }
      totalExpenses += cat.amount;
    }
  });

  nodes.push({
    name: `Total Expenses|${formatCurrency(totalExpenses)} (${(
      (totalExpenses / totalIncome) *
      100
    ).toFixed(1)}%)`,
    color: "#800080",
  });
  nextNodeIndex++;
  links.push({
    source: 0,
    target: nextNodeIndex - 1,
    value: Math.min(totalIncome, totalExpenses),
    color: baseIncomeColor,
  });
  let savings = totalIncome - totalExpenses;
  let savingsIndex = -1;
  let incomingSavingsIndex = -1;

  if (savings > 0) {
    savingsIndex = nodes.length;
    nodes.push({
      name: `Savings|${formatCurrency(savings)}`,
      color: customGreenColor,
    });
  } else if (savings < 0) {
    incomingSavingsIndex = nodes.length;
    nodes.push({
      name: `Incoming Savings|${formatCurrency(savings)}`,
      color: customRedColor,
    });
  }
  if (incomingSavingsIndex !== -1) {
    links.push({
      source: incomingSavingsIndex,
      target: nextNodeIndex - 1,
      value: Math.abs(savings),
      color: customRedColor,
    });
  }
  if (savingsIndex !== -1) {
    links.push({
      source: 0,
      target: savingsIndex,
      value: savings,
      color: customGreenColor,
    });
  }
  Object.keys(expenseGroupMap).forEach((group) => {
    let totalGroupExpense = categories
      .filter((cat) => cat.type === "exp" && cat.group === group)
      .reduce((sum, cat) => sum + cat.amount, 0);
    links.push({
      source: nextNodeIndex - 1,
      target: expenseGroupMap[group],
      value: totalGroupExpense,
      color: nodes[expenseGroupMap[group]].color,
    });
    categories
      .filter((cat) => cat.type === "exp" && cat.group === group)
      .forEach((cat) => {
        links.push({
          source: expenseGroupMap[group],
          target: expenseSubtypeMap[cat.subtype],
          value: cat.amount,
          color: nodes[expenseSubtypeMap[cat.subtype]].color,
        });
      });
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <div className="date-picker-container">
        <h4>Select Date Range</h4>
        <div className="date-picker-row">
          <input
            type="date"
            className="date-picker"
            id="start-date"
            value={startDate ? startDate.toISOString().substr(0, 10) : ""}
            onChange={(e) => setStartDate(new Date(e.target.value))}
          />
          <input
            type="date"
            className="date-picker"
            id="end-date"
            value={endDate ? endDate.toISOString().substr(0, 10) : ""}
            onChange={(e) => setEndDate(new Date(e.target.value))}
          />
        </div>
        <select
          className="dropdown"
          onChange={(e) => handleRangeSelect(e.target.value)}
        >
          <option selected disabled>
            Select
          </option>
          <option value="This Week">This Week</option>
          <option value="Last Week">Last Week</option>
          <option value="This Month">This Month</option>
          <option value="Last Month">Last Month</option>
          <option value="Year to Date">Year to Date</option>
        </select>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : categories.length > 0 ? (
        <div
          style={{
            fontSize: "calc(8px + (12 - 8) * ((100vw - 300px) / (1600 - 300)))",
            maxHeight: "80vh", // Sets the maximum height to 80% of the viewport height
            maxWidth: "100%", // You could adjust this if needed
          }}
        >
          <Sankey
            nodes={nodes}
            links={links}
            width={dimensions.width}
            height={dimensions.height}
            align={"justify"}
          />
        </div>
      ) : (
        <div
          style={{
            margin: "auto",
            marginTop: "50px",
            padding: "20px",
            textAlign: "center",
            backgroundColor: "#f4f4f4",
            borderRadius: "15px",
            width: "80%",
            maxWidth: "600px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          No data available for this time range.
        </div>
      )}
    </div>
  );
};

export default CashFlow;
