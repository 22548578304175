import React, { useState, useEffect, useContext } from "react";
import "../App.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RulesEdit from "../components/transactionRulesEdit";
import TipContainer from "../components/tipContainer";
import { AccountsContext } from "../context/AccountsContext";
import { Stack, useMediaQuery, useTheme, Button } from "@mui/material";
import OverlayModal from "./OverlayModal";
import APIService from "./api";

const RuleCard = ({ rule, index }) => {
  const { deleteRule } = APIService();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const conditions = rule.conditions;
  const handleDeleteRule = async (rule_id, e) => {
    e.stopPropagation();

    try {
      await deleteRule(rule_id);
    } catch (error) {
      console.error("Error deleting rule:", error);
    }
  };
  if (isMobile) {
    return (
      <Draggable
        key={rule.rule_id}
        draggableId={rule.rule_id.toString()}
        index={index}
      >
        {(provided) => (
          <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="ruleCardMobile"
          >
            <div className="ruleTitleMobile ruleCardDiv">{rule.name}</div>
            <div className="ruleActionMobile ">
              <RulesEdit ruleToEdit={rule} />

              <Button
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.error.main,
                  px: 1,
                  py: 0.25,
                }}
                onClick={(e) => handleDeleteRule(rule.rule_id, e)}
              >
                Delete
              </Button>
            </div>
          </li>
        )}
      </Draggable>
    );
  }

  return (
    <Draggable
      key={rule.rule_id}
      draggableId={rule.rule_id.toString()}
      index={index}
    >
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="ruleCard"
        >
          <div className="ruleTitle ruleCardDiv">{rule.name}</div>
          <div className="ruleCategory ruleCardDiv">{rule.category_name}</div>
          <div className="ruleAutoAccept ruleCardDiv">
            {rule.auto_add ? "Yes" : "No"}
          </div>

          {conditions.length === 1 ? (
            renderCondition(conditions[0])
          ) : (
            <div className="ruleCondition ruleCardDiv">Multiple Conditions</div>
          )}

          <div className="ruleAction ruleCardDiv">
            <RulesEdit ruleToEdit={rule} />

            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.error.main,
                px: 1,
                py: 0.25,
              }}
              onClick={(e) => handleDeleteRule(rule.rule_id, e)}
            >
              Delete
            </Button>
          </div>
        </li>
      )}
    </Draggable>
  );
};
const RulesManagerModal = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { fetchRules, updateRulePriorities } = APIService();
  const { state } = useContext(AccountsContext);
  const [loading, setLoading] = useState(true);
  const { fetchCategoriesContext, fetchTransactionsContext, fetchUserContext } =
    APIService();
  useEffect(() => {
    if (!state.categoriesLoaded && !state.categoriesLoading) {
      fetchCategoriesContext();
    }
    if (!state.transactionsLoaded && !state.transactionsLoading) {
      fetchTransactionsContext();
    }
    if (!state.rulesLoaded && !state.rulesLoading) {
      fetchRules();
    }
    setLoading(false);
  }, [
    state.rulesLoaded,
    state.rulesLoading,
    state.categoriesLoaded,
    state.categoriesLoading,
    state.transactionsLoaded,
    state.transactionsLoading,
  ]);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      state.rules,
      result.source.index,
      result.destination.index
    );

    updateRulePriorities(items);
  };
  const RulesHeader = () => {
    return (
      <div className="rulesHeader">
        <div className="ruleTitle">Rule Name</div>

        {!isMobile && (
          <>
            <div className="ruleCategory">Assigned Category</div>
            <div className="ruleAutoAccept">Auto Accept</div>
            <div className="ruleCondition">Conditions</div>
          </>
        )}

        {/* Actions header is always shown but can be adjusted if necessary */}
        <div className="ruleActionTitle">Actions</div>
      </div>
    );
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result.forEach((rule, index) => {
      rule.priority = index + 1;
    });

    return result;
  };
  return (
    <OverlayModal open={open} onClose={onClose} isFullScreen={true}>
      <Stack>
        <h2>Manage Your Rules</h2>
        <TipContainer header="Quick Tips: Managing Transactions with Rules">
          <ul>
            <li>
              <strong>Automate Sorting:</strong> Rules help automate the sorting
              and management of your transactions into categories.
            </li>
            <li>
              <strong>Comparison:</strong> When created, rules compare all
              unsorted transactions to see if they match any criteria, then sort
              matching transactions into specified categories.
            </li>
            <li>
              <strong>Automatic or Suggested:</strong> Rules can automatically
              add transactions to categories as they come through, or they can
              suggest categories for your approval.
            </li>
            <li>
              <strong>Criteria:</strong> You can create rules based on
              transaction amount, memo, merchant name, suggested category, or
              bank account.
            </li>
            <li>
              <strong>Priority System:</strong> If multiple rules qualify a
              transaction, the rule with higher priority (or higher on the list)
              categorizes it first. Use drag and drop to adjust rule order.
            </li>
            <li>
              <strong>Control:</strong> By defining rules, you can choose to be
              as hands-off or as involved as you like in managing your finances,
              from auto-adding all transactions to reviewing suggested
              categorizations.
            </li>
          </ul>
        </TipContainer>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="rulesCard ruleCardDiv">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="rulesList">
                {(provided) => (
                  <ul
                    className="rulesList"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <RulesHeader />
                    {state.rules && state.rules.length > 0 ? (
                      state.rules.map((rule, index) => (
                        <RuleCard
                          key={rule.rule_id}
                          rule={rule}
                          index={index}
                        />
                      ))
                    ) : (
                      <li className="no-rules-message">No rules available</li>
                    )}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </Stack>
    </OverlayModal>
  );
};

const renderCondition = (condition) => (
  <div className="ruleCondition ruleCardDiv">{`${condition.target} ${condition.comparison} "${condition.value}"`}</div>
);

export default RulesManagerModal;
