import React, { useEffect, useRef, useState } from "react";
import "../App.css";
import brand from "../images/Pachira Vector Black.svg";
import AdComponent from "../components/adComponent";

const Planning = () => {
  const adContainerRef = useRef();
  const [numberOfAds, setNumberOfAds] = useState(0);

  useEffect(() => {
    document.title = "Planning - Pachira";
    calculateAdsToFit();
    window.addEventListener("resize", calculateAdsToFit);

    return () => {
      window.removeEventListener("resize", calculateAdsToFit);
    };
  }, []);

  const calculateAdsToFit = () => {
    if (adContainerRef.current) {
      const containerWidth = adContainerRef.current.offsetWidth;
      const containerHeight = adContainerRef.current.offsetHeight;
      const aspectRatio = 16 / 9;
      let adWidth = 224;
      let adHeight = adWidth / aspectRatio;
      const totalAdsSpaceWidth = Math.floor(containerWidth / adWidth) * adWidth;
      const totalAdsSpaceHeight =
        Math.floor(containerHeight / adHeight) * adHeight;
      adWidth = totalAdsSpaceWidth / Math.floor(containerWidth / adWidth);
      adHeight = totalAdsSpaceHeight / Math.floor(containerHeight / adWidth);
      const adsPerRow = Math.floor(containerWidth / adWidth);
      const adsPerColumn = Math.floor(containerHeight / adHeight);
      setNumberOfAds(adsPerRow * adsPerColumn);
    }
  };
  return (
    <div ref={adContainerRef} className="background">
      <div className="ad-container">
        {Array.from({ length: numberOfAds }, (_, index) => (
          <AdComponent key={index} />
        ))}
      </div>
      <div className="dev-card">
        <img src={brand} alt="Brand Logo" className="dev-logo" />
        <h1 className="dev-header">We're Working Hard on This Page!</h1>
        <p className="message">
          Your ideas matter to us. Feel free to share your thoughts on how you'd
          like this page to look. We're developing as fast as we can to bring
          you new features!
        </p>
      </div>
    </div>
  );
};

export default Planning;
