import React, { useEffect, useState, useContext } from "react";
import { Outlet } from "react-router-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CurrencyContext } from "./context/currencyContext";
import { formatCurrency, formatNumber } from "./components/utils";
import SideBar from "./components/sideBar";
import TopBar from "./components/topBar";
import BottomAdBar from "./components/bottomAdBar";
import MobileTopBar from "./components/mobileTopBar";
import MainContent from "./components/mainContent";
import BottomNavBar from "./components/bottomNavBar";
import useAuthCheck from "./authentication/useAuthenticationCheck";
import Dashboard from "./pages/Dashboard";
import Accounts from "./pages/Accounts";
import Budgets from "./pages/Budgets";
import Transactions from "./pages/Transactions";
import RecurringTransactions from "./pages/RecurringTransactions";
import Portfolio from "./pages/Portfolio";
import InvestmentParties from "./pages/InvestmentParties";
import Planning from "./pages/Planning";
import CashFlow from "./pages/CashFlow";
import Settings from "./pages/Settings";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { CollapsibleProvider } from "./components/collapsibleContext";
import "./App.css";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import TimeoutModal from "./components/timeoutModal";
import MFALogin from "./pages/MFALogin";
import LoadingWithLogo from "./components/loadingWithLogo";
import usePageTracking from "./components/usePageTracking";
import PageTracker from "./components/pageTracker";
import NotificationContainer from "./components/notificationContainer";
import {
  ACTION_TYPES as AUTH_ACTIONS,
  AuthContext,
} from "./authentication/authenticationContext";
import { AccountsContext } from "./context/AccountsContext";
import APIService from "./components/api";

const MainLayout = () => {
  const { refreshToken, fetchSubscriptions } = APIService();
  const { state } = useContext(AccountsContext);
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext);
  usePageTracking();
  const { isAuthenticated, isLoading } = useAuthCheck();
  const [showTimeout, setShowTimeout] = useState(false);
  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {};

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);
  useEffect(() => {
    if (authState.tokenExpiryTime > 2) {
      let inactivityTimer;
      inactivityTimer = setTimeout(() => {
        if (!showTimeout) {
          if (authState.tokenExpiryTime <= 60) {
            setShowTimeout(true);
            return;
          } else {
            setShowTimeout(false);
            authDispatch({
              type: AUTH_ACTIONS.SET_TOKEN_EXPIRATION,
              payload: 60,
            });
          }
        }
      }, (authState.tokenExpiryTime - 60) * 1000);

      return () => {
        clearTimeout(inactivityTimer);
      };
    }
  }, [authDispatch, authState.tokenExpiryTime, showTimeout]);
  useEffect(() => {
    if (!state.subscriptionsLoaded && !state.subscriptionsLoading) {
      fetchSubscriptions();
    }
  }, [state.subscriptionsLoaded, state.subscriptionsLoading]);
  const handleModalClose = async () => {
    await refreshToken();
    setShowTimeout(false);
  };

  if (isLoading) {
    return <LoadingWithLogo />;
  }
  if (authState.tokenExpiryTime === 0) {
    refreshToken();
    return <LoadingWithLogo />;
  }
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <div
        className={`app-container ${
          state.subscriptions.some(
            (subscription) =>
              subscription.donation === false &&
              (subscription.is_active === true ||
                new Date(subscription.next_payment_date) >=
                  new Date().setHours(0, 0, 0, 0))
          )
            ? "has-subscription"
            : ""
        }`}
      >
        <CollapsibleProvider>
          <NotificationContainer />
          <div className="top-bar">
            <TopBar />
          </div>
          <div className="mobile-top-bar">
            <MobileTopBar />
          </div>
          <div className="side-bar">
            <SideBar />
          </div>
          <MainContent>
            <Outlet />
          </MainContent>
          <div className="bottom-nav">
            <BottomNavBar />
          </div>
          {!state.subscriptions.some(
            (subscription) =>
              subscription.donation === false &&
              (subscription.is_active === true ||
                new Date(subscription.next_payment_date) >=
                  new Date().setHours(0, 0, 0, 0))
          ) && (
            <div className="bottombar">
              <BottomAdBar />
            </div>
          )}
        </CollapsibleProvider>
      </div>
      {showTimeout ? <TimeoutModal onClose={handleModalClose} /> : <></>}
    </>
  );
};

const App = () => {
  return (
    <CurrencyContext.Provider
      value={{ formatCurrency: formatCurrency, formatNumber: formatNumber }}
    >
      <Router>
        <PageTracker>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/mfa-login/:mfaLoginID" element={<MFALogin />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/reset-password/:userID/:requestID"
              element={<ResetPassword />}
            />
            <Route path="*" element={<Navigate to="/login" replace />} />
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="accounts" element={<Accounts />} />
              <Route path="transactions" element={<Transactions />} />
              <Route path="budgets" element={<Budgets />} />
              <Route path="cashFlow" element={<CashFlow />} />
              <Route
                path="recurringTransactions"
                element={<RecurringTransactions />}
              />
              <Route path="portfolio" element={<Portfolio />} />
              <Route path="planning" element={<Planning />} />
              <Route path="investmentParties" element={<InvestmentParties />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          </Routes>
        </PageTracker>
      </Router>
    </CurrencyContext.Provider>
  );
};

export default App;
