import React, { useContext, useState, useEffect } from "react";
import Modal from "../components/modal";
import "../App.css";
import { AccountsContext } from "../context/AccountsContext";
import APIService from "./api";

const BudgetPopup = ({ onClose, onSave }) => {
  const { fetchDefaultCategories } = APIService();
  const { state } = useContext(AccountsContext);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    if (!state.defaultCategoriesLoaded && !state.defaultCategoriesLoading) {
      fetchDefaultCategories();
    }
  }, [
    fetchDefaultCategories,
    state.defaultCategoriesLoaded,
    state.defaultCategoriesLoading,
    state.defaultCategories,
  ]);
  const handleSave = () => {
    onSave(selectedCategories);
    onClose();
  };
  const toggleCategory = (category) => {
    setSelectedCategories((prev) => {
      let newCategories = [...prev];
      const isCategorySelected = newCategories.some(
        (c) => c.category_id === category.category_id
      );
      if (isCategorySelected) {
        newCategories = newCategories.filter(
          (c) => c.category_id !== category.category_id
        );
        state.defaultCategories.forEach((cat) => {
          if (cat.parent_id === category.category_id) {
            newCategories = newCategories.filter(
              (c) => c.category_id !== cat.category_id
            );
          }
        });
      } else {
        if (
          category.level === 2 &&
          category.parent_id &&
          !newCategories.find((c) => c.category_id === category.parent_id)
        ) {
          const parentCategory = state.defaultCategories.find(
            (c) => c.category_id === category.parent_id
          );
          newCategories.push(parentCategory);
        }
        newCategories.push(category);
        if (category.level === 1) {
          state.defaultCategories.forEach((cat) => {
            if (
              cat.parent_id === category.category_id &&
              !newCategories.find((c) => c.category_id === cat.category_id)
            ) {
              newCategories.push(cat);
            }
          });
        }
      }
      return newCategories;
    });
  };

  const isCategorySelected = (category_id) =>
    selectedCategories.some((category) => category.category_id === category_id);

  const categoryStyle = (isSelected, isParent) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px",
    padding: "10px",
    fontWeight: isParent ? "bold" : "normal",
    paddingLeft: !isParent ? "10px" : "20px",
    width: !isParent ? "80%" : "90%",
    backgroundColor: isSelected ? "#0040c0" : "#f0f0f0",
    color: isSelected ? "#FFF" : "#000",
    textAlign: "left",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "transform 0.1s, background-color 0.1s",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    backgroundImage: isSelected
      ? "linear-gradient(to right, #001e80, #0040c0)"
      : "linear-gradient(to right, #e6e6e6, #f0f0f0)",
  });

  return (
    <Modal
      onClose={onClose}
      title="Select Budget Categories"
      description="Please select the initial categories for your budget. You will be able to edit, add, and delete categories after the initial setup. There are group categories and regular categories. All regular categories must be inside a group."
    >
      <div class="modalContent">
        {state.defaultCategories
          .filter((cat) => cat.level === 1) // Start with parent categories
          .map((parentCategory) => (
            <React.Fragment key={parentCategory.category_id}>
              <div
                onClick={() => toggleCategory(parentCategory)}
                style={categoryStyle(
                  isCategorySelected(parentCategory.category_id),
                  true
                )}
              >
                <span>{parentCategory.category_name}</span>
                {isCategorySelected(parentCategory.category_id) && (
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    Added
                  </span>
                )}
              </div>
              {/* Child Categories */}
              {state.defaultCategories
                .filter((cat) => cat.parent_id === parentCategory.category_id)
                .map((childCategory) => (
                  <div
                    key={childCategory.category_id}
                    onClick={() => toggleCategory(childCategory)}
                    style={categoryStyle(
                      isCategorySelected(childCategory.category_id),
                      false
                    )}
                  >
                    <span>{childCategory.category_name}</span>
                    {isCategorySelected(childCategory.category_id) && (
                      <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                        Added
                      </span>
                    )}
                  </div>
                ))}
            </React.Fragment>
          ))}
        <button className="fullLengthButton" onClick={handleSave}>
          Save
        </button>
        <br></br>
      </div>
    </Modal>
  );
};

export default BudgetPopup;
