import React from "react";
import "../App.css";

const TimeRangeToggleButtonGroup = ({ timeRange, setTimeRange, className }) => {
  return (
    <select
      className={`dropdown ${className}`}
      value={timeRange}
      onChange={(e) => setTimeRange(e.target.value)}
    >
      <option value="Last7Days">Last 7 Days</option>
      <option value="Last28Days">Last 28 Days</option>
      <option value="LastYear">Last Year</option>
      <option value="AllTime">All Time</option>
      <option value="ThisWeek">This Week</option>
      <option value="ThisMonth">This Month</option>
      <option value="ThisYear">This Year</option>
    </select>
  );
};

export default TimeRangeToggleButtonGroup;
