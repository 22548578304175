import React, { useState, useContext, useEffect } from "react";
import Modal from "../components/modal";
import "../App.css";
import Spinner from "../components/modalLoading";
import { AccountsContext } from "../context/AccountsContext";
import { AppContext } from "../context/AppContext";
import APIService from "../components/api";

const Settings = () => {
  const {
    fetchPayments,
    processPayment,
    savePayment,
    cancelSubscription,
    deleteUser,
    logoutUser,
    resetPassword,
  } = APIService();

  const { state: appState } = useContext(AppContext);
  const { state: accountsState } = useContext(AccountsContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [amount, setAmount] = useState(""); // Default to $10 for monthly
  const [recurring, setRecurring] = useState(false); // Default payment type
  const [selectedOption, setSelectedOption] = useState("");
  const [agreeToTermsMonthly, setAgreeToTermsMonthly] = useState(false);
  const [agreeToTermsYearly, setAgreeToTermsYearly] = useState(false);
  const [agreeToTermsDonation, setAgreeToTermsDonation] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    specialChar: false,
  });
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setNewPassword(value);
    setPasswordRequirements({
      minLength: value.length >= 8,
      upperCase: /[A-Z]/.test(value),
      lowerCase: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      specialChar: /[\W_]/.test(value),
    });
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };
  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };
  const handleResetPassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    if (!Object.values(passwordRequirements).every(Boolean)) {
      setPasswordError("Password does not meet requirements");
      return;
    }

    setIsLoading(true);
    setPasswordError("");
    setSuccessMessage("");

    try {
      let result = await resetPassword(
        appState.user.user_id,
        "",
        newPassword,
        currentPassword
      );
      if (result?.invalid) {
        setPasswordError("Failed to reset password. Invalid Password.");
      } else {
        setSuccessMessage("Password has been successfully reset.");
      }
    } catch (error) {
      console.log(error);
      setPasswordError("Failed to reset password. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    if (
      option === "Pachira Simplify Pass (Monthly)" ||
      option === "Pachira Simplify Pass (Yearly)"
    ) {
      setAmount(option === "Pachira Simplify Pass (Monthly)" ? "2" : "15");
      setRecurring(true);
    } else if (option === "Donation") {
      // Donation amount managed separately through its buttons and input
    }
  };
  const removeHelcimPayIframe = () => {
    const iframe = document.getElementById("helcimPayIframe");
    if (iframe) {
      iframe.remove();
    }
  };
  const handlePayment = async (paymentDetails) => {
    setLoading(true);
    try {
      const response = await processPayment(paymentDetails);
      if (response.checkoutToken) {
        window.appendHelcimPayIframe(response.checkoutToken);
        // Now set up the event listener using the received checkoutToken
        window.addEventListener("message", (event) => {
          const helcimPayJsIdentifierKey =
            "helcim-pay-js-" + response.checkoutToken;
          if (event.data.eventName === helcimPayJsIdentifierKey) {
            setLoading(false);
            if (event.data.eventStatus === "ABORTED") {
              console.error("Transaction failed!", event.data);
            }

            if (event.data.eventStatus === "SUCCESS") {
              const transactionData = JSON.parse(event.data.eventMessage);
              saveTransactionDetails(transactionData, paymentDetails);
              setLoading(false);
              removeHelcimPayIframe();
            }
          }
        });
      } else {
        setLoading(false);
        throw new Error("Failed to retrieve payment token");
      }
    } catch (error) {
      setLoading(false);
      console.error("Payment processing error:", error);
      alert("Failed to process payment.");
    }
  };
  useEffect(() => {
    // Fetch subscriptions and payments from the backend
    const fetchSubscriptionsAndPayments = async () => {
      if (!accountsState.paymentsLoaded && !accountsState.paymentsLoading) {
        fetchPayments();
      }
    };

    fetchSubscriptionsAndPayments();
  }, [
    fetchPayments,
    accountsState.subscriptionsLoaded,
    accountsState.paymentsLoaded,
    accountsState.paymentsLoading,
  ]);

  const handleDeleteAccount = async () => {
    try {
      await deleteUser();
      await logoutUser();
    } catch (error) {
      setErrorMessage("Failed to delete account. Please try again later.");
      console.error("Error deleting account:", error);
    }
  };
  const handleSubmit = () => {
    if (confirmationText.trim().toLowerCase() === "delete my account") {
      handleDeleteAccount();
    } else {
      setErrorMessage("Please type 'delete my account' to confirm.");
    }
  };

  const saveTransactionDetails = async (transactionData, paymentDetails) => {
    savePayment({
      transactionData: transactionData.data.data,
      paymentDetails,
    });
  };
  return (
    <div className="dashboard">
      <div className="header">
        <h1>Settings</h1>
      </div>
      <div className="delete-card" style={{ borderRadius: "10px" }}>
        <h2 className="delete-header">Delete Account</h2>
        <p className="delete-message">
          Click the button to delete your account.
        </p>
        <button className="dangerButton" onClick={() => setIsModalOpen(true)}>
          Delete Account
        </button>
      </div>
      <div className="reset-password-card" style={{ borderRadius: "10px" }}>
        <h2 className="reset-password-header">Reset Password</h2>
        <input
          className="input-full"
          type="password"
          value={currentPassword}
          onChange={handleCurrentPasswordChange}
          placeholder="Current Password"
        />
        <input
          className="input-full"
          type="password"
          value={newPassword}
          onChange={handlePasswordChange}
          placeholder="New Password"
        />
        <input
          className="input-full"
          type="password"
          value={confirmNewPassword}
          onChange={handleConfirmPasswordChange}
          placeholder="Confirm New Password"
        />
        {passwordError && <p className="error">{passwordError}</p>}
        {successMessage && (
          <p className="registrationSuccess">{successMessage}</p>
        )}
        <div className="password-requirements">
          <p
            style={{ color: passwordRequirements.minLength ? "green" : "red" }}
          >
            {passwordRequirements.minLength ? "✓" : "✗"} Minimum 8 characters
          </p>
          <p
            style={{ color: passwordRequirements.upperCase ? "green" : "red" }}
          >
            {passwordRequirements.upperCase ? "✓" : "✗"} At least one uppercase
            letter
          </p>
          <p
            style={{ color: passwordRequirements.lowerCase ? "green" : "red" }}
          >
            {passwordRequirements.lowerCase ? "✓" : "✗"} At least one lowercase
            letter
          </p>
          <p style={{ color: passwordRequirements.number ? "green" : "red" }}>
            {passwordRequirements.number ? "✓" : "✗"} At least one number
          </p>
          <p
            style={{
              color: passwordRequirements.specialChar ? "green" : "red",
            }}
          >
            {passwordRequirements.specialChar ? "✓" : "✗"} At least one special
            character
          </p>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <button className="blueButton" onClick={handleResetPassword}>
            Reset Password
          </button>
        )}
      </div>
      <div className="payment-options delete-card">
        <h2>Manage Subscription and Donations</h2>
        <div className="subscriptions-and-payments">
          <div className="card-container">
            {/* Pachira Simplify Pass (Monthly) */}
            <div
              className="paymentCard"
              style={{
                background: "linear-gradient(to right, #6a11cb, #2575fc)",
                opacity:
                  selectedOption === "Pachira Simplify Pass (Monthly)" ||
                  selectedOption === ""
                    ? "1"
                    : "0.5",
                transform:
                  selectedOption === "Pachira Simplify Pass (Monthly)"
                    ? "scale(1.05)"
                    : "scale(1)",
                transition: "all 0.3s ease",
                cursor: "pointer",
              }}
              onClick={() => selectOption("Pachira Simplify Pass (Monthly)")}
            >
              <h3>Pachira Simplify Pass (Monthly)</h3>
              <p>$2.50 per month for a completely ad-free experience</p>
              <p>Amount: $2.50</p>
              <strong>Payment Type:</strong> Recurring
              <p>
                By proceeding, you understand that your account will be charged
                according to the subscription terms outlined for the selected
                plan.
              </p>
              <div>
                <input
                  type="checkbox"
                  checked={agreeToTermsMonthly}
                  onChange={(e) => setAgreeToTermsMonthly(e.target.checked)}
                  id="agreeTermsMonthly"
                />
                <label htmlFor="agreeTermsMonthly">
                  {" "}
                  I understand the{" "}
                  <a
                    href="https://pachira.abandm.com/documents/Terms-of-Service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                  .
                </label>
              </div>
              <button
                className="blueButton"
                disabled={!agreeToTermsMonthly}
                onClick={() =>
                  handlePayment({
                    amount: 2.5,
                    recurring: true,
                    paymentPeriod: "Monthly",
                    subscription: true,
                  })
                }
              >
                {" "}
                Add Card Details
              </button>
            </div>

            {/* Pachira Simplify Pass (Yearly) */}
            <div
              className="paymentCard"
              style={{
                background: "linear-gradient(to right, #fc4a1a, #f7b733)",
                opacity:
                  selectedOption === "Pachira Simplify Pass (Yearly)" ||
                  selectedOption === ""
                    ? "1"
                    : "0.5",
                transform:
                  selectedOption === "Pachira Simplify Pass (Yearly)"
                    ? "scale(1.05)"
                    : "scale(1)",
                transition: "all 0.3s ease",
                cursor: "pointer",
                position: "relative", // Needed for absolute positioning of the discount tag
              }}
              onClick={() => selectOption("Pachira Simplify Pass (Yearly)")}
            >
              {/* Discount Tag */}
              <div className="discountTag">50% DISCOUNT</div>
              <h3>Pachira Simplify Pass (Yearly)</h3>
              <p>$15 per year for a completely ad-free experience</p>
              <p>Amount: $15</p>
              <strong>Payment Type:</strong> Recurring
              <p>
                By proceeding, you understand that your account will be charged
                according to the subscription terms outlined for the selected
                plan.
              </p>
              <div>
                <input
                  type="checkbox"
                  checked={agreeToTermsYearly}
                  onChange={(e) => setAgreeToTermsYearly(e.target.checked)}
                  id="agreeTermsYearly"
                />
                <label htmlFor="agreeTermsYearly">
                  {" "}
                  I understand the{" "}
                  <a
                    href="https://pachira.abandm.com/documents/Terms-of-Service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                  .
                </label>
              </div>
              <button
                className="blueButton"
                disabled={!agreeToTermsYearly}
                onClick={() =>
                  handlePayment({
                    amount: 15,
                    recurring: true,
                    paymentPeriod: "Yearly",
                    subscription: true,
                  })
                }
              >
                Add Card Details
              </button>
            </div>

            {/* Donation Card */}
            <div
              className="paymentCard"
              style={{
                background:
                  "linear-gradient(to right, #12c2e9, #c471ed, #f64f59)",
                opacity:
                  selectedOption === "Donation" || selectedOption === ""
                    ? "1"
                    : "0.5",
                transform:
                  selectedOption === "Donation" ? "scale(1.05)" : "scale(1)",
                transition: "all 0.3s ease",
                cursor: "pointer",
              }}
              onClick={() => selectOption("Donation")}
            >
              <h3>Donation</h3>
              <p>Support our services with a donation</p>
              <div className="donation-amounts">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setAmount("10");
                  }}
                >
                  $10
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setAmount("20");
                  }}
                >
                  $20
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setAmount("50");
                  }}
                >
                  $50
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setAmount("100");
                  }}
                >
                  $100
                </button>
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => {
                    e.stopPropagation();
                    setAmount(e.target.value);
                  }}
                  placeholder="Custom Amount"
                  className="custom-amount-input"
                />
              </div>
              <div className="donation-type">
                <strong>Payment Type:</strong>
                <label>
                  <input
                    type="radio"
                    name="donationType"
                    value="One-time"
                    checked={recurring === false}
                    onChange={() => setRecurring(false)}
                  />{" "}
                  One-time
                </label>
                <label>
                  <input
                    type="radio"
                    name="donationType"
                    value="Recurring"
                    checked={recurring === true}
                    onChange={() => setRecurring(true)}
                  />{" "}
                  Recurring Monthly
                </label>
              </div>

              <p>
                By proceeding, you understand that your account will be charged
                according to the subscription terms outlined for the selected
                plan.
              </p>
              <div>
                <input
                  type="checkbox"
                  checked={agreeToTermsDonation}
                  onChange={(e) => setAgreeToTermsDonation(e.target.checked)}
                  id="agreeTermsDonation"
                />
                <label htmlFor="agreeTermsDonation">
                  {" "}
                  I understand the{" "}
                  <a
                    href="https://pachira.abandm.com/documents/Terms-of-Service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                  .
                </label>
              </div>
              <button
                className="blueButton"
                disabled={!agreeToTermsDonation}
                onClick={() =>
                  handlePayment({
                    amount: amount,
                    recurring: recurring,
                    paymentPeriod: recurring ? "Monthly" : "One-Time",
                    subscription: false,
                  })
                }
              >
                Add Card Details
              </button>
            </div>
          </div>
          <h3>Your Subscriptions</h3>
          <div className="subscriptions-container">
            {accountsState.subscriptions.length > 0 ? (
              accountsState.subscriptions
                .filter((sub) => sub.is_reccurring)
                .map((sub) => (
                  <div key={sub.subscription_id} className="subscription-card">
                    <p>
                      <strong>Type:</strong> {sub.subscription_type}
                    </p>
                    <p>
                      <strong>Amount:</strong> ${sub.amount}
                    </p>
                    <p>
                      <strong>Next Payment Date:</strong>{" "}
                      {new Date(sub.next_payment_date).toLocaleDateString()}
                    </p>
                    <p>
                      <strong>Active:</strong> {sub.is_active ? "Yes" : "No"}
                    </p>
                    <button
                      className="dangerButton"
                      onClick={() => cancelSubscription(sub.subscription_id)}
                      disabled={!sub.is_active}
                    >
                      Cancel Subscription
                    </button>
                  </div>
                ))
            ) : (
              <p>No current subscriptions</p>
            )}
          </div>

          <h3>Your Payment History</h3>
          {accountsState?.payments?.length > 0 ? (
            <table className="payments-table">
              <thead>
                <tr>
                  <th>Transaction ID</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {accountsState.payments
                  .sort(
                    (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
                  )
                  .map((payment) => (
                    <tr key={payment.transactionId}>
                      <td>{payment.transactionId}</td>
                      <td>${payment.amount}</td>
                      <td>
                        {new Date(payment.dateCreated).toLocaleDateString()}
                      </td>
                      <td>{payment.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <p>No payments found</p>
          )}
        </div>
      </div>
      {isModalOpen && (
        <Modal
          title="Confirm Account Deletion"
          onClose={() => setIsModalOpen(false)}
        >
          <p className="delete-message">
            Are you sure you want to delete your account? This action cannot be
            undone. Upon deletion, all your data will be permanently erased from
            our systems. This includes, but is not limited to:
            <ul>
              <li>Your account information and profile details.</li>
              <li>
                All historical transactions, including those linked to any banks
                or financial institutions.
              </li>
              <li>
                All accounts, including any connections to banks and other
                financial services.
              </li>
              <li>
                Any stored preferences, settings, and personalized
                configurations.
              </li>
            </ul>
            Additionally, your username will become available for someone else
            to use. There is no way to recover any data once your account is
            deleted. Please consider this action carefully.
          </p>

          <p>
            Type <strong>"delete my account"</strong> to confirm:
          </p>
          <input
            className="modalInput"
            type="text"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          />
          <button
            className="dangerButton"
            onClick={handleSubmit}
            disabled={
              confirmationText.trim().toLowerCase() !== "delete my account"
            }
          >
            Delete
          </button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </Modal>
      )}{" "}
      {loading && (
        <div className="loading-overlay">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Settings;
