import React, { useState, useContext, useEffect } from "react";
import { CurrencyContext } from "../context/currencyContext";
import { DataGrid } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";
import Modal from "../components/modal";
import "../App.css";
import Spinner from "./spinner";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import MobileTransactionRow from "./MobileTransactionRow";
import APIService from "./api";
import { ACTION_TYPES, AccountsContext } from "../context/AccountsContext";

const BudgetProgressBar = ({ categories, activePeriod }) => {
  const { updateTransaction, updateTransactionSplit } = APIService();
  const { dispatch } = useContext(AccountsContext);
  const { formatCurrency } = useContext(CurrencyContext);
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactionCategory, setTransactionCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteChecked, setIsDeleteChecked] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [merchant, setMerchant] = useState("");
  const [memo, setMemo] = useState("");
  const [date, setDate] = useState("");
  const [amount, setAmount] = useState("");
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (selectedTransaction) {
      setTransactionCategory(selectedTransaction.pachira_category_id || "");
      setMerchant(selectedTransaction.merchant_name || "");
      setNotes(selectedTransaction.notes || "");
      setMemo(selectedTransaction.memo || "");
      setAmount(selectedTransaction.amount.toString());
      setDate(selectedTransaction.date || "");
    }
  }, [selectedTransaction]);
  const [splitRows, setSplitRows] = useState([
    { category_id: "", amount: "", notes: "" },
    { category_id: "", amount: "", notes: "" },
  ]);
  const [splits, setSplits] = useState(false);
  useEffect(() => {
    if (
      selectedTransaction &&
      selectedTransaction.splits &&
      selectedTransaction.splits.length >= 2
    ) {
      setSplits(true);
      setTransactionCategory("split");
      const initialSplitRows = selectedTransaction.splits.map((split) => ({
        category_id: split.split_category_id,
        amount: split.split_amount,
        notes: split.split_notes,
      }));
      setSplitRows(initialSplitRows);
    } else {
      setSplits(false);
    }
  }, [selectedTransaction]);
  const addSplitRow = () => {
    setSplitRows([...splitRows, { category_id: "", amount: "", notes: "" }]);
  };
  const handleSplitCategoryChange = (e, index) => {
    const updatedRows = splitRows.map((row, idx) =>
      idx === index ? { ...row, category_id: e.target.value } : row
    );
    setSplitRows(updatedRows);
  };
  const handleSplitNotesChange = (e, index) => {
    const updatedRows = splitRows.map((row, idx) =>
      idx === index ? { ...row, notes: e.target.value } : row
    );
    setSplitRows(updatedRows);
  };
  const handleSplitAmountChange = (e, index) => {
    const updatedRows = splitRows.map((row, idx) =>
      idx === index ? { ...row, amount: e.target.value } : row
    );
    setSplitRows(updatedRows);
  };
  const handleSplitAmountBlur = () => {
    const totalAmount = calculateDifference();
    const remainingAmount = selectedTransaction.amount - totalAmount;

    if (splitRows.length > 1) {
      const updatedRows = [...splitRows];
      updatedRows[updatedRows.length - 1].amount = remainingAmount.toFixed(2);
      setSplitRows(updatedRows);
    }
  };
  const calculateDifference = () => {
    return splitRows
      .slice(0, -1)
      .reduce((acc, curr) => acc + (parseFloat(curr.amount) || 0), 0);
  };
  const removeSplitRow = (index) => {
    if (splitRows.length > 2) {
      const updatedRows = splitRows.filter((_, idx) => idx !== index);
      setSplitRows(updatedRows);
    }
  };
  const calculateTotal = () => {
    return splitRows
      .reduce((acc, curr) => acc + (parseFloat(curr.amount) || 0), 0)
      .toFixed(2);
  };
  const handleDeleteCheckboxChange = (e) => {
    setIsDeleteChecked(e.target.checked);
  };
  const handleProgressBarClick = (categoryId) => {
    setActiveCategoryId((prev) => (prev === categoryId ? null : categoryId));
  };
  const formatDateForInput = (isoDate) => {
    const date = new Date(isoDate);
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split("T")[0];
  };
  const renderTransactionsTable = (category) => {
    let transactions = category.transactions;
    if (!category.parent_id) {
      const children = categories.filter(
        (cat) => cat.parent_id === category.category_id
      );
      transactions = children.reduce(
        (acc, child) => [...acc, ...child.transactions],
        transactions
      );
    }
    if (transactions.length === 0) {
      return (
        <div className="noTransactionsMessage">No transactions available</div>
      );
    }
    if (isMobile) {
      return transactions.map((transactionRow) => {
        return (
          <MobileTransactionRow
            rowData={transactionRow}
            actionButtons={<EditButton rowData={transactionRow} />}
          />
        );
      });
    }
    return (
      <div className="gridContainer">
        <DataGrid
          rows={transactions}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 25, 100]}
          getRowId={(row) => row.pachira_transaction_id}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
    );
  };

  const getAggregatedData = (category) => {
    let totalSpent = 0;
    let totalBudget = 0;

    if (category.parent_id) {
      const parentCategory = categories.find(
        (cat) => cat.category_id === category.parent_id
      );
      if (parentCategory && !parentCategory.parent_budget) {
        totalSpent = category.spent;
        totalBudget = convertAmountBasedOnPeriod(
          parseFloat(category.amount),
          category.frequency
        );
      } else {
        totalSpent = category.spent;
        totalBudget = category.spent;
      }
    } else {
      if (category.parent_budget) {
        totalBudget = convertAmountBasedOnPeriod(
          parseFloat(category.amount),
          category.frequency
        );

        const children = categories.filter(
          (cat) => cat.parent_id === category.category_id
        );
        totalSpent = children.reduce((sum, child) => sum + child.spent, 0);
      } else {
        const children = categories.filter(
          (cat) => cat.parent_id === category.category_id
        );
        children.forEach((child) => {
          totalSpent += child.spent;
          totalBudget += convertAmountBasedOnPeriod(
            parseFloat(child.amount),
            child.frequency
          );
        });
      }
    }

    return { totalSpent, totalBudget };
  };
  const shadeColor = (color, amount = 10) => {
    let r = parseInt(color.substring(1, 3), 16);
    let g = parseInt(color.substring(3, 5), 16);
    let b = parseInt(color.substring(5, 7), 16);

    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          console.error(
            "Unexpected case in color conversion: max does not match any RGB component."
          );
          h = 0;
          break;
      }
      h /= 6;
    }

    l = Math.min(1, l + amount / 100);

    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);

    const toHex = (x) => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };
  const colors = [
    "#1e40af", // Dark Vibrant Blue
    "#c2410c", // Dark Vibrant Orange
    "#047857", // Dark Vibrant Green
    "#b91c1c", // Dark Vibrant Red
    "#5b21b6", // Dark Vibrant Purple
    "#b45309", // Dark Vibrant Yellow
    "#9d174d", // Dark Vibrant Pink
    "#4338ca", // Dark Vibrant Indigo
    "#0d9488", // Dark Vibrant Teal
    "#831843", // Dark Vibrant Magenta
  ];

  const groupCategories = () => {
    const categoryGroups = {};
    let colorIndex = 0;

    categories.forEach((category) => {
      const { category_id, parent_id } = category;

      if (parent_id === null) {
        categoryGroups[category_id] = {
          subcategories: {},
          color: colors[colorIndex],
        };
        colorIndex = (colorIndex + 1) % colors.length;
      }
    });

    categories.forEach((category) => {
      const { category_id, parent_id } = category;

      if (categoryGroups[parent_id]) {
        const parentColor = categoryGroups[parent_id].color;
        category.color = shadeColor(parentColor, 0);
        categoryGroups[parent_id].subcategories[category_id] = {};
        categoryGroups[parent_id].subcategories[category_id].color = shadeColor(
          parentColor,
          6 * Object.keys(categoryGroups[parent_id].subcategories).length + 10
        );
      }
      if (categoryGroups[category_id]) {
        const parentColor = categoryGroups[category_id].color;
        category.color = shadeColor(parentColor, 0);
        categoryGroups[category_id].subcategories[category_id] = {};
        categoryGroups[category_id].subcategories[category_id].color =
          parentColor;
      }
    });

    return categoryGroups;
  };
  const colorCategories = groupCategories();

  const getCategoryColor = (category) => {
    if (category.parent_id) {
      return colorCategories[category.parent_id].subcategories[
        category.category_id
      ].color;
    } else if (colorCategories[category.category_id]) {
      return colorCategories[category.category_id].subcategories[
        category.category_id
      ].color;
    }

    return "#1e40af";
  };

  const renderProgressBar = (
    category,
    isChild = false,
    parentBudgetFlag = false
  ) => {
    let progress, totalSpent, totalBudget;
    if (isChild && parentBudgetFlag) {
      totalSpent = totalBudget = category.spent;
      progress = 100;
    } else {
      ({ totalSpent, totalBudget } = getAggregatedData(category));
      progress =
        totalBudget > 0 ? Math.max(0, (totalSpent / totalBudget) * 100) : 0;
    }

    const barColor = getCategoryColor(category);

    const amountLeft = totalBudget - totalSpent;
    const isNegative = amountLeft < 0;

    return (
      <div
        className="progressContainer"
        style={{
          backgroundColor: isChild ? "#f0f0f0" : "#e0e0e0",
          marginTop: isChild ? "5px" : "10px",
        }}
        key={category.category_id}
      >
        <div className="progressBarBudget">
          <div
            className="filledProgressBar"
            style={{
              width: `${Math.max(0.5, Math.min(100, progress))}%`,
              backgroundColor: barColor,
            }}
          ></div>
          <div className="progressBarTextContainer">
            <span className="progressBarTextLeft">
              {formatCurrency(totalSpent)}
            </span>
            <span className="progressBarTextMiddle">
              {category.category_name}
            </span>
            <span
              className="remainingBudget"
              style={{ color: isNegative ? "red" : "inherit" }}
            >
              {`${formatCurrency(amountLeft)} Left`}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderTotalProgressBar = () => {
    let totalSpent = 0;
    let totalBudget = 0;

    categories.forEach((category) => {
      if (!category.parent_id) {
        const { totalSpent: categorySpent, totalBudget: categoryBudget } =
          getAggregatedData(category);
        totalSpent += categorySpent;
        totalBudget += categoryBudget;
      }
    });

    const progress =
      totalBudget > 0 ? Math.max(0, (totalSpent / totalBudget) * 100) : 0;

    return (
      <div
        className="progressContainer"
        style={{
          backgroundColor: "#e0e0e0",
          marginTop: "10px",
        }}
        key="total-progress"
      >
        <div className="progressBarBudget">
          <div
            className="filledProgressBar"
            style={{
              width: `${Math.min(100, progress)}%`,
              backgroundColor: "#001e80",
            }}
          ></div>

          <div className="progressBarTextContainer">
            <span className="progressBarTextLeft">
              {formatCurrency(totalSpent)}
            </span>
            <span className="progressBarTextMiddle">Total</span>
            <span
              className="remainingBudget"
              style={{ color: progress > 100 ? "red" : "inherit" }}
            >
              {`${formatCurrency(totalBudget - totalSpent)} Left`}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderGroupedProgressBars = () => {
    return categories
      .filter((category) => !category.parent_id)
      .map((parentCategory) => (
        <div key={parentCategory.category_id} className="cardStyle">
          <div
            onClick={() => handleProgressBarClick(parentCategory.category_id)}
          >
            {renderProgressBar(parentCategory)}
          </div>
          {activeCategoryId === parentCategory.category_id &&
            renderTransactionsTable(parentCategory)}

          {categories
            .filter((cat) => cat.parent_id === parentCategory.category_id)
            .map((childCategory) => (
              <div key={childCategory.category_id}>
                <div
                  onClick={() =>
                    handleProgressBarClick(childCategory.category_id)
                  }
                >
                  {renderProgressBar(
                    childCategory,
                    true,
                    parentCategory.parent_budget
                  )}
                </div>
                {activeCategoryId === childCategory.category_id &&
                  renderTransactionsTable(childCategory)}
              </div>
            ))}
        </div>
      ));
  };

  const handleEditClick = (rowData) => {
    setSelectedTransaction(rowData);
    setShowEditModal(true);
  };

  const handleModalSubmit = async () => {
    setIsLoading(true);
    let splitPayload;
    if (splits) {
      const totalAmount = splitRows.reduce(
        (sum, row) => sum + parseFloat(row.amount || 0),
        0
      );

      if (totalAmount !== parseFloat(selectedTransaction.amount)) {
        alert(
          "The total split amounts must equal the original transaction amount."
        );
        setIsLoading(false);
        return;
      }

      for (const row of splitRows) {
        if (!row.category_id) {
          alert("Each split must have a category selected.");
          setIsLoading(false);
          return;
        }
      }
      splitPayload = splitRows.map((row) => ({
        pachira_transaction_id: selectedTransaction.pachira_transaction_id,
        category_id: row.category_id,
        amount: parseFloat(row.amount).toFixed(2),
        notes: row.notes,
      }));
    }
    if (splits) {
      await updateTransactionSplit(splitPayload, dispatch);
    }
    const categoryIDNumber = parseInt(transactionCategory);

    const updatedSplits = splitRows.map((row) => ({
      split_category_id: parseInt(row.category_id),
      split_amount: parseFloat(row.amount).toFixed(2),
      split_notes: row.notes,

      split_category_name: categories.find(
        (cat) => cat.category_id === parseInt(row.category_id)
      )?.category_name,
    }));

    let updatedTransaction = {
      ...selectedTransaction,
      pachira_category_id:
        categoryIDNumber !== "split" ? categoryIDNumber : null,
      merchant_name: merchant,
      notes,
      memo,
      date,
      amount: parseFloat(amount),
      isDeleted: isDeleteChecked,
      category_name: categories.find(
        (cat) => cat.category_id === categoryIDNumber
      )?.category_name,
      splits: splits ? updatedSplits : [],
    };

    try {
      await updateTransaction(updatedTransaction, dispatch);
      dispatch({
        type: ACTION_TYPES.DELETE_TRANSACTIONS,
        payload: [updateTransaction.pachira_transaction_id],
      });
      for (let category of categories) {
        if (category.category_id === updatedTransaction.pachira_category_id) {
          const transactionIndex = category.transactions.findIndex(
            (t) =>
              t.pachira_transaction_id ===
              updatedTransaction.pachira_transaction_id
          );
          if (transactionIndex !== -1) {
            if (updatedTransaction.isDeleted) {
              category.transactions.splice(transactionIndex, 1);
            } else {
              category.transactions[transactionIndex] = updatedTransaction;
            }
            break;
          }
        }
      }
      setIsLoading(false);
      setShowEditModal(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating transaction:", error);
    }
  };

  const convertAmountBasedOnPeriod = (amount, frequency) => {
    switch (frequency) {
      case "W":
        return activePeriod === "week"
          ? amount
          : activePeriod === "month"
          ? amount * 4.33
          : amount * 52;
      case "M":
        return activePeriod === "week"
          ? amount / 4.33
          : activePeriod === "month"
          ? amount
          : amount * 12;
      case "Y":
        return activePeriod === "week"
          ? amount / 52
          : activePeriod === "month"
          ? amount / 12
          : amount;
      default:
        return amount;
    }
  };
  const EditButton = ({ rowData }) => {
    return (
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.primary.main,
          px: 1,
          py: 0.25,
        }}
        onClick={() => handleEditClick(rowData)}
      >
        Edit
      </Button>
    );
  };
  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 90,
      renderCell: (params) => format(parseISO(params.value), "MM/dd/yyyy"),
      sortComparator: (v1, v2, param1, param2) => {
        return (
          new Date(param1.value).getTime() - new Date(param2.value).getTime()
        );
      },
    },

    {
      field: "name",
      headerName: "Memo",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "merchant_name",
      headerName: "Merchant",
      flex: 2,
      minWidth: 100,
      maxWidth: 200,
    },
    {
      field: "category_name",
      headerName: "Category",
      flex: 1,
      minWidth: 60,
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      flex: 1,
      minWidth: 100,
      renderCell: ({ value }) => {
        const amount = parseFloat(value);
        const isNegative = amount < 0;
        return (
          <span style={{ color: isNegative ? "red" : "green" }}>
            {formatCurrency(amount)}
          </span>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => {
        return <EditButton rowData={params.row} />;
      },
      sortable: false,
      width: 80,
    },
  ];
  const getSortedAndGroupedCategories = () => {
    const typeOrder = { INC: 1, TRA: 2, EXP: 3 };
    let groupedCategories = [];
    let sortedLevel1Categories = categories
      .filter((cat) => cat.level === 1)
      .sort((a, b) => {
        const typeComparison = typeOrder[a.type] - typeOrder[b.type];
        if (typeComparison !== 0) {
          return typeComparison;
        }
        return a.category_name.localeCompare(b.category_name);
      });
    sortedLevel1Categories.forEach((level1Cat) => {
      let subCategories = categories
        .filter((cat) => cat.parent_id === level1Cat.category_id)
        .sort((a, b) => a.category_name.localeCompare(b.category_name));

      if (subCategories.length > 0) {
        groupedCategories.push({
          parent: level1Cat,
          children: subCategories,
        });
      }
    });

    return groupedCategories;
  };
  const renderDropdownOptions = () => {
    const groupedCategories = getSortedAndGroupedCategories();

    return groupedCategories.flatMap((group) => {
      return [
        <option
          key={group.parent.category_id}
          value={group.parent.category_id}
          disabled
          className="optionGroup"
        >
          {group.parent.category_name}
        </option>,
        ...group.children.map((subCategory) => (
          <option
            key={subCategory.category_id}
            value={subCategory.category_id}
            className="option"
          >
            {subCategory.category_name}
          </option>
        )),
      ];
    });
  };
  return (
    <div className="tableContainer">
      {renderGroupedProgressBars()}
      <div className="cardStyle">{renderTotalProgressBar()}</div>
      {showEditModal && (
        <Modal
          open={showEditModal}
          onClose={() => {
            setShowEditModal(false);
            setSplits(false);
            setSplitRows([
              { category_id: "", amount: "", notes: "" },
              { category_id: "", amount: "", notes: "" },
            ]);
          }}
          title="Edit Transaction"
        >
          <div className="modalContent">
            <input
              type="date"
              value={
                selectedTransaction.date
                  ? formatDateForInput(selectedTransaction.date)
                  : ""
              }
              onChange={(e) => setDate(e.target.value)}
              className="modalInput"
            />

            <label className="modalLabel">
              Memo
              <input
                type="text"
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
                className="modalInput"
              />
            </label>

            <label className="modalLabel">
              Merchant
              <input
                type="text"
                value={merchant}
                onChange={(e) => setMerchant(e.target.value)}
                className="modalInput"
              />
            </label>
            <label className="modalLabel">
              Category
              <select
                value={transactionCategory}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setTransactionCategory(selectedValue);
                  if (selectedValue === "split") {
                    setSplits(true);
                  } else {
                    setSplits(false);
                  }
                }}
                className="modalInput"
              >
                <option value="">Select Category</option>
                <option value="split">Split</option>
                {renderDropdownOptions()}
              </select>
            </label>
            {(selectedTransaction.splits > 0 || splits) && (
              <>
                <div className="card">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Amount</th>
                        <th>Notes</th>
                        <th style={{ width: "30px" }}> </th>{" "}
                      </tr>
                    </thead>
                    <tbody>
                      {splitRows.map((split, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              value={split.category_id}
                              onChange={(e) =>
                                handleSplitCategoryChange(e, index)
                              }
                              className="modalSelect2"
                            >
                              <option value="">Select Category</option>
                              {renderDropdownOptions()}
                            </select>
                          </td>
                          <td>
                            <input
                              type="number"
                              value={split.amount}
                              onChange={(e) =>
                                handleSplitAmountChange(e, index)
                              }
                              className="modalInput"
                              onBlur={() => handleSplitAmountBlur()}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={split.notes}
                              onChange={(e) => handleSplitNotesChange(e, index)}
                              className="modalInput"
                            />
                          </td>
                          <td>
                            {splitRows.length > 2 && (
                              <button
                                className="removeButton"
                                onClick={() => removeSplitRow(index)}
                                aria-label="Remove row"
                              >
                                X
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr className="tableFooter">
                        <td>Total</td>
                        <td>{calculateTotal()}</td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <button className="whiteButton" onClick={addSplitRow}>
                  Add Split
                </button>
              </>
            )}

            <label className="modalLabel">
              Amount
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="modalInput"
              />
            </label>
            <label className="modalLabel">
              Notes
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="modalInput"
              />
            </label>
            <label className="modalLabel warning">
              <input
                type="checkbox"
                checked={selectedTransaction.isDeleted}
                className="modalCheckbox "
                onChange={handleDeleteCheckboxChange}
              />
              DELETE THIS TRANSACTION
            </label>

            {isLoading ? (
              <Spinner />
            ) : (
              <button className="fullLengthButton" onClick={handleModalSubmit}>
                Submit
              </button>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};
export default BudgetProgressBar;
