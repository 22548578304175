import React, { createContext, useReducer } from "react";

export const AuthContext = createContext(null);
export const ACTION_TYPES = {
  SET_TOKEN_LOADING: "SET_TOKEN_LOADING",
  SET_TOKEN_EXPIRATION: "SET_TOKEN_EXPIRATION",
};
const initialState = {
  isLoading: false,
  tokenExpiryTime: 0,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_TOKEN_LOADING:
      return { ...state, isLoading: action.payload };
    case ACTION_TYPES.SET_TOKEN_EXPIRATION:
      return { ...state, tokenExpiryTime: action.payload };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
