import React, { useContext, useEffect, useState, useRef } from "react";
import { AccountsContext } from "../context/AccountsContext";

const NotificationContainer = () => {
  const { state } = useContext(AccountsContext);
  const [notifications, setNotifications] = useState([]);

  const initialStates = useRef({
    transactions: { loading: false, updating: false },
    recurringTransactions: { loading: false, updating: false },
    categories: { loading: false, updating: false },
    defaultCategories: { loading: false, updating: false },
    accounts: { loading: false, updating: false },
    rules: { loading: false, updating: false },
    parties: { loading: false, updating: false },
    accountBalancesOverTime: { loading: false, updating: false },
    investmentsOverTime: { loading: false, updating: false },
    investments: { loading: false, updating: false },
    subscriptions: { loading: false, updating: false },
    payments: { loading: false, updating: false },
  });

  useEffect(() => {
    const camelCaseToTitleCase = (camelCase) => {
      if (camelCase === "") return "";

      let titleCase = camelCase.replace(/([A-Z])/g, " $1");
      return titleCase.charAt(0).toUpperCase() + titleCase.slice(1);
    };

    const createNotification = (identifier, type, isError = false) => {
      let message, color;

      let formattedIdentifier = camelCaseToTitleCase(identifier);

      switch (type) {
        case "loading":
        case "updating":
          message = `${formattedIdentifier} are ${type}...`;
          color = "#add8e6";
          break;
        case "success":
          message = `${formattedIdentifier} loaded successfully`;
          color = "#98FB98";
          break;
        case "updated":
          message = `${formattedIdentifier} updated successfully`;
          color = "#98FB98";
          break;
        default:
      }
      if (isError) {
        message = `Error ${type} ${formattedIdentifier} - Retry Pending`;
        color = "#f08080";
      }
      return {
        id: `${identifier}-${type}${isError ? "-error" : ""}`,
        message,
        color,
        type,
      };
    };
    const handleNotification = (
      identifier,
      loading,
      updating,
      loaded,
      updated,
      error
    ) => {
      if (error) {
        const notifId = `${identifier}-${
          loading ? "loading" : "updating"
        }-error`;
        setNotifications((prev) => [
          ...prev,
          createNotification(
            identifier,
            loading ? "loading" : "updating",
            true
          ),
        ]);
        setTimeout(() => removeNotification(notifId), 2000);
      }
      if (loading) {
        initialStates.current[identifier].loading = true;
        setNotifications((prev) => [
          ...prev,
          createNotification(identifier, "loading"),
        ]);
      }
      if (updating) {
        initialStates.current[identifier].updating = true;
        setNotifications((prev) => [
          ...prev,
          createNotification(identifier, "updating"),
        ]);
      }

      if (loaded && initialStates.current[identifier].loading) {
        const notifId = `${identifier}-success`;
        setNotifications((prev) => [
          ...prev,
          createNotification(identifier, "success"),
        ]);
        setTimeout(() => removeNotification(notifId), 2000);
      }
      if (updated && initialStates.current[identifier].updating) {
        const notifId = `${identifier}-updated`;
        setNotifications((prev) => [
          ...prev,
          createNotification(identifier, "updated"),
        ]);
        setTimeout(() => removeNotification(notifId), 2000);
      }
    };

    setNotifications([]);

    handleNotification(
      "transactions",
      state.transactionsLoading,
      state.transactionsUpdating,
      state.transactionsLoaded,
      state.transactionsUpdated,
      state.errorFields.transactions
    );
    handleNotification(
      "recurringTransactions",
      state.recurringTransactionsLoading,
      state.recurringTransactionsUpdating,
      state.recurringTransactionsLoaded,
      state.recurringTransactionsUpdated,
      state.errorFields.recurringTransactions
    );
    handleNotification(
      "categories",
      state.categoriesLoading,
      state.categoriesUpdating,
      state.categoriesLoaded,
      state.categoriesUpdated,
      state.errorFields.categories
    );

    handleNotification(
      "defaultCategories",
      state.defaultCategoriesLoading,
      false,
      state.defaultCategoriesLoaded,
      false,
      state.errorFields.defaultCategories
    );

    handleNotification(
      "accounts",
      state.accountsLoading,
      state.accountsUpdating,
      state.accountsLoaded,
      state.accountsUpdated,
      state.errorFields.accounts
    );

    handleNotification(
      "rules",
      state.rulesLoading,
      state.rulesUpdating,
      state.rulesLoaded,
      state.rulesUpdated,
      state.errorFields.rules
    );

    handleNotification(
      "parties",
      state.partiesLoading,
      state.partiesUpdating,
      state.partiesLoaded,
      state.partiesUpdated,
      state.errorFields.parties
    );

    handleNotification(
      "accountBalancesOverTime",
      state.accountBalancesOverTimeLoading,
      false,
      state.accountBalancesOverTimeLoaded,
      false,
      state.errorFields.accountBalancesOverTime
    );

    handleNotification(
      "investmentsOverTime",
      state.investmentsOverTimeLoading,
      false,
      state.investmentsOverTimeLoaded,
      false,
      state.errorFields.investmentsOverTime
    );

    handleNotification(
      "investments",
      state.investmentsLoading,
      false,
      state.investmentsLoaded,
      false,
      state.errorFields.investments
    );
    handleNotification(
      "subscriptions",
      state.subscriptionsLoading,
      state.subscriptionsUpdating,
      state.subscriptionsLoaded,
      state.subscriptionsUpdated,
      state.errorFields.subscriptions
    );

    handleNotification(
      "payments",
      state.paymentsLoading,
      state.paymentsUpdating,
      state.paymentsLoaded,
      state.paymentsUpdated,
      state.errorFields.payments
    );
    const removeNotification = (id) => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== id)
      );

      const [identifier, type] = id.split("-");

      updateStatusMemo(type, identifier);
    };
  }, [state]);

  const updateStatusMemo = (type, identifier) => {
    if (type === "loading") {
      initialStates.current[identifier].loading = false;
    } else if (type === "updating") {
      initialStates.current[identifier].updating = false;
    }

    if (type === "success" || type === "updated") {
      initialStates.current[identifier].loading = false;
      initialStates.current[identifier].updating = false;
    }
  };

  return (
    <div style={{ position: "fixed", top: "10px", right: "10px", zIndex: 997 }}>
      {notifications.map((notification) => (
        <div
          key={notification.id}
          style={{
            marginBottom: "10px",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: notification.color,
            color: "black",
            width: "250px",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
          }}
        >
          {notification.message}
          {(notification.type === "loading" ||
            notification.type === "updating") &&
            !notification.id.includes("error") && <LoadingBar />}
          {notification.type === "success" && <SuccessLoadingBar />}
          {notification.id.includes("error") && <ErrorLoadingBar />}
        </div>
      ))}
    </div>
  );
};
const LoadingBar = () => (
  <div
    style={{
      height: "5px",
      width: "100%",
      backgroundColor: "#eeeeee",
      marginTop: "5px",
      overflow: "hidden",
    }}
  >
    <div
      style={{
        height: "100%",
        width: "50%",
        backgroundColor: "#d3d3d3",
        animation: "loadingAnimation 1.5s infinite",
      }}
    />
    <style>{`
        @keyframes loadingAnimation {
          0% { transform: translateX(-100%); }
          100% { transform: translateX(100%); }
        }
      `}</style>
  </div>
);
const SuccessLoadingBar = () => (
  <div
    style={{
      height: "5px",
      width: "100%",
      backgroundColor: "#98FB98",
      marginTop: "5px",
      overflow: "hidden",
    }}
  >
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#32CD32",
        animation: "successLoadingAnimation 2s",
      }}
    />
    <style>{`
        @keyframes successLoadingAnimation {
          0% { transform: translateX(-100%); }
          100% { transform: translateX(100%); }
        }
      `}</style>
  </div>
);

const ErrorLoadingBar = () => (
  <div
    style={{
      height: "5px",
      width: "100%",
      backgroundColor: "#f08080",
      marginTop: "5px",
      overflow: "hidden",
    }}
  >
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#FF0000",
        animation: "errorLoadingAnimation 2s",
      }}
    />
    <style>{`
        @keyframes errorLoadingAnimation {
          0% { transform: translateX(-100%); }
          100% { transform: translateX(100%); }
        }
      `}</style>
  </div>
);

export default NotificationContainer;
