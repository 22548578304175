import React, { useCallback, useState, useEffect, useContext } from "react";
import { CurrencyContext } from "../context/currencyContext";
import ConnectButton from "../components/connectButton";
import Modal from "../components/modal";
import TipContainer from "../components/tipContainer";
import YodleeModal from "../components/yodleeModal";
import "../App.css";
import APIService from "../components/api";
import { AccountsContext } from "../context/AccountsContext";
import { AuthContext } from "../authentication/authenticationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import OverlayModal from "../components/OverlayModal";
const Accounts = () => {
  const theme = useTheme();
  const { fetchAccountsContext, updateAccount } = APIService();
  const { dispatch: authDispatch } = useContext(AuthContext);
  const { formatCurrency } = useContext(CurrencyContext);
  const { state, dispatch } = useContext(AccountsContext);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingAccount, setEditingAccount] = useState(null);
  const [accountNameOption, setAccountNameOption] = useState("bankName");
  const [isAccountHidden, setIsAccountHidden] = useState(false);
  const [isAccountRemoved, setIsAccountRemoved] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const resetModalState = useCallback(() => {
    setAccountNameOption("defaultOption");
    setEditingAccount({});
    setIsAccountHidden(false);
    setIsAccountRemoved(false);
    setIsModalOpen(false);
  }, []);
  const getAccountData = useCallback(async () => {
    if (!state.accountsLoaded && !state.accountsLoading) {
      fetchAccountsContext();
    }
  }, [fetchAccountsContext, state.accountsLoaded, state.accountsLoading]);
  const refreshAccountsAndCloseModal = useCallback(() => {
    resetModalState();
  }, [resetModalState]);

  const handleSaveChanges = async () => {
    let preferredName = accountNameOption;
    let customAccountName =
      accountNameOption === "custom" ? editingAccount?.custom_name : null;

    const bodyData = {
      pachira_account_uuid: editingAccount.pachira_account_uuid,
      preferred_name: preferredName,
      custom_name: customAccountName,
      isAccountHidden,
      source: editingAccount?.source,
      source_id: editingAccount?.source_id,
      isAccountRemoved,
    };

    try {
      await updateAccount(bodyData, dispatch, authDispatch);

      refreshAccountsAndCloseModal();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };
  const handleOpenModal = (accountId) => {
    console.log("first id check", accountId);
    setCurrentAccountId(accountId);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setCurrentAccountId(null);
  };
  const handleEditAccount = (account) => {
    setEditingAccount({
      ...account,
      custom_name: account.custom_name || "",
    });
    setAccountNameOption(account.preferred_name || "name");

    setIsModalOpen(true);
  };

  useEffect(() => {
    document.title = "Accounts - Pachira";
    getAccountData();
  }, [getAccountData]);

  const groupAccounts = (accounts) => {
    const sortedAccounts = accounts.sort((a, b) => {
      if (a.type !== b.type) {
        return a.type === "asset" ? -1 : 1;
      }

      if (a.is_liquid !== b.is_liquid) {
        return a.is_liquid ? -1 : 1;
      }

      if (a.group !== b.group) {
        return a.group.localeCompare(b.group);
      }

      return a.name.localeCompare(b.name);
    });

    return sortedAccounts.reduce((acc, account) => {
      const typeKey = account.group;
      acc[typeKey] = acc[typeKey] || [];
      acc[typeKey].push(account);
      return acc;
    }, {});
  };

  const getTotalBalance = (accounts) => {
    return accounts.reduce(
      (sum, account) => sum + parseFloat(account.balance_current),
      0
    );
  };
  const getTotalAssetsAndLiabilities = (accounts) => {
    return accounts.reduce(
      (acc, account) => {
        if (account.type === "asset") {
          acc.assets += parseFloat(account.balance_current);
        } else {
          acc.liabilities += parseFloat(account.balance_current);
        }
        return acc;
      },
      { assets: 0, liabilities: 0 }
    );
  };
  const groupedAccounts = groupAccounts(state.accounts);
  console.log(groupedAccounts);
  const getTotalBalanceByGroup = (groupedAccounts) => {
    return Object.keys(groupedAccounts).reduce((acc, group) => {
      acc[group] = groupedAccounts[group].reduce(
        (sum, account) => sum + parseFloat(account.balance_current),
        0
      );
      return acc;
    }, {});
  };

  const balanceByGroup = getTotalBalanceByGroup(groupedAccounts);

  const { assets, liabilities } = getTotalAssetsAndLiabilities(state.accounts);
  const totalBalance = getTotalBalance(state.accounts);
  const handleOpenInfoModal = () => {
    setInfoModalOpen(true);
  };

  const handleCloseInfoModal = () => {
    setInfoModalOpen(false);
  };
  return (
    <div className="dashboard">
      <div className="header">
        <Stack direction="row" alignItems="center" spacing={2}>
          <h1>Accounts</h1>

          <Tooltip title="Info">
            <FontAwesomeIcon
              id="info-button"
              icon={faCircleInfo}
              onClick={handleOpenInfoModal}
            />
          </Tooltip>
        </Stack>
        <div className="iconCard">
          <ConnectButton onAccountsUpdated={refreshAccountsAndCloseModal} />
        </div>
      </div>
      <OverlayModal open={infoModalOpen} onClose={handleCloseInfoModal}>
        <Stack px={2}>
          <Typography variant="h6" color={theme.palette.primary.main}>
            Quick Tips: Efficient Transaction Management
          </Typography>
          <ul className="tipList">
            <li>
              To add an account, click the "Connect Account" button in the top
              right corner.
            </li>
            <li>
              All your accounts and assets will be listed here. Account balances
              are set when you add an account and will update based on
              transactions you add later on.
            </li>
          </ul>
        </Stack>
      </OverlayModal>
      <div className="accounts-display">
        {Object.keys(groupedAccounts).map((group) => (
          <div key={group} className="group">
            <h2 className="groupTitle">{group}</h2>
            <ul className="accountList">
              {groupedAccounts[group].map((account) => (
                <li key={account.pachira_account_uuid} className="accountItem">
                  <div className="accountText">
                    {!account.preferred_name && account.name}
                    {account.preferred_name === "name" && account.name}
                    {account.preferred_name === "nickname" && account.nickname}
                    {account.preferred_name === "custom" && account.custom_name}
                    {" - "}
                    Balance: {formatCurrency(account.balance_current)}
                  </div>

                  <div className="accountActions">
                    {account.source !== "Manual" &&
                      (account.update ? (
                        <button
                          className="warning whiteButton"
                          style={{ color: "red" }}
                          onClick={() =>
                            handleOpenModal(account.provider_account_id)
                          }
                        >
                          Update Needed
                        </button>
                      ) : (
                        <button
                          className="whiteButton"
                          style={{ color: "black" }}
                          onClick={() =>
                            handleOpenModal(account.provider_account_id)
                          }
                        >
                          Update Login
                        </button>
                      ))}
                    <button
                      className="whiteButton"
                      onClick={() => handleEditAccount(account)}
                    >
                      Edit
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <h3>
              Total in {group}: {formatCurrency(balanceByGroup[group])}
            </h3>
          </div>
        ))}
      </div>
      {modalIsOpen && (
        <YodleeModal
          isOpen={modalIsOpen}
          onClose={handleCloseModal}
          accountId={currentAccountId}
        />
      )}
      <div className="totals">
        <div className="totalRow">
          Total Liabilities: {formatCurrency(liabilities)}
        </div>
        <div className="totalRow">Total Assets: {formatCurrency(assets)}</div>
        <div className="totalRow">
          Total Balance: {formatCurrency(totalBalance)}
        </div>
      </div>
      {isModalOpen && (
        <Modal title="Edit Account" onClose={() => resetModalState()}>
          <div className="modalContent">
            <div className="cardStyle">
              <label className="label">Account Name:</label>
              <div className="modalRadioGroup">
                <div className="radioOption">
                  <input
                    type="radio"
                    id="radioName"
                    name="accountNameOption"
                    value="name"
                    checked={accountNameOption === "name"}
                    onChange={() => setAccountNameOption("name")}
                  />
                  <label htmlFor="radioName">
                    Bank Name: {editingAccount?.name}
                  </label>
                </div>
                <div className="radioOption">
                  <input
                    type="radio"
                    id="radioNickname"
                    name="accountNameOption"
                    value="nickname"
                    checked={accountNameOption === "nickname"}
                    onChange={() => setAccountNameOption("nickname")}
                  />
                  <label htmlFor="radioNickname">
                    Bank Nickname: {editingAccount?.nickname}
                  </label>
                </div>
                <div className="radioOption">
                  <input
                    type="radio"
                    id="radioCustom"
                    name="accountNameOption"
                    value="custom"
                    checked={accountNameOption === "custom"}
                    onChange={() => setAccountNameOption("custom")}
                  />
                  <label htmlFor="radioCustom">
                    Custom Name:
                    {accountNameOption === "custom" && (
                      <input
                        type="text"
                        className="modalInput"
                        value={editingAccount?.custom_name}
                        onChange={(e) =>
                          setEditingAccount({
                            ...editingAccount,
                            custom_name: e.target.value,
                          })
                        }
                      />
                    )}
                  </label>
                </div>
              </div>
            </div>

            <div className="modalCheckboxGroup">
              <input
                type="checkbox"
                id="hideAccount"
                name="hideAccount"
                onChange={(e) => setIsAccountHidden(e.target.checked)}
              />
              <label htmlFor="hideAccount">Hide account</label>
            </div>
            <div className="modalCheckboxGroup">
              <input
                type="checkbox"
                id="removeAccount"
                name="removeAccount"
                onChange={(e) => setIsAccountRemoved(e.target.checked)}
              />
              <label htmlFor="removeAccount">Remove account</label>
            </div>
            {isAccountHidden && (
              <div className="warningText">
                Account will be hidden, but transactions will still be included
                in the budgets.
              </div>
            )}
            {isAccountRemoved && (
              <div className="dangerText">
                Removing the account will erase it and all of its related
                transactions, potentially affecting budgets, net worth, etc.
              </div>
            )}
            <button className="fullLengthButton" onClick={handleSaveChanges}>
              Save
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Accounts;
