import React, { useContext, useState } from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CollapsibleContext } from "./collapsibleContext";
import { format, parseISO } from "date-fns";
import Modal from "./modal";
import AdComponent from "./adComponent";
import { useNavigate } from "react-router-dom";
import releaseNotes from "../versioning/releaseNotes.json";
import {
  faHome,
  faChartLine,
  faSignOutAlt,
  faUniversity,
  faExchangeAlt,
  faPiggyBank,
  faBriefcase,
  faTools,
  faMoneyBillWave,
  faUsers,
  faRepeat,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../images/TransparentLogo.png";
import brand from "../images/Pachira Full Logo Transparent White.png";
import APIService from "../components/api";

const Sidebar = () => {
  const { logoutUser } = APIService();
  const navigate = useNavigate();
  const { toggleCollapse, isCollapsed } = useContext(CollapsibleContext);
  const [isReleaseNotesOpen, setIsReleaseNotesOpen] = useState(false);
  const latestVersion = Object.keys(releaseNotes).pop();
  const openReleaseNotes = () => setIsReleaseNotesOpen(true);
  const closeReleaseNotes = () => setIsReleaseNotesOpen(false);
  const renderReleaseNotes = () => {
    return (
      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
        {Object.entries(releaseNotes)
          .sort((a, b) =>
            b[0].localeCompare(a[0], undefined, { numeric: true })
          )
          .map(([version, details]) => (
            <li key={version}>
              <div>
                <h3>Version {version}</h3>
                <p>
                  <strong>Release Date:</strong>{" "}
                  {format(parseISO(details.releaseDate), "MMMM dd, yyyy, p")}
                </p>
                <ul>
                  {details.notes.map((note, index) => (
                    <li key={index}>{note}</li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
      </ul>
    );
  };

  const handleLogout = async () => {
    await logoutUser();
    localStorage.clear();
  };
  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <div className="logo-container">
        <img
          src={logo}
          alt="Pachira Logo"
          className={`logo ${isCollapsed ? "collapsed" : ""}`}
        />
      </div>
      <div className="brand-container">
        <img
          src={brand}
          alt="Pachira Logo"
          className={`brand ${isCollapsed ? "collapsed" : ""}`}
        />
      </div>
      <div className="partial-line"></div>

      <ul className="nav-menu">
        <li className="nav-item" onClick={() => navigate("/dashboard")}>
          <FontAwesomeIcon
            icon={faHome}
            className={`nav-icon ${!isCollapsed && "icon-margin"}`}
          />
          <span>Dashboard</span>
        </li>
      </ul>
      <div className="partial-line"></div>

      <ul className="nav-menu">
        <li className="group-title">BUDGETING</li>
        <li className="nav-item" onClick={() => navigate("/accounts")}>
          <FontAwesomeIcon
            icon={faUniversity}
            className={`nav-icon ${!isCollapsed && "icon-margin"}`}
          />
          <span>Accounts</span>
        </li>

        <li className="nav-item" onClick={() => navigate("/transactions")}>
          <FontAwesomeIcon
            icon={faExchangeAlt}
            className={`nav-icon ${!isCollapsed && "icon-margin"}`}
          />
          <span>Transactions</span>
        </li>
        <li className="nav-item" onClick={() => navigate("/budgets")}>
          <FontAwesomeIcon
            icon={faPiggyBank}
            className={`nav-icon ${!isCollapsed && "icon-margin"}`}
          />
          <span>Budgets</span>
        </li>
        <li
          className="nav-item"
          onClick={() => navigate("/recurringTransactions")}
        >
          <FontAwesomeIcon
            icon={faRepeat}
            className={`nav-icon ${!isCollapsed && "icon-margin"}`}
          />

          <span>Recurring</span>
        </li>
        <li className="nav-item" onClick={() => navigate("/cashFlow")}>
          <FontAwesomeIcon
            icon={faMoneyBillWave}
            className={`nav-icon ${!isCollapsed && "icon-margin"}`}
          />
          <span>Cash Flow</span>
        </li>
      </ul>
      <div className="partial-line"></div>
      <ul className="nav-menu">
        <li className="group-title">Investments</li>
        <li className="nav-item" onClick={() => navigate("/portfolio")}>
          <FontAwesomeIcon
            icon={faBriefcase}
            className={`nav-icon ${!isCollapsed && "icon-margin"}`}
          />
          <span>Portfolio</span>
        </li>
        <li className="nav-item" onClick={() => navigate("/investmentParties")}>
          <FontAwesomeIcon
            icon={faUsers}
            className={`nav-icon ${!isCollapsed && "icon-margin"}`}
          />
          <span>Investment Parties</span>
        </li>
        <li className="nav-item" onClick={() => navigate("/planning")}>
          <FontAwesomeIcon
            icon={faChartLine}
            className={`nav-icon ${!isCollapsed && "icon-margin"}`}
          />
          <span>Planning</span>
        </li>
      </ul>
      <div className="partial-line"></div>
      <ul className="nav-menu">
        <li className="group-title">Preferences</li>

        <li className="nav-item" onClick={() => navigate("/settings")}>
          <FontAwesomeIcon
            icon={faTools}
            className={`nav-icon ${!isCollapsed && "icon-margin"}`}
          />
          <span>Settings</span>
        </li>
        <li className="nav-item" onClick={handleLogout}>
          <FontAwesomeIcon
            icon={faSignOutAlt}
            className={`nav-icon ${!isCollapsed && "icon-margin"}`}
          />
          <span>Logout</span>
        </li>
      </ul>
      <ul className="nav-menu-bottom ">
        <button onClick={toggleCollapse} className="collapse-button">
          {isCollapsed ? ">" : "<"}
        </button>
        {!isCollapsed && <AdComponent />}
      </ul>
      <div className="buttons-container">
        <button className="version-link" onClick={openReleaseNotes}>
          Version: {latestVersion} Beta
        </button>
        <button
          className="policy-link"
          onClick={() =>
            window.open("https://pachira.abandm.com/documents", "_blank")
          }
        >
          Policies
        </button>
      </div>

      {isReleaseNotesOpen && (
        <Modal onClose={closeReleaseNotes} title="Release Notes">
          <div className="release-notes-modal-content">
            <div className="release-notes">{renderReleaseNotes()}</div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Sidebar;
