import React, { useEffect, useContext } from "react";
import "../App.css";
import Modal from "./modal";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ACTION_TYPES as AUTH_ACTIONS,
  AuthContext,
} from "../authentication/authenticationContext";
import APIService from "../components/api";

const TimeoutModal = ({ onClose }) => {
  const { logoutUser } = APIService();
  const navigate = useNavigate();
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    let countDown;
    countDown = setTimeout(async () => {
      if (authState.tokenExpiryTime === 1) {
        await logoutUser();
        localStorage.clear();
        return;
      }
      authDispatch({
        type: AUTH_ACTIONS.SET_TOKEN_EXPIRATION,
        payload: authState.tokenExpiryTime - 1,
      });
    }, 1000);

    return () => clearTimeout(countDown);
  }, [authState.tokenExpiryTime, authDispatch, logoutUser]);

  const handleKeepWorking = () => {
    onClose();
    navigate(location.pathname);
  };

  return (
    <Modal onClose={onClose} title={"Are you still there?"} description={""}>
      {authState.tokenExpiryTime > 0 ? (
        <>
          <p>
            You will be logged out in {authState.tokenExpiryTime} second
            {authState.tokenExpiryTime > 1 ? "s" : ""}
          </p>
          <button
            title="Keep Working"
            onClick={handleKeepWorking}
            className="fullLengthButton"
          >
            Keep Working
          </button>
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default TimeoutModal;
