import React from "react";

const formatCurrency = (number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  }).format(number);
};
const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  }).format(number);
};

export const CurrencyContext = React.createContext({
  formatCurrency,
  formatNumber,
});
