import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Modal from "../components/modal";
import { CurrencyContext } from "../context/currencyContext";
import SoftPopup from "./softPopup";
import APIService from "../components/api";

const PartyCard = ({ party }) => {
  const { fetchStockPrice, deleteParty } = APIService();
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [stockPrice, setStockPrice] = useState(party.price);
  const { formatCurrency, formatNumber } = useContext(CurrencyContext);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  useEffect(() => {
    let data = fetchStockPrice(party.stock_ticker);

    setStockPrice(data);

    const intervalId = setInterval(fetchStockPrice, 60000 * 15);

    return () => clearInterval(intervalId);
  }, [fetchStockPrice, party.stock_ticker]);

  const copyToClipboard = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).then(
        () => {
          setShowPopup(true);
          setPopupMessage("Party code copied to clipboard!");
        },
        (err) => console.error("Could not copy text:", err)
      );
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        setShowPopup(true);
        setPopupMessage("Party code copied to clipboard!");
      } catch (err) {
        console.error("Fallback: Could not copy text:", err);
      }
      document.body.removeChild(textArea);
    }
  };

  const leaveParty = async () => {
    const response = await deleteParty(party.party_code);
    if (response.message === "Successfully left the party.") {
      setShowLeaveModal(false);
    }
  };

  function formatPercentage(value) {
    if (value < 0.01) {
      return value.toExponential(2);
    } else {
      return value.toFixed(2);
    }
  }
  return (
    <>
      <div className="partyCard">
        <div className="partyHeader">
          <h2>
            {party.party_name} (TICKER: {party.stock_ticker})
          </h2>
          <div className="partyActions">
            <div
              className="copyCode"
              onClick={() => copyToClipboard(party.party_code)}
            >
              <FontAwesomeIcon icon={faCopy} />
              <span>Copy Code</span>
            </div>
            <button
              className="dangerButton"
              onClick={() => setShowLeaveModal(true)}
            >
              Leave Party
            </button>
          </div>
        </div>
        <div className="partyDetailsGrid">
          <div className="detail">
            <span>Total Shares Held By Party</span>
            <p>{formatNumber(party.stock_count)}</p>
          </div>
          <div className="detail">
            <span>Price</span>
            <p>{formatCurrency(stockPrice)}</p>
          </div>
          <div className="detail">
            <span>Total Value</span>
            <p>
              {formatCurrency((stockPrice * party.stock_count).toFixed(2))}
            </p>{" "}
          </div>
          <div className="detail">
            <span>Shareholders in Party</span>
            <p>{formatNumber(party.member_count.toFixed(2))}</p>
          </div>
          <div className="detail">
            <span>% of Outstanding Stocks</span>
            <p>
              {formatPercentage(
                party.stock_count / party.total_outstanding_stocks
              )}
              %
            </p>
          </div>
        </div>
      </div>

      {showPopup && (
        <SoftPopup message={popupMessage} onClose={() => setShowPopup(false)} />
      )}
      {showLeaveModal && (
        <Modal
          title="Leave Party"
          onClose={() => setShowLeaveModal(false)}
          description="Are you sure you want to leave? Your present and past investment data will be removed, and you will no longer be able to view the metrics of this party."
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              height: "100%",
            }}
          >
            <button className="dangerButton" onClick={leaveParty}>
              Confirm Leave
            </button>
            <button
              className="blackButton"
              onClick={() => setShowLeaveModal(false)}
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PartyCard;
