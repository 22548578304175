import React from "react";

const ModalLoading = ({ message }) => (
  <div className="modalLoading">
    <div className="modalContent">
      <p className="loadingText">{message}</p>
      <div className="Big-spinner"></div>
    </div>
  </div>
);

export default ModalLoading;
