import React, { useEffect, useState, useContext } from "react";
import Modal from "./modal";
import APIService from "./api";
import "../App.css";
import Spinner from "./spinner";
import { AccountsContext } from "../context/AccountsContext";

const RulesAdd = ({
  initialCategoryId = "",
  initialConditions = [],
  isOpen = false,
  onClose,
}) => {
  const {
    fetchAccountsContext,
    fetchCategoriesContext,
    fetchTransactionsContext,
    createRule,
  } = APIService();
  const { state } = useContext(AccountsContext);
  const [suggestedCategories, setSuggestedCategories] = useState([]);
  const [showModal, setShowModal] = useState(isOpen);
  const [isLoading, setIsLoading] = useState(false);
  const initialRuleData = {
    name: "",
    category_id: initialCategoryId,
    conditions:
      initialConditions.length > 0
        ? initialConditions
        : [{ target: "memo", comparison: "contains", value: "" }],
    auto_add: false,
  };

  const targetOptions = [
    { value: "memo", label: "Memo" },
    { value: "amount", label: "Amount" },
    { value: "suggested_category", label: "Suggested Category" },
    { value: "pachira_account_uuid", label: "Account" },
    { value: "merchant_name", label: "Merchant" },
  ];
  const [ruleData, setRuleData] = useState(initialRuleData);
  useEffect(() => {
    setShowModal(isOpen);
    setRuleData(initialRuleData);
  }, [isOpen]);

  useEffect(() => {
    if (!state.accountsLoaded && !state.accountsLoading) {
      fetchAccountsContext();
    }
    if (!state.categoriesLoaded && !state.categoriesLoading) {
      fetchCategoriesContext();
    }
    if (!state.transactionsLoaded && !state.transactionsLoading) {
      fetchTransactionsContext();
    }
    const suggestedCategories = state.transactions.map(
      (transaction) => transaction.suggested_category
    );

    const uniqueSortedSuggestedCategories = Array.from(
      new Set(suggestedCategories)
    )
      .filter((category) => category)
      .sort((a, b) => a.localeCompare(b));
    setSuggestedCategories(uniqueSortedSuggestedCategories);
  }, [
    state.accountsLoaded,
    state.categoriesLoaded,
    state.transactions,
    state.transactionsLoaded,
    state.accountsLoading,
    state.categoriesLoading,
    state.transactionsLoading,
  ]);
  const handleChange = (e, index, field) => {
    if (field) {
      const newConditions = [...ruleData.conditions];
      newConditions[index][field] = e.target.value;
      setRuleData({ ...ruleData, conditions: newConditions });
    } else {
      const { name, value } = e.target;
      setRuleData({ ...ruleData, [name]: value });
    }
  };
  const isFormComplete = () => {
    const { name, category_id, conditions, auto_add } = ruleData;

    if (!name.trim() || !category_id) {
      return false;
    }

    for (let condition of conditions) {
      if (
        !condition.target ||
        !condition.comparison ||
        condition.value === ""
      ) {
        return false;
      }
    }

    if (auto_add === undefined) {
      return false;
    }

    return true;
  };
  const addCondition = () => {
    setRuleData({
      ...ruleData,
      conditions: [
        ...ruleData.conditions,
        { target: "memo", comparison: "contains", value: "" },
      ],
    });
  };
  const getComparisonOptions = (target) => {
    switch (target) {
      case "memo":
      case "merchant_name":
        return [
          { value: "contains", label: "Contains" },
          { value: "matchesExactly", label: "Matches Exactly" },
          { value: "doesNotContain", label: "Does Not Contain" },
        ];
      case "amount":
        return [
          { value: "equals", label: "Equal To" },
          { value: "greaterThan", label: "Greater Than" },
          { value: "lessThan", label: "Less Than" },
          { value: "greaterThanOrEqualTo", label: "Greater Than Or Equal To" },
          { value: "lessThanOrEqualTo", label: "Less Than Or Equal To" },
        ];
      case "suggested_category":
      case "pachira_account_uuid":
        return [
          { value: "is", label: "Is" },
          { value: "isNot", label: "Is Not" },
        ];
      default:
        return [];
    }
  };
  const removeCondition = (index) => {
    const newConditions = ruleData.conditions.filter((_, idx) => idx !== index);
    setRuleData({ ...ruleData, conditions: newConditions });
  };

  const handleSave = async () => {
    setIsLoading(true);
    let body = { ...ruleData, conditions: JSON.stringify(ruleData.conditions) };
    try {
      await createRule(body);
      onClose();
      setIsLoading(false);
      setShowModal(false);
      setRuleData(initialRuleData);
    } catch (error) {
      console.error("Error saving rule:", error);
      setIsLoading(false);
    }
  };

  const defaultOnClose = () => {
    onClose();
    setShowModal(false);
    setRuleData(initialRuleData);
  };

  // Use the passed onClose if available, otherwise use the default
  const handleCloseModal = defaultOnClose;

  const renderConditions = () => {
    return (
      <div className="card conditionsCard">
        {ruleData.conditions.map((condition, index) => (
          <div key={index} className="conditionRow">
            <select
              value={condition.target}
              onChange={(e) => handleChange(e, index, "target")}
              className="modalSelect"
            >
              {targetOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            <select
              value={condition.comparison}
              onChange={(e) => handleChange(e, index, "comparison")}
              className="modalSelect"
            >
              {getComparisonOptions(condition.target).map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            {renderValueInput(condition, index)}

            {ruleData.conditions.length > 1 && (
              <button
                onClick={() => removeCondition(index)}
                className="removeButton"
              >
                X
              </button>
            )}
          </div>
        ))}
      </div>
    );
  };
  const renderValueInput = (condition, index) => {
    const { target } = condition;
    switch (target) {
      case "memo":
      case "merchant_name":
        return (
          <input
            type="text"
            value={condition.value}
            onChange={(e) => handleChange(e, index, "value")}
            className="modalInput"
            required
          />
        );
      case "amount":
        return (
          <input
            type="number"
            value={condition.value}
            onChange={(e) => handleChange(e, index, "value")}
            className="modalInput"
            required
          />
        );
      case "suggested_category":
        return (
          <select
            value={condition.value}
            onChange={(e) => handleChange(e, index, "value")}
            className="modalSelect"
            required
          >
            <option value="">Select Suggested Category</option>
            {suggestedCategories.map((categoryName) => (
              <option key={categoryName} value={categoryName}>
                {categoryName}
              </option>
            ))}
          </select>
        );
      case "pachira_account_uuid":
        return (
          <select
            value={condition.value}
            onChange={(e) => handleChange(e, index, "value")}
            className="modalSelect"
            required
          >
            <option value="">Select Account</option>
            {state.accounts.map((account) => (
              <option
                key={account.pachira_account_uuid}
                value={account.pachira_account_uuid}
              >
                {account.name}
              </option>
            ))}
          </select>
        );
      default:
        return null;
    }
  };
  const getSortedAndGroupedCategories = () => {
    const typeOrder = { INC: 1, TRA: 2, EXP: 3 };
    let groupedCategories = [];

    // Filter and sort Level 1 categories by type
    let sortedLevel1Categories = state.categories
      .filter((cat) => cat.level === 1)
      .sort((a, b) => {
        const typeComparison = typeOrder[a.type] - typeOrder[b.type];
        if (typeComparison !== 0) {
          return typeComparison;
        }

        return a.category_name.localeCompare(b.category_name);
      });

    sortedLevel1Categories.forEach((level1Cat) => {
      let subCategories = state.categories
        .filter((cat) => cat.parent_id === level1Cat.category_id)
        .sort((a, b) => a.category_name.localeCompare(b.category_name));

      if (subCategories.length > 0) {
        groupedCategories.push({
          parent: level1Cat,
          children: subCategories,
        });
      }
    });

    return groupedCategories;
  };
  const renderDropdownOptions = () => {
    const groupedCategories = getSortedAndGroupedCategories();

    return groupedCategories.flatMap((group) => {
      return [
        <option
          key={group.parent.category_id}
          value={group.parent.category_id}
          disabled
          className="optionGroup"
        >
          {group.parent.category_name}
        </option>,
        ...group.children.map((subCategory) => (
          <option
            key={subCategory.category_id}
            value={subCategory.category_id}
            className="option"
          >
            {subCategory.category_name}
          </option>
        )),
      ];
    });
  };
  return (
    <div>
      <Modal open={true} onClose={handleCloseModal} title="Add New Rule">
        <div className="modalContent">
          <input
            type="text"
            name="name"
            placeholder="Rule Name"
            value={ruleData.name}
            onChange={handleChange}
            className="modalInput"
            required
          />
          <select
            name="category_id"
            value={ruleData.category_id}
            onChange={handleChange}
            className="modalSelect"
            required
          >
            <option value="">Select Category</option>
            {renderDropdownOptions()}
          </select>
          <div className="conditionsContainer">
            {renderConditions()}
            <button onClick={addCondition} className="whiteButton">
              Add Condition
            </button>
          </div>

          <div className="tabContainer">
            <button
              className={`tabButton ${
                ruleData.auto_add === false ? "tabButtonActive" : ""
              }`}
              onClick={() => setRuleData({ ...ruleData, auto_add: false })}
            >
              Suggested
            </button>
            <button
              className={`tabButton ${
                ruleData.auto_add === true ? "tabButtonActive" : ""
              }`}
              onClick={() => setRuleData({ ...ruleData, auto_add: true })}
            >
              Automatic
            </button>
          </div>

          {isLoading ? (
            <Spinner />
          ) : (
            <button
              onClick={handleSave}
              className="fullLengthButton"
              disabled={!isFormComplete()}
            >
              Save Rule
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};
export default RulesAdd;
