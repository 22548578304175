import React, { createContext, useReducer, useEffect } from "react";

const AppContext = createContext(null);

const initialState = {
  accesstoken: localStorage.getItem("accessToken"),
  userLoading: false,
  userLoaded: false,
  user: {},
  dashboardPreferencesUpdating: false,
  dashboardPreferencesUpdated: false,
  dashboardPreferencesLoaded: false,
  dashboardPreferences: {
    visibility: [
      {
        key: "WidgetTrends",
        isShown: true,
      },
      {
        key: "WidgetAccounts",
        isShown: true,
      },
      {
        key: "WidgetRecentTransactions",
        isShown: true,
      },
      {
        key: "WidgetBudgetCheck",
        isShown: true,
      },
      {
        key: "WidgetSpendingVsIncome",
        isShown: true,
      },
      {
        key: "WidgetMonthlyExpenses",
        isShown: true,
      },
      {
        key: "WidgetFinancialOverview",
        isShown: true,
      },
      {
        key: "WidgetAd",
        isShown: true,
      },
    ],
    layouts: {
      lg: [
        { i: "WidgetTrends", x: 0, y: 0, w: 12, h: 9 },
        { i: "WidgetAccounts", x: 0, y: 9, w: 3, h: 23 },
        { i: "WidgetRecentTransactions", x: 3, y: 9, w: 3, h: 23 },
        { i: "WidgetBudgetCheck", x: 9, y: 9, w: 3, h: 23 },
        { i: "WidgetSpendingVsIncome", x: 6, y: 17, w: 3, h: 15 },
        { i: "WidgetMonthlyExpenses", x: 0, y: 32, w: 12, h: 19 },
        { i: "WidgetFinancialOverview", x: 0, y: 32, w: 12, h: 19 },
        { i: "WidgetAd", x: 6, y: 9, w: 3, h: 8 },
      ],
      md: [
        { i: "WidgetTrends", x: 0, y: 0, w: 8, h: 8 },
        { i: "WidgetAccounts", x: 0, y: 16, w: 3, h: 20 },
        { i: "WidgetRecentTransactions", x: 0, y: 8, w: 5, h: 8 },
        { i: "WidgetBudgetCheck", x: 3, y: 27, w: 5, h: 9 },
        { i: "WidgetSpendingVsIncome", x: 3, y: 16, w: 5, h: 11 },
        { i: "WidgetMonthlyExpenses", x: 0, y: 36, w: 8, h: 13 },
        { i: "WidgetFinancialOverview", x: 0, y: 36, w: 8, h: 13 },
        { i: "WidgetAd", x: 5, y: 8, w: 3, h: 8 },
      ],
      sm: [
        { i: "WidgetTrends", x: 0, y: 0, w: 6, h: 11 },
        { i: "WidgetAccounts", x: 0, y: 28, w: 6, h: 9 },
        { i: "WidgetRecentTransactions", x: 0, y: 46, w: 6, h: 11 },
        { i: "WidgetBudgetCheck", x: 0, y: 11, w: 6, h: 9 },
        { i: "WidgetSpendingVsIncome", x: 0, y: 37, w: 6, h: 9 },
        { i: "WidgetMonthlyExpenses", x: 0, y: 57, w: 6, h: 24 },
        { i: "WidgetFinancialOverview", x: 0, y: 57, w: 6, h: 24 },
        { i: "WidgetAd", x: 0, y: 20, w: 6, h: 8 },
      ],
    },
  },
};

export const ACTION_TYPES = {
  SET_LOADING_USER: "SET_LOADING_USER",
  LOAD_USER: "LOAD_USER",
  UPDATE_USER: "UPDATE_USER",
  LOAD_ACCESS_TOKEN: "LOAD_ACCESS_TOKEN",
  UPDATE_DASHBOARD_PREFERENCES: "UPDATE_DASHBOARD_PREFERENCES",
  UPDATED_DASHBOARD_PREFERENCES: "UPDATED_DASHBOARD_PREFERENCES",
  LOAD_DASHBOARD_PREFERENCES: "LOAD_DASHBOARD_PREFERENCES",
  RESET_STATE: "RESET_STATE",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_LOADING_USER:
      return {
        ...state,
        userLoading: true,
      };
    case ACTION_TYPES.LOAD_USER:
      return {
        ...state,
        // isAuthenticated: true,
        userLoaded: true,
        userLoading: false,
        user: action.payload,
        // accesstoken: action.payload.accesstoken,
      };
    case ACTION_TYPES.UPDATE_USER:
      const updatedUser = {
        ...state.user,
        ...action.payload,
      };
      return {
        ...state,
        user: updatedUser,
      };
    case ACTION_TYPES.LOAD_ACCESS_TOKEN:
      return {
        ...state,
        accesstoken: action.payload,
        // TODO: Include timestamp here for expiration
      };
    case ACTION_TYPES.UPDATE_DASHBOARD_PREFERENCES:
      return {
        ...state,
        dashboardPreferencesUpdating: true,
        dashboardPreferencesUpdated: false,
      };
    case ACTION_TYPES.UPDATED_DASHBOARD_PREFERENCES:
      return {
        ...state,
        dashboardPreferencesUpdating: false,
        dashboardPreferencesUpdated: true,
        dashboardPreferences: {
          ...state.dashboardPreferences,
          ...(action.payload.visibility
            ? { visibility: action.payload.visibility }
            : {}),
          ...(action.payload.layouts
            ? { layouts: action.payload.layouts }
            : {}),
        },
      };
    case ACTION_TYPES.LOAD_DASHBOARD_PREFERENCES:
      const ensureDefaultPreferences = (
        incomingPreferences,
        defaultPreferences
      ) => {
        // Ensure all default visibility keys are present
        const updatedVisibility = [...defaultPreferences.visibility];
        incomingPreferences.visibility.forEach((incomingItem) => {
          const existingItem = updatedVisibility.find(
            (defaultItem) => defaultItem.key === incomingItem.key
          );
          if (existingItem) {
            existingItem.isShown = incomingItem.isShown;
          } else {
            updatedVisibility.push(incomingItem);
          }
        });

        // Ensure all default layouts are present
        const updatedLayouts = { ...defaultPreferences.layouts };
        Object.keys(defaultPreferences.layouts).forEach((layoutSize) => {
          if (!incomingPreferences.layouts[layoutSize]) {
            updatedLayouts[layoutSize] = [
              ...defaultPreferences.layouts[layoutSize],
            ];
          } else {
            const existingLayout = [...defaultPreferences.layouts[layoutSize]];
            incomingPreferences.layouts[layoutSize].forEach(
              (incomingLayout) => {
                const existingItem = existingLayout.find(
                  (defaultItem) => defaultItem.i === incomingLayout.i
                );
                if (existingItem) {
                  Object.assign(existingItem, incomingLayout);
                } else {
                  existingLayout.push(incomingLayout);
                }
              }
            );
            updatedLayouts[layoutSize] = existingLayout;
          }
        });

        return {
          visibility: updatedVisibility,
          layouts: updatedLayouts,
        };
      };
      const newPreferences = ensureDefaultPreferences(
        action.payload,
        state.dashboardPreferences
      );
      return {
        ...state,
        dashboardPreferencesLoaded: true,
        dashboardPreferences: newPreferences,
      };

    case ACTION_TYPES.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  const checkAuthStatus = async () => {
    try {
      // const token = await refreshToken(authDispatch);
      // dispatch({ type: ACTION_TYPES.LOAD_ACCESS_TOKEN, payload: token });
    } catch (error) {
      console.error("Authentication check failed:", error);
    }
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  // Can add a useEffect here to initialize things if the the refresh token is still valid.

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
