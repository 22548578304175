import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIService from "../components/api";

const useAuthCheck = () => {
  const { refreshToken } = APIService();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let isComponentMounted = true;

    const checkAuth = async () => {
      try {
        const response = await refreshToken();
        // THE APP ISELF IS JUST RENDERING FOREVER
        if (isComponentMounted) {
          if (response.status === 200) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
            navigate("/login?logout=true");
          }
        }
      } catch (error) {
        console.error("Error:", error);
        if (isComponentMounted) {
          setIsAuthenticated(false);
          navigate("/login");
        }
      } finally {
        if (isComponentMounted) {
          setIsLoading(false);
        }
      }
    };

    checkAuth();

    return () => {
      isComponentMounted = false;
    };
  }, [navigate]);

  return { isAuthenticated, isLoading }; // Return both isAuthenticated and isLoading states
};

export default useAuthCheck;
