import React, { useState, useEffect, useContext, useMemo } from "react";
import { CurrencyContext } from "../context/currencyContext";
import { DataGrid } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";
import Modal from "../components/modal";
import "../App.css";
import Spinner from "./spinner";
import RulesAdd from "../components/transactionRulesAdd";
import APIService from "./api";
import { AccountsContext } from "../context/AccountsContext";
import { AppContext } from "../context/AppContext";
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MobileTransactionRow from "./MobileTransactionRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsSplitUpAndLeft,
  faFloppyDisk,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

const filterTransactions = (transactions, searchParams) => {
  const filtered = transactions.filter((transaction) => {
    const startDateMatch = searchParams.startDate
      ? new Date(transaction.date) >= new Date(searchParams.startDate)
      : true;
    const endDateMatch = searchParams.endDate
      ? new Date(transaction.date) <= new Date(searchParams.endDate)
      : true;
    const memoMatch = transaction.memo
      ? transaction.memo.toLowerCase().includes(searchParams.memo.toLowerCase())
      : true;
    const merchantMatch =
      transaction.merchant_name === searchParams.merchant ||
      !searchParams.merchant;
    const bankMatch =
      transaction.account_display_name === searchParams.bank ||
      !searchParams.bank;
    const categoryMatch =
      transaction.suggested_category === searchParams.category ||
      !searchParams.category;
    const minAmountMatch = searchParams.minAmount
      ? transaction.amount >= parseFloat(searchParams.minAmount)
      : true;
    const maxAmountMatch = searchParams.maxAmount
      ? transaction.amount <= parseFloat(searchParams.maxAmount)
      : true;

    return (
      startDateMatch &&
      endDateMatch &&
      memoMatch &&
      merchantMatch &&
      categoryMatch &&
      minAmountMatch &&
      maxAmountMatch &&
      bankMatch
    );
  });
  return filtered.map((t) => {
    return { ...t, id: t.pachira_transaction_id };
  });
};

const TransactionsUncategorized = ({ searchParams, topElementsRef }) => {
  const {
    updateTransactionSplit,
    fetchCategoriesContext,
    fetchTransactionsContext,
    fetchUserContext,
    updateBulkTransaction,
    updateTransactionCategory,
  } = APIService();
  const { formatCurrency } = useContext(CurrencyContext);
  const { state, dispatch } = useContext(AccountsContext);
  const { state: appState } = useContext(AppContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [categorySelections, setCategorySelections] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [bulkCategory, setBulkCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    const adjustTableHeight = () => {
      const mainContent = document.querySelector(".main-content");
      if (mainContent) {
        setTableHeight(
          mainContent.offsetHeight - topElementsRef.current.offsetHeight
        );
      }
    };
    adjustTableHeight();
    window.addEventListener("resize", adjustTableHeight);
    return () => window.removeEventListener("resize", adjustTableHeight);
  }, []);

  const filteredTransactions = useMemo(() => {
    const uncategorized = state.transactions.filter(
      (transaction) =>
        !transaction.pachira_category_id && !transaction.category_name
    );
    return filterTransactions(uncategorized, searchParams);
  }, [state.transactions, searchParams]);

  const tableDataLoading = useMemo(() => {
    return (
      !state.transactionsLoaded ||
      !state.categoriesLoaded ||
      !appState.userLoaded
    );
  }, [state.transactionsLoaded, state.categoriesLoaded, appState.userLoaded]);

  const [showSplitModal, setShowSplitModal] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [splitRows, setSplitRows] = useState([
    { category_id: "", amount: "", notes: "" },
    { category_id: "", amount: "", notes: "" },
  ]);
  const [showAddRuleModal, setShowAddRuleModal] = useState(false);
  const [initialConditions, setInitialConditions] = useState({
    category_id: "",
    conditions: [],
  });
  const handleAddRuleClick = (transaction) => {
    const conditions = [
      { target: "memo", comparison: "contains", value: transaction.memo || "" },
      {
        target: "amount",
        comparison: "equals",
        value: transaction.amount.toString(),
      },
      {
        target: "suggested_category",
        comparison: "is",
        value: transaction.suggested_category || "",
      },
      {
        target: "pachira_account_uuid",
        comparison: "is",
        value: transaction.pachira_account_uuid || "",
      },
    ];

    if (transaction.merchant_name) {
      conditions.push({
        target: "merchant_name",
        comparison: "contains",
        value: transaction.merchant_name,
      });
    }

    setInitialConditions(conditions);
    setShowAddRuleModal(true);
  };
  const handleSplitClick = (transaction) => {
    setCurrentTransaction(transaction);
    setSplitRows([
      { category_id: "", amount: "", notes: "" },
      { category_id: "", amount: "", notes: "" },
    ]);
    setShowSplitModal(true);
  };
  const addSplitRow = () => {
    setSplitRows([...splitRows, { category_id: "", amount: "", notes: "" }]);
  };

  const removeSplitRow = (index) => {
    if (splitRows.length > 2) {
      const updatedRows = splitRows.filter((_, idx) => idx !== index);
      setSplitRows(updatedRows);
    }
  };
  const handleSubmitSplits = async () => {
    const totalAmount = parseFloat(
      splitRows
        .reduce((sum, row) => sum + parseFloat(row.amount || 0), 0)
        .toFixed(2)
    );

    if (totalAmount !== parseFloat(currentTransaction.amount)) {
      alert(
        "The total split amounts must equal the original transaction amount."
      );

      return;
    }

    for (const row of splitRows) {
      if (!row.category_id) {
        alert("Each split must have a category selected.");

        return;
      }
    }
    setIsLoading(true);
    const payload = splitRows.map((row) => ({
      pachira_transaction_id: currentTransaction.pachira_transaction_id,
      category_id: row.category_id,
      amount: parseFloat(row.amount).toFixed(2),
    }));

    try {
      await updateTransactionSplit(payload, dispatch);
      setIsLoading(false);
      setShowSplitModal(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating transaction split:", error);
    }
  };

  const handleSplitCategoryChange = (e, index) => {
    const updatedRows = splitRows.map((row, idx) =>
      idx === index ? { ...row, category_id: e.target.value } : row
    );
    setSplitRows(updatedRows);
  };
  const handleSplitNotesChange = (e, index) => {
    const updatedRows = splitRows.map((row, idx) =>
      idx === index ? { ...row, notes: e.target.value } : row
    );
    setSplitRows(updatedRows);
  };

  const handleSplitAmountChange = (e, index) => {
    const inputAmount = parseFloat(e.target.value);
    const transactionAmount = currentTransaction?.amount;

    // Ensure the input value matches the sign of the transaction amount
    const adjustedAmount =
      transactionAmount >= 0 ? Math.abs(inputAmount) : -Math.abs(inputAmount);

    const updatedRows = splitRows.map((row, idx) =>
      idx === index ? { ...row, amount: adjustedAmount } : row
    );
    setSplitRows(updatedRows);
  };
  const calculateTotal = () => {
    return splitRows
      .reduce((acc, curr) => acc + (parseFloat(curr.amount) || 0), 0)
      .toFixed(2);
  };
  const calculateDifference = () => {
    return splitRows
      .slice(0, -1)
      .reduce((acc, curr) => acc + (parseFloat(curr.amount) || 0), 0);
  };
  const handleBulkAddClick = () => {
    setShowModal(true);
  };
  const handleSplitAmountBlur = () => {
    const totalAmount = calculateDifference();
    const remainingAmount = currentTransaction.amount - totalAmount;

    if (splitRows.length > 1) {
      const updatedRows = [...splitRows];
      updatedRows[updatedRows.length - 1].amount = remainingAmount.toFixed(2);
      setSplitRows(updatedRows);
    }
  };

  const handleModalSubmit = async () => {
    const transactionsToUpdate = selectedRows.map((row) => ({
      pachira_transaction_id: row,
    }));
    try {
      await updateBulkTransaction(transactionsToUpdate, bulkCategory);

      setShowModal(false);
      // Handle successful update (e.g., refresh data or show success message)
    } catch (e) {
      console.error("Error in bulk update:", e);
    }
  };

  const handleRowSelection = (newSelection) => {
    setSelectedRows(newSelection);
  };

  useEffect(() => {
    if (!state.categoriesLoaded && !state.categoriesLoading) {
      fetchCategoriesContext();
    }
    if (!state.transactionsLoaded && !state.transactionsLoading) {
      fetchTransactionsContext();
    }
    if (!appState.userLoaded && !appState.userLoading) {
      fetchUserContext();
    }
  }, [
    appState.userLoaded,
    appState.userLoading,
    fetchCategoriesContext,
    fetchTransactionsContext,
    fetchUserContext,
    state.categoriesLoaded,
    state.categoriesLoading,
    state.transactionsLoaded,
    state.transactionsLoading,
  ]);
  const getSortedAndGroupedCategories = () => {
    const typeOrder = { INC: 1, TRA: 2, EXP: 3 };
    let groupedCategories = [];

    // Filter and sort Level 1 categories by type
    let sortedLevel1Categories = state.categories
      .filter((cat) => cat.level === 1)
      .sort((a, b) => {
        const typeComparison = typeOrder[a.type] - typeOrder[b.type];
        if (typeComparison !== 0) {
          return typeComparison;
        }
        return a.category_name.localeCompare(b.category_name);
      });
    sortedLevel1Categories.forEach((level1Cat) => {
      let subCategories = state.categories
        .filter((cat) => cat.parent_id === level1Cat.category_id)
        .sort((a, b) => a.category_name.localeCompare(b.category_name));

      if (subCategories.length > 0) {
        groupedCategories.push({
          parent: level1Cat,
          children: subCategories,
        });
      }
    });

    return groupedCategories;
  };
  const renderDropdownOptions = () => {
    const groupedCategories = getSortedAndGroupedCategories();

    return groupedCategories.flatMap((group) => {
      return [
        <option
          key={group.parent.category_id}
          value={group.parent.category_id}
          disabled
          className="optionGroup"
        >
          {group.parent.category_name}
        </option>,
        ...group.children.map((subCategory) => (
          <option
            key={subCategory.category_id}
            value={subCategory.category_id}
            className="option"
          >
            {subCategory.category_name}
          </option>
        )),
      ];
    });
  };

  const CategoryDropdown = ({
    selectedCategoryId,
    onCategoryChange,
    defaultCategory,
    userSuggestedCategory,
  }) => {
    const isSelectedOrSuggested = selectedCategoryId || userSuggestedCategory;
    useEffect(() => {
      if (!selectedCategoryId && userSuggestedCategory) {
        onCategoryChange(userSuggestedCategory);
      }
    }, [userSuggestedCategory, selectedCategoryId, onCategoryChange]);
    return (
      <select
        className={`input dropdown ${
          isSelectedOrSuggested ? "selectedOrSuggested" : ""
        }`}
        style={{
          flex: "1",
          backgroundColor: isSelectedOrSuggested ? "#001e80" : "white",
          color: isSelectedOrSuggested ? "white" : "black",
          width: "100%",
        }}
        value={
          selectedCategoryId || userSuggestedCategory || defaultCategory || ""
        }
        onChange={(e) => onCategoryChange(e.target.value)}
      >
        <option value="">{defaultCategory || "Select Category"}</option>

        {renderDropdownOptions()}
      </select>
    );
  };

  const handleCategoryChange = (transactionId, newCategoryId) => {
    setCategorySelections({
      ...categorySelections,
      [transactionId]: newCategoryId,
    });
  };
  const AddRuleButton = ({ rowData }) => {
    const isCategoryChanged =
      categorySelections[rowData.id] !== rowData.suggested_category;
    return (
      <Button
        variant="outlined"
        sx={{ opacity: isCategoryChanged ? 1 : 0.5, px: 1, py: 0, minWidth: 0 }}
        onClick={() => isCategoryChanged && handleAddRuleClick(rowData)}
        disabled={!isCategoryChanged}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <FontAwesomeIcon icon={faPlus} color={theme.palette.primary.main} />
          <Typography variant="button">Rule</Typography>
        </Stack>
      </Button>
    );
  };
  const SplitButton = ({ rowData }) => {
    const isCategoryChanged =
      categorySelections[rowData.id] !== rowData.suggested_category;
    return (
      <Button
        variant="outlined"
        sx={{
          opacity: isCategoryChanged ? 1 : 0.5,
          px: 1,
          py: 0,
          minWidth: 0,
        }}
        onClick={() => isCategoryChanged && handleSplitClick(rowData)}
        disabled={!isCategoryChanged}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <FontAwesomeIcon
            icon={faArrowsSplitUpAndLeft}
            color={theme.palette.primary.main}
          />
          <Typography variant="button">Split</Typography>
        </Stack>
      </Button>
    );
  };

  const AddButton = ({ rowData }) => {
    const selectedCategory = categorySelections[rowData.id];
    const isDefaultCategory = !selectedCategory;
    const user_suggested_category_id = rowData.user_suggested_category_id;
    const isUserCategory = !user_suggested_category_id;
    const enableButton = !isDefaultCategory || !isUserCategory;
    return (
      <Button
        variant="contained"
        onClick={() => enableButton && handleEditClick(rowData)}
        disabled={!enableButton}
        sx={{
          backgroundColor: theme.palette.primary.main,
          px: 1,
          py: 0,
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <FontAwesomeIcon icon={faFloppyDisk} color="white" />
          <Typography variant="button">Save</Typography>
        </Stack>
      </Button>
    );
  };

  const RowCategoryDropdown = ({ rowData }) => {
    return (
      <CategoryDropdown
        selectedCategoryId={categorySelections[rowData.id]}
        onCategoryChange={(newCategoryId) =>
          handleCategoryChange(rowData.id, newCategoryId)
        }
        defaultCategory={rowData.suggested_category}
        userSuggestedCategory={rowData.user_suggested_category_id}
      />
    );
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      maxWidth: 200,

      renderCell: (params) => format(parseISO(params.value), "PPP"),
      sortComparator: (v1, v2, param1, param2) => {
        return (
          new Date(param1.value).getTime() - new Date(param2.value).getTime()
        );
      },
    },
    {
      field: "memo",
      headerName: "Memo",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "merchant_name",
      headerName: "Merchant",
      flex: 2,
      minWidth: 100,
      maxWidth: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.merchant_logo && (
            <img
              src={params.row.merchant_logo}
              alt={params.value}
              style={{ marginRight: 8, width: "30px", height: "30px" }}
            />
          )}
          {params.value}
        </div>
      ),
    },
    {
      field: "account_display_name",
      headerName: "Bank",
      flex: 2,
      minWidth: 100,
      maxWidth: 200,
    },
    {
      field: "suggested_category",
      headerName: "Select Category",
      flex: 1,
      minWidth: 100,
      maxWidth: 200,
      renderCell: (params) => {
        return <RowCategoryDropdown rowData={params.row} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      flex: 1,
      minWidth: 100,
      maxWidth: 100,
      renderCell: ({ value }) => {
        const amount = parseFloat(value);
        const isNegative = amount < 0;
        return (
          <span style={{ color: isNegative ? "red" : "green" }}>
            {formatCurrency(amount)}
          </span>
        );
      },
    },
    {
      field: "rule",
      headerName: "Add Rule",
      renderCell: (params) => {
        return <AddRuleButton rowData={params.row} />;
      },
      sortable: false,
    },
    {
      field: "split",
      headerName: "Split",
      renderCell: (params) => {
        return <SplitButton rowData={params.row} />;
      },
      sortable: false,
    },
    {
      field: "add",
      headerName: "Add",
      renderCell: (params) => {
        return <AddButton rowData={params.row} />;
      },
      sortable: false,
    },
  ];

  const handleEditClick = async (transaction) => {
    const updatedCategoryId = categorySelections[transaction.id];

    if (updatedCategoryId) {
      const updatedTransaction = {
        ...transaction,
        category_id: updatedCategoryId,
      };

      try {
        await updateTransactionCategory(updatedTransaction);
        const categoryId = categorySelections[transaction.id];

        const categoryObject = state.categories.find(
          (category) => category.category_id === parseInt(categoryId)
        );

        setBulkCategory(categoryObject);
      } catch (e) {
        console.error("Error updating transaction:", e);
      }
    }
  };

  return (
    <div>
      {selectedRows.length > 1 && (
        <div
          className={`bulkAddContainer ${
            selectedRows.length > 1 ? "bulkAddContainerVisible" : ""
          }`}
        >
          <button className="blueButton" onClick={handleBulkAddClick}>
            Bulk Add
          </button>
        </div>
      )}
      <div className="tableContainer">
        <div className="gridContainer" style={{ height: tableHeight }}>
          {isMobile ? (
            tableDataLoading ? (
              <Spinner />
            ) : (
              <Stack>
                {filteredTransactions.map((transactionRow) => {
                  return (
                    <MobileTransactionRow
                      rowData={transactionRow}
                      actionButtons={
                        <Stack spacing={1}>
                          <RowCategoryDropdown rowData={transactionRow} />
                          <AddButton rowData={transactionRow} />
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                          >
                            <AddRuleButton rowData={transactionRow} />
                            <SplitButton rowData={transactionRow} />
                          </Stack>
                        </Stack>
                      }
                    />
                  );
                })}
              </Stack>
            )
          ) : (
            <DataGrid
              rows={filteredTransactions}
              columns={columns}
              pageSize={100}
              loading={tableDataLoading}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={handleRowSelection}
            />
          )}
        </div>
      </div>

      {showModal && (
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          title="Select Category for Bulk Add"
        >
          <div className="modalContent">
            <select
              value={bulkCategory}
              onChange={(e) => setBulkCategory(e.target.value)}
              className="modalSelect"
            >
              <option value="">Select Category</option>
              {renderDropdownOptions()}
            </select>
            <button className="fullLengthButton" onClick={handleModalSubmit}>
              Submit
            </button>
          </div>
        </Modal>
      )}
      {showSplitModal && (
        <Modal
          open={showSplitModal}
          onClose={() => setShowSplitModal(false)}
          title="Split Transaction"
        >
          <div className="modalContent">
            <p>
              Transaction Memo: {currentTransaction?.memo}
              <br />
              <br />
              Transaction Amount: {currentTransaction?.amount}
            </p>
            <div className="card">
              <table className="table">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Amount</th>
                    <th>Notes</th>
                    <th style={{ width: "30px" }}> </th>{" "}
                  </tr>
                </thead>
                <tbody>
                  {splitRows.map((split, index) => (
                    <tr key={index}>
                      <td>
                        <select
                          value={split.category_id}
                          onChange={(e) => handleSplitCategoryChange(e, index)}
                          className="modalSelect2"
                        >
                          <option value="">Select Category</option>
                          {renderDropdownOptions()}
                        </select>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={split.amount}
                          onChange={(e) => handleSplitAmountChange(e, index)}
                          className="modalInput"
                          onBlur={() => handleSplitAmountBlur()}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={split.notes}
                          onChange={(e) => handleSplitNotesChange(e, index)}
                          className="modalInput"
                        />
                      </td>
                      <td>
                        {splitRows.length > 2 && (
                          <button
                            className="removeButton"
                            onClick={() => removeSplitRow(index)}
                            aria-label="Remove row"
                          >
                            X
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="tableFooter">
                    <td>Total</td>
                    <td>{calculateTotal()}</td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <button className="whiteButton" onClick={addSplitRow}>
              Add Split
            </button>
            {isLoading ? (
              <Spinner />
            ) : (
              <button className="fullLengthButton" onClick={handleSubmitSplits}>
                Submit
              </button>
            )}
          </div>
        </Modal>
      )}
      {showAddRuleModal && (
        <RulesAdd
          isOpen={showAddRuleModal}
          onClose={() => setShowAddRuleModal(false)}
          initialConditions={initialConditions}
          // initialCategoryId={}
        />
      )}
    </div>
  );
};

export default TransactionsUncategorized;
