import React, { useEffect, useContext, useMemo } from "react";
import "../App.css";
import { CurrencyContext } from "../context/currencyContext";
import APIService from "./api";
import { AccountsContext } from "../context/AccountsContext";

const WidgetAccounts = () => {
  const { fetchAccountsContext } = APIService();

  const { formatCurrency } = useContext(CurrencyContext);
  const { state } = useContext(AccountsContext);

  const sortedAccounts = useMemo(() => {
    return state.accounts.sort((a, b) => {
      if (a.type !== b.type) {
        return a.type === "asset" ? -1 : 1;
      }

      if (a.is_liquid !== b.is_liquid) {
        return a.is_liquid ? -1 : 1;
      }

      return a.name.localeCompare(b.name);
    });
  }, [state.accounts]);

  useEffect(() => {
    if (!state.accountsLoaded && !state.accountsLoading) {
      fetchAccountsContext();
    }
  }, [fetchAccountsContext, state.accountsLoaded, state.accountsLoading]);

  const totalBalance = sortedAccounts.reduce(
    (sum, account) => sum + parseFloat(account.balance_current),
    0
  );
  return (
    <div className="widgetContainer">
      <h3 className="header">Account Balances</h3>
      <div className="accountItem totalBalance">
        <span>Total Balance:</span>
        <span key={"Total"} style={getColorStyle(totalBalance)}>
          {formatCurrency(totalBalance)}
        </span>
      </div>
      <ul className="accountList">
        {sortedAccounts.map((account) => (
          <li key={account.pachira_account_uuid} className="accountItem">
            <span>{account.name}</span>
            <span style={getColorStyle(account.balance_current)}>
              {formatCurrency(account.balance_current)}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const getColorStyle = (balance) => ({
  color: balance < 0 ? "red" : "green",
});

export default WidgetAccounts;
