import React, { Component } from "react";
import { AccountsContext } from "../context/AccountsContext";

class AdSenseAdAuto extends Component {
  static contextType = AccountsContext;
  constructor(props) {
    super(props);
    this.state = {
      width: "99%",
      height: "100%",
    };
    this.adRef = React.createRef();
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver((entries) => {
      const parentHeight = this.adRef.current.parentNode.offsetHeight;
      if (`${parentHeight - 12}px` !== this.state.height) {
        this.setState({ height: `${parentHeight}px` });
      }
    });
    setTimeout(() => {
      const { state } = this.context;
      if (this.adRef.current) {
        this.resizeObserver.observe(this.adRef.current.parentNode);

        if (
          this.adRef.current.offsetWidth >= 120 &&
          this.adRef.current.parentNode.offsetWidth >= 120 &&
          !state.subscriptions.some(
            (subscription) =>
              subscription.donation === false &&
              (subscription.is_active === true ||
                new Date(subscription.next_payment_date) >=
                  new Date().setHours(0, 0, 0, 0))
          )
        ) {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } else {
          console.log("Ad not loaded: Container width less than 120 pixels.");
        }
      }
    }, 500);
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  render() {
    const { state } = this.context;

    if (
      state.subscriptions.some(
        (subscription) =>
          subscription.donation === false &&
          (subscription.is_active === true ||
            new Date(subscription.next_payment_date) >=
              new Date().setHours(0, 0, 0, 0))
      )
    ) {
      return null;
    }
    return (
      <div
        ref={this.adRef}
        style={{
          border: "1px solid black",
          backgroundColor: "white",
          textAlign: "center",
          marginBottom: "10px",
          borderRadius: "10px",
          overflow: "hidden",
          width: this.state.width,
          height: this.state.height,
          flexDirection: "column", // Stack children vertically
          justifyContent: "center", // Center items vertically if there's extra space
          alignItems: "center", // Center items horizontally
        }}
      >
        <div
          style={{
            fontSize: ".7rem",
            padding: "3px",
            color: "black",
          }}
        >
          This Is A Paid Ad
        </div>
        <ins
          className="adsbygoogle"
          style={{
            display: "inline-block",
            width: "300px",
            height: "250px",
          }}
          data-ad-client="ca-pub-9738518352357622"
          data-ad-slot="2871723614"
        ></ins>
      </div>
    );
  }
}

export default AdSenseAdAuto;
